import { useState } from "react";
import { useRescheduleManyEmailCampaignItemsMutation } from "generated/graphql";
import moment from "moment";
import Button from "components/common/Button";
import {
  ModalOverlay,
  Modal,
  ModalBody,
  ModalTitle,
  ButtonArea,
} from "components/common/GrowlabModal";
import { message, TimePicker } from "antd";
import Text from "components/text/Text";
import { DatePicker } from "antd";
import FormItem from "components/common/FormItem";
import styled from "styled-components";

const PickerWrapper = styled.div`
  z-index: 15000;
  position: relative;
`;

export default function RescheduleManyModal({
  isVisible,
  onClose,
  emailCampaignId,
}: {
  isVisible: boolean;
  onClose: () => void;
  emailCampaignId: string;
}) {
  const [rescheduleManyEmailCampaignItemsMutation, { loading }] =
    useRescheduleManyEmailCampaignItemsMutation();

  const [newScheduledAt, setNewScheduledAt] = useState<number | null>(null);

  const handleDateTimeChange = (
    date: moment.Moment | null,
    time: moment.Moment | null
  ) => {
    const now = moment();
    const dateObj = date || now;
    const timeObj = time || now;

    if (dateObj && timeObj) {
      const combinedDateTime = moment(
        dateObj.format("YYYY-MM-DD") + " " + timeObj.format("HH:mm:ss")
      );

      console.log("scheduledAt", combinedDateTime.valueOf());
      setNewScheduledAt(combinedDateTime.valueOf());
    }
  };

  const disabledTime = (date: moment.Moment) => {
    if (date && date.isSame(moment(), "day")) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === currentHour
            ? Array.from({ length: currentMinute }, (_, i) => i)
            : [],
      };
    }
    return {};
  };

  const disabledDate = (current: moment.Moment) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <Modal>
        <ModalBody>
          <ModalTitle style={{ marginBottom: 48 }}>
            Reschedule All Failed Emails
          </ModalTitle>
          <FormItem label="Reschedule for:">
            <Text>
              This will reschedule all failed emails from this campaign based on
              the time you select below.{" "}
            </Text>
          </FormItem>

          <FormItem
            label="New Send Date and Time"
            required
            containerProps={{ style: { marginTop: 32 } }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <PickerWrapper style={{ flex: 1 }}>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date) =>
                    handleDateTimeChange(
                      date,
                      newScheduledAt
                        ? (moment(
                            newScheduledAt
                          ).local() as any as moment.Moment)
                        : null
                    )
                  }
                  value={
                    newScheduledAt ? (moment(newScheduledAt) as any) : null
                  }
                  disabledDate={disabledDate}
                />
              </PickerWrapper>
              <PickerWrapper style={{ flex: 1 }}>
                <TimePicker
                  style={{ width: "100%" }}
                  format="hh:mm a"
                  use12Hours
                  onChange={(time) =>
                    handleDateTimeChange(
                      newScheduledAt ? moment(newScheduledAt) : null,
                      time
                    )
                  }
                  value={
                    newScheduledAt ? (moment(newScheduledAt) as any) : null
                  }
                  disabledTime={disabledTime}
                />
              </PickerWrapper>
            </div>
          </FormItem>
        </ModalBody>{" "}
        <ButtonArea>
          <Button key="cancel" onClick={onClose} grey>
            Cancel
          </Button>
          <Button
            key="reschedule"
            loading={loading}
            disabled={!newScheduledAt || loading}
            onClick={async () => {
              try {
                if (emailCampaignId && newScheduledAt) {
                  await rescheduleManyEmailCampaignItemsMutation({
                    variables: { emailCampaignId, scheduledAt: newScheduledAt },
                  });
                }
                message.success("Your email campaign is being rescheduled");
                setNewScheduledAt(null);
                onClose();
              } catch (err: any) {
                console.log(err);
                message.error(err?.message);
              }
            }}
          >
            Reschedule
          </Button>
        </ButtonArea>
      </Modal>
    </ModalOverlay>
  );
}
