import { LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Caption from "components/text/Caption";
import Loading from "components/common/Loading";
import message from "components/common/message";
import ObjectPermissionsInput from "components/common/ObjectPermissionsInput";
import {
  ObjectPermissionAccessEnum,
  ObjectPermissionFragmentFragment,
  ObjectPermissionSubjectTypeEnum,
  ObjectTypeEnum,
  UpdateArrayActionEnum,
  UserGroupFragmentFragment,
  useGetManyObjectPermissionsQuery,
  useGetManyUserGroupsQuery,
  useUpdateOneObjectsPermissionsMutation,
} from "generated/graphql";

const ObjectPermissionsModalContainer = styled.div`
  width: 500px;
  min-height: 100px;
  max-width: 100%;
`;

export default function ObjectPermissionsManager({
  objectType,
  id,
}: {
  id: string;
  objectType: ObjectTypeEnum;
}) {
  const { data, loading } = useGetManyObjectPermissionsQuery({
    variables: {
      type: objectType,
      id,
    },
  });

  const { data: userGroupsQuery } = useGetManyUserGroupsQuery({
    variables: {
      not: data?.getManyObjectPermissions?.map((perm) => perm?.subject?.id),
      includeCustomerDefault: true,
    },
    skip: loading && !data?.getManyObjectPermissions?.[0],
  });

  const [updateOneObjectsPermissionsMutation] =
    useUpdateOneObjectsPermissionsMutation();

  if (loading)
    return (
      <ObjectPermissionsModalContainer>
        <Loading height={100} />
      </ObjectPermissionsModalContainer>
    );

  const onRemove = async (permission: ObjectPermissionFragmentFragment) => {
    try {
      await updateOneObjectsPermissionsMutation({
        variables: {
          type: objectType,
          id: id,
          action: UpdateArrayActionEnum.Remove,
          permission: {
            subjectId: permission?.subjectId,
          },
        },
        refetchQueries: ["getManyObjectPermissions"],
      });
      message.success(`${permission?.subject?.title} Removed`);
    } catch (err) {
      console.log(err);
    }
  };

  const onAdd = async (group: UserGroupFragmentFragment) => {
    try {
      await updateOneObjectsPermissionsMutation({
        variables: {
          type: objectType,
          id: id,
          action: UpdateArrayActionEnum.Add,
          permission: {
            subjectId: group?.id,
            subjectType: ObjectPermissionSubjectTypeEnum.Group,
            access: [
              ObjectPermissionAccessEnum.Edit,
              ObjectPermissionAccessEnum.View,
            ],
          },
        },
        refetchQueries: ["getManyObjectPermissions"],
      });
      message.success(`${group?.title} Added`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ObjectPermissionsModalContainer>
      <Caption style={{ marginBottom: 16 }}>
        <LockOutlined style={{ marginRight: 4 }} />
        Restrict which groups have access to this record
      </Caption>
      <ObjectPermissionsInput
        permissions={data?.getManyObjectPermissions || []}
        availableGroups={userGroupsQuery?.getManyUserGroups || []}
        onRemove={onRemove}
        onAdd={onAdd}
      />
    </ObjectPermissionsModalContainer>
  );
}
