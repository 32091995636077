import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
// COMPONENTS

// APOLLO
import { Draggable } from "react-beautiful-dnd";
import BusinessLogo from "components/common/BusinessLogo";

const CardContainer = styled.div`
  background: #fff;
  max-width: 100%;
  min-height: 100px;
  cursor: pointer;
  padding: 16px;
  border-radius: 5px;
  margin-top: 8px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  &:hover {
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
`;

const CardTitle = styled.h3`
  color: ${(p) => p.theme.colors.neutral3};
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  cursor: pointer !important;
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const CardCaption = styled.p`
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 14px;
`;

export default function ProjectCard({ project, index }) {
  const navigate = useNavigate();
  let logo =
    project &&
    project.businesses &&
    project.businesses[0] &&
    project.businesses[0].logo &&
    project.businesses[0].logo.url;
  return (
    <Draggable draggableId={project.id} index={index}>
      {(provided, snapshot) => {
        return (
          <CardContainer
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            data-testid="draggable-project-card"
          >
            <CardTitle
              onClick={() => navigate(`/app/projects/${project.id}`)}
              data-testid="project-card-title"
            >
              {project.title}
            </CardTitle>
            <CardCaption>
              ${numeral(project.amount).format("0,0") || "0"}
            </CardCaption>
            {logo && (
              <BusinessLogo
                src={logo}
                alt="test"
                width="24"
                style={{ borderRadius: "50%" }}
              />
            )}
          </CardContainer>
        );
      }}
    </Draggable>
  );
}
