/**
 * @index.js
 * EmailCampaignTestEmailModal
 *
 * This component allows users to preview what their email template will look like
 * using example contact or business data. It provides options to send a test email
 * to either a selected business or contact, helping users visualize the final output
 * before sending the actual campaign.
 */

import { useState, useEffect } from "react";
import { Radio, Typography, Modal, Alert } from "antd";
import styled from "styled-components";
import Button from "components/common/Button";
import BusinessInput from "components/inputs/BusinessInput";
import Caption from "components/text/Caption";
import config from "lib/config";
import { useBusinessByIdQuery, useContactByIdQuery } from "generated/graphql";
import ContactInputSimple from "components/inputs/ContactInputSimple";
import Text from "components/text/Text";

const { Title, Paragraph } = Typography;

const PreviewContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #d9d9d9;
  padding: 16px;
  background-color: #f5f5f5;
`;

const BASE_UNSUBSCRIBE_URL = `${config.SITE_URL}/actions/email-unsubscribe`;

interface SpecialTag {
  name: string;
  defaultValue: string;
  getValue: (params: {
    toEmail: string;
    customerId: string;
    business?: any;
    contact?: any;
  }) => Promise<string | undefined>;
}

const specialTags: SpecialTag[] = [
  {
    name: "unsubscribe_url",
    defaultValue: "",
    getValue: async ({ customerId, toEmail }) =>
      `${BASE_UNSUBSCRIBE_URL}?customerId=${customerId}&email=${encodeURIComponent(
        toEmail
      )}`,
  },
  {
    name: "contact_first_name",
    defaultValue: "",
    getValue: async ({ contact }) => contact?.firstName,
  },
  {
    name: "business_name",
    defaultValue: "",
    getValue: async ({ business }) => business?.title,
  },
];

const replaceSpecialTags = async ({
  emailString,
  toEmail,
  customerId,
  business,
  contact,
}: {
  emailString: string;
  toEmail: string;
  customerId: string;
  contact?: any;
  business?: any;
}): Promise<string> => {
  let newBody = emailString;

  const regex = /{{(.*?)}}/g;
  const matches = newBody.match(regex) || [];

  for (const match of matches) {
    const tagContent = match.slice(2, -2).trim();
    const tagParts = tagContent.split("||").map((part) => part.trim());

    let replacement = "";

    for (const part of tagParts) {
      if (part.startsWith("default:")) {
        replacement = part.split(":")[1].trim().replace(/^"|"$/g, "");
        break;
      }

      const tag = specialTags.find((t) => t.name === part);
      if (tag) {
        const value = await tag.getValue({
          customerId,
          toEmail,
          business,
          contact,
        });
        if (value) {
          replacement = value;
          break;
        }
      }
    }

    newBody = newBody.replace(match, replacement);
  }

  return newBody;
};

const EmailCampaignTestEmailModal = ({ emailCampaign }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendTo, setSendTo] = useState("business");
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [previewSubject, setPreviewSubject] = useState<string | null>(null);

  const { data: businessData } = useBusinessByIdQuery({
    variables: { id: selectedBusiness ?? "" },
    skip: !selectedBusiness,
  });

  const { data: contactData } = useContactByIdQuery({
    variables: { id: selectedContact ?? "" },
    skip: !selectedContact,
  });

  const businessContact =
    (selectedBusiness && businessData?.businessById?.primaryContact) ??
    businessData?.businessById?.contacts?.[0];

  const contactBusiness =
    selectedContact && contactData?.contactById?.businesses?.[0];

  useEffect(() => {
    if (isModalVisible) {
      const updatePreview = async () => {
        const recipient =
          sendTo === "business"
            ? businessData?.businessById
            : contactData?.contactById;

        if (recipient && emailCampaign) {
          let contact = sendTo === "contact" ? recipient : undefined;
          let business = sendTo === "business" ? recipient : undefined;
          if (sendTo === "business") {
            contact = businessContact;
          }
          if (sendTo === "contact" && contactBusiness) {
            business = contactBusiness;
          }

          const previewContent = await replaceSpecialTags({
            emailString: emailCampaign.emailTemplate ?? "",
            toEmail: recipient.email || "",
            customerId: recipient.id,
            business,
            contact,
          });
          setPreviewHtml(previewContent);

          const previewSubject = await replaceSpecialTags({
            emailString: emailCampaign.subject ?? "",
            toEmail: recipient.email || "",
            customerId: recipient.id,
            business,
            contact,
          });
          setPreviewSubject(previewSubject);
        }
      };

      updatePreview();
    }
  }, [
    isModalVisible,
    selectedBusiness,
    selectedContact,
    sendTo,
    businessData,
    contactData,
    emailCampaign,
    businessContact,
    contactBusiness,
  ]);

  const showModal = () => {
    handleClearValues();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    handleClearValues();
  };

  const handleClearValues = () => {
    setSelectedBusiness(null);
    setSelectedContact(null);
    setPreviewHtml(null);
    setPreviewSubject(null);
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          bottom: 0,
          position: "absolute",
          minWidth: 100,
          right: 220,
        }}
        grey
      >
        Preview Email
      </Button>
      <Modal
        title="Preview Email"
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <Button key="back" grey onClick={handleCancel}>
            Close
          </Button>,
        ]}
        width={600}
      >
        <Caption style={{ marginBottom: 32 }}>
          Use this preview to see how your email will look before sending it.
          You can send a test email to a business or contact to ensure
          everything appears as intended. This helps you catch any formatting
          issues or personalization errors before launching your campaign.
        </Caption>
        <Radio.Group
          onChange={(e) => {
            setSendTo(e.target.value);
            handleClearValues();
          }}
          value={sendTo}
          style={{ marginBottom: 16 }}
        >
          <Radio value="business">Send to Business</Radio>
          <Radio value="contact">Send to Contact</Radio>
        </Radio.Group>

        {sendTo === "business" ? (
          <>
            <BusinessInput
              onChange={(value) => setSelectedBusiness(value)}
              canCreateNew={false}
            />
            {businessContact && (
              <Alert
                style={{ marginTop: 8 }}
                type="info"
                message={
                  <>
                    <Text>
                      <strong>
                        {" "}
                        We will use a contact of the business as the recipeint
                        for this email to {businessData?.businessById?.title}:
                      </strong>
                    </Text>
                    <Text>
                      {businessContact?.firstName} {businessContact?.lastName} (
                      {businessContact?.email})
                    </Text>
                  </>
                }
              />
            )}
          </>
        ) : (
          <>
            {" "}
            <ContactInputSimple
              onChange={(value) => setSelectedContact(value)}
            />{" "}
            {contactBusiness && (
              <Alert
                style={{ marginTop: 8 }}
                type="info"
                message={
                  <>
                    <Text>
                      <strong>
                        {" "}
                        We will use the business associated with this contact
                        for any business template variables:
                      </strong>
                    </Text>
                    <Text>
                      {contactBusiness?.title} (associated with{" "}
                      {contactData?.contactById?.firstName}{" "}
                      {contactData?.contactById?.lastName})
                    </Text>
                  </>
                }
              />
            )}
          </>
        )}

        <PreviewContainer>
          <Title level={4}>Email Preview</Title>
          <Paragraph>
            <strong>Subject:</strong>{" "}
            {previewSubject || emailCampaign?.subject || "No subject available"}
          </Paragraph>
          <Paragraph>
            <strong>Body:</strong>
          </Paragraph>
          <div
            dangerouslySetInnerHTML={{
              __html:
                previewHtml ||
                emailCampaign?.emailTemplate ||
                "No body available",
            }}
          />
        </PreviewContainer>
      </Modal>
    </>
  );
};

export default EmailCampaignTestEmailModal;
