import {
  CurrentUserFragmentFragment,
  ListTypeEnum,
  SurveyTemplateNewFragmentFragment,
  useGetManyDefaultSurveyTemplatesQuery,
  useGetManySurveyCampaignsQuery,
  useGetManySurveyTemplatesNewQuery,
} from "generated/graphql";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import message from "components/common/message";
import Row from "components/common/Row";
import Col from "components/common/Col";
import PageContainer from "components/common/PageContainer";
import Tooltip from "components/common/Tooltip";

import {
  DotChartOutlined,
  ShareAltOutlined,
  EditOutlined,
  CloseOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import PageHeader from "components/text/PageHeader";

import { DefaultPageProps } from "lib/@types/DefaultPageProps";
import { BooleanParam, useQueryParam } from "use-query-params";
import { ModalBody, ModalOverlay } from "components/common/GrowlabModal";

import Title from "components/text/Title";
import useClipboardHook from "../../lib/hooks/useClipboard";
import { Tabs } from "antd";
import AppSurveyCampaigns from "routes/app-survey-campaigns";
import Loading from "components/common/Loading";

const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
  margin: 8px;
`;

const EmptyCard = styled.button`
  padding: 0px;
  width: 100%;
  border-radius: 8px;
  margin: 8px;
  background: transparent;
  height: 170px;
  border: 2px dotted ${({ theme }) => theme.colors.primary7};
  background: ${({ theme }) => theme.colors.primary10};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary2};
  transition:
    color 0.3s ease,
    background-color 0.3s ease,
    border-color 0.3s ease,
    width 0.3s ease,
    opacity 0.3s ease;
  &:hover {
    border: 2px dotted ${({ theme }) => theme.colors.primary6};
    background: ${({ theme }) => theme.colors.primary9};
    cursor: pointer;
  }
`;

const TemplateCard = styled(EmptyCard)`
  border: 2px dotted ${({ theme }) => theme.colors.neutral8};
  background: ${({ theme }) => theme.colors.neutral10};
  color: ${({ theme }) => theme.colors.neutral2};
  &:hover {
    border: 2px dotted ${({ theme }) => theme.colors.neutral6};
    background: ${({ theme }) => theme.colors.neutral9};
  }
`;

const CardHeader = styled.div`
  height: 120px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  padding: 24px;
`;

const CardFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral9};
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Button = styled.button`
  flex: 1;
  height: 48px;
  border: 0px;
  font-weight: 500;
  background: transparent;
  color: ${({ theme }) => theme.colors.neutral4};
  &:hover {
    background: ${({ theme }) => theme.colors.neutral11};
    cursor: pointer;
  }
`;

const ButtonLeft = styled(Button)`
  border-right: 1px solid ${({ theme }) => theme.colors.neutral9};
`;
const ModalContent = styled.div`
  border-radius: 5px;
  width: 830px;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
  padding-bottom: 24px;
`;
const CloseBtn = styled(CloseOutlined)`
  font-size: 18px;
  padding: 16px;
  color: ${(p) => p.theme.colors.neutral5};
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
`;
const LineBreak = styled.div`
  height: 2px;
  background-color: ${(p) => p.theme.colors.neutral10};
`;

const SurveyTemplateBLock = ({
  template,
}: {
  template: SurveyTemplateNewFragmentFragment;
  editExisting: boolean;
  currentUser: CurrentUserFragmentFragment;
}) => {
  const navigate = useNavigate();
  const [copyToClipboard] = useClipboardHook();

  return (
    <Card>
      <CardHeader>{template?.title}</CardHeader>
      <CardFooter>
        <Tooltip title="Edit Template">
          <ButtonLeft
            onClick={() =>
              navigate(`/app/survey/create?editSurveyId=${template.id}`)
            }
          >
            <EditOutlined style={{ marginRight: 4 }} />
          </ButtonLeft>
        </Tooltip>
        <Tooltip title="See Results">
          <ButtonLeft
            onClick={() => {
              navigate(`/app/survey/report?templateId=${template.id}`);
            }}
          >
            <DotChartOutlined style={{ marginRight: 4 }} />
          </ButtonLeft>
        </Tooltip>
        <Tooltip title="Copy to Clipboard">
          <ButtonLeft
            onClick={() => {
              // navigate(`/app/survey/view?templateId=${template._id}`);
              copyToClipboard(
                `${window.location.origin}/app/survey/view?templateId=${template.id}`
              );
              message.success("Survey link was copied to your clipboard!");
            }}
          >
            <ShareAltOutlined style={{ marginRight: 4 }} />
          </ButtonLeft>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

export default function AppSurveyTemplates({ currentUser }: DefaultPageProps) {
  const { data, loading } = useGetManySurveyTemplatesNewQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: defaultSurveyTemplates } =
    useGetManyDefaultSurveyTemplatesQuery();
  const [open, setCreateSurvey] = useQueryParam(
    "setCreateSurvey",
    BooleanParam
  );

  const { data: campaignQuery } = useGetManySurveyCampaignsQuery({
    variables: {
      listType: ListTypeEnum.Contacts,
    },
  });

  const navigate = useNavigate();

  return (
    <PageContainer>
      <Tabs
        defaultActiveKey={undefined}
        onChange={(value) => console.log(value as any)}
        tabBarExtraContent={{
          right: <></>,
        }}
        items={[
          {
            key: "Templates",
            label: "Templates",
            children: (
              <>
                <PageHeader style={{ marginBottom: 16 }}>
                  My Templates
                </PageHeader>
                {open && (
                  <ModalOverlay>
                    <ModalContent>
                      <ModalBody>
                        <CloseBtn onClick={() => setCreateSurvey(undefined)} />
                        <Title>Start from scratch</Title>
                        <Row gutter={16} justify="start">
                          <Col xs={8}>
                            <EmptyCard
                              onClick={() => navigate(`/app/survey/create`)}
                            >
                              + New Blank Template
                            </EmptyCard>
                          </Col>
                        </Row>
                        {defaultSurveyTemplates
                          ?.getManyDefaultSurveyTemplates?.[0] && (
                          <Title style={{ marginTop: 32 }}>
                            Start from a template
                          </Title>
                        )}
                        <Row gutter={16} justify="start">
                          {defaultSurveyTemplates?.getManyDefaultSurveyTemplates.map(
                            (template) => {
                              if (!template) return null;
                              return (
                                <Col xs={8} key={template?.id}>
                                  <TemplateCard
                                    onClick={() =>
                                      navigate(
                                        `/app/survey/create?surveyTemplateId=${template.id}`
                                      )
                                    }
                                  >
                                    {template?.title}
                                  </TemplateCard>
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </ModalBody>
                    </ModalContent>
                  </ModalOverlay>
                )}
                <Row gutter={16} justify="start">
                  <Col xs={8}>
                    <EmptyCard onClick={() => setCreateSurvey(true)}>
                      + Create New Template
                    </EmptyCard>
                  </Col>
                  {loading && (
                    <Col xs={8} key="loading">
                      <Loading height={100} />
                    </Col>
                  )}
                  {data?.getManySurveyTemplatesNew.map((template) => {
                    if (!template) return null;
                    return (
                      <Col xs={8} key={template?.id}>
                        <SurveyTemplateBLock
                          template={template}
                          editExisting={true}
                          currentUser={currentUser}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </>
            ),
          },
          ...(data?.getManySurveyTemplatesNew?.[0]
            ? [
                {
                  key: "Campaigns",
                  label: (
                    <>
                      Campaigns{" "}
                      {!campaignQuery?.getManySurveyCampaigns?.[0] && (
                        <>
                          <Tooltip title="Create your first campaign to start tracking survey results.">
                            <InfoCircleFilled />
                          </Tooltip>
                        </>
                      )}
                    </>
                  ),
                  children: <AppSurveyCampaigns />,
                },
              ]
            : []),
        ]}
      />
    </PageContainer>
  );
}

//navigate("/app/survey/create")
