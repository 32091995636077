import React from "react";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";
import {
  Card,
  CardTitle,
  CardCaption,
  CardAvatar,
  CardActions,
  RemoveBtn,
} from "../index";
import { Contact } from "generated/graphql";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { Tag } from "antd";

export default function ContactCard({
  item,
  type = "contacts",
  onRemove,
  isPrimary,
  onMakePrimaryContact,
}: {
  item: Contact;
  onRemove?: any;
  type?: string;
  isPrimary?: boolean;
  onMakePrimaryContact?: any;
}) {
  return (
    <Card>
      <div style={{ display: "flex" }}>
        <CardAvatar type={"contacts"} src={item?.avatar?.url || undefined} />
        <div>
          <CardTitle
            to={`/app/contacts/${item.id}?tab=1`}
            data-testid="contact-card-title"
          >
            {item?.firstName} {item?.lastName}{" "}
            {isPrimary && (
              <Tag style={{ marginLeft: 16 }} color={"blue"}>
                Primary
              </Tag>
            )}
          </CardTitle>
          <CardCaption>{item.title}</CardCaption>

          <CardCaption>{item.email}</CardCaption>
          <CardCaption>
            {item.cellPhone || item.homePhone || item.workPhone}
          </CardCaption>
        </div>
      </div>
      {onRemove && (
        <CardActions className="card-actions">
          {onMakePrimaryContact && (
            <Popconfirm
              title={
                <div style={{ maxWidth: "100%", width: 300 }}>
                  <Text>
                    Are you sure you want to make this contact the primary?
                  </Text>
                </div>
              }
              onConfirm={() => onMakePrimaryContact(item)}
              okText="Yes"
            >
              <RemoveBtn
                style={{ marginRight: 8 }}
                title="Make primary contact"
              >
                <UserAddOutlined />
              </RemoveBtn>
            </Popconfirm>
          )}

          <Popconfirm
            title={
              <div style={{ maxWidth: "100%", width: 300 }}>
                <Text>Are you sure you want to remove this contact?</Text>
                <Caption>
                  This won't delete the contact. It will only remove the
                  contact's association to this record.
                </Caption>
              </div>
            }
            onConfirm={onRemove}
            okText="Yes"
          >
            <RemoveBtn title="Remove contact">
              <DeleteOutlined />
            </RemoveBtn>
          </Popconfirm>
        </CardActions>
      )}
    </Card>
  );
}
