// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import businessFragment from "./businessFragment";
import caseFragmentShort from "./caseFragmentShort";
import contactShortFragment from "./contactShortFragment";

export default gql`
  fragment businessByIdFragment on Business {
    ...businessFragment
    annualRevenue
    contactIds
    email
    projects {
      id
      title
      image {
        id
        url
      }
    }
    primaryContact {
      ...contactShortFragment
    }
    businessSectorId
    tradeName
    businessSector {
      id
      title
    }
    cases {
      ...caseFragmentShort
    }
    owner {
      id
      firstName
      lastName
      email
    }
    companyInvestments {
      id
      amount
      description
      createdAt
      investedOn
    }
    contacts {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }

  ${businessFragment}
  ${caseFragmentShort}
  ${contactShortFragment}
`;
