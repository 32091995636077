import React from 'react';
import chartSVG from 'lib/media/Chart-line-colored.svg';
import greySVG from 'lib/media/chart-line-grey.svg';
import SideNavItem from 'components/common/SideNavItem';
// ICONS
import eventsActive from './icons/events-active.svg';
import eventsInactive from './icons/events-inactive.svg';
import fundingActive from './icons/funding-active.svg';
import fundingInactive from './icons/funding-inactive.svg';
import incentiveActive from './icons/incentive-active.svg';
import incentiveInactive from './icons/incentive-inactive.svg';
import organizationsActive from './icons/organizations-active.svg';
import organizationsInactive from './icons/organizations-inactive.svg';
import programsActive from './icons/programs-active.svg';
import programsInactive from './icons/programs-inactive.svg';
import sitesActive from './icons/sites-active.svg';
import sitesInactive from './icons/sites-inactive.svg';

const TABS = [
  {
    label: 'Available Sites',
    value: 'sites',
    activeIcon: sitesActive,
    inactiveIcon: sitesInactive,
  },
  // {
  //   label: 'Tax Incentives',
  //   value: 'taxIncentives',
  //   activeIcon: fundingActive,
  //   inactiveIcon: fundingInactive,
  // },
  // {
  //   label: 'Events',
  //   value: 'events',
  //   activeIcon: eventsActive,
  //   inactiveIcon: eventsInactive,
  // },
  // {
  //   label: 'Incentives',
  //   value: 'incentives',
  //   activeIcon: incentiveActive,
  //   inactiveIcon: incentiveInactive,
  // },
  {
    label: 'Support',
    value: 'programs',
    activeIcon: programsActive,
    inactiveIcon: programsInactive,
  },
  {
    label: 'Funding',
    value: 'funding',
    activeIcon: fundingActive,
    inactiveIcon: fundingInactive,
  },

  {
    label: 'Organizations',
    value: 'organizations',
    activeIcon: organizationsActive,
    inactiveIcon: organizationsInactive,
  },
];

const SideNav = ({onParamsChange, sidenav}) => {
  return TABS.map((tab) => {
    return (
      <SideNavItem
        key={tab.value}
        label={tab.label}
        icon={sidenav === tab.value ? tab.activeIcon : tab.inactiveIcon}
        active={sidenav === tab.value}
        onChange={() => onParamsChange({sidenav: tab.value})}
      />
    );
  });
};

export default SideNav;
