import { Select, DatePicker, TimePicker } from "antd";
import FormItem from "components/common/FormItem";
import SectionBreak from "components/common/SectionBreak";
import TextInput from "components/inputs/TextInput";
import {
  EmailCampaignFragmentFragment,
  EmailCampaignInput,
  useGetManyIntegrationsQuery,
  useGetManySurveyTemplatesNewQuery,
} from "generated/graphql";
import { PageTitle } from "routes/app-automations-create/shared";
import styled from "styled-components";
import Button from "components/common/Button";
import Caption from "components/text/Caption";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import moment from "moment";
import TemplateVariableOptions from "../TemplateVariableOptions";
import Col from "components/common/Col";
import Row from "components/common/Row";

const Card = styled.div`
  background: #fff;
  padding: 24px;
  padding-top: 8px;
`;

export default function EmailCampaignSettingsEditor({
  emailCampaign,
  onChange,
  loading,
  onNext,
}: {
  onChange: (fieldName: string, newValue: any) => void;
  emailCampaign:
    | null
    | undefined
    | EmailCampaignFragmentFragment
    | EmailCampaignInput;
  loading: boolean;
  onNext: () => void;
}) {
  const { data } = useGetManyIntegrationsQuery({
    onCompleted: (data) => {
      const addressId = data?.getManyIntegrations?.[0]?.id;
      if (!emailCampaign?.fromAddress) {
        onChange("fromAddress", addressId);
      }
      if (!emailCampaign?.replyToAddress) {
        onChange("replyToAddress", addressId);
      }
    },
  });

  const { data: surveyTemplates } = useGetManySurveyTemplatesNewQuery();

  const queryComplete = data?.getManyIntegrations?.[0]?.id ? true : false;

  const handleDateTimeChange = (
    date: moment.Moment | null,
    time: moment.Moment | null
  ) => {
    const now = moment();
    const dateObj = date || now;
    const timeObj = time || now;

    if (dateObj && timeObj) {
      const combinedDateTime = moment(
        dateObj.format("YYYY-MM-DD") + " " + timeObj.format("HH:mm:ss")
      );

      console.log("scheduledAt", combinedDateTime.valueOf());
      onChange("scheduledAt", combinedDateTime.valueOf());
    }
  };

  const disabledDate = (current: moment.Moment) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledTime = (date: moment.Moment) => {
    if (date && date.isSame(moment(), "day")) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === currentHour
            ? Array.from({ length: currentMinute }, (_, i) => i)
            : [],
      };
    }
    return {};
  };

  console.log(emailCampaign?.scheduledAt);
  console.log(moment(emailCampaign?.scheduledAt).format("HH:mm:ss"));

  return (
    <>
      <PageTitle style={{ marginBottom: 0 }}>Email Settings</PageTitle>
      <Caption style={{ marginBottom: 16 }}>
        Configure settings related to your email
      </Caption>
      <Card>
        <SectionBreak header="General" headerStyle={{ fontSize: 16 }}>
          <FormItem label="Email Campaign Name" required>
            <TextInput
              value={emailCampaign?.title}
              onChange={(value) => onChange("title", value)}
            />
          </FormItem>
          <FormItem label="Schedule" required>
            <div style={{ display: "flex", gap: "10px" }}>
              <DatePicker
                style={{ flex: 1 }}
                onChange={(date) =>
                  handleDateTimeChange(
                    date,
                    emailCampaign?.scheduledAt
                      ? (moment(
                          emailCampaign.scheduledAt
                        ).local() as any as moment.Moment)
                      : null
                  )
                }
                value={
                  emailCampaign?.scheduledAt
                    ? (moment(emailCampaign.scheduledAt) as any)
                    : null
                }
                disabledDate={disabledDate}
              />
              <TimePicker
                style={{ flex: 1 }}
                format="hh:mm a"
                use12Hours
                onChange={(time) =>
                  handleDateTimeChange(
                    emailCampaign?.scheduledAt
                      ? moment(emailCampaign.scheduledAt)
                      : null,
                    time
                  )
                }
                value={
                  emailCampaign?.scheduledAt
                    ? (moment(emailCampaign.scheduledAt) as any)
                    : null
                }
                disabledTime={disabledTime}
              />
            </div>
          </FormItem>
        </SectionBreak>
        <SectionBreak header="Email" headerStyle={{ fontSize: 16 }}>
          <FormItem label="From name" required>
            <TextInput
              value={emailCampaign?.fromName}
              onChange={(value) => onChange("fromName", value)}
            />
          </FormItem>
          <FormItem label="Subject line" required>
            <TextInput
              value={emailCampaign?.subject}
              onChange={(value) => onChange("subject", value)}
            />
          </FormItem>
          <FormItem label="From address">
            <DropdownStyleWrapper>
              <Select
                style={{ width: "100%" }}
                size="large"
                value={queryComplete && emailCampaign?.fromAddress}
                onChange={(value) => onChange("fromAddress", value)}
                options={data?.getManyIntegrations?.map((item) => {
                  return {
                    label: item?.email,
                    value: item?.id,
                  };
                })}
              />
            </DropdownStyleWrapper>
          </FormItem>
          <FormItem label="Reply to">
            <DropdownStyleWrapper>
              <Select
                style={{ width: "100%" }}
                size="large"
                value={queryComplete && emailCampaign?.replyToAddress}
                onChange={(value) => onChange("replyToAddress", value)}
                options={data?.getManyIntegrations?.map((item) => {
                  return {
                    label: item?.email,
                    value: item?.id,
                  };
                })}
              />
            </DropdownStyleWrapper>
          </FormItem>{" "}
        </SectionBreak>

        <SectionBreak header="Survey" headerStyle={{ fontSize: 16 }}>
          <FormItem
            label="Survey Template"
            hint="If you'd like to select a survey template to include in your campaign, you can do so here."
          >
            {" "}
            <DropdownStyleWrapper>
              <Select
                value={emailCampaign?.surveyTemplateId}
                onChange={(value) => onChange("surveyTemplateId", value)}
                placeholder="Select a survey template"
                style={{ width: "100%" }}
                size="large"
              >
                {surveyTemplates?.getManySurveyTemplatesNew?.map((template) => (
                  <Select.Option key={template?.id} value={template.id}>
                    {template?.title}
                  </Select.Option>
                ))}
              </Select>{" "}
            </DropdownStyleWrapper>
          </FormItem>
        </SectionBreak>

        {/* <SectionBreak header="Footer Settings" headerStyle={{ fontSize: 16 }}>
          <FormItem label="Organization Name">
            <TextInput />
          </FormItem>
          <FormItem label="Address">
            <TextInput />
          </FormItem>
        </SectionBreak>*/}
      </Card>

      <Row align="top">
        <Col xs={24} sm={16}>
          <TemplateVariableOptions emailCampaign={emailCampaign} />
        </Col>
        <Col xs={24} sm={8}>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={onNext}
              disabled={loading}
              loading={loading}
              secondary
              style={{ marginTop: 16 }}
            >
              Save & Next
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
