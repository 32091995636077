import React from "react";
import { UsergroupAddOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import LinkButton from "components/common/LinkButton";
import SelectInput from "components/inputs/SelectInput";
import Avatar from "components/common/Avatar";
import Row from "components/common/Row";
import Col from "components/common/Col";
import {
  ObjectPermissionFragmentFragment,
  UserGroupFragmentFragment,
} from "generated/graphql";
import theme from "lib/theme";

const ObjectPermissionsRow = ({
  objectPermission,
  onRemove,
}: {
  objectPermission: ObjectPermissionFragmentFragment;
  onRemove: () => void;
}) => {
  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Avatar
          shape="square"
          icon={<UsergroupAddOutlined />}
          style={{ marginRight: 8, marginTop: 4 }}
        />
        <div>
          <Text style={{ fontWeight: 600 }}>
            {objectPermission?.subject?.title}
          </Text>{" "}
          <Caption>{objectPermission?.subject?.users?.length} people</Caption>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <LinkButton
          danger
          onClick={onRemove}
          data-testid="remove-group-permissions-btn"
        >
          Remove
        </LinkButton>
      </div>
    </div>
  );
};

interface ObjectPermissionsInputProps {
  permissions: ObjectPermissionFragmentFragment[];
  availableGroups: UserGroupFragmentFragment[];
  onRemove: (permission: ObjectPermissionFragmentFragment) => void;
  onAdd: (group: UserGroupFragmentFragment) => void;
}

export default function ObjectPermissionsInput({
  permissions,
  availableGroups,
  onRemove,
  onAdd,
}: ObjectPermissionsInputProps) {
  return (
    <>
      {permissions.map((permission) => (
        <ObjectPermissionsRow
          key={permission.id}
          objectPermission={permission}
          onRemove={() => onRemove(permission)}
        />
      ))}
      {permissions.length === 0 && (
        <Text style={{ fontWeight: 600, marginTop: 16 }}>
          Only the creator and organization admins will be able to see this
          record
        </Text>
      )}
      {availableGroups.length !== 0 && (
        <Row gutter={12} style={{ marginTop: 16, marginBottom: 16 }}>
          <Col xs={20}>
            <SelectInput
              data-testid="groups-select-input"
              options={availableGroups.map((group) => ({
                id: group?.id || "",
                label: group.title || "",
              }))}
              placeholder="Search for groups to share this record with"
              containerStyle={{
                width: "100%",
                backgroundColor: theme.colors?.neutral11,
              }}
              value={undefined}
              onChange={(groupId) => {
                const groupToAdd = availableGroups.find(
                  (group) => group.id === groupId
                );
                groupToAdd && onAdd(groupToAdd);
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
}
