import PageContainer from "components/common/PageContainer";
import {
  EmailCampaignFragmentFragment,
  EmailCampaignInput,
  EmailCampaignStatusEnum,
  useGetOneEmailCampaignQuery,
  useStartEmailCampaignMutation,
  useUpdateOneEmailCampaignMutation,
  useGetEstimatedRecipientsForEmailCampaignQuery,
  useGetManyIntegrationsQuery,
} from "generated/graphql";
import Button from "components/common/Button";
import styled from "styled-components";
import { Tabs } from "components/detail-components";
import { useState, useRef, useCallback } from "react";

import EmailCampaignSettingsEditor from "./EmailCampaignSettingsEditor";
import EmailCampaignEmailEditor from "./EmailCampaignEmailEditor";
import { useNavigate, useParams } from "react-router-dom";
import EmailCampaignRecipientEditor from "./EmailCampaignRecipientEditor";
import { message, Drawer } from "antd";
import Loading from "components/common/Loading";
import removeTypename from "lib/helpers/removeTypename";
import { LoadingOutlined } from "@ant-design/icons";
import numeral from "numeral";
import moment from "moment";
import EmailCampaignTestEmailModal from "./EmailCampaignTestEmailModal";
import BusinessInput from "components/inputs/BusinessInput";

const NavBar = styled.div`
  padding-top: 60px;
  min-height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 2px solid ${({ theme }) => theme.colors?.neutral9};
`;

const TABS = [
  {
    id: "settings",
    label: "1. Settings",
  },
  {
    id: "edit",
    label: "2. Edit",
  },
  {
    id: "send-to",
    label: "3. Recipients",
  },
];

const FUTURE_REQ = 3;

const getId = (emailCampaign: any): string => {
  return emailCampaign?.id;
};

const useReviewHook = (emailCampaign, setTab, setIsReviewDrawerVisible) => {
  const subjectRef = useRef<HTMLInputElement>(null);
  const fromAddressRef = useRef<HTMLInputElement>(null);
  const replyToAddressRef = useRef<HTMLInputElement>(null);
  const scheduledAtRef = useRef<HTMLInputElement>(null);

  const onReview = useCallback(() => {
    if (!emailCampaign?.subject) {
      setTab(TABS[0].id);
      message.error("Please add a subject");
      subjectRef.current?.focus();
      subjectRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (!emailCampaign?.recipientReferences?.[0]?.id) {
      setTab(TABS[2].id);
      message.error("Please choose at least one list to communicate with");
      return;
    }
    if (!emailCampaign?.fromAddress) {
      setTab(TABS[0].id);
      message.error("Please select a from address");
      fromAddressRef.current?.focus();
      fromAddressRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (!emailCampaign?.replyToAddress) {
      setTab(TABS[0].id);
      message.error("Please select a reply-to address");
      replyToAddressRef.current?.focus();
      replyToAddressRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (!emailCampaign?.scheduledAt) {
      setTab(TABS[0].id);
      message.error("Please set a schedule for the campaign");
      scheduledAtRef.current?.focus();
      scheduledAtRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    const scheduledTime = moment(parseInt(emailCampaign.scheduledAt));
    const minimumScheduledTime = moment().add(FUTURE_REQ, "minutes");
    if (scheduledTime.isBefore(minimumScheduledTime)) {
      setTab(TABS[0].id);
      message.error(
        `Scheduled time must be at least ${FUTURE_REQ} minutes from now`
      );
      scheduledAtRef.current?.focus();
      scheduledAtRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (
      emailCampaign?.surveyTemplateId &&
      !emailCampaign?.emailTemplate?.includes("{{survey_url}}")
    ) {
      setTab(TABS[1].id);
      message.error(
        "Please include the {{survey_url}} template variable in your email content"
      );
      return;
    }
    setIsReviewDrawerVisible(true);
  }, [emailCampaign, setTab, setIsReviewDrawerVisible]);

  return {
    onReview,
    subjectRef,
    fromAddressRef,
    replyToAddressRef,
    scheduledAtRef,
  };
};

const ReviewDrawer = ({
  isVisible,
  onClose,
  emailCampaign,
  estimatedRecipientsData,
  estimatedRecipientsLoading,
  onSend,
  starting,
  updating,
}) => {
  const { data: integrationsData } = useGetManyIntegrationsQuery();

  return (
    <Drawer
      title="Review Email Campaign"
      placement="right"
      onClose={onClose}
      open={isVisible}
      width={500}
    >
      <p>
        <strong>Title:</strong> {emailCampaign?.title}
      </p>
      <p>
        <strong>Subject:</strong> {emailCampaign?.subject}
      </p>
      <p>
        <strong>From:</strong> {emailCampaign?.fromName} (
        {integrationsData?.getManyIntegrations?.find(
          (i) => i?.id === emailCampaign?.fromAddress
        )?.email ?? "N/A"}
        )
      </p>
      <p>
        <strong>Reply To:</strong>{" "}
        {integrationsData?.getManyIntegrations?.find(
          (i) => i?.id === emailCampaign?.replyToAddress
        )?.email ?? "N/A"}
      </p>
      <p>
        <strong>Scheduled At:</strong>{" "}
        {moment(parseInt(emailCampaign?.scheduledAt)).format(
          "MMM, Do YYYY, h:mm a"
        )}
      </p>
      <h3>Recipients</h3>
      <ul>
        {emailCampaign?.recipientReferences?.map((ref, index) => (
          <li key={index}>{ref.title}</li>
        ))}
      </ul>

      {estimatedRecipientsLoading ? (
        <LoadingOutlined />
      ) : (
        <>
          <p>
            <strong>Estimated Recipients:</strong>{" "}
            {estimatedRecipientsData?.getEstimatedRecipientsForEmailCampaign
              ?.count || 0}
          </p>
          <p>
            <strong>Sends Remaining:</strong>{" "}
            {numeral(
              2000 -
                (estimatedRecipientsData?.getEstimatedRecipientsForEmailCampaign
                  ?.emailsSent || 0)
            ).format("0,0")}
          </p>
        </>
      )}
      <Button
        onClick={onSend}
        loading={starting}
        disabled={updating || starting}
        primary
      >
        Schedule Campaign
      </Button>
    </Drawer>
  );
};

export default function AppEmailCampaignsEditor() {
  const [tab, setTab] = useState(TABS[0]?.id);
  const [emailCampaign, setEmailCampaign] = useState<
    EmailCampaignInput | EmailCampaignFragmentFragment | undefined | null
  >(undefined);
  const [isReviewDrawerVisible, setIsReviewDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [updateOneEmailCampaignMutation, { loading: updating }] =
    useUpdateOneEmailCampaignMutation();

  const [startEmailCampaignMutation, { loading: starting }] =
    useStartEmailCampaignMutation();
  const { loading } = useGetOneEmailCampaignQuery({
    variables: {
      emailCampaignId: (params?.id as string) || "",
    },
    onCompleted: (data) => {
      if (data?.getOneEmailCampaign?.status !== EmailCampaignStatusEnum.Draft) {
        return navigate(
          `/app/email-campaigns/${data?.getOneEmailCampaign?.id}`
        );
      }
      setEmailCampaign(data?.getOneEmailCampaign);
    },
    skip: params?.id ? false : true,
  });

  const {
    onReview,
    subjectRef,
    fromAddressRef,
    replyToAddressRef,
    scheduledAtRef,
  } = useReviewHook(emailCampaign, setTab, setIsReviewDrawerVisible);

  const { data: estimatedRecipientsData, loading: estimatedRecipientsLoading } =
    useGetEstimatedRecipientsForEmailCampaignQuery({
      variables: {
        recipientReferences:
          removeTypename(emailCampaign?.recipientReferences) || [],
      },
      skip: !emailCampaign?.recipientReferences?.[0],
    });

  if (loading) return <Loading />;

  const updateParams: EmailCampaignInput = {
    title: emailCampaign?.title || "",
    subject: emailCampaign?.subject,
    recipientReferences:
      removeTypename(emailCampaign?.recipientReferences) || [],
    fromAddress: emailCampaign?.fromAddress,
    replyToAddress: emailCampaign?.replyToAddress,
    fromName: emailCampaign?.fromName,
    emailTemplate: emailCampaign?.emailTemplate,
    scheduledAt: emailCampaign?.scheduledAt,
    surveyTemplateId: emailCampaign?.surveyTemplateId,
  };

  const updateOneEmailCampaign = async (e?: any) => {
    try {
      e?.preventDefault();
      await updateOneEmailCampaignMutation({
        variables: {
          id: (params?.id as string) || "",
          params: updateParams,
        },
      });
      message.success("Your campaign settings have been saved");
    } catch (err) {
      console.log(err);
    }
  };

  const onSend = async () => {
    try {
      if (!emailCampaign?.scheduledAt) return;
      const scheduledTime = moment(emailCampaign.scheduledAt);
      const minimumScheduledTime = moment().add(FUTURE_REQ, "minutes");
      if (scheduledTime.isBefore(minimumScheduledTime)) {
        message.error(
          `Scheduled time must be at least ${FUTURE_REQ} minutes from now`
        );
        return;
      }

      await updateOneEmailCampaignMutation({
        variables: {
          id: (params?.id as string) || "",
          params: updateParams,
        },
      });
      const emailCampaignId = getId(emailCampaign);
      await startEmailCampaignMutation({
        variables: {
          emailCampaignId: emailCampaignId,
        },
      });
      setIsReviewDrawerVisible(false);
      navigate(`/app/email-campaigns/${emailCampaignId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const defaultProps = {
    emailCampaign,
    updateOneEmailCampaign,
    loading: updating,
    onNext: async () => {
      try {
        await updateOneEmailCampaign();
        const currentTabIndex = TABS?.findIndex((t) => t?.id === tab);
        if (currentTabIndex === 2) return; // 2 is the last step, so do not increment anything
        setTab(TABS?.[currentTabIndex + 1]?.id);
      } catch (err) {
        console.log(err);
      }
    },
    onChange: (fieldName, newValue) => {
      setEmailCampaign((oldValues: any) => {
        return {
          ...oldValues,
          [fieldName]: newValue,
        };
      });
    },
    subjectRef,
    fromAddressRef,
    replyToAddressRef,
    scheduledAtRef,
  };

  return (
    <>
      <NavBar>
        <div
          style={{
            top: -24,
            position: "relative",
            minWidth: 100,
            flex: 1,
            right: -24,
          }}
        >
          <Button
            grey
            onClick={() => navigate("/app/email-campaigns")}
            disabled={updating}
            style={{ marginRight: 16 }}
            red
          >
            Exit
          </Button>
          <Button
            onClick={updateOneEmailCampaign}
            loading={updating}
            disabled={updating || starting}
            secondary
          >
            Save
          </Button>
        </div>
        <div style={{ flex: 1, position: "relative", top: 3 }}>
          <Tabs
            active={tab}
            onClick={(tab) => setTab(tab)}
            tabs={TABS}
            width={500}
          />
        </div>

        <div
          style={{
            flex: 1,
            position: "relative",
          }}
        >
          {" "}
          <EmailCampaignTestEmailModal emailCampaign={emailCampaign} />
          <Button
            onClick={onReview}
            loading={starting}
            disabled={updating || starting}
            primary
            style={{
              bottom: 0,
              position: "absolute",
              minWidth: 100,
              right: 24,
            }}
          >
            Review & Schedule
          </Button>
        </div>
      </NavBar>
      <PageContainer>
        {(() => {
          switch (tab) {
            case TABS?.[0]?.id:
              return <EmailCampaignSettingsEditor {...defaultProps} />;
            case TABS?.[1]?.id:
              return <EmailCampaignEmailEditor {...defaultProps} />;
            case TABS?.[2]?.id:
              return <EmailCampaignRecipientEditor {...defaultProps} />;
            default:
              return null;
          }
        })()}
      </PageContainer>
      <ReviewDrawer
        isVisible={isReviewDrawerVisible}
        onClose={() => setIsReviewDrawerVisible(false)}
        emailCampaign={emailCampaign}
        estimatedRecipientsData={estimatedRecipientsData}
        estimatedRecipientsLoading={estimatedRecipientsLoading}
        onSend={onSend}
        starting={starting}
        updating={updating}
      />
    </>
  );
}
