import Row from "components/common/Row";
import Col from "components/common/Col";
import {
  EmailCampaignFragmentFragment,
  EmailCampaignInput,
  EmailCampaignRecipientReferenceEnum,
  EmailCampaignRecipientReferenceInput,
  GetManyListsOwnerTypeEnum,
  ListFragmentFragment,
  ListTypeEnum,
  useGetEstimatedRecipientsForEmailCampaignQuery,
  useGetManyListsQuery,
  useTagsQuery,
} from "generated/graphql";
import numeral from "numeral";
import { PageTitle } from "routes/app-automations-create/shared";
import styled from "styled-components";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { Select, Tag } from "antd";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import { LoadingOutlined } from "@ant-design/icons";
import removeTypename from "lib/helpers/removeTypename";

const EstimatedRecipientsCard = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  margin-top: 24px;
`;

const EstimatedRecipients = ({
  recipientReferences,
}: {
  recipientReferences?: EmailCampaignRecipientReferenceInput[];
}) => {
  const { data, loading } = useGetEstimatedRecipientsForEmailCampaignQuery({
    variables: {
      recipientReferences: removeTypename(recipientReferences),
    },
    // skip: !recipientReferences?.[0],
  });

  const count = data?.getEstimatedRecipientsForEmailCampaign?.count || 0;
  const sent = data?.getEstimatedRecipientsForEmailCampaign?.emailsSent || 0;
  const allowed = 2000;

  return (
    <EstimatedRecipientsCard>
      <PageTitle style={{ margin: 0, marginBottom: 16 }}>
        Estimated Recipients
      </PageTitle>
      <Text style={{ fontSize: 16 }}>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <strong data-testid="estimated-recipients-count">{count}</strong>
        )}
      </Text>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 11,
          marginTop: 32,
        }}
      >
        <Caption>
          {loading ? (
            <LoadingOutlined />
          ) : (
            numeral(allowed - sent).format("0,0")
          )}{" "}
          sends remaining next 30 days
        </Caption>
        <Caption>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <>
              {sent}/{numeral(allowed).format("0,0")} sent
            </>
          )}
        </Caption>
      </div>
    </EstimatedRecipientsCard>
  );
};

export default function EmailCampaignRecipientEditor({
  emailCampaign,
  onChange,
  onNext,
  loading,
}: {
  loading?: boolean;
  onNext: () => void;
  onChange: (name: string, newValue: any) => void;
  emailCampaign:
    | null
    | undefined
    | EmailCampaignFragmentFragment
    | EmailCampaignInput;
}) {
  const { data } = useGetManyListsQuery({
    variables: {
      listType: ListTypeEnum.Contacts,
      ownerType: GetManyListsOwnerTypeEnum.All,
      pagination: {
        limit: 10000,
      },
    },
  });

  const { data: tagsData } = useTagsQuery({
    variables: {},
  });

  const selectedLists = [];

  // data?.getManyLists?.results?.filter(
  //   (item) =>
  //     emailCampaign?.recipientReferences
  //       ?.map((item) => item.id)
  //       ?.includes(item?.id)
  // );

  const options = [
    {
      type: EmailCampaignRecipientReferenceEnum.AllBusinesses,
      label: "All Businesses",
      value: EmailCampaignRecipientReferenceEnum.AllBusinesses,
      title: "All Businesses",
    },
    {
      type: EmailCampaignRecipientReferenceEnum.AllContacts,
      label: "All Contacts",
      value: EmailCampaignRecipientReferenceEnum.AllContacts,
      title: "All Contacts",
    },
    ...(data?.getManyLists?.results?.map((item) => ({
      value: item?.id,
      title: item?.name,
      label: (
        <>
          <Tag color="blue">List</Tag> {item?.name} ({item?.numberOfRecords})
        </>
      ),
      type: EmailCampaignRecipientReferenceEnum.List,
    })) || []),
    ...(tagsData?.tags?.tags?.map((item) => ({
      value: item?.id,
      title: item?.title,
      label: (
        <>
          <Tag color="purple">Tag</Tag> {item?.title}
        </>
      ),
      type: EmailCampaignRecipientReferenceEnum.Tag,
    })) || []),
  ];

  return (
    <>
      <Row align={"top"} gutter={16}>
        <Col xs={24} sm={14}>
          <PageTitle style={{ marginBottom: 0 }}>Recipients</PageTitle>
          <Caption style={{ marginBottom: 16 }}>
            Choose who to include in your email campaign
          </Caption>
          <FormItem
            label="Send to"
            containerProps={{ style: { marginTop: 0 } }}
          >
            <Select
              mode="tags"
              size="large"
              placeholder="Please select"
              value={
                emailCampaign?.recipientReferences?.map((item) => item.id) || []
              }
              onChange={(values) => {
                const newValues: EmailCampaignRecipientReferenceInput[] =
                  values?.map((val) => {
                    const found = options?.find((item) => item?.value === val);
                    return {
                      id: found?.value || "",
                      title: found?.title || "",
                      referenceType: found?.type,
                    };
                  });
                console.log({
                  newValues,
                });
                onChange("recipientReferences", newValues);
              }}
              style={{ width: 500, maxWidth: "100%" }}
              options={options}
            />
          </FormItem>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              onClick={onNext}
              disabled={loading}
              loading={loading}
              style={{ marginTop: 16 }}
              secondary
            >
              Save
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={10}>
          <EstimatedRecipients
            recipientReferences={emailCampaign?.recipientReferences || []}
          />
        </Col>
      </Row>
    </>
  );
}
