import React from "react";
import styled from "styled-components";
import BuildingFilled from "components/icons/BuildingFilled.js";
import theme from "lib/theme";

const Logo = styled.img`
  width: 32px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral10};
`;

const BlankLogo = styled.div`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.neutral10};
  border: 1px solid ${({ theme }) => theme.colors.neutral10};
`;

export default function BusinessLogo(props) {
  const [hasError, setHasError] = React.useState(false);

  if (!props.src || hasError) {
    return (
      <BlankLogo {...props}>
        <BuildingFilled height={24} width={24} fill={theme.colors.neutral7} />
      </BlankLogo>
    );
  }

  return <Logo {...props} onError={() => setHasError(true)} />;
}
