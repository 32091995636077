// antd specific style sheet that will be injected
// this unfortunately requires a lot of !importants, but, for now it's working reasonably enough.

import theme from "./theme";

export const antdStyle = `

  .ant-table-content {
    overflow: auto hidden !important;
  }

  .ant-table-selection-column {
    width: 25px important;
  }

  .ant-table th.ant-table-selection-column {
    width: 25px important;
  }

  .your-work-popover {
    .ant-popover-inner-content {
      padding: 0px !important;
    }
  }



  .ant-alert-message {
    font-size: 12px !important;
  }

  .ant-drawer-header {
    background: ${theme.colors.neutral1} !important;
    border-radius: 0px !important;
  }

  .ant-input-number-lg input {
      height: 50px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.primary3} !important;
  }


  .ant-input-number-input {
    height: 50px;
  }
  .ant-drawer-close {
    color: ${theme.colors.neutral9} !important;
  }

  btn.ant-btn:focus {
    color: #fff !important;
  }

  .ant-drawer-title {
    color: #fff !important;
  }

  div.ant-calendar-input-wrap {
    height: 60px !important;
  }

  .ant-collapse-item {
    border-bottom-color: transparent !important;
  }


  .ant-select-search__field {
    background: transparent !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.primary5} !important;
    border-color: ${theme.colors.primary5} !important;
  }



  .ant-calendar-input-wrap {
    height: 40px;
    padding: 6px 10px;
    border-bottom: 1px solid #e8e8e8;
}

  .ant-tabs-ink-bar {
    background-color: ${theme.colors.primary6} !important;
  }

  tr {
    cursor: pointer;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .ant-calendar-selected-date .ant-calendar-date {
    background: ${theme.colors.primary5} !important;
  }

  .ant-alert-description {
    font-size: 11px !important;
  }

  .ant-tabs-nav {
    padding-top: 31px !important;
  }


  .ant-input, .ant-btn, .ant-select, .ant-table, .ant-modal, .ant-input-number {
    font-family: ${theme.fontFamily} !important;
  }

  .ant-input, .ant-input-number {
    border: 1px solid ${theme.colors.neutral9} !important;
  }

  .ant-modal-header {
    background: ${theme.colors.primary5} !important;
  }


  .ant-spin-dot i {
    background-color: ${theme.colors.primary5} !important;
  }


  .ant-btn-primary {
    color: #fff !important;
  }


  .ant-pagination-item-active {
    border-color: ${theme.colors.primary5} !important
  }


  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.primary5} !important;
  }

  .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: ${theme.colors.primary5} !important;
}

  .ant-switch-checked {
    background-color: ${theme.colors.primary5} !important;
}

.ant-modal-confirm-btns {
  .ant-btn:focus {
    color: #fff !important;
  }
}

.growlab-confirm-modal {

  .ant-btn {
    span {
      &:hover {
        color: ${theme.colors.neutral1} !important;
      }
    }
    background-color: ${theme.colors.neutral10} !important;
    border-color: ${theme.colors.neutral10} !important;
    color: ${theme.colors.neutral1} !important;
    border: 2px ${theme.colors.primary3} !important;
    border-adius: 5px;
    font-family: ${theme.fontFamily} !important;
    &:hover {
      color: ${theme.colors.neutral1} !important;
      background-color: ${theme.colors.neutral9} !important;
      border-color: ${theme.colors.neutral9} !important;
    }
  }

   .ant-btn-primary:hover {
    color: ${theme.colors.primary10} !important;
    background-color: ${theme.colors.primary2} !important;
  }

  .ant-btn-primary {
    background-color: ${theme.colors.primary3} !important;
    border-color: ${theme.colors.primary3} !important;
    color: ${theme.colors.primary10} !important;
    border: 2px ${theme.colors.primary3} !important;
    border-adius: 5px;
    color: #fff;
    font-family: ${theme.fontFamily} !important;
    font-weight: 700;
    transition-delay: 0s;
    transition-duration: 0.15s;
    transition-property: all;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.045em;
    &:hover {
      background-color: ${theme.colors.primary2} !important;
      border-color: ${theme.colors.primary2} !important;
    }
  }

  .ant-btn-primary > span {
    &:hover {
      color: ${theme.colors.primary10} !important;
    }
  }

}


 .ant-btn:focus {
  border-color: ${theme.colors.primary7} !important;
  outline: 0;
}


 
.ant-btn:active {
  color: ${theme.colors.primary10} !important;
  background: ${theme.colors.primary4} !important;
}


.ant-select-lg, .ant-number, .ant-input-lg, .ant-input, .ant-select-selection--multiple, .ant-select-selection, .ant-select-selection--single {
  background: ${theme.colors.neutral11} !important;
  border: 0px !important;
}

.ant-select-selection__choice__content {
  color: ${theme.colors.primary2} !important;
}
.ant-select-selection__choice {
  border: 0px !important;
  background: ${theme.colors.primary9} !important;

  border-radius: 24px !important;
}

  .ant-calendar-selected-day .ant-calendar-date {
    color: ${theme.colors.primary10} !important;
  }

  .ant-calendar-ok-btn {
    background: ${theme.colors.primary2} !important;
    color: ${theme.colors.primary10} !important;
    border-color: ${theme.colors.primary2} !important;
  }

.ant-select-selection-selected-value {
  display: flex !important;
  align-items: center !important;
}

  .ant-input, .ant-select-selection__rendered, .ant-textarea, .ant-select, .ant-option, .ant-input-number {
    font-family: ${theme.fontFamily} !important;
    font-size: 16px !important;
    color: ${theme.colors.neutral1} !important;
    font-weight: 500 !important;
    background: ${theme.colors.neutral11} !important;

    .ant-select-selection-placeholder {
      font-weight: 400 !important;
    }
  }

  .ant-select-selection__placeholder {
    color: ${theme.colors.neutral8} !important;
  }


  ::placeholder { 
    /* Most modern browsers support this now. */
    color:    ${theme.colors.neutral6} !important;
    font-family: ${theme.fontFamily} !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
 
  .trigger {
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
    transition: all .3s;
    margin: 0 4px;
  }
  

  .result {
    text-align: center;
    width: 72%;
    margin: 0 auto;
  
    .icon {
      font-size: 72px;
      line-height: 72px;
      margin-bottom: 24px;
  
      & > .success {
        color: ${theme.colors.primary1} !important;
      }
  
      & > .error {
        color: ${theme.colors.red5};
      }
    }
  
    .title {
      font-size: 24px;
      color: @heading-color;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
  
    .description {
      font-size: 14px;
      line-height: 22px;
      color: @text-color-secondary;
      margin-bottom: 24px;
    }
  
    .extra {
      background:   ${theme.colors.neutral10};
      padding: 24px 40px;
      border-radius: @border-radius-sm;
      text-align: left;
    }
  
    .actions {
      margin-top: 32px;
  
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  



  .ant-form {
    font-family: ${theme.fontFamily} !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${theme.colors.primary10} !important;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: ${theme.colors.primary1} !important;
    border-color: ${theme.colors.primary1} !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.primary1} !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${theme.colors.primary1} !important;
  }

  .ant-steps-item-title {
    font-weight: 700 !important;
    color: #334E68 !important;
  }

  .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: ${theme.colors.primary1} !important;
  }
  .ant-modal-body {
    border-radius: 6px;
  }


  .ant-menu-item:hover {
    color: ${theme.colors.primary5} !important;
  }

  .ant-layout-content {
    background-color: #fff;
    border-radius: 5px;
  }
  .ant-layout, .ant-layout-footer{
    background-color: ${theme.colors.neutral10} !important;
  }

  .ant-menu-item-selected {
    color: ${theme.colors.primary3} !important;
  }

  .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
    border-right: 3px solid ${theme.colors.primary5} !important;
  }

  th {

    span{
      color: ${theme.colors.neutral1} !important;
    }
  }

  .ant-input-number, .ant-input-number-lg {
    border: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${theme.colors.primary2} !important;
  }

  .ant-menu-submenu-title:hover, .ant-menu-item:hover, .ant-menu-submenu-arrow {
      color: ${theme.colors.primary2} !important;
  }

  .ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: ${theme.colors.primary8} !important;
    border-color: ${theme.colors.primary8} !important;
  }

  .ant-transfer-list {
    background: #fff !important;
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: ${theme.colors.neutral11} !important;
  }

  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color: ${theme.colors.primary8} !important;
  }

  .ant-input:focus, ant-select-selection:focus, .ant-select-selection:hover, .ant-input:hover, .ant-input-number:hover, .ant-input-number:focus, .ant-input-number-focused {
    border-color: ${theme.colors.neutral11} !important;
    box-shadow: 0 0 0 2px ${theme.colors.neutral11} !important;

  }

  .ant-radio-button-wrapper-checked:first-child {
    border-color: ${theme.colors.primary8}!important;
    color: ${theme.colors.primary8}!important;
    box-shadow: none !important;
  }

  .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
    border-top-color: ${theme.colors.primary8}!important;
  }

  .ant-layout-sider-children {
    border-right: 1px solid   ${theme.colors.neutral10};
  }

  .ant-layout-sider {
  
    .ant-menu-item {
      margin-top: 10px !important;
      font-size: 15px !important;
      font-weight: 300 !important;
    }
    .ant-menu-inline {
      border-right: 0px !important;
    }

    .sider-nav-icon {
      font-size: 15px;
      color: ${theme.colors.neutral10};
    }
  
  }

  /********** TABS *********/

  .ant-tabs-bar {
    background-color: #fff !important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${theme.colors.primary5} !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: ${theme.colors.primary8} !important;
  }

  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.primary5} !important;
  }

  .ant-modal-content {
    border-radius: 5px;
  }







  
  .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    height: 50px;
  }
  
  .ant-tabs-content {
    min-height: 1000px;
  }
  
  // CHECKBOXES
  // ========================================
  .ant-checkbox-inner {
    height: 18px;
    width: 18px;
  }
  
  
 


  .ant-select-selection__rendered {
    font-size: 14px !important;
  }



.ant-layout-sider-collapsed {
  .headway-widget-wrapper {
      display: none;
  }
}




    
}

`;

export default antdStyle;
