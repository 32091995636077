import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// COMPONENTS
import {
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
// LIB
import theme from "lib/theme";
import useUploadFile from "lib/hooks/useUploadFile";
import BuildingFilled from "components/icons/BuildingFilled";
// APOLLO

const TabWrapper = styled.div`
  z-index: 1000;
  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
  }
`;
const TabText = styled.div<{ active?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 7px;
  color: ${(p) =>
    p.active ? p.theme.colors.neutral1 : p.theme.colors.neutral4};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const Activebar = styled.div`
  height: 3px;
  background: ${(p) => p.theme.colors.supportB2};
  width: 75%;
  margin: auto;
`;

const Tab = ({ label, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{ cursor: "pointer" }}
      data-testid={`detail-page-tab-${label}`}
    >
      <TabText active={active}>{label}</TabText>
      {active ? <Activebar /> : <div style={{ height: 3 }} />}
    </div>
  );
};

const DEFAULT_TABS = [
  {
    label: "Timeline",
    id: "1",
  },
  {
    label: "Notes",
    id: "2",
  },
  {
    label: "Todos",
    id: "3",
  },
  {
    label: "Files",
    id: "4",
  },
  {
    label: "Interactions",
    id: "5",
  },
];

export const Tabs = ({
  active,
  width,
  onClick,
  tabs = DEFAULT_TABS,
}: {
  active: string | null | undefined | string[];
  width?: number;
  onClick?: any;
  tabs?: { id: string; label: string }[];
}) => {
  return (
    <TabWrapper>
      <div style={{ width: width || 700, display: "flex" }}>
        {tabs.map((item) => (
          <div style={{ flex: 1 }} key={item.id}>
            <Tab
              onClick={() => onClick(item.id)}
              label={item.label}
              active={active === item.id}
            />
          </div>
        ))}
      </div>
    </TabWrapper>
  );
};

export const PageContainer = styled.div`
  height: calc(100vh - 177px);
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  /* overflow: visible; */
  overflow-y: hidden;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
`;

export const LeftCol = styled.div`
  flex-basis: 25%;
  max-width: 500px;
  min-width: 280px;
  background: #f9fbfd;
  overflow-y: scroll;
  z-index: 1;
  padding: 24px 40px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow-y: scroll;
`;

export const MiddleContent = styled.div`
  min-width: 500px;
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  overflow-y: scroll;
  white-space: normal;
  padding-right: 32px;
  padding-left: 32px;
`;

export const RightCol = styled.div`
  background-color: #fff;
  flex-basis: 25%;
  max-width: 500px;
  z-index: 1;
  overflow-y: scroll;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  min-width: 280px;
`;

export const HeaderContainer = styled.div`
  min-height: 124px;
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  z-index: 0;
  background: #fff;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
`;

export const HeadLeft = styled.div`
  flex-basis: 25%;
  max-width: 600px;
  min-width: 280px;
  padding-top: 8px;
`;

export const HeadMiddle = styled.div`
  min-width: 500px;
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeadRight = styled.div`
  background-color: #fff;
  flex-basis: 25%;
  max-width: 500px;
  z-index: 1;
  will-change: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 280px;
  position: relative;
`;

export const ProjectTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 22px;
`;

export const ProjectCaption = styled.h3`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  margin-top: 0px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral5};
`;

export const DetailsTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0px;
  line-height: 22px;
`;

export const DetailContainer = styled.div`
  background: #f9fbfd;
  padding: 24px 40px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
  width: 320px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100vh - 170px);
  overflow-y: scroll;
`;

export const DetailImageContainer = styled.div`
  height: 48px;
  width: 48px;
  background: ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 5px;
`;

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition:
    color 0.3s ease,
    background-color 0.3s ease,
    border-color 0.3s ease,
    width 0.3s ease,
    opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.primary8};
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export const DetailImage = ({ src, type, onChange }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadFile] = useUploadFile();
  const ICON_PROPS = {
    style: {
      fontSize: 24,
      color: theme.colors.neutral6,
    },
  };

  const handleUpload = async (event) => {
    try {
      // if already uploading, return
      if (uploading) return;
      // set loader
      setUploading(true);
      let file = event.target.files[0];
      // upload file to s3
      let uploadResult = await uploadFile(file);
      // remove typename field
      onChange(uploadResult);
      // message.success('Upload successful!');
      setUploading(false);
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };

  const getIcon = () => {
    if (type === "contacts") {
      return <UserOutlined {...ICON_PROPS} />;
    }
    if (type === "projects") {
      return <FileTextOutlined {...ICON_PROPS} />;
    }
    if (type === "businesses") {
      return (
        <BuildingFilled
          height={ICON_PROPS?.style?.fontSize}
          width={ICON_PROPS?.style?.fontSize}
          fill={ICON_PROPS?.style?.color}
        />
      );
    }
    if (type === "organization") {
      return (
        <BuildingFilled
          height={ICON_PROPS?.style?.fontSize}
          width={ICON_PROPS?.style?.fontSize}
          fill={ICON_PROPS?.style?.color}
        />
      );
    }
    if (type === "sites") {
      return <EnvironmentOutlined {...ICON_PROPS} />;
    }
    return <UserOutlined {...ICON_PROPS} />;
  };
  return (
    <div>
      <UploadLabel htmlFor="file-input">
        {src && <DetailImg src={src} alt={src} />}
        {!src && <DetailImageContainer>{getIcon()}</DetailImageContainer>}
      </UploadLabel>
      <UploadButton
        id="file-input"
        name="file-input"
        type="file"
        onChange={handleUpload}
      />
    </div>
  );
};

export const BreadCrumb = styled.p`
  color: ${(p) => p.theme.colors.neutral4};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  height: 40px;
  width: 325px;
`;

export const BreadCrumbLink = styled.span`
  color: ${(p) => p.theme.colors.neutral4} !important;
  font-weight: 500 !important;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral3} !important;
  }
`;

export const Card = styled.div`
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  padding: 16px;
  border-radius: 5px;
  margin-top: 8px;
  position: relative;
  .card-actions {
    display: none;
  }
  &:hover {
    .card-actions {
      display: inherit;
    }
  }
`;

export const CardTitle = styled(Link)`
  font-size: 14px;
  margin: 0px;
  display: block;
`;

export const CardCaption = styled.div`
  font-size: 14px;
  margin: 0px;
  display: block;
`;

export const CardImg = styled.img`
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.neutral10};
  height: 32px;
  width: 32px;
  margin-right: 8px;
`;

export const ImgPlaceholder = styled.div`
  border-radius: 50%;
  background: ${(p) => p.theme.colors.neutral10};
  height: 32px;
  width: 32px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardAvatar = (props: {
  src?: string | undefined;
  type?: "projects" | "contacts" | "businesses";
}) => {
  const ICON_PROPS = {
    style: {
      fontSize: 20,
      color: theme.colors.neutral7,
    },
  };
  if (props.src) {
    return <CardImg {...props} />;
  }
  if (props.type === "contacts") {
    return (
      <ImgPlaceholder>
        <UserOutlined {...ICON_PROPS} />
      </ImgPlaceholder>
    );
  }
  if (props.type === "projects") {
    return (
      <ImgPlaceholder>
        <FileTextOutlined {...ICON_PROPS} />
      </ImgPlaceholder>
    );
  }
  if (props.type === "businesses") {
    return (
      <ImgPlaceholder>
        <BuildingFilled
          height={ICON_PROPS?.style?.fontSize}
          width={ICON_PROPS?.style?.fontSize}
          fill={ICON_PROPS?.style?.color}
        />
      </ImgPlaceholder>
    );
  }
  return (
    <ImgPlaceholder>
      <TeamOutlined {...ICON_PROPS} />
    </ImgPlaceholder>
  );
};

export const CardActions = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const RemoveBtn = styled.button`
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  background: ${(p) => p.theme.colors.neutral10};
  color: ${(p) => p.theme.colors.neutral3};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.neutral2};
    border: 1px solid ${(p) => p.theme.colors.neutral8};
    background: ${(p) => p.theme.colors.neutral9};
  }
`;
