import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import {
  ListItemFragmentFragment,
  useGetOneListWithListItemsQuery,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import { useParams } from "react-router-dom";
import { PageTitle } from "routes/app-automations-create/shared";

export default function AppListDetail() {
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();
  const params = useParams<{ id: string }>();
  const { data, loading } = useGetOneListWithListItemsQuery({
    variables: {
      id: params?.id || "",
      listItemPagination: {
        limit,
        skip,
      },
    },
    skip: !params?.id,
  });
  const total = data?.getOneList?.listItems?.count;

  const cols = [
    {
      title: "Name",
      render: (row: ListItemFragmentFragment) => getFullName(row?.contact),
    },
    {
      title: "Email",
      render: (row: ListItemFragmentFragment) => row?.contact?.email,
    },
    {
      title: "Relationship",
      render: (row: ListItemFragmentFragment) => row?.business?.title,
    },
  ];

  return (
    <PageContainer>
      <PageTitle>{data?.getOneList?.name}</PageTitle>
      <Table
        dataSource={data?.getOneList?.listItems?.results}
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: limit,
          total: total ? total : 0,
          onChange: (page, pageSize) => {
            onTablePaginationChange(page, pageSize);
          },
          showSizeChanger: true,
          current: currentPage,
        }}
        loading={loading}
        columns={cols}
        rowKey={(record) => record.id}
      />
    </PageContainer>
  );
}
