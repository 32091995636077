import {
  InteractionParams,
  useSaveInteractionMutation,
} from "generated/graphql";
import moment from "moment";
import removeTypename from "lib/helpers/removeTypename";
import { useNavigate } from "react-router-dom";

const getInteractionValues = (params: InteractionParams) => {
  const { title, description, type, date, attachments } = params;
  let shapedTodo = {
    ...params,
    title: title || null,
    description: description || null,
    type: type || null,
    date: date ? date.valueOf().toString() : moment().valueOf().toString(),
    attachments: attachments,
  };

  return removeTypename(shapedTodo);
};

export default function useSaveInteraction() {
  const [saveInteraction, { loading: savingInteraction }] =
    useSaveInteractionMutation();

  const navigate = useNavigate();

  const onSubmitInteraction = async (
    params: InteractionParams,
    onComplete?: () => void,
    id?: string,
    redirectToNewItem?: boolean
  ) => {
    try {
      const result = await saveInteraction({
        variables: {
          id,
          params: getInteractionValues(params),
        },
        refetchQueries: ["interactions"],
      });

      if (redirectToNewItem) {
        const busienssId = result?.data?.saveInteraction?.businessId;
        if (busienssId) {
          const url = `/app/businesses/${busienssId}?tab=5`;
          return navigate(url);
        }
        const contactId = result?.data?.saveInteraction?.contactId;
        if (contactId) {
          return navigate(`/app/contacts/${contactId}?tab=5`);
        }
      }

      onComplete && onComplete();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitInteraction,
    savingInteraction,
  };
}
