import { useCreateNylasGrantMutation } from "generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import Loading from "../../components/common/Loading";
import { message } from "antd";

export default function PublicNylasOAuth() {
  const [createNylasGrantMutation, { loading, error }] =
    useCreateNylasGrantMutation();

  const location = useLocation();
  const navigate = useNavigate();

  const finishGrant = useCallback(
    async (code: string) => {
      try {
        if (!code) throw new Error("code is required");
        const result = await createNylasGrantMutation({
          variables: {
            code,
          },
        });
        if (result?.data?.createNylasGrant?.success) {
          message.success(`Your account was successfully connected`);
          return navigate(`/app/settings?sidenav=email`);
        }
      } catch (err: any) {
        message.error(err?.message);
      }
    },
    [createNylasGrantMutation, navigate]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      console.log(" code : ", code);
      finishGrant(code);
    }
  }, [location.search, createNylasGrantMutation, finishGrant]);

  return <div>{loading && <Loading />}</div>;
}

// need to grab the code from the URL and pass it to the grant endpoint
// // http://localhost:3000/oauth/nylas/callback?code=58wyn_-kiAt_3OzsDpiRr28Zivr7BgNNyDHQYcI9AuaeYYZul9QjdsAMYITYUsFGObJY2DUJGc5qGFJuChQfQJQ70Bh0OSVJ
