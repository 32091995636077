import { useState } from "react";
import styled from "styled-components";
// components
import FormItem from "components/common/FormItem";
import PasswordInput from "components/inputs/PasswordInput";
import Button from "components/common/Button";
// COMPONENTS
import Caption from "components/text/Caption";
import logo from "lib/media/logo2.svg";
import message from "components/common/message";
import client from "ApolloClient/index";
import { Link, useLocation, useParams } from "react-router-dom";
// LIB
import constants from "lib/config";
import useCheckPasswordErrors from "lib/hooks/useCheckPasswordErrors";
import {
  CurrentUserDocument,
  useCheckResetQuery,
  useResetPasswordMutation,
} from "generated/graphql";
import setLocalStorage from "lib/helpers/setLocalStorage";
import Loading from "components/common/Loading";
import Text from "components/text/Text";

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const FormContainer = styled.form`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: auto;
  padding-top: 20px;
  background: #fff;
  padding: 32px 64px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  width: 100%;
  display: flex;
  height: 100vh;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`;

const Logo = styled.img`
  height: 50px;
  margin: auto;
`;
const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const ResetPassword = () => {
  const location = useLocation();
  const params = useParams();
  const IS_RESET_FORM = location?.pathname?.includes("reset");

  const { data, loading: loadingData } = useCheckResetQuery({
    variables: {
      token: params.token || "",
    },
    skip: !params.token,
  });

  const { checkPasswordErrors } = useCheckPasswordErrors();
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
    agreeToTerms?: string;
  }>({
    password: undefined,
    confirmPassword: undefined,
    agreeToTerms: undefined,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword] = useResetPasswordMutation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmedPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    try {
      e?.preventDefault();
      setErrors({});
      if (password !== confirmPassword) {
        return setErrors({
          confirmPassword: "Passwords do not match.",
        });
      }

      if (typeof params.token === "string") {
        setLoading(true);
        let res = await resetPassword({
          variables: {
            newPassword: password,
            token: params.token || "",
          },
        });

        message.success("Password reset. Logging you in...");
        const accessToken = res?.data?.resetPassword?.tokens?.accessToken;
        const refreshToken = res?.data?.resetPassword?.tokens?.refreshToken;
        if (
          typeof accessToken === "string" &&
          typeof refreshToken === "string"
        ) {
          setLocalStorage(constants.authTokenName, accessToken);
          setLocalStorage(constants.refreshTokenName, refreshToken);
          setTimeout(async () => {
            await client.query({
              query: CurrentUserDocument,
              fetchPolicy: "network-only",
            });
          }, 500);
        }
      }
    } catch (err: any) {
      console.log();
      setLoading(false);
      message.error(err?.message);
    }
  };

  const onPasswordChange = (password) => {
    setPassword(password);
    const error = checkPasswordErrors(password);
    const newErrors = { ...errors };
    if (error) {
      newErrors.password = error;
    } else {
      newErrors.password = undefined;
    }
    if (confirmPassword && confirmPassword !== password) {
      newErrors.confirmPassword = "Your passwords do not match";
    } else {
      newErrors.confirmPassword = undefined;
    }
    setErrors(newErrors);
  };

  const onConfirmPasswordChange = (confirmPassword) => {
    setConfirmedPassword(confirmPassword);
    const error = checkPasswordErrors(password);
    const newErrors = { ...errors };
    if (error) {
      newErrors.password = error;
    } else {
      newErrors.password = undefined;
    }
    if (confirmPassword && confirmPassword !== password) {
      newErrors.confirmPassword = "Your passwords do not match";
    } else {
      newErrors.confirmPassword = undefined;
    }
    setErrors(newErrors);
  };

  const disabled =
    /**
     * check to see if they agreed to terms, if they haven't the disabled button logic
     * will include if they've checked off/agreed to the terms.
     * If they already agreed, we just look at if the passwords a valid, match, etc
     */
    checkPasswordErrors(password) ||
    errors?.confirmPassword || // if there is a confirm password error
    errors?.password || // if there is a password error
    !password?.[0] || // if the password does not exist
    !confirmPassword?.[0] || // if the confirm password does not exist
    password !== confirmPassword; //

  if (loadingData) {
    return <Loading />;
  }

  if (!data?.checkReset?.valid) {
    return (
      <Container
        onSubmit={onSubmit}
        id={IS_RESET_FORM ? "reset-form" : "set-form"}
      >
        <FormContainer onSubmit={onSubmit}>
          <TitleContainer>
            <Logo src={logo} />
          </TitleContainer>
          <Text style={{ textAlign: "center" }}>
            <strong>
              {IS_RESET_FORM
                ? "Your password reset has expired"
                : `Your Growlab invitation has expired`}
            </strong>
          </Text>
          <Caption style={{ textAlign: "center" }}>
            {IS_RESET_FORM ? (
              <>
                You can request a new password reset email{" "}
                <Link to="/forgot-password">here</Link>
              </>
            ) : (
              <>
                Please reach out to your Growlab adminstrator to request a new
                email invitation.
                {/* <br />
                Or, you can also request a password reset email{" "}
                <Link to="/forgot-password">here</Link>. */}
              </>
            )}
          </Caption>
        </FormContainer>
      </Container>
    );
  }

  return (
    <Container
      onSubmit={onSubmit}
      id={IS_RESET_FORM ? "reset-form" : "set-form"}
    >
      <FormContainer onSubmit={onSubmit}>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{ margin: "16px auto" }}>
            {IS_RESET_FORM ? "Reset your password" : `Create your account`}
          </Caption>
        </TitleContainer>

        <FormItem
          label={IS_RESET_FORM ? "New password" : `Choose a password`}
          error={errors.password}
          hint="Minimum 8 characters with a number, uppercase and lowercase letter"
        >
          <PasswordInput
            value={password}
            onChange={onPasswordChange}
            // placeholder={i18n.t(`SetPassword.newPassword.placeholder`)}
            onToggleShowPassword={() => setShowPassword(!showPassword)}
            type={showPassword ? "text" : "password"}
            showPassword={showPassword}
            data-testid="new-password-input"
          />
        </FormItem>
        <FormItem label="Confirm New Password" error={errors.confirmPassword}>
          <PasswordInput
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
            onBlur={() => {
              if (confirmPassword !== password) {
                return setErrors({
                  confirmPassword: "Your passwords do not match",
                });
              }
            }}
            data-testid="confirm-password-input"
            // placeholder={i18n.t(`SetPassword.confirmPassword.placeholder`)}
            onToggleShowPassword={() => setShowPassword(!showPassword)}
            type={showPassword ? "text" : "password"}
            showPassword={showPassword}
          />
        </FormItem>

        <ButtonContainer>
          <Button
            disabled={disabled || loading ? true : false}
            loading={loading ? true : undefined}
            onClick={onSubmit}
            fullWidth
          >
            Set password
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

export default ResetPassword;
