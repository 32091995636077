// TOP LEVEL IMPORTS
import queryString from "query-string";
import { useLocation } from "react-router-dom";
//
import Drawer from "components/common/Drawer";
import CaseForm from "components/forms/CaseForm";
import TodoForm from "components/forms/TodoForm";
import NoteForm from "components/forms/NoteForm";
import InteractionForm from "components/forms/InteractionForm";
import OrganizationForm from "components/forms/OrganizationForm";
import BusinessForm from "components/forms/BusinessForm";
import ContactForm from "components/forms/ContactForm";
import FundingForm from "components/forms/FundingForm";
// HOOKS
import useSaveTodo from "./useSaveTodo";
import useSaveCase from "./useSaveCase";
import useSaveOrganization from "./useSaveOrganization";
import useSaveNote from "./useSaveNote";
import useSaveInteraction from "./useSaveInteraction";
import useSaveBusiness from "./useSaveBusiness";
// import useSaveProject from './useSaveProject';
import useSaveContact from "./useSaveContact";
// LIB
import formTypes from "lib/constants/formTypes";
import useUrlChange from "hooks/useUrlChange";

import useSaveFundingProgram from "./useSaveFundingProgram";
import {
  InteractionTypeEnum,
  useGetOneInteractionQuery,
  useGetOneNoteQuery,
} from "generated/graphql";
import ProgramForm from "components/forms/ProgramForm";
import SiteForm from "components/forms/SiteForm";
import ProjectForm from "components/detail-components/ProjectForm/index2";
import useSaveProject from "./useSaveProject";
import useSaveSupportProgram from "./useSaveSupportProgram";
import useSaveSite from "./useSaveSite";

const getTitle = (type) => {
  if (type === formTypes.TODOS) {
    return "Create a todo";
  }
  if (type === formTypes.FUNDING) {
    return "Add funding program";
  }
  if (type === formTypes.BUSINESSES) {
    return "Create a Business";
  }
  if (type === formTypes.PROJECTS) {
    return "Create a Project";
  }
  if (type === formTypes.SITE) {
    return "Create a Site";
  }
  if (type === formTypes.SUPPORT) {
    return "Create a Support Resource";
  }
  if (type === formTypes.EMAIL) {
    return "Log an Email";
  }
  if (type === formTypes.PHONE) {
    return "Log a Call";
  }
  if (type === formTypes.NOTES) {
    return "Create a Note";
  }
  if (type === formTypes.VISIT) {
    return "Log a Visit";
  }
  if (type === formTypes.CONTACTS) {
    return "Create a Person";
  }
  if (type === formTypes.CASES) {
    return "Create a Case";
  }
  if (type === formTypes.ORGANIZATIONS) {
    return "Organization Details";
  }
};

const CLEAR_URL_STATE = {
  drawerVisible: undefined,
  businessId: undefined,
  projectId: undefined,
  contactId: undefined,
  siteId: undefined,
  editInteractionId: undefined,
  editNoteId: undefined,
};

const INTERACTION_TYPES = [formTypes.VISIT, formTypes.PHONE, formTypes.EMAIL];

const getInteractionType = (drawerVisible) => {
  switch (drawerVisible) {
    case formTypes.VISIT:
      return InteractionTypeEnum.Visit;
    case formTypes.PHONE:
      return InteractionTypeEnum.Phone;
    case formTypes.EMAIL:
      return InteractionTypeEnum.Email;
    default:
      return null;
  }
};

export default function GlobalDrawer({ currentUser }) {
  const location = useLocation();
  const {
    drawerVisible,
    siteId,
    businessId,
    projectId,
    contactId,
    editNoteId,
    organizationId,
    editInteractionId, // the id of the interaction we're editing
    selectedPipeline,
  } = queryString.parse(location.search);

  const { onUrlChange } = useUrlChange();
  const { onSaveCase, savingCase } = useSaveCase();
  const { onSaveTodo, savingTodo } = useSaveTodo();
  const { onSaveOrganization, savingOrganization } = useSaveOrganization();
  const { onSubmitNote, savingNote } = useSaveNote();
  const { onSubmitInteraction, savingInteraction } = useSaveInteraction();
  const { onSubmitBusiness, savingBusiness } = useSaveBusiness();
  const { onSubmitContact, savingContact } = useSaveContact();
  const { onSubmitFundingProgram, saveingFundingProgram } =
    useSaveFundingProgram();
  const { onSubmitProject, savingProject } = useSaveProject();

  const { onSubmitSupportProgram } = useSaveSupportProgram();

  const { onSaveSite } = useSaveSite();

  const { data: noteQuery } = useGetOneNoteQuery({
    variables: {
      id: editNoteId as string,
    },
    skip: !editNoteId,
  });
  const url = window.location.href;

  const { data: interactionQuery } = useGetOneInteractionQuery({
    variables: {
      id: editInteractionId as string,
    },
    skip: !editInteractionId,
  });

  const loading =
    savingCase ||
    savingTodo ||
    savingOrganization ||
    savingNote ||
    savingInteraction ||
    savingContact ||
    savingProject ||
    savingBusiness ||
    saveingFundingProgram;

  const defaultValues = {
    businessId,
    projectId,
    contactId,
    siteId,
    organizationId,
    pipeline: selectedPipeline,
  };

  const onClose = (otherUrlParams?: object) => {
    onUrlChange({ ...CLEAR_URL_STATE, ...otherUrlParams });
  };

  // id is passed if we're updating the values VS creating a new one
  const onSubmit = async (values, id?: string) => {
    try {
      if (drawerVisible === formTypes.CASES) {
        const newCase = await onSaveCase(values);
        return onClose({
          selectedCase: newCase?.id,
        });
      }
      if (drawerVisible === formTypes.TODOS) {
        const todo = await onSaveTodo(values);
        return onUrlChange({ selectedTodo: todo?.id, ...CLEAR_URL_STATE });
      }
      if (drawerVisible === formTypes.ORGANIZATIONS) {
        await onSaveOrganization(values);
      }
      if (drawerVisible === formTypes.NOTES) {
        await onSubmitNote(
          values,
          editNoteId ? (editNoteId as string) : undefined
        );
      }
      if (drawerVisible === formTypes.BUSINESSES) {
        await onSubmitBusiness(values);
      }
      if (drawerVisible === formTypes.CONTACTS) {
        await onSubmitContact(values);
      }
      if (drawerVisible === formTypes.FUNDING) {
        await onSubmitFundingProgram(values);
      }
      if (drawerVisible === formTypes.SITE) {
        await onSaveSite(values);
      }
      if (drawerVisible === formTypes.PROJECTS) {
        await onSubmitProject(values);
      }
      if (drawerVisible === formTypes.SUPPORT) {
        await onSubmitSupportProgram(values);
      }
      if (INTERACTION_TYPES.includes(drawerVisible as string)) {
        return await onSubmitInteraction(
          {
            ...values,
            type: getInteractionType(drawerVisible),
          },
          () => onClose(),
          editInteractionId ? (editInteractionId as string) : undefined,
          true
        );
      }

      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer
      title={getTitle(drawerVisible)}
      onClose={() => onClose()}
      visible={drawerVisible ? true : false}
    >
      {drawerVisible === formTypes.NOTES && (
        <NoteForm
          defaultValues={{
            ...defaultValues,
            ...noteQuery?.getOneNote,
          }}
          loading={loading}
          onSubmit={onSubmit}
          customer={currentUser?.customer}
          onCancel={() => onClose()}
        />
      )}
      {drawerVisible === formTypes.CASES && (
        <CaseForm
          defaultValues={{
            reporterId: currentUser?.id,
            ...defaultValues,
          }}
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
          submitBtnText="Submit"
        />
      )}
      {drawerVisible === formTypes.BUSINESSES && (
        <BusinessForm
          defaultValues={defaultValues}
          loading={loading}
          onSubmit={onSubmit}
          onSaveExisting={onSubmit}
          onCancel={() => onClose()}
        />
      )}

      {drawerVisible === formTypes.ORGANIZATIONS && (
        <OrganizationForm
          loading={loading}
          onSubmit={onSubmit}
          onClose={() => onClose()}
        />
      )}
      {drawerVisible === formTypes.PROJECTS && (
        <ProjectForm
          defaultValue={defaultValues}
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
        />
      )}
      {drawerVisible === formTypes.TODOS && (
        <TodoForm
          defaultValues={defaultValues}
          loading={loading}
          onSubmit={onSubmit}
          customer={currentUser?.customer}
          onCancel={() => onClose()}
        />
      )}
      {drawerVisible === formTypes.CONTACTS && (
        <ContactForm
          defaultValues={defaultValues}
          parentType={null}
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
        />
      )}
      {drawerVisible === formTypes.FUNDING && (
        <FundingForm
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
          defaultValue={defaultValues}
        />
      )}
      {drawerVisible === formTypes.SUPPORT && (
        <ProgramForm
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
          defaultValue={defaultValues}
        />
      )}
      {drawerVisible === formTypes.SITE && (
        <SiteForm
          loading={loading}
          onSubmit={onSubmit}
          onCancel={() => onClose()}
          defaultValue={defaultValues}
        />
      )}
      {INTERACTION_TYPES.includes(drawerVisible as string) && (
        <InteractionForm
          defaultValues={{
            ...defaultValues,
            ...interactionQuery?.getOneInteraction,
          }}
          loading={loading}
          onSubmit={onSubmit}
          customer={currentUser?.customer}
          onCancel={() => onClose()}
        />
      )}
    </Drawer>
  );
}
