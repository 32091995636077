import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  Upload: any;
  UtcOffset: any;
  Void: any;
};

export type ActivityItem = {
  __typename?: "ActivityItem";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  activityType?: Maybe<ActivityTypeEnum>;
  parent?: Maybe<ActivityItemParentEnum>;
  parentId?: Maybe<Scalars["String"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<ActivityItemSourceEnum>;
  createdAt?: Maybe<Scalars["String"]>;
  interaction?: Maybe<Interaction>;
  case?: Maybe<Case>;
  /** This is the date that was used for sorting the list. Might be the createdAt date or might be the interaction.date field, depending on the activity item */
  sortDate: Scalars["String"];
};

export enum ActivityItemParentEnum {
  Businesses = "Businesses",
  Contacts = "Contacts",
  Projects = "Projects",
  Cases = "Cases",
  Surveys = "Surveys",
  Todos = "Todos",
  Sites = "Sites",
  Notes = "Notes",
  Organizations = "Organizations",
}

export enum ActivityItemSourceEnum {
  Notes = "Notes",
  Attachments = "Attachments",
  Todos = "Todos",
  Interactions = "Interactions",
  Cases = "Cases",
  SurveyResponses = "SurveyResponses",
  Sites = "Sites",
}

export enum ActivityTypeEnum {
  NoteAdded = "noteAdded",
  ProjectActivity = "projectActivity",
  AttachmentAdded = "attachmentAdded",
  TaskAdded = "taskAdded",
  GeneralActivity = "generalActivity",
  Email = "email",
  Phone = "phone",
  Visit = "visit",
  TodoCreated = "todoCreated",
  SurveyResponse = "SURVEY_RESPONSE",
  TodoUpdated = "TODO_UPDATED",
  CaseCreated = "CASE_CREATED",
  CaseStatusUpdated = "CASE_STATUS_UPDATED",
  SiteAdded = "SITE_ADDED",
  CaseClosed = "CASE_CLOSED",
}

export type Attachment = {
  __typename?: "Attachment";
  id?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
  baseUrl?: Maybe<Scalars["String"]>;
  mimetype?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  encoding?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  fileType?: Maybe<Scalars["String"]>;
  base64?: Maybe<Scalars["String"]>;
  /** id of who created it */
  createdBy?: Maybe<Scalars["String"]>;
  /** denomormalized name of who uploaded it */
  createdByName?: Maybe<Scalars["String"]>;
  /** the id of the business, project, contact, todo or other item this attachment is associated with */
  parentId?: Maybe<Scalars["String"]>;
};

export type AttachmentParams = {
  id?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  baseUrl?: Maybe<Scalars["String"]>;
  mimetype?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  encoding?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  fileType?: Maybe<Scalars["String"]>;
  base64?: Maybe<Scalars["String"]>;
  /** id of who created it */
  createdBy?: Maybe<Scalars["String"]>;
  /** denomormalized name of who uploaded it */
  createdByName?: Maybe<Scalars["String"]>;
  /** the id of the business, project, contact, todo or other item this attachment is associated with */
  parentId?: Maybe<Scalars["String"]>;
};

export type AuthenticateParamsInput = {
  access_token?: Maybe<Scalars["String"]>;
  access_token_secret?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  user?: Maybe<UserInput>;
  code?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type Automation = {
  __typename?: "Automation";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  /** Stringified object of json-rules-engine conditions */
  conditions: Scalars["String"];
  /** Stringified array of events */
  events: Scalars["String"];
  pipelineId?: Maybe<Scalars["String"]>;
  trigger: AutomationTriggerType;
  lastRun?: Maybe<Scalars["String"]>;
};

export enum AutomationEventType {
  AddBusinessToConstantContactList = "ADD_BUSINESS_TO_CONSTANT_CONTACT_LIST",
  NotifyTeamMember = "NOTIFY_TEAM_MEMBER",
  CreateACase = "CREATE_A_CASE",
}

export type AutomationInput = {
  name: Scalars["String"];
  /** Stringified object of json-rules-engine conditions */
  conditions: Scalars["String"];
  /** Stringified array of events */
  events: Scalars["String"];
  pipelineId?: Maybe<Scalars["String"]>;
  formId?: Maybe<Scalars["String"]>;
  trigger: AutomationTriggerType;
};

export enum AutomationTriggerType {
  FormSurveyResponse = "FORM_SURVEY_RESPONSE",
  ProjectStatusChange = "PROJECT_STATUS_CHANGE",
}

export type Business = {
  __typename?: "Business";
  id: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  optResult?: Maybe<Scalars["Boolean"]>;
  tradeName?: Maybe<Scalars["String"]>;
  businessSectorId?: Maybe<Scalars["String"]>;
  businessSector?: Maybe<BusinessSector>;
  logo?: Maybe<Attachment>;
  cases?: Maybe<Array<Case>>;
  description?: Maybe<Scalars["String"]>;
  contactIds?: Maybe<Array<Scalars["String"]>>;
  phone?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  numberOfRetailLocations?: Maybe<Scalars["Float"]>;
  apolloAccountId?: Maybe<Scalars["String"]>;
  projects?: Maybe<Array<Project>>;
  /** What sorts of economic development services does this business need */
  serviceNeeds?: Maybe<Array<BusinessNeedEnum>>;
  industries?: Maybe<Array<Maybe<Scalars["String"]>>>;
  clusters?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Boolean telling us if this business is okay recieving surveys by email or text */
  surveyPermission?: Maybe<Scalars["Boolean"]>;
  /** Naics codes associated with this business */
  naics?: Maybe<Array<Naic>>;
  /** The list of diversity tags for this business */
  diversity?: Maybe<Array<Scalars["String"]>>;
  /** The list of amenity tags for this business */
  amenities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The number of employees working at this business */
  employeeCount?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Tag>>;
  naicsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** What stage is this business in? */
  businessStage?: Maybe<BusinessStageEnum>;
  /** What type of business is this? */
  businessType?: Maybe<BusinessTypeEnum>;
  /** The primary contact for this contact */
  primaryContact?: Maybe<Contact>;
  /** Contacts related to this business */
  contacts?: Maybe<Array<Contact>>;
  /** Field for tracking if this business a current business, past business or a prospective business? */
  status?: Maybe<BusinessStatusEnum>;
  /** The date this business was opened */
  opened?: Maybe<Scalars["String"]>;
  /** The date the business was closed */
  closed?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  /** A list of interactions for this business */
  interactions?: Maybe<Array<Interaction>>;
  annualRevenue?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  /** like the suite # or office # */
  street2?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  companyInvestments?: Maybe<Array<Maybe<CompanyInvestment>>>;
  owner?: Maybe<UserProfile>;
};

export enum BusinessNeedEnum {
  Workforce = "workforce",
  RealEstate = "realEstate",
  ResearchAndProductDev = "researchAndProductDev",
  SalesAndMarketing = "salesAndMarketing",
  LegalAssistance = "legalAssistance",
  Funding = "funding",
  FinancialManagement = "financialManagement",
  BusinessPlanning = "businessPlanning",
}

export type BusinessParams = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  logo?: Maybe<AttachmentParams>;
  description?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  optResult?: Maybe<Scalars["Boolean"]>;
  closed?: Maybe<Scalars["String"]>;
  businessSectorId?: Maybe<Scalars["String"]>;
  opened?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  numberOfRetailLocations?: Maybe<Scalars["Int"]>;
  apolloAccountId?: Maybe<Scalars["String"]>;
  /** The ID of the customer team member who is responsbile (or 'the lead') for this business relationship */
  ownerId?: Maybe<Scalars["String"]>;
  /** The contact id of the primary contact for this business */
  primaryContactId?: Maybe<Scalars["String"]>;
  /** What sorts of economic development services does this business need */
  serviceNeeds?: Maybe<Array<Maybe<BusinessNeedEnum>>>;
  /** Field for tracking if this business a current business, past business or a prospective business? */
  status?: Maybe<BusinessStatusEnum>;
  industries?: Maybe<Array<Maybe<Scalars["String"]>>>;
  clusters?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Boolean telling us if this business is okay recieving surveys by email or text */
  surveyPermission?: Maybe<Scalars["Boolean"]>;
  /** Naics codes associated with this business */
  naicsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The list of diversity tags for this business */
  diversity?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The list of amenity tags for this business */
  amenities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The number of employees working at this business */
  employeeCount?: Maybe<Scalars["Int"]>;
  tagIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** What stage is this business in? */
  businessStage?: Maybe<BusinessStageEnum>;
  /** What type of business is this? */
  businessType?: Maybe<BusinessTypeEnum>;
  /** The ideas of the contacts associated with this business */
  contactIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  /** like the suite # or office # */
  street2?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  annualRevenue?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  tradeName?: Maybe<Scalars["String"]>;
};

export type BusinessSearchParams = {
  searchText?: Maybe<Scalars["String"]>;
  tagIds?: Maybe<Array<Scalars["String"]>>;
  clusters?: Maybe<Array<Scalars["String"]>>;
  contactIds?: Maybe<Array<Scalars["ID"]>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  statuses?: Maybe<Array<BusinessStatusEnum>>;
  diversity?: Maybe<Array<Scalars["String"]>>;
  businessStages?: Maybe<Array<Scalars["String"]>>;
  businessSectorIds?: Maybe<Array<Scalars["ID"]>>;
  /** Query when a business opened. Takes a start date string and end date string */
  opened?: Maybe<Array<Scalars["String"]>>;
  closed?: Maybe<Array<Scalars["String"]>>;
  naicsIds?: Maybe<Array<Scalars["String"]>>;
};

export type BusinessSector = {
  __typename?: "BusinessSector";
  id: Scalars["String"];
  title: Scalars["String"];
};

export enum BusinessStageEnum {
  Idea = "idea",
  Startup = "startup",
  Existing = "existing",
}

export enum BusinessStatusEnum {
  Prospect = "prospect",
  Current = "current",
  Former = "former",
  Closed = "closed",
}

export enum BusinessTypeEnum {
  Unknown = "unknown",
  Llc = "llc",
  Ccorp = "ccorp",
  SoleProprietor = "soleProprietor",
}

export type BusinessesByClusterResponse = {
  __typename?: "BusinessesByClusterResponse";
  title?: Maybe<Scalars["String"]>;
  numberOfBusinesses?: Maybe<Scalars["Int"]>;
};

export type BusinessesByNaicsResponse = {
  __typename?: "BusinessesByNaicsResponse";
  title?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  numberOfBusinesses?: Maybe<Scalars["Int"]>;
};

export type BusinessesSearchResponse = {
  __typename?: "BusinessesSearchResponse";
  businesses?: Maybe<Array<Business>>;
  count?: Maybe<Scalars["Int"]>;
};

export type Case = {
  __typename?: "Case";
  id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** an array of services that this case required */
  serviceIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  services?: Maybe<Array<Maybe<CaseService>>>;
  businessId?: Maybe<Scalars["ID"]>;
  business?: Maybe<Business>;
  project?: Maybe<Project>;
  contactId?: Maybe<Scalars["ID"]>;
  contact?: Maybe<Contact>;
  caseStatusId?: Maybe<Scalars["String"]>;
  caseStatus?: Maybe<CaseStatus>;
  /** IDs of the users who are assigned tot his case */
  assignedToId?: Maybe<Scalars["ID"]>;
  assignedTo?: Maybe<UserProfile>;
  caseCategoryId?: Maybe<Scalars["String"]>;
  caseCategory?: Maybe<CaseCategory>;
  resolutionNotes?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  resolvedOn?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  /** Number of todos that are not closed */
  openTodos?: Maybe<Scalars["Int"]>;
  /** number of todos that have a isFinalStep status  */
  closedTodos?: Maybe<Scalars["Int"]>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  caseOutcomes?: Maybe<Array<Maybe<CaseOutcome>>>;
  reporter?: Maybe<UserProfile>;
  reporterId?: Maybe<Scalars["ID"]>;
};

export type CaseCategory = {
  __typename?: "CaseCategory";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CaseCategoryInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CaseInput = {
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  caseStatusId?: Maybe<Scalars["String"]>;
  businessId?: Maybe<Scalars["String"]>;
  serviceIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  caseCategoryId?: Maybe<Scalars["String"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  resolutionNotes?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  dueDate?: Maybe<Scalars["String"]>;
  resolvedOn?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["ID"]>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  caseOutcomeIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  todos?: Maybe<Array<CaseTemplateTodoInput>>;
  reporterId?: Maybe<Scalars["ID"]>;
};

export type CaseOutcome = {
  __typename?: "CaseOutcome";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CaseService = {
  __typename?: "CaseService";
  id: Scalars["String"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CaseServiceInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CaseStatus = {
  __typename?: "CaseStatus";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  isFinalStep?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Int"]>;
};

export type CaseStatusInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CaseStatusReportResult = {
  __typename?: "CaseStatusReportResult";
  id?: Maybe<Scalars["ID"]>;
  status?: Maybe<CaseStatus>;
  total?: Maybe<Scalars["Int"]>;
};

export type CaseTemplate = {
  __typename?: "CaseTemplate";
  id: Scalars["ID"];
  templateName: Scalars["String"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  caseCategoryId?: Maybe<Scalars["ID"]>;
  todos?: Maybe<Array<CaseTemplateTodo>>;
};

export type CaseTemplateInput = {
  id?: Maybe<Scalars["ID"]>;
  templateName: Scalars["String"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  caseCategoryId?: Maybe<Scalars["ID"]>;
  todos?: Maybe<Array<CaseTemplateTodoInput>>;
};

export type CaseTemplateTodo = {
  __typename?: "CaseTemplateTodo";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  /** either an ID or caseAssignee */
  assignedTo?: Maybe<Scalars["String"]>;
};

export type CaseTemplateTodoInput = {
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  /** either an ID or caseAssignee */
  assignedTo?: Maybe<Scalars["String"]>;
};

export type CasesResponse = {
  __typename?: "CasesResponse";
  result?: Maybe<Array<Maybe<Case>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type CheckResetResponse = {
  __typename?: "CheckResetResponse";
  valid?: Maybe<Scalars["Boolean"]>;
};

export type Cluster = {
  __typename?: "Cluster";
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type Comment = {
  __typename?: "Comment";
  id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  todoId?: Maybe<Scalars["String"]>;
  noteId?: Maybe<Scalars["String"]>;
  caseId?: Maybe<Scalars["String"]>;
  createdByUser?: Maybe<UserProfile>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type CommentParams = {
  content?: Maybe<Scalars["String"]>;
  todoId?: Maybe<Scalars["String"]>;
  noteId?: Maybe<Scalars["String"]>;
  caseId?: Maybe<Scalars["String"]>;
};

export type CompanyApiResult = {
  __typename?: "CompanyAPIResult";
  /** If it already exists, businessId will hold the _id */
  businessId?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  employees?: Maybe<Scalars["String"]>;
  revenue?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  founded?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  /** Tells you whether the customer already has this buisness in their DB */
  exists?: Maybe<Scalars["Boolean"]>;
};

export type CompanyInvestment = {
  __typename?: "CompanyInvestment";
  id?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  /** date of the investment */
  investedOn?: Maybe<Scalars["String"]>;
};

export type ConstantContactCustomerIntegration = {
  __typename?: "ConstantContactCustomerIntegration";
  exists?: Maybe<Scalars["Boolean"]>;
};

export type ConstantContactList = {
  __typename?: "ConstantContactList";
  /** 7468fd52-4da5-11ed-ba89-fa163ec87d62 */
  list_id: Scalars["String"];
  /** General Interest */
  name: Scalars["String"];
  favorite?: Maybe<Scalars["String"]>;
  /** 2022-10-16T22:54:13Z */
  created_at?: Maybe<Scalars["String"]>;
  /** 2022-10-16T22:54:13Z */
  updated_at?: Maybe<Scalars["String"]>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  fullName?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  projects?: Maybe<Array<Project>>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
  avatar?: Maybe<Attachment>;
  title?: Maybe<Scalars["String"]>;
  /** If the contact has opted into communication. True is yes. False is No */
  optResult?: Maybe<Scalars["Boolean"]>;
  homePhone?: Maybe<Scalars["String"]>;
  cellPhone?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  status?: Maybe<ContactStatusEnum>;
  tagIds?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<Tag>>;
  businesses?: Maybe<Array<Business>>;
  /** Some contacts have user records attached to them (they can sign in). This is the id of their user record */
  userId?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
};

export type ContactInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  avatar?: Maybe<AttachmentParams>;
  homePhone?: Maybe<Scalars["String"]>;
  cellPhone?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  status?: Maybe<ContactStatusEnum>;
  tagIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  businessId?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  emailStatus?: Maybe<Scalars["String"]>;
  seniority?: Maybe<Scalars["String"]>;
  apolloContactId?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  business?: Maybe<BusinessParams>;
  /** If the contact has opted into communication. True is yes. False is No */
  optResult?: Maybe<Scalars["Boolean"]>;
};

export type ContactSearchParams = {
  searchText?: Maybe<Scalars["String"]>;
  statuses?: Maybe<Array<Maybe<ContactStatusEnum>>>;
  includesAtleastContactIds?: Maybe<Array<Scalars["String"]>>;
  tagIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contactIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  businessId?: Maybe<Scalars["ID"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export enum ContactStatusEnum {
  Contact = "contact",
  Client = "client",
  Lead = "lead",
}

export type ContactsSearchResponse = {
  __typename?: "ContactsSearchResponse";
  contacts?: Maybe<Array<Maybe<Contact>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type CreateNyasAuthResponse = {
  __typename?: "CreateNyasAuthResponse";
  url?: Maybe<Scalars["String"]>;
};

export type CreateUserInput = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type CreateUserResult = {
  __typename?: "CreateUserResult";
  userId?: Maybe<Scalars["ID"]>;
  loginResult?: Maybe<LoginResult>;
};

export type Customer = {
  __typename?: "Customer";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<UserProfile>>>;
  features?: Maybe<Array<Maybe<CustomerFeatureSetting>>>;
  integrations?: Maybe<CustomerIntegrations>;
  businessCount?: Maybe<Scalars["Int"]>;
  contactsCount?: Maybe<Scalars["Int"]>;
  integrationCount?: Maybe<Scalars["Int"]>;
  maximumEmailsPerDay?: Maybe<Scalars["Int"]>;
  maximumEmailsPerMinute?: Maybe<Scalars["Int"]>;
  sharingSettings?: Maybe<Array<SharingCustomerSetting>>;
  customIntakePageUrl?: Maybe<Scalars["String"]>;
};

export type CustomerFeatureSetting = {
  __typename?: "CustomerFeatureSetting";
  title?: Maybe<Scalars["String"]>;
  key?: Maybe<CustomerFeatureSettingKeyEnum>;
  active?: Maybe<Scalars["Boolean"]>;
  available?: Maybe<Scalars["Boolean"]>;
  featureSettings?: Maybe<Array<Maybe<SubFeatureSetting>>>;
};

export type CustomerFeatureSettingInput = {
  title?: Maybe<Scalars["String"]>;
  key?: Maybe<CustomerFeatureSettingKeyEnum>;
  active?: Maybe<Scalars["Boolean"]>;
  available?: Maybe<Scalars["Boolean"]>;
  featureSettings?: Maybe<Array<Maybe<SubFeatureSettingInput>>>;
};

export enum CustomerFeatureSettingKeyEnum {
  Projects = "PROJECTS",
  Surveys = "SURVEYS",
  Reports = "REPORTS",
  Resources = "RESOURCES",
  Cases = "CASES",
  Automations = "AUTOMATIONS",
  EmailCampaigns = "EMAIL_CAMPAIGNS",
  DataDiscovery = "DATA_DISCOVERY",
}

export type CustomerInput = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  features?: Maybe<Array<Maybe<CustomerFeatureSettingInput>>>;
  maximumEmailsPerDay?: Maybe<Scalars["Int"]>;
  maximumEmailsPerMinute?: Maybe<Scalars["Int"]>;
  customIntakePageUrl?: Maybe<Scalars["String"]>;
};

export type CustomerIntegrations = {
  __typename?: "CustomerIntegrations";
  constantContact?: Maybe<ConstantContactCustomerIntegration>;
};

export type CustomerResponse = {
  __typename?: "CustomerResponse";
  customers?: Maybe<Array<Maybe<Customer>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type DataDiscoveryBusiness = {
  __typename?: "DataDiscoveryBusiness";
  id?: Maybe<Scalars["ID"]>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  editorial_reviews?: Maybe<Scalars["String"]>;
  googleRating?: Maybe<Scalars["String"]>;
  google_places_id?: Maybe<Scalars["String"]>;
  google_places_type?: Maybe<Scalars["String"]>;
  hours?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  naics_code?: Maybe<Scalars["String"]>;
  naics_definition?: Maybe<Scalars["String"]>;
  operating_hours?: Maybe<Scalars["JSON"]>;
  phone?: Maybe<Scalars["String"]>;
  place_id_search?: Maybe<Scalars["String"]>;
  runId?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["JSON"]>;
  ignored?: Maybe<Scalars["Boolean"]>;
  existingBusinessId?: Maybe<Scalars["ID"]>;
  contacts?: Maybe<Array<Scalars["JSON"]>>;
  merged?: Maybe<Scalars["Boolean"]>;
};

export type DataDiscoveryRun = {
  __typename?: "DataDiscoveryRun";
  id: Scalars["ID"];
  status?: Maybe<DataDiscoveryRunStatusEnum>;
  createdAt?: Maybe<Scalars["String"]>;
  /** The total number of businesses found */
  businessesFound?: Maybe<Scalars["Int"]>;
  /** Of the businesses that were found, how many are new? */
  newBusinessesFound?: Maybe<Scalars["Int"]>;
  contactsFound?: Maybe<Scalars["Int"]>;
  mergedAt?: Maybe<Scalars["String"]>;
  mergedBusinesses?: Maybe<Scalars["Int"]>;
  mergedContacts?: Maybe<Scalars["Int"]>;
  searchTerms?: Maybe<Array<Scalars["String"]>>;
  businesses?: Maybe<DataDiscoveryRunBusinesses>;
};

export type DataDiscoveryRunBusinessesArgs = {
  newBusinesses?: Maybe<Scalars["Boolean"]>;
  ignored?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationInput>;
  hasContacts?: Maybe<Scalars["Boolean"]>;
};

export type DataDiscoveryRunBusinesses = {
  __typename?: "DataDiscoveryRunBusinesses";
  results?: Maybe<Array<DataDiscoveryBusiness>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum DataDiscoveryRunStatusEnum {
  RunCompleted = "run_completed",
  Merging = "merging",
  Merged = "merged",
  FailedToMerge = "failed_to_merge",
}

export type DefaultFormTemplateQuestionCategory = {
  __typename?: "DefaultFormTemplateQuestionCategory";
  id: Scalars["String"];
  questions?: Maybe<Array<FormQuestion>>;
};

export type DefaultSurveyTemplate = {
  __typename?: "DefaultSurveyTemplate";
  id: Scalars["ID"];
  createdBy: Scalars["ID"];
  template: Scalars["JSON"];
  title?: Maybe<Scalars["String"]>;
};

export type EmailCampaign = {
  __typename?: "EmailCampaign";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  fromAddress?: Maybe<Scalars["String"]>;
  replyToAddress?: Maybe<Scalars["String"]>;
  fromName?: Maybe<Scalars["String"]>;
  emailTemplate?: Maybe<Scalars["String"]>;
  campaignType: EmailCampaignTypeEnum;
  status?: Maybe<EmailCampaignStatusEnum>;
  createdAt?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["Float"]>;
  recipientReferences?: Maybe<Array<EmailCampaignRecipientReference>>;
  delivered?: Maybe<Scalars["Int"]>;
  recipients?: Maybe<Scalars["Int"]>;
  failed?: Maybe<Scalars["Int"]>;
  bounced?: Maybe<Scalars["Int"]>;
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  bounceRate?: Maybe<Scalars["Float"]>;
  openRate?: Maybe<Scalars["Float"]>;
  /** This is the user selected date that they want to sent the campaign. It should be UTC. */
  scheduledAt?: Maybe<Scalars["Float"]>;
  emailCampaignItems?: Maybe<EmailCampaignItemsResponse>;
};

export type EmailCampaignEmailCampaignItemsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type EmailCampaignInput = {
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  fromAddress?: Maybe<Scalars["String"]>;
  replyToAddress?: Maybe<Scalars["String"]>;
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  fromName?: Maybe<Scalars["String"]>;
  emailTemplate?: Maybe<Scalars["String"]>;
  campaignType?: Maybe<EmailCampaignTypeEnum>;
  recipientReferences?: Maybe<Array<EmailCampaignRecipientReferenceInput>>;
  /** This is the user selected date that they want to sent the campaign. It should be UTC. */
  scheduledAt?: Maybe<Scalars["Float"]>;
};

export type EmailCampaignItem = {
  __typename?: "EmailCampaignItem";
  id: Scalars["ID"];
  email: Scalars["String"];
  status?: Maybe<EmailCampaignItemStatusEnum>;
  scheduledAt?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  toEmail?: Maybe<Scalars["String"]>;
  failedReason?: Maybe<Scalars["String"]>;
};

export enum EmailCampaignItemStatusEnum {
  Scheduled = "scheduled",
  Sent = "sent",
  /** The email failed to be sent or scheduled */
  Failed = "failed",
  Bounced = "bounced",
  Opened = "opened",
  Cancelled = "cancelled",
}

export type EmailCampaignItemsResponse = {
  __typename?: "EmailCampaignItemsResponse";
  results?: Maybe<Array<EmailCampaignItem>>;
  count: Scalars["Int"];
};

export type EmailCampaignRecipientReference = {
  __typename?: "EmailCampaignRecipientReference";
  id: Scalars["ID"];
  title: Scalars["String"];
  referenceType?: Maybe<EmailCampaignRecipientReferenceEnum>;
};

export enum EmailCampaignRecipientReferenceEnum {
  List = "list",
  Tag = "tag",
  AllBusinesses = "all_businesses",
  AllContacts = "all_contacts",
}

export type EmailCampaignRecipientReferenceInput = {
  id: Scalars["ID"];
  title: Scalars["String"];
  referenceType?: Maybe<EmailCampaignRecipientReferenceEnum>;
};

export enum EmailCampaignStatusEnum {
  Draft = "draft",
  Creating = "creating",
  Started = "started",
  Scheduled = "scheduled",
  Complete = "complete",
  Cancelled = "cancelled",
  Cancelling = "cancelling",
  PartiallyCancelled = "partially_cancelled",
  PartiallyFailed = "partially_failed",
}

export enum EmailCampaignTypeEnum {
  Regular = "regular",
}

export type EmailIntegration = {
  __typename?: "EmailIntegration";
  id: Scalars["ID"];
  email: Scalars["String"];
  provider: Scalars["String"];
  invalid: Scalars["Boolean"];
};

export type EmailRecord = {
  __typename?: "EmailRecord";
  address?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

export type EmailThread = {
  __typename?: "EmailThread";
  id: Scalars["ID"];
  subject?: Maybe<Scalars["String"]>;
  interactions?: Maybe<Array<Interaction>>;
};

export enum EntityTypeEnum {
  Businesses = "businesses",
  Projects = "projects",
  Contacts = "contacts",
  Sites = "sites",
}

export type Event = {
  __typename?: "Event";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  date?: Maybe<Scalars["String"]>;
  businessStages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
};

export type EventLog = {
  __typename?: "EventLog";
  id?: Maybe<Scalars["ID"]>;
  content?: Maybe<Scalars["String"]>;
  user?: Maybe<UserProfile>;
  key?: Maybe<EventLogKeyEnum>;
  createdAt?: Maybe<Scalars["String"]>;
};

export enum EventLogKeyEnum {
  /** The user logged in */
  Login = "login",
  /** The user logged an email with inbound forarding webhook */
  InboundEmail = "inboundEmail",
  /** User created a new organization */
  CreatedOneOrg = "createdOneOrg",
  /** User created a new Business */
  CreatedOneBusiness = "createdOneBusiness",
  /** User created a new Contact */
  CreatedOneContact = "createdOneContact",
  /** User created a new Todo */
  CreatedOneTodo = "createdOneTodo",
  /** User created a new Case */
  CreatedOneCase = "createdOneCase",
  /** User used the global search */
  GlobalSearch = "globalSearch",
}

export type EventLogsResult = {
  __typename?: "EventLogsResult";
  result?: Maybe<Array<EventLog>>;
  count?: Maybe<Scalars["Int"]>;
};

export type EventParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  businessStages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
};

export type EventsSearchResponse = {
  __typename?: "EventsSearchResponse";
  events?: Maybe<Array<Event>>;
  count?: Maybe<Scalars["Int"]>;
};

export type ExtraField = {
  __typename?: "ExtraField";
  fieldName: Scalars["String"];
  fieldType: Scalars["String"];
  value: Scalars["String"];
};

export type ExtraFieldInput = {
  fieldName: Scalars["String"];
  fieldType: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type File = {
  __typename?: "File";
  id?: Maybe<Scalars["String"]>;
  filename: Scalars["String"];
  mimetype: Scalars["String"];
  encoding: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
  key: Scalars["String"];
};

export type FormQuestion = {
  __typename?: "FormQuestion";
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  questionType: FormQuestionTypeEnum;
  validation?: Maybe<FormQuestionValidation>;
  showIfConditions?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<FormQuestionOption>>;
  subQuestions?: Maybe<Array<FormQuestion>>;
};

export type FormQuestionInput = {
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  questionType: FormQuestionTypeEnum;
  validation?: Maybe<FormQuestionValidationInput>;
  showIfConditions?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<FormQuestionOptionInput>>;
  subQuestions?: Maybe<Array<FormQuestionInput>>;
};

export type FormQuestionOption = {
  __typename?: "FormQuestionOption";
  label: Scalars["String"];
  value: Scalars["String"];
  /** If this option is 'Other', then this will be true */
  isOther?: Maybe<Scalars["Boolean"]>;
};

export type FormQuestionOptionInput = {
  label: Scalars["String"];
  value: Scalars["String"];
  /** If this option is 'Other', then this will be true */
  isOther?: Maybe<Scalars["Boolean"]>;
};

export type FormQuestionOtherAnswer = {
  __typename?: "FormQuestionOtherAnswer";
  /** ID of the respondent */
  id: Scalars["ID"];
  textValue?: Maybe<Scalars["String"]>;
};

export enum FormQuestionTypeEnum {
  Text = "TEXT",
  TextArea = "TEXT_AREA",
  Number = "NUMBER",
  Currency = "CURRENCY",
  Phone = "PHONE",
  Date = "DATE",
  MultipleChoice = "MULTIPLE_CHOICE",
  SingleChoice = "SINGLE_CHOICE",
  Rating = "RATING",
  YesNo = "YES_NO",
  Naics = "NAICS",
  Year = "YEAR",
  BusinessId = "BUSINESS_ID",
  Percent = "PERCENT",
  SectionHeader = "SECTION_HEADER",
  Matrix = "MATRIX",
  Attachments = "ATTACHMENTS",
}

export type FormQuestionValidation = {
  __typename?: "FormQuestionValidation";
  required?: Maybe<Scalars["Boolean"]>;
};

export type FormQuestionValidationInput = {
  required?: Maybe<Scalars["Boolean"]>;
};

export type FormSubmission = {
  __typename?: "FormSubmission";
  id?: Maybe<Scalars["String"]>;
  formTemplateId: Scalars["ID"];
  respondentType?: Maybe<FormSubmissionRespondentTypeEnum>;
  respondentId: Scalars["ID"];
  attributionDate?: Maybe<Scalars["String"]>;
  business?: Maybe<Business>;
  answers?: Maybe<Array<FormSubmissionAnswer>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type FormSubmissionAnswer = {
  __typename?: "FormSubmissionAnswer";
  questionId: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  other?: Maybe<Scalars["String"]>;
};

export type FormSubmissionAnswerInput = {
  questionId: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  other?: Maybe<Scalars["String"]>;
};

export type FormSubmissionInput = {
  id?: Maybe<Scalars["String"]>;
  formTemplateId: Scalars["ID"];
  businessId?: Maybe<Scalars["ID"]>;
  attributionDate?: Maybe<Scalars["String"]>;
  answers?: Maybe<Array<FormSubmissionAnswerInput>>;
  respondentType?: Maybe<FormSubmissionRespondentTypeEnum>;
  respondentId: Scalars["ID"];
  referrerId?: Maybe<Scalars["ID"]>;
};

export type FormSubmissionReportItem = {
  __typename?: "FormSubmissionReportItem";
  id: Scalars["String"];
  respondentIds?: Maybe<Array<Scalars["ID"]>>;
  formTemplateId: Scalars["ID"];
  question: FormQuestion;
  /** The total number of responses received for the form tiself */
  totalFormRespondents: Scalars["Int"];
  responses?: Maybe<Array<FormSubmissionReportResponse>>;
};

export type FormSubmissionReportResponse = {
  __typename?: "FormSubmissionReportResponse";
  answers?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
  /** The IDs of who responded to this question with this given answer */
  respondentIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum FormSubmissionRespondentTypeEnum {
  Business = "business",
  Contact = "contact",
  Anonymous = "anonymous",
  /** no connected to any entity type */
  Unconnected = "unconnected",
}

export type FormTemplate = {
  __typename?: "FormTemplate";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  questions: Array<FormQuestion>;
};

export type FormTemplateInput = {
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  questions: Array<FormQuestionInput>;
};

export type FundingProgramSearchResponse = {
  __typename?: "FundingProgramSearchResponse";
  fundingPrograms?: Maybe<Array<Resource>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum FundingTypeEnum {
  Competition = "competition",
  Incentive = "incentive",
  Grant = "grant",
  Loan = "loan",
  Equity = "equity",
  Other = "other",
}

export type GenericChartResult = {
  __typename?: "GenericChartResult";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type GetEstimatedRecipientsForEmailCampaignResponse = {
  __typename?: "GetEstimatedRecipientsForEmailCampaignResponse";
  count?: Maybe<Scalars["Int"]>;
  emailsSent?: Maybe<Scalars["Int"]>;
};

export type GetEstimatedRecipientsForEmailCampaignResponseEmailsSentArgs = {
  days?: Maybe<Scalars["Int"]>;
};

export type GetManyDataDiscoveryRunsResponse = {
  __typename?: "GetManyDataDiscoveryRunsResponse";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<DataDiscoveryRun>>;
};

export enum GetManyListsOwnerTypeEnum {
  All = "all",
  My = "my",
  Team = "team",
}

export type GetManyListsResponse = {
  __typename?: "GetManyListsResponse";
  results?: Maybe<Array<List>>;
  count: Scalars["Int"];
};

export type GetManySurveyCampaignItemsResponse = {
  __typename?: "GetManySurveyCampaignItemsResponse";
  campaign?: Maybe<SurveyCampaign>;
  results?: Maybe<Array<SurveyCampaignItem>>;
  count: Scalars["Int"];
};

export type GroupedSurveyByBusinessResult = {
  __typename?: "GroupedSurveyByBusinessResult";
  /** surveyTemplateId */
  id?: Maybe<Scalars["String"]>;
  surveyTemplateName?: Maybe<Scalars["String"]>;
  responses?: Maybe<Array<Maybe<SurveyResponse>>>;
};

export type ImpersonateReturn = {
  __typename?: "ImpersonateReturn";
  authorized?: Maybe<Scalars["Boolean"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type ImpersonationUserIdentityInput = {
  userId?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export enum IntegrationServiceEnum {
  ConstantContact = "CONSTANT_CONTACT",
}

export type Interaction = {
  __typename?: "Interaction";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<InteractionTypeEnum>;
  createdBy?: Maybe<UserProfile>;
  createdAt?: Maybe<Scalars["String"]>;
  contact?: Maybe<Contact>;
  business?: Maybe<Business>;
  project?: Maybe<Project>;
  organization?: Maybe<Organization>;
  site?: Maybe<Site>;
  date?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
  email?: Maybe<InteractionEmail>;
  direction?: Maybe<InteractionDirectionEnum>;
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  emailCampaign?: Maybe<EmailCampaign>;
};

export type InteractionCategory = {
  __typename?: "InteractionCategory";
  id: Scalars["ID"];
  title: Scalars["String"];
};

export enum InteractionDirectionEnum {
  Inbound = "inbound",
  Outbound = "outbound",
}

export type InteractionEmail = {
  __typename?: "InteractionEmail";
  from?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
};

export type InteractionParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<InteractionTypeEnum>;
  date?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  direction?: Maybe<InteractionDirectionEnum>;
  categoryId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export enum InteractionTypeEnum {
  Visit = "visit",
  Email = "email",
  Phone = "phone",
  Other = "other",
}

export type InteractionsByMonthResult = {
  __typename?: "InteractionsByMonthResult";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["Int"]>;
  visit?: Maybe<Scalars["Int"]>;
};

export type InteractionsResponse = {
  __typename?: "InteractionsResponse";
  results?: Maybe<Array<Interaction>>;
  count: Scalars["Int"];
};

export type List = {
  __typename?: "List";
  id: Scalars["ID"];
  name: Scalars["String"];
  listType: ListTypeEnum;
  numberOfRecords: Scalars["Int"];
  createdBy?: Maybe<UserProfile>;
  updatedAt: Scalars["Float"];
  listItems?: Maybe<ListItemsResponse>;
};

export type ListListItemsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type ListItem = {
  __typename?: "ListItem";
  id: Scalars["ID"];
  contact?: Maybe<Contact>;
  business?: Maybe<Business>;
  listId: Scalars["ID"];
  createdAt?: Maybe<Scalars["Float"]>;
};

export type ListItemsResponse = {
  __typename?: "ListItemsResponse";
  results?: Maybe<Array<ListItem>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum ListTypeEnum {
  Contacts = "contacts",
  Businesses = "businesses",
}

export type LoginResult = {
  __typename?: "LoginResult";
  sessionId?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export enum MultipartUploadCompleteEnum {
  Complete = "complete",
  Abort = "abort",
}

export type MultipartUploadPartInput = {
  ETag: Scalars["String"];
  PartNumber: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  rescheduleOneEmailCampaignItem?: Maybe<EmailCampaignItem>;
  rescheduleManyEmailCampaignItems?: Maybe<EmailCampaign>;
  cancelOneEmailCampaign?: Maybe<EmailCampaign>;
  stopOneScheduledEmail?: Maybe<EmailCampaignItem>;
  updateOneCustomerSharingSetting?: Maybe<Customer>;
  testSendEmailTemplate?: Maybe<MutationResponse>;
  unsubscribeOneContactFromEmailCampaign?: Maybe<MutationResponse>;
  ignoreOneDataDiscoveryContact?: Maybe<DataDiscoveryBusiness>;
  mergeDiscoveredBusinesses?: Maybe<DataDiscoveryRun>;
  ignoreDataDiscoveryBusiness?: Maybe<DataDiscoveryBusiness>;
  createNylasGrant?: Maybe<MutationResponse>;
  createOneEmailCampaign?: Maybe<EmailCampaign>;
  updateOneEmailCampaign?: Maybe<EmailCampaign>;
  startEmailCampaign?: Maybe<EmailCampaign>;
  updateOneObjectsPermissions?: Maybe<Array<ObjectPermission>>;
  removeUsersFromUserGroup?: Maybe<UserGroup>;
  addUsersToUserGroup?: Maybe<UserGroup>;
  createOneUserGroup?: Maybe<UserGroup>;
  updateOneUserGroup?: Maybe<UserGroup>;
  deleteOneUserGroup?: Maybe<MutationResponse>;
  uploadContactCSVContent?: Maybe<UpdatedCountResponse>;
  reActivateUser?: Maybe<MutationResponse>;
  deleteOneBusiness?: Maybe<MutationResponse>;
  archiveSite?: Maybe<MutationResponse>;
  deleteOneCaseTemplate?: Maybe<MutationResponse>;
  createOneCaseTemplate?: Maybe<CaseTemplate>;
  updateOneCaseTemplate?: Maybe<CaseTemplate>;
  archiveOneFormTemplate?: Maybe<MutationResponse>;
  integrate_disconnectIntegration?: Maybe<MutationResponse>;
  integrate_ConstantContact?: Maybe<MutationResponse>;
  createOneFormSubmission?: Maybe<FormSubmission>;
  deleteOneContact?: Maybe<MutationResponse>;
  saveOneFormTemplate?: Maybe<FormTemplate>;
  createOneFormTemplate?: Maybe<FormTemplate>;
  createOneAutomation?: Maybe<Automation>;
  /** Log an analytics event */
  trackEvent?: Maybe<MutationResponse>;
  deleteInteraction?: Maybe<MutationResponse>;
  removeOneFromSurveyCampaign?: Maybe<MutationResponse>;
  saveSurveyResult: MutationResponse;
  getPresignedUploadMultiPart?: Maybe<PresignedMultipartResult>;
  completeMultiUpload?: Maybe<MutationResponse>;
  resendTeamInvite?: Maybe<MutationResponse>;
  getPresignedUploadUrl?: Maybe<PresignedUploadResponse>;
  /** marks a notification as read and returns the updated version of the notification */
  markNotificationAsRead?: Maybe<Notification>;
  submitPublicBusinessSurveyResponse?: Maybe<MutationResponse>;
  submitSurveyResponse?: Maybe<MutationResponse>;
  deleteCase?: Maybe<MutationResponse>;
  deleteTodo?: Maybe<MutationResponse>;
  /** Allows team administrators to edit field on their team members */
  editTeamMember?: Maybe<UserProfile>;
  saveSurveyTemplateNew: SurveyTemplateNew;
  saveDefaultSurveyTemplate: DefaultSurveyTemplate;
  deleteOneDefaultSurveyTemplate: MutationResponse;
  /** Customer administrators can use this to delete a user */
  deleteTeamMember?: Maybe<MutationResponse>;
  /** A mutation where users can submit a message that will alert growlab they need help */
  contactSupport?: Maybe<MutationResponse>;
  /** Update a customer record. Super admin only. */
  saveCustomer?: Maybe<Customer>;
  /** Delete a customer. Super admin only. */
  deleteCustomer?: Maybe<MutationResponse>;
  /** If ID is passed, updates the given case. If no id is passed, will create a new case.  */
  saveCase?: Maybe<Case>;
  /** If ID is passed, updates the given case status. If no id is passed, will create a new case status.  */
  saveCaseStatus?: Maybe<CaseStatus>;
  /** If ID is passed, updates the given case category. If no id is passed, will create a new case category.  */
  saveCaseCategory?: Maybe<CaseCategory>;
  /** If ID is passed, updates the given case service. If no id is passed, will create a new case service.  */
  saveCaseService?: Maybe<CaseService>;
  /** Delete a captial investment */
  deleteCompanyInvestment?: Maybe<MutationResponse>;
  /** Add a captial investment to a business's history */
  addCompanyInvestment?: Maybe<MutationResponse>;
  /** Save an attachment to our attachments collection */
  saveAttachment?: Maybe<Attachment>;
  /** Removes an entity (project, contact, etc) */
  removeEntityFromBusiness?: Maybe<MutationResponse>;
  addEntityToProject?: Maybe<Project>;
  addEntityToBusiness?: Maybe<Contact>;
  removeEntityFromProject?: Maybe<Project>;
  inviteUser?: Maybe<UserProfile>;
  createCustomer?: Maybe<Customer>;
  createCustomerAdmin?: Maybe<UserProfile>;
  /** Impersonate a customer by providing a customerId. if turnoff true, will turn off impersonation */
  impersonateCustomer?: Maybe<MutationResponse>;
  /** A way to attach files (e.g. from singleUpload) to a given entity (business, contact, project, etc) */
  addAttachment?: Maybe<MutationResponse>;
  /** Upload a file to AWS */
  singleUpload: File;
  saveInteraction?: Maybe<Interaction>;
  saveProject?: Maybe<Project>;
  saveProjectType?: Maybe<ProjectType>;
  updatePipelineCol?: Maybe<Pipeline>;
  addPipelineCol?: Maybe<Pipeline>;
  savePipeline?: Maybe<Pipeline>;
  /** creates a user profile if one does not exist */
  createUserProfile?: Maybe<UserProfile>;
  saveUser?: Maybe<UserProfile>;
  deleteUser?: Maybe<UserProfile>;
  saveTodo?: Maybe<Todo>;
  saveTodoList?: Maybe<TodoList>;
  saveContact?: Maybe<Contact>;
  saveEvent?: Maybe<Event>;
  saveTag?: Maybe<Tag>;
  saveBusiness?: Maybe<Business>;
  saveOrganization?: Maybe<Organization>;
  saveProgram?: Maybe<Resource>;
  saveSite?: Maybe<Site>;
  deleteTodoList?: Maybe<TodoList>;
  saveFundingProgram?: Maybe<Resource>;
  saveComment?: Maybe<Comment>;
  deleteComment?: Maybe<MutationResponse>;
  saveNote?: Maybe<Note>;
  deleteNote?: Maybe<Note>;
  publicBusinessSignup?: Maybe<Business>;
  deleteSite?: Maybe<MutationResponse>;
  deleteEvent?: Maybe<MutationResponse>;
  deleteResource?: Maybe<MutationResponse>;
  deleteOrganization?: Maybe<MutationResponse>;
  deleteTag?: Maybe<MutationResponse>;
  uploadBusinesses?: Maybe<MutationResponse>;
  createOneList?: Maybe<MutationResponse>;
  deleteOneList?: Maybe<MutationResponse>;
  addManyItemsToList?: Maybe<MutationResponse>;
  removeManyItemsFromList?: Maybe<List>;
  createOneSurveyCampaign?: Maybe<SurveyCampaign>;
  addManyItemsToSurveyCampaign?: Maybe<SurveyCampaign>;
  createNylasAuth?: Maybe<CreateNyasAuthResponse>;
  deleteEmailIntegration?: Maybe<MutationResponse>;
  createUser?: Maybe<CreateUserResult>;
  verifyEmail?: Maybe<Scalars["Boolean"]>;
  resetPassword?: Maybe<LoginResult>;
  sendVerificationEmail?: Maybe<Scalars["Boolean"]>;
  sendResetPasswordEmail?: Maybe<Scalars["Boolean"]>;
  addEmail?: Maybe<Scalars["Boolean"]>;
  changePassword?: Maybe<Scalars["Boolean"]>;
  twoFactorSet?: Maybe<Scalars["Boolean"]>;
  twoFactorUnset?: Maybe<Scalars["Boolean"]>;
  impersonate?: Maybe<ImpersonateReturn>;
  refreshTokens?: Maybe<LoginResult>;
  logout?: Maybe<Scalars["Boolean"]>;
  authenticate?: Maybe<LoginResult>;
  verifyAuthentication?: Maybe<Scalars["Boolean"]>;
};

export type MutationRescheduleOneEmailCampaignItemArgs = {
  emailCampaignItemId: Scalars["ID"];
  scheduledAt: Scalars["Float"];
};

export type MutationRescheduleManyEmailCampaignItemsArgs = {
  emailCampaignId: Scalars["ID"];
  scheduledAt: Scalars["Float"];
};

export type MutationCancelOneEmailCampaignArgs = {
  emailCampaignId: Scalars["ID"];
};

export type MutationStopOneScheduledEmailArgs = {
  emailCampaignItemId: Scalars["ID"];
};

export type MutationUpdateOneCustomerSharingSettingArgs = {
  key: SharingCustomerSettingKeyEnum;
  enabled: Scalars["Boolean"];
};

export type MutationTestSendEmailTemplateArgs = {
  contactId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  toEmailIntegrationId: Scalars["ID"];
  emailCampaignId: Scalars["ID"];
};

export type MutationUnsubscribeOneContactFromEmailCampaignArgs = {
  email: Scalars["String"];
  customerId: Scalars["ID"];
};

export type MutationIgnoreOneDataDiscoveryContactArgs = {
  discoveryBusinessId: Scalars["ID"];
  discoveryContactEmail: Scalars["String"];
};

export type MutationMergeDiscoveredBusinessesArgs = {
  runId: Scalars["ID"];
};

export type MutationIgnoreDataDiscoveryBusinessArgs = {
  businessId: Scalars["ID"];
};

export type MutationCreateNylasGrantArgs = {
  code: Scalars["String"];
};

export type MutationCreateOneEmailCampaignArgs = {
  params?: Maybe<EmailCampaignInput>;
};

export type MutationUpdateOneEmailCampaignArgs = {
  id: Scalars["ID"];
  params?: Maybe<EmailCampaignInput>;
};

export type MutationStartEmailCampaignArgs = {
  emailCampaignId: Scalars["ID"];
};

export type MutationUpdateOneObjectsPermissionsArgs = {
  type: ObjectTypeEnum;
  id: Scalars["ID"];
  permission: ObjectPermissionInput;
  action: UpdateArrayActionEnum;
};

export type MutationRemoveUsersFromUserGroupArgs = {
  userIds: Array<Scalars["ID"]>;
  userGroupId: Scalars["ID"];
};

export type MutationAddUsersToUserGroupArgs = {
  userIds: Array<Scalars["ID"]>;
  userGroupId: Scalars["ID"];
};

export type MutationCreateOneUserGroupArgs = {
  params: UserGroupInput;
};

export type MutationUpdateOneUserGroupArgs = {
  id: Scalars["ID"];
  params: UserGroupInput;
};

export type MutationDeleteOneUserGroupArgs = {
  id: Scalars["ID"];
};

export type MutationUploadContactCsvContentArgs = {
  customerId: Scalars["ID"];
  params: Array<Maybe<ContactInput>>;
};

export type MutationReActivateUserArgs = {
  userId: Scalars["ID"];
};

export type MutationDeleteOneBusinessArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveSiteArgs = {
  siteId: Scalars["ID"];
  archived: Scalars["Boolean"];
};

export type MutationDeleteOneCaseTemplateArgs = {
  id: Scalars["ID"];
};

export type MutationCreateOneCaseTemplateArgs = {
  params?: Maybe<CaseTemplateInput>;
};

export type MutationUpdateOneCaseTemplateArgs = {
  id: Scalars["ID"];
  params?: Maybe<CaseTemplateInput>;
};

export type MutationArchiveOneFormTemplateArgs = {
  formTemplateId: Scalars["ID"];
};

export type MutationIntegrate_DisconnectIntegrationArgs = {
  service: IntegrationServiceEnum;
};

export type MutationIntegrate_ConstantContactArgs = {
  code: Scalars["String"];
};

export type MutationCreateOneFormSubmissionArgs = {
  params: FormSubmissionInput;
};

export type MutationDeleteOneContactArgs = {
  id: Scalars["ID"];
};

export type MutationSaveOneFormTemplateArgs = {
  formTemplateId: Scalars["ID"];
  params: FormTemplateInput;
};

export type MutationCreateOneFormTemplateArgs = {
  params?: Maybe<FormTemplateInput>;
};

export type MutationCreateOneAutomationArgs = {
  params: AutomationInput;
};

export type MutationTrackEventArgs = {
  key: EventLogKeyEnum;
  content: Scalars["String"];
  meta?: Maybe<Scalars["String"]>;
};

export type MutationDeleteInteractionArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveOneFromSurveyCampaignArgs = {
  surveyCampaignItemId: Scalars["ID"];
};

export type MutationSaveSurveyResultArgs = {
  params: SurveyResultInput;
};

export type MutationGetPresignedUploadMultiPartArgs = {
  contentType: Scalars["String"];
  parts: Scalars["Int"];
  keyPath?: Maybe<Scalars["String"]>;
};

export type MutationCompleteMultiUploadArgs = {
  type: MultipartUploadCompleteEnum;
  uploadId: Scalars["String"];
  key: Scalars["String"];
  parts?: Maybe<Array<MultipartUploadPartInput>>;
};

export type MutationResendTeamInviteArgs = {
  userId: Scalars["ID"];
};

export type MutationGetPresignedUploadUrlArgs = {
  contentType: Scalars["String"];
};

export type MutationMarkNotificationAsReadArgs = {
  notifId?: Maybe<Scalars["ID"]>;
  markAllAsRead?: Maybe<Scalars["Boolean"]>;
};

export type MutationSubmitPublicBusinessSurveyResponseArgs = {
  surveyTemplateId: Scalars["ID"];
  businessId?: Maybe<Scalars["ID"]>;
  answers?: Maybe<Array<Maybe<SurveyResponseAnswerInput>>>;
  referrerId?: Maybe<Scalars["ID"]>;
};

export type MutationSubmitSurveyResponseArgs = {
  surveyTemplateId: Scalars["ID"];
  businessId?: Maybe<Scalars["ID"]>;
  answers?: Maybe<Array<Maybe<SurveyResponseAnswerInput>>>;
  attributionDate: Scalars["String"];
  createdById: Scalars["ID"];
};

export type MutationDeleteCaseArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTodoArgs = {
  id: Scalars["ID"];
};

export type MutationEditTeamMemberArgs = {
  id: Scalars["ID"];
  permission: UserPermissionEnum;
};

export type MutationSaveSurveyTemplateNewArgs = {
  params: SurveyTemplateNewInput;
};

export type MutationSaveDefaultSurveyTemplateArgs = {
  params: SurveyTemplateNewInput;
};

export type MutationDeleteOneDefaultSurveyTemplateArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTeamMemberArgs = {
  id: Scalars["ID"];
};

export type MutationContactSupportArgs = {
  subject?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type MutationSaveCustomerArgs = {
  id: Scalars["ID"];
  params?: Maybe<CustomerInput>;
};

export type MutationDeleteCustomerArgs = {
  customerId: Scalars["ID"];
};

export type MutationSaveCaseArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseInput>;
};

export type MutationSaveCaseStatusArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseStatusInput>;
};

export type MutationSaveCaseCategoryArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseCategoryInput>;
};

export type MutationSaveCaseServiceArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseServiceInput>;
};

export type MutationDeleteCompanyInvestmentArgs = {
  id: Scalars["ID"];
};

export type MutationAddCompanyInvestmentArgs = {
  amount?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  investedOn?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
};

export type MutationSaveAttachmentArgs = {
  params?: Maybe<AttachmentParams>;
};

export type MutationRemoveEntityFromBusinessArgs = {
  businessId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
};

export type MutationAddEntityToProjectArgs = {
  projectId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
};

export type MutationAddEntityToBusinessArgs = {
  businessId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
};

export type MutationRemoveEntityFromProjectArgs = {
  projectId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
};

export type MutationInviteUserArgs = {
  customerId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  permission: UserPermissionEnum;
};

export type MutationCreateCustomerArgs = {
  title: Scalars["String"];
};

export type MutationCreateCustomerAdminArgs = {
  customerId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  permission: UserPermissionEnum;
};

export type MutationImpersonateCustomerArgs = {
  customerId: Scalars["String"];
  turnOff?: Maybe<Scalars["Boolean"]>;
};

export type MutationAddAttachmentArgs = {
  parentId: Scalars["ID"];
  parentType: ParentTypeEnum;
  file: AttachmentParams;
};

export type MutationSingleUploadArgs = {
  file: Scalars["Upload"];
};

export type MutationSaveInteractionArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<InteractionParams>;
};

export type MutationSaveProjectArgs = {
  id?: Maybe<Scalars["ID"]>;
  params: ProjectParams;
};

export type MutationSaveProjectTypeArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ProjectTypeParams>;
};

export type MutationUpdatePipelineColArgs = {
  params?: Maybe<PipelineStageInput>;
};

export type MutationAddPipelineColArgs = {
  params?: Maybe<PipelineStageInput>;
};

export type MutationSavePipelineArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<PipelineParams>;
};

export type MutationCreateUserProfileArgs = {
  userId?: Maybe<Scalars["ID"]>;
  role?: Maybe<Scalars["String"]>;
};

export type MutationSaveUserArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<UserParams>;
};

export type MutationDeleteUserArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type MutationSaveTodoArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TodoParams>;
};

export type MutationSaveTodoListArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TodoListParams>;
};

export type MutationSaveContactArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ContactInput>;
};

export type MutationSaveEventArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<EventParams>;
};

export type MutationSaveTagArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TagParams>;
};

export type MutationSaveBusinessArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<BusinessParams>;
};

export type MutationSaveOrganizationArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<OrganizationParams>;
};

export type MutationSaveProgramArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ResourceParams>;
};

export type MutationSaveSiteArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<SiteInput>;
};

export type MutationDeleteTodoListArgs = {
  id: Scalars["ID"];
};

export type MutationSaveFundingProgramArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ResourceParams>;
};

export type MutationSaveCommentArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CommentParams>;
};

export type MutationDeleteCommentArgs = {
  id: Scalars["ID"];
};

export type MutationSaveNoteArgs = {
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<NoteParams>;
};

export type MutationDeleteNoteArgs = {
  id: Scalars["ID"];
};

export type MutationPublicBusinessSignupArgs = {
  params?: Maybe<BusinessParams>;
};

export type MutationDeleteSiteArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteEventArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteResourceArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTagArgs = {
  id: Scalars["ID"];
};

export type MutationUploadBusinessesArgs = {
  businesses?: Maybe<Array<Maybe<UploadBusinessInput>>>;
  customerId: Scalars["ID"];
};

export type MutationCreateOneListArgs = {
  name: Scalars["String"];
  listType: ListTypeEnum;
  listItemIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationDeleteOneListArgs = {
  listId: Scalars["ID"];
};

export type MutationAddManyItemsToListArgs = {
  listId: Scalars["ID"];
  itemIds: Array<Scalars["ID"]>;
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
};

export type MutationRemoveManyItemsFromListArgs = {
  listId: Scalars["ID"];
  itemIds?: Maybe<Array<Scalars["ID"]>>;
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
};

export type MutationCreateOneSurveyCampaignArgs = {
  name: Scalars["String"];
  surveyTemplateId: Scalars["ID"];
};

export type MutationAddManyItemsToSurveyCampaignArgs = {
  listId?: Maybe<Scalars["ID"]>;
  surveyCampaignId: Scalars["ID"];
  itemIds?: Maybe<Array<Scalars["ID"]>>;
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
};

export type MutationDeleteEmailIntegrationArgs = {
  id: Scalars["ID"];
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationSendVerificationEmailArgs = {
  email: Scalars["String"];
};

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars["String"];
};

export type MutationAddEmailArgs = {
  newEmail: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationTwoFactorSetArgs = {
  secret: TwoFactorSecretKeyInput;
  code: Scalars["String"];
};

export type MutationTwoFactorUnsetArgs = {
  code: Scalars["String"];
};

export type MutationImpersonateArgs = {
  accessToken: Scalars["String"];
  impersonated: ImpersonationUserIdentityInput;
};

export type MutationRefreshTokensArgs = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type MutationAuthenticateArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationVerifyAuthenticationArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  success?: Maybe<Scalars["Boolean"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type MyNotificationsResult = {
  __typename?: "MyNotificationsResult";
  unreadCount?: Maybe<Scalars["Int"]>;
  result?: Maybe<Array<Notification>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum MyNotificationsStatusEnum {
  Read = "read",
  Unread = "unread",
}

export type Naic = {
  __typename?: "Naic";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
};

export type Note = {
  __typename?: "Note";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  createdByUser?: Maybe<UserProfile>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  project?: Maybe<Project>;
};

export type NoteParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
};

export enum NoteParentTypeEnum {
  Projects = "Projects",
  Businesses = "Businesses",
  Contacts = "Contacts",
  Sites = "Sites",
}

export type NotesResponse = {
  __typename?: "NotesResponse";
  notes?: Maybe<Array<Note>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum NotifcationActionTypeEnum {
  CaseAssignedTo = "CASE_ASSIGNED_TO",
  CaseResolved = "CASE_RESOLVED",
  TodoCommentedOn = "TODO_COMMENTED_ON",
  TodoMarkedDone = "TODO_MARKED_DONE",
  SurveySubmitted = "SURVEY_SUBMITTED",
}

export type Notification = {
  __typename?: "Notification";
  id?: Maybe<Scalars["String"]>;
  actorId?: Maybe<Scalars["ID"]>;
  content?: Maybe<Scalars["String"]>;
  entity?: Maybe<NotificationEntity>;
  read?: Maybe<Scalars["Boolean"]>;
  actionType?: Maybe<NotifcationActionTypeEnum>;
  createdAt?: Maybe<Scalars["String"]>;
};

export type NotificationEntity = SurveyResponse | Todo | Case;

export type NotificationSetting = {
  __typename?: "NotificationSetting";
  notificationType?: Maybe<NotificationSettingTypeEnum>;
  sms?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["Boolean"]>;
  push?: Maybe<Scalars["Boolean"]>;
};

export type NotificationSettingInput = {
  notificationType?: Maybe<NotificationSettingTypeEnum>;
  sms?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["Boolean"]>;
  push?: Maybe<Scalars["Boolean"]>;
};

export enum NotificationSettingTypeEnum {
  CaseAssignedTo = "CASE_ASSIGNED_TO",
  CaseResolved = "CASE_RESOLVED",
  TodoCommentedOn = "TODO_COMMENTED_ON",
  TodoMarkedDone = "TODO_MARKED_DONE",
  SurveySubmitted = "SURVEY_SUBMITTED",
}

export type ObjectPermission = {
  __typename?: "ObjectPermission";
  subjectType?: Maybe<ObjectPermissionSubjectTypeEnum>;
  subject: UserGroup;
  subjectId: Scalars["ID"];
  access?: Maybe<Array<ObjectPermissionAccessEnum>>;
};

export enum ObjectPermissionAccessEnum {
  View = "view",
  Edit = "edit",
}

export type ObjectPermissionInput = {
  subjectType?: Maybe<ObjectPermissionSubjectTypeEnum>;
  subjectId: Scalars["ID"];
  access?: Maybe<Array<ObjectPermissionAccessEnum>>;
};

export enum ObjectPermissionSubjectTypeEnum {
  Group = "group",
  User = "user",
}

export enum ObjectTypeEnum {
  Business = "Business",
  Note = "Note",
  Interaction = "Interaction",
  Attachment = "Attachment",
  Contact = "Contact",
  Project = "Project",
}

export enum OrgTypeEnum {
  Venture = "venture",
  Angel = "angel",
  Incubator = "incubator",
  Accelerator = "accelerator",
  Cdfi = "cdfi",
  Sba = "sba",
  Chamber = "chamber",
  Score = "score",
  University = "university",
  Other = "other",
}

export type OrganiationSearchParams = {
  searchText?: Maybe<Scalars["String"]>;
};

export type Organization = {
  __typename?: "Organization";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  businessStages?: Maybe<Array<Maybe<BusinessStageEnum>>>;
  clusters?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diversity?: Maybe<Array<Maybe<Scalars["String"]>>>;
  orgType?: Maybe<OrgTypeEnum>;
  programs?: Maybe<Array<Maybe<Resource>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contactIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  fundingPrograms?: Maybe<Array<Maybe<Resource>>>;
  serviceTypes?: Maybe<Array<Maybe<ServiceTypeEnum>>>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type OrganizationActivitiesResult = {
  __typename?: "OrganizationActivitiesResult";
  result?: Maybe<Array<Maybe<ActivityItem>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type OrganizationParams = {
  title?: Maybe<Scalars["String"]>;
  orgType?: Maybe<OrgTypeEnum>;
  description?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  businessStages?: Maybe<Array<BusinessStageEnum>>;
  clusters?: Maybe<Array<Scalars["String"]>>;
  serviceTypes?: Maybe<Array<ServiceTypeEnum>>;
  diversity?: Maybe<Array<Scalars["String"]>>;
  tagIds?: Maybe<Array<Scalars["ID"]>>;
  contactIds?: Maybe<Array<Scalars["ID"]>>;
};

export type OrganizationSearchResponse = {
  __typename?: "OrganizationSearchResponse";
  organizations?: Maybe<Array<Maybe<Organization>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type PaginationInput = {
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export enum ParentTypeEnum {
  Businesses = "Businesses",
  Contacts = "Contacts",
  Projects = "Projects",
}

export type Pipeline = {
  __typename?: "Pipeline";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  stages?: Maybe<Array<PipelineStage>>;
  projectType?: Maybe<ProjectType>;
};

export type PipelineParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  projectTypeId: Scalars["ID"];
};

export type PipelineStage = {
  __typename?: "PipelineStage";
  id: Scalars["String"];
  label: Scalars["String"];
  order?: Maybe<Scalars["Int"]>;
};

export type PipelineStageInput = {
  pipelineId: Scalars["ID"];
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
};

export type PresignedMultipartResult = {
  __typename?: "PresignedMultipartResult";
  key?: Maybe<Scalars["String"]>;
  uploadId?: Maybe<Scalars["String"]>;
  urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PresignedUploadResponse = {
  __typename?: "PresignedUploadResponse";
  url?: Maybe<Scalars["String"]>;
  /** Stringified object */
  fields?: Maybe<Scalars["String"]>;
};

export type ProgramSearchResponse = {
  __typename?: "ProgramSearchResponse";
  programs?: Maybe<Array<Resource>>;
  count?: Maybe<Scalars["Int"]>;
};

export type Project = {
  __typename?: "Project";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  projectType?: Maybe<Scalars["String"]>;
  pipeline?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["Int"]>;
  stageId?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  pipelineStage?: Maybe<PipelineStage>;
  pipelineName?: Maybe<Scalars["String"]>;
  image?: Maybe<Attachment>;
  attachments?: Maybe<Array<Attachment>>;
  contacts?: Maybe<Array<Contact>>;
  businesses?: Maybe<Array<Business>>;
  sites?: Maybe<Array<Site>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  cases?: Maybe<Array<Maybe<Case>>>;
  extraFields?: Maybe<Array<ExtraField>>;
  customFields?: Maybe<Array<ExtraField>>;
  updatedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  businessIds?: Maybe<Array<Scalars["String"]>>;
  contactIds?: Maybe<Array<Scalars["String"]>>;
  todos?: Maybe<Array<Todo>>;
  tag?: Maybe<Array<Tag>>;
  tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type ProjectParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  projectType?: Maybe<Scalars["String"]>;
  pipeline?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["Int"]>;
  stageId?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  extraFields?: Maybe<Array<ExtraFieldInput>>;
  tagIds?: Maybe<Array<Scalars["ID"]>>;
  customFields?: Maybe<Array<ExtraFieldInput>>;
  /** IDs of businesses assigned to this project */
  businessIds?: Maybe<Array<Scalars["ID"]>>;
  contactIds?: Maybe<Array<Scalars["ID"]>>;
  partnerIds?: Maybe<Array<Scalars["ID"]>>;
  incentiveIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  image?: Maybe<AttachmentParams>;
  amount?: Maybe<Scalars["Int"]>;
};

export type ProjectType = {
  __typename?: "ProjectType";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  extraFields?: Maybe<Array<ExtraField>>;
  customFields?: Maybe<Array<ExtraField>>;
};

export type ProjectTypeParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  extraFields?: Maybe<Array<ExtraFieldInput>>;
  customFields?: Maybe<Array<ExtraFieldInput>>;
};

export type Query = {
  __typename?: "Query";
  /** Returns a list of email threads for a given item id (contact, business, project, etc) */
  getManyEmailThreads?: Maybe<Array<EmailThread>>;
  getOneDataDiscoveryRun?: Maybe<DataDiscoveryRun>;
  getManyDataDiscoveryRuns?: Maybe<GetManyDataDiscoveryRunsResponse>;
  getEstimatedRecipientsForEmailCampaign?: Maybe<GetEstimatedRecipientsForEmailCampaignResponse>;
  getOneList?: Maybe<List>;
  getManyIntegrations?: Maybe<Array<Maybe<EmailIntegration>>>;
  /** returns a paginated response of email campaigns */
  getManyEmailCampaigns?: Maybe<GetManyEmailCampaignsResponse>;
  /** Returns one email campaign */
  getOneEmailCampaign?: Maybe<EmailCampaign>;
  /** Returns a list of persions (objects) for a given resource */
  getManyObjectPermissions?: Maybe<Array<ObjectPermission>>;
  /** returns a list of user groups */
  getManyUserGroups?: Maybe<Array<UserGroup>>;
  getManyCaseTemplates?: Maybe<Array<Maybe<CaseTemplate>>>;
  getOneNote?: Maybe<Note>;
  checkReset?: Maybe<CheckResetResponse>;
  getOneInteraction?: Maybe<Interaction>;
  tagSearch?: Maybe<TagSearchResult>;
  getManyClusters?: Maybe<Array<Maybe<Cluster>>>;
  getManyCustomerFeatures?: Maybe<Array<CustomerFeatureSettingKeyEnum>>;
  getManyDefaultFormTemplateQuestions?: Maybe<
    Array<DefaultFormTemplateQuestionCategory>
  >;
  getManyConstantContactLists?: Maybe<Array<ConstantContactList>>;
  groupFormSubmissionsByRecipient?: Maybe<Array<FormSubmission>>;
  getFormQuestionsOtherAnswers?: Maybe<Array<FormQuestionOtherAnswer>>;
  getManyFormTemplates?: Maybe<Array<FormTemplate>>;
  getOneFormTemplate?: Maybe<FormTemplate>;
  getManyAutomations?: Maybe<Array<Maybe<Automation>>>;
  getManySurveyTemplatesNew: Array<SurveyTemplateNew>;
  getManyDefaultSurveyTemplates: Array<DefaultSurveyTemplate>;
  getOneDefaultSurveyTemplate: DefaultSurveyTemplate;
  getOneSurveyTemplateNew: SurveyTemplateNew;
  getSurveyQuestionOtherAnswers?: Maybe<SurveyQuestionOtherAnswerResponse>;
  reports_CasesResolvedByDateRange?: Maybe<Scalars["Int"]>;
  yourWork?: Maybe<YourWorkResponse>;
  getManyNaics: Array<Maybe<Naic>>;
  getManyAnswers: Array<SurveyTemplateAnswers>;
  eventLogs?: Maybe<EventLogsResult>;
  getManyInteractionCategories?: Maybe<Array<Maybe<InteractionCategory>>>;
  csvDownload_surveys?: Maybe<Array<Maybe<SurveyResponse>>>;
  csvDownload_contacts?: Maybe<Array<Maybe<Contact>>>;
  csvDownload_businesses?: Maybe<Array<Maybe<Business>>>;
  csvDownload_cases?: Maybe<Array<Maybe<Case>>>;
  organizationAcitvities?: Maybe<OrganizationActivitiesResult>;
  businessSectors?: Maybe<Array<BusinessSector>>;
  todoById?: Maybe<Todo>;
  /** Returns a given users notifications */
  myNotifications?: Maybe<MyNotificationsResult>;
  /** Returns a survey given an id */
  surveyResponseById?: Maybe<SurveyResponse>;
  /** Given a business ID, will return a list of survey results grouped by the survey and sorted by submission date */
  groupedSurveysByBusinessId?: Maybe<
    Array<Maybe<GroupedSurveyByBusinessResult>>
  >;
  reports_ProjectsByStage?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_NumberOfBusinesses?: Maybe<Scalars["Int"]>;
  reports_BusinessesByNaics?: Maybe<Array<Maybe<BusinessesByNaicsResponse>>>;
  reports_BusinessesByCluster?: Maybe<
    Array<Maybe<BusinessesByClusterResponse>>
  >;
  reports_SupportProgramsByStage?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_SupportProgramsByType?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_OrganizationsByType?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_CasesResolvedByMonth?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_BusinessAssistedByService?: Maybe<Array<Maybe<GenericChartResult>>>;
  reports_InteractionsByMonth?: Maybe<Array<Maybe<InteractionsByMonthResult>>>;
  reports_NumberOfBusinessesAssisted?: Maybe<Scalars["Int"]>;
  reports_BusinessesBySector?: Maybe<Array<BusinessesByClusterResponse>>;
  caseStatusReport?: Maybe<Array<Maybe<CaseStatusReportResult>>>;
  surveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  /** Testing idea for reports query */
  reportsTest?: Maybe<Array<Maybe<ReportsTestResponse>>>;
  formSubmissionReport?: Maybe<Array<FormSubmissionReportItem>>;
  caseById?: Maybe<Case>;
  /** Returns a list of case statuses for the currently logged in user's organization */
  caseStatuses?: Maybe<Array<Maybe<CaseStatus>>>;
  /** Returns a list of case categories for the currently logged in user's organization */
  caseCategories?: Maybe<Array<Maybe<CaseCategory>>>;
  /** Returns a list of case services for the currently logged in user's organization */
  caseServices?: Maybe<Array<CaseService>>;
  /** Returns a list of cases for the user's current organization */
  cases?: Maybe<CasesResponse>;
  /** returns a list of attachmetns given a parent ID */
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  getManyCaseOutcomes?: Maybe<Array<Maybe<CaseOutcome>>>;
  /** Given a pipeline ID, it retuns a pipeline */
  pipelineById?: Maybe<Pipeline>;
  /** Provide at least one id (contactId, projectId, businessId, etc) and it returns a list of interactions associated with that ID */
  interactions?: Maybe<InteractionsResponse>;
  /** Get project by an ID */
  getProjectsByIds?: Maybe<Array<Maybe<Project>>>;
  /** Serach through projects */
  projects?: Maybe<Array<Maybe<Project>>>;
  /** Returns the team for the organization of the currently signed in user's organization */
  team?: Maybe<Array<UserProfile>>;
  getCompanyByDomain?: Maybe<CompanyApiResult>;
  projectsByCustomer?: Maybe<Array<Maybe<Project>>>;
  customerById?: Maybe<Customer>;
  customers?: Maybe<CustomerResponse>;
  search?: Maybe<SearchResult>;
  activityItems?: Maybe<Array<ActivityItem>>;
  projectById?: Maybe<Project>;
  projectsByStage?: Maybe<Array<Maybe<Project>>>;
  projectTypesByCustomer?: Maybe<Array<Maybe<ProjectType>>>;
  /** Returns the currently signed in user or null if user is not signed in */
  currentUser?: Maybe<UserProfile>;
  todos?: Maybe<TodosResponse>;
  pipelinesByCustomer?: Maybe<Array<Pipeline>>;
  /** get all users. User must be an admin */
  users?: Maybe<UsersSearchResponse>;
  /** returns a user given an id. User must be an admin */
  userById?: Maybe<UserProfile>;
  searchUsers?: Maybe<Array<Maybe<UserProfile>>>;
  /** returns an object with a count of total results (for pagination or infinite loading) and an array of contacts */
  contacts?: Maybe<ContactsSearchResponse>;
  /** pass in an contact id, get back a contact object */
  contactById?: Maybe<Contact>;
  /** returns an object with a count of total results (for pagination or infinite loading) and an array of businesses */
  businesses?: Maybe<BusinessesSearchResponse>;
  /** pass in an business id, get back a business object */
  businessById?: Maybe<Business>;
  /** pass in an organization id, get back a organization object */
  organizationById?: Maybe<Organization>;
  /** pass in an Event id, get back a Event object */
  eventById?: Maybe<Event>;
  /** returns an object with a count of total results (for pagination or infinite loading) and an array of tags */
  tags?: Maybe<TagsSearchResponse>;
  naicsSearch?: Maybe<Array<Maybe<Naic>>>;
  suggestedResources?: Maybe<Array<Maybe<Resource>>>;
  resourceById?: Maybe<Resource>;
  events?: Maybe<EventsSearchResponse>;
  notes?: Maybe<NotesResponse>;
  organizations?: Maybe<OrganizationSearchResponse>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  todoLists?: Maybe<TodoListsResponse>;
  programs?: Maybe<ProgramSearchResponse>;
  fundingPrograms?: Maybe<FundingProgramSearchResponse>;
  /** Search sites */
  sites?: Maybe<SiteSearchResponse>;
  siteById?: Maybe<Site>;
  getManyLists?: Maybe<GetManyListsResponse>;
  getManySurveyCampaigns?: Maybe<Array<SurveyCampaign>>;
  getManySurveyCampaignItems?: Maybe<GetManySurveyCampaignItemsResponse>;
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
  getUser?: Maybe<User>;
};

export type QueryGetManyEmailThreadsArgs = {
  itemId: Scalars["ID"];
  pagination?: Maybe<PaginationInput>;
};

export type QueryGetOneDataDiscoveryRunArgs = {
  runId: Scalars["ID"];
};

export type QueryGetEstimatedRecipientsForEmailCampaignArgs = {
  recipientReferences?: Maybe<Array<EmailCampaignRecipientReferenceInput>>;
};

export type QueryGetOneListArgs = {
  id: Scalars["ID"];
};

export type QueryGetManyIntegrationsArgs = {
  includeInvalidGrants?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetManyEmailCampaignsArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type QueryGetOneEmailCampaignArgs = {
  emailCampaignId: Scalars["ID"];
};

export type QueryGetManyObjectPermissionsArgs = {
  type: ObjectTypeEnum;
  id: Scalars["ID"];
};

export type QueryGetManyUserGroupsArgs = {
  not?: Maybe<Array<Scalars["ID"]>>;
  includeCustomerDefault?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetOneNoteArgs = {
  id: Scalars["ID"];
};

export type QueryCheckResetArgs = {
  token: Scalars["String"];
};

export type QueryGetOneInteractionArgs = {
  id: Scalars["ID"];
};

export type QueryTagSearchArgs = {
  tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryGroupFormSubmissionsByRecipientArgs = {
  recipientId: Scalars["ID"];
};

export type QueryGetFormQuestionsOtherAnswersArgs = {
  formTemplateId: Scalars["ID"];
  questionId: Scalars["ID"];
  otherOptionValue: Scalars["ID"];
};

export type QueryGetOneFormTemplateArgs = {
  formTemplateId: Scalars["ID"];
};

export type QueryGetOneDefaultSurveyTemplateArgs = {
  defaultTemplateId: Scalars["String"];
};

export type QueryGetOneSurveyTemplateNewArgs = {
  templateId: Scalars["ID"];
};

export type QueryGetSurveyQuestionOtherAnswersArgs = {
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  surveyQuestionId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
};

export type QueryReports_CasesResolvedByDateRangeArgs = {
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type QueryYourWorkArgs = {
  type?: Maybe<YourWorkTypeEnum>;
  assignedToIds?: Maybe<Array<Scalars["ID"]>>;
  pagination?: Maybe<PaginationInput>;
};

export type QueryGetManyAnswersArgs = {
  templateId?: Maybe<Scalars["String"]>;
  businessId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  answerId?: Maybe<Scalars["String"]>;
  surveyCampaignId?: Maybe<Scalars["ID"]>;
};

export type QueryEventLogsArgs = {
  keys?: Maybe<Array<EventLogKeyEnum>>;
  userIds?: Maybe<Array<Scalars["ID"]>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationAcitvitiesArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
};

export type QueryTodoByIdArgs = {
  id: Scalars["ID"];
};

export type QueryMyNotificationsArgs = {
  status?: Maybe<MyNotificationsStatusEnum>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QuerySurveyResponseByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGroupedSurveysByBusinessIdArgs = {
  businessId: Scalars["ID"];
};

export type QueryReports_ProjectsByStageArgs = {
  pipelineId: Scalars["ID"];
};

export type QueryReports_BusinessesByNaicsArgs = {
  top?: Maybe<Scalars["Int"]>;
};

export type QueryReports_BusinessesByClusterArgs = {
  top?: Maybe<Scalars["Int"]>;
};

export type QueryReports_BusinessesBySectorArgs = {
  top?: Maybe<Scalars["Int"]>;
};

export type QuerySurveyResponsesArgs = {
  businessIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  surveyTemplateId: Scalars["ID"];
};

export type QueryReportsTestArgs = {
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  year?: Maybe<Scalars["String"]>;
};

export type QueryFormSubmissionReportArgs = {
  formTemplateId: Scalars["ID"];
};

export type QueryCaseByIdArgs = {
  id: Scalars["ID"];
};

export type QueryCaseServicesArgs = {
  ids?: Maybe<Array<Scalars["ID"]>>;
  caseCategoryId?: Maybe<Scalars["ID"]>;
};

export type QueryCasesArgs = {
  businessId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  caseStatusIds?: Maybe<Array<Scalars["ID"]>>;
  caseCategoryId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  searchText?: Maybe<Scalars["String"]>;
  caseIds?: Maybe<Array<Scalars["ID"]>>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  caseOutcomeIds?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryAttachmentsArgs = {
  parentId: Scalars["ID"];
};

export type QueryPipelineByIdArgs = {
  id: Scalars["ID"];
};

export type QueryInteractionsArgs = {
  contactId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  businessId?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  direction?: Maybe<InteractionDirectionEnum>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryGetProjectsByIdsArgs = {
  projectIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryProjectsArgs = {
  searchText?: Maybe<Scalars["String"]>;
  projectIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type QueryTeamArgs = {
  deactivated?: Maybe<Scalars["Boolean"]>;
  doNotInclude?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryGetCompanyByDomainArgs = {
  domain: Scalars["String"];
};

export type QueryCustomerByIdArgs = {
  id: Scalars["ID"];
};

export type QuerySearchArgs = {
  searchText: Scalars["String"];
  tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryActivityItemsArgs = {
  parentId: Scalars["ID"];
  parentType?: Maybe<NoteParentTypeEnum>;
};

export type QueryProjectByIdArgs = {
  id: Scalars["ID"];
};

export type QueryProjectsByStageArgs = {
  pipelineId: Scalars["ID"];
  stageId: Scalars["String"];
};

export type QueryTodosArgs = {
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  caseId?: Maybe<Scalars["ID"]>;
  done?: Maybe<Scalars["Boolean"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  sortBy?: Maybe<TodoSortBy>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryUsersArgs = {
  params?: Maybe<UserSearchParams>;
};

export type QueryUserByIdArgs = {
  id: Scalars["ID"];
};

export type QuerySearchUsersArgs = {
  params?: Maybe<UserSearchParams>;
};

export type QueryContactsArgs = {
  params?: Maybe<ContactSearchParams>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryContactByIdArgs = {
  id: Scalars["ID"];
};

export type QueryBusinessesArgs = {
  params?: Maybe<BusinessSearchParams>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  businessIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  includesAtleastBusinessIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sort?: Maybe<Array<SortByInput>>;
};

export type QueryBusinessByIdArgs = {
  id: Scalars["ID"];
};

export type QueryOrganizationByIdArgs = {
  id: Scalars["ID"];
};

export type QueryEventByIdArgs = {
  id: Scalars["ID"];
};

export type QueryNaicsSearchArgs = {
  searchText?: Maybe<Scalars["String"]>;
};

export type QuerySuggestedResourcesArgs = {
  businessId: Scalars["ID"];
};

export type QueryResourceByIdArgs = {
  id: Scalars["ID"];
};

export type QueryEventsArgs = {
  searchText?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  organizationId?: Maybe<Scalars["String"]>;
};

export type QueryNotesArgs = {
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationsArgs = {
  searchText?: Maybe<Scalars["String"]>;
  includesAtleastOrganizationIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  organiationIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  businessStages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diversityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryCommentsArgs = {
  todoId?: Maybe<Scalars["String"]>;
  caseId?: Maybe<Scalars["String"]>;
  noteId?: Maybe<Scalars["String"]>;
};

export type QueryTodoListsArgs = {
  archived?: Maybe<Scalars["Boolean"]>;
};

export type QueryProgramsArgs = {
  searchText?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  supportTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  businessStages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diversityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryFundingProgramsArgs = {
  searchText?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  supportTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  businessStages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diversityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QuerySitesArgs = {
  searchText?: Maybe<Scalars["String"]>;
  siteIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QuerySiteByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetManyListsArgs = {
  listType: ListTypeEnum;
  ownerType: GetManyListsOwnerTypeEnum;
  pagination?: Maybe<PaginationInput>;
};

export type QueryGetManySurveyCampaignsArgs = {
  listType: ListTypeEnum;
};

export type QueryGetManySurveyCampaignItemsArgs = {
  surveyCampaignId: Scalars["ID"];
  pagination: PaginationInput;
};

export type RawSurveyUploadInput = {
  label?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  type?: Maybe<SurveyQuestionTypeEnum>;
  options?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  showIfKey?: Maybe<Scalars["String"]>;
  showIfAnswer?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  updateKey?: Maybe<Scalars["String"]>;
};

export type ReportsTestResponse = {
  __typename?: "ReportsTestResponse";
  /** The ID of the question */
  id?: Maybe<Scalars["ID"]>;
  question?: Maybe<SurveyQuestion>;
  totalRespondents?: Maybe<Scalars["Int"]>;
  businessIds?: Maybe<Array<Maybe<Array<Maybe<Scalars["String"]>>>>>;
  questionResponses?: Maybe<Scalars["Int"]>;
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  responses?: Maybe<Array<Maybe<ReportsTestResponsesArray>>>;
  matrixResults?: Maybe<Array<Maybe<ReportsTestResponse>>>;
};

export type ReportsTestResponsesArray = {
  __typename?: "ReportsTestResponsesArray";
  answers?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type Resource = {
  __typename?: "Resource";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<ResourceType>;
  /** Is this resource geared toward a certain diversity measure? */
  diversityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** If Resource type is support, what type of support is it? */
  supportType?: Maybe<SupporType>;
  /** What organization is this resource under? */
  organization?: Maybe<Organization>;
  /** What business stages would be interested in this resource? */
  businessStages?: Maybe<Array<Maybe<BusinessStageEnum>>>;
  /** If Resource type is support, what type of support is it? */
  fundingType?: Maybe<Scalars["String"]>;
  /** Random tags you can add */
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** A list of industries/clusters. see the 'clusterTypes' array in the constants folder */
  clusters?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ResourceParams = {
  title?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  description?: Maybe<Scalars["String"]>;
  /** If Resource type is support, what type of support is it? */
  fundingType?: Maybe<FundingTypeEnum>;
  /** If Resource type is support, what type of support is it? */
  supportType?: Maybe<SupporType>;
  /** Business in what stages would be interested in this resource? */
  businessStages?: Maybe<Array<Maybe<BusinessStageEnum>>>;
  /** Is this resource set aside for businesses with specific diversity measurses (like women-owned businesses) */
  diversityTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** A list of industries/clusters. see the 'clusterTypes' array in the constants folder */
  clusters?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** What organization is this resource tied to/who provides this resource */
  organizationId?: Maybe<Scalars["String"]>;
};

export enum ResourceType {
  Support = "support",
  Funding = "funding",
}

export enum RoleType {
  /** this is a growlab employee */
  AppAdmin = "appAdmin",
  /** basic user */
  Gov = "gov",
  /** we previously thought maybe businesses would have access to growlab in some capacity */
  Business = "business",
}

export type SearchResult = {
  __typename?: "SearchResult";
  projects?: Maybe<Array<Project>>;
  businesses?: Maybe<Array<Business>>;
  contacts?: Maybe<Array<Contact>>;
  todos?: Maybe<Array<Todo>>;
  cases?: Maybe<Array<Case>>;
  resources?: Maybe<Array<Resource>>;
  sites?: Maybe<Array<Site>>;
  organizations?: Maybe<Array<Organization>>;
};

export enum ServiceTypeEnum {
  Support = "support",
  Funding = "funding",
}

export type SharingCustomerSetting = {
  __typename?: "SharingCustomerSetting";
  key: SharingCustomerSettingKeyEnum;
  enabled: Scalars["Boolean"];
};

export enum SharingCustomerSettingKeyEnum {
  DefaultShareBusinessesWithAllUsers = "DefaultShareBusinessesWithAllUsers",
  DefaultShareContactsWithAllUsers = "DefaultShareContactsWithAllUsers",
  DefaultShareInteractionsWithAllUsers = "DefaultShareInteractionsWithAllUsers",
  DefaultShareNotesWithAllUsers = "DefaultShareNotesWithAllUsers",
}

export type Site = {
  __typename?: "Site";
  id: Scalars["ID"];
  url?: Maybe<Scalars["String"]>;
  contacts?: Maybe<Array<Contact>>;
  businesses?: Maybe<Array<Business>>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Attachment>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  /** What type of site is this... land, building, etc */
  type?: Maybe<SiteTypeEnum>;
  /** The id of the person to contact about the site */
  contactId?: Maybe<Scalars["ID"]>;
  /** How many sqft or acres */
  size?: Maybe<Scalars["Int"]>;
  attachments?: Maybe<Array<Attachment>>;
  contact?: Maybe<Contact>;
  extraFields?: Maybe<Array<SiteExtraField>>;
  tagIds?: Maybe<Array<Scalars["ID"]>>;
  projects?: Maybe<Array<Project>>;
};

export type SiteExtraField = {
  __typename?: "SiteExtraField";
  fieldName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  fieldType?: Maybe<SiteExtraFieldTypeEnum>;
};

export type SiteExtraFieldInput = {
  fieldName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  fieldType?: Maybe<SiteExtraFieldTypeEnum>;
};

export enum SiteExtraFieldTypeEnum {
  Text = "text",
  Number = "number",
  Textarea = "textarea",
  Currency = "currency",
  Checkbox = "checkbox",
  Upload = "upload",
  Date = "date",
}

export enum SiteExtraKeyEnum {
  DevelopmentZone = "developmentZone",
  LandAcreage = "landAcreage",
  SquareFeet = "squareFeet",
  FrontageAccessTo = "frontageAccessTo",
  AccessToGate = "accessToGate",
  ZoningDistrict = "zoningDistrict",
  AccessToAirportRunway = "accessToAirportRunway",
  CurrentAccessToWater = "currentAccessToWater",
  CurrentAccessToSewer = "currentAccessToSewer",
  IfNotHowWouldTheyBeConnected = "ifNotHowWouldTheyBeConnected",
  BuildingSetbacks = "buildingSetbacks",
  DeedBookAttachment = "deedBookAttachment",
  DeedBookPageNumber = "deedBookPageNumber",
  UtilityEasements = "utilityEasements",
  FAaRequirements = "fAARequirements",
  OtherNotes = "otherNotes",
}

export type SiteInput = {
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<AttachmentParams>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  /** What type of site is this... land, building, etc */
  type?: Maybe<SiteTypeEnum>;
  /** The id of the person to contact about the site */
  contactId?: Maybe<Scalars["ID"]>;
  /** How many sqft or acres */
  size?: Maybe<Scalars["Int"]>;
  contactIds?: Maybe<Array<Scalars["ID"]>>;
  businessIds?: Maybe<Array<Scalars["ID"]>>;
  projectIds?: Maybe<Array<Scalars["ID"]>>;
  attachments?: Maybe<Array<AttachmentParams>>;
  extraFields?: Maybe<Array<SiteExtraFieldInput>>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type SiteSearchResponse = {
  __typename?: "SiteSearchResponse";
  sites?: Maybe<Array<Site>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum SiteTypeEnum {
  Land = "land",
  Building = "building",
}

export type SortByInput = {
  field: Scalars["String"];
  order: SortOrder;
};

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SubFeatureKeyEnum {
  Sites = "SITES",
}

export type SubFeatureSetting = {
  __typename?: "SubFeatureSetting";
  key?: Maybe<SubFeatureKeyEnum>;
  extraFields?: Maybe<Array<Maybe<SiteExtraField>>>;
};

export type SubFeatureSettingInput = {
  key?: Maybe<SubFeatureKeyEnum>;
  extraFields?: Maybe<Array<Maybe<SiteExtraFieldInput>>>;
};

export enum SupporType {
  /** Do you need help with business plans, pitch decks or other planning work? */
  BusinessPlanning = "businessPlanning",
  /** Do you need help creating or reviewing estimates, spreadsheets or other financial data? */
  FinancialManagement = "financialManagement",
  /** Do you need help finding/applying to investors, loans or grants? */
  Funding = "funding",
  /** Do you need help creating a business entity or filing a patent? */
  LegalAssistance = "legalAssistance",
  /** Do you need help with sales and marketing? Such as logos or ad copy? */
  SalesAndMarketing = "salesAndMarketing",
  /** Do you need help with real estate, such as finding storefronts or offices for you businesses? */
  RealEstate = "realEstate",
  /** Do you need R&D help from engineers, scientists, or others? */
  ResearchAndDevelopment = "researchAndDevelopment",
  /** Do you require consulting on workforce items, like hiring, growing teams and genearl HR? */
  Workforce = "workforce",
}

export type SupportMessage = {
  __typename?: "SupportMessage";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  status?: Maybe<SupportMessageStatusEnum>;
};

export type SupportMessageInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  status?: Maybe<SupportMessageStatusEnum>;
};

export enum SupportMessageStatusEnum {
  Open = "open",
  Resolved = "resolved",
}

export type SurveyCampaign = {
  __typename?: "SurveyCampaign";
  id: Scalars["ID"];
  name: Scalars["String"];
  listType: ListTypeEnum;
  numberOfRecords: Scalars["Int"];
  createdBy?: Maybe<UserProfile>;
  surveyTemplateId: Scalars["ID"];
  surveyTemplate?: Maybe<SurveyTemplateNew>;
  updatedAt: Scalars["Float"];
};

export type SurveyCampaignItem = {
  __typename?: "SurveyCampaignItem";
  id: Scalars["ID"];
  contact?: Maybe<Contact>;
  business?: Maybe<Business>;
  status: SurveyCampaignItemStatusEnum;
  surveyResultId?: Maybe<Scalars["ID"]>;
  surveyResultAt?: Maybe<Scalars["Float"]>;
};

export enum SurveyCampaignItemStatusEnum {
  /** The survey has not been sent yet */
  Pending = "pending",
  /** The survey was sent but not responded yet */
  Sent = "sent",
  /** The survey has been responded to */
  Responded = "responded",
}

export type SurveyQuestion = {
  __typename?: "SurveyQuestion";
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  options?: Maybe<Array<Maybe<SurveyQuestionOption>>>;
  questionType?: Maybe<SurveyQuestionTypeEnum>;
  showIfKey?: Maybe<Scalars["String"]>;
  showIfAnswer?: Maybe<Scalars["String"]>;
  parentQuestionId?: Maybe<Scalars["String"]>;
  childQuestions?: Maybe<Array<Maybe<SurveyQuestion>>>;
};

export type SurveyQuestionInput = {
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  options?: Maybe<Array<Maybe<SurveyQuestionOptionInput>>>;
  questionType?: Maybe<SurveyQuestionTypeEnum>;
  showIfKey?: Maybe<Scalars["String"]>;
  showIfAnswer?: Maybe<Scalars["String"]>;
  parentQuestionId?: Maybe<Scalars["String"]>;
  childQuestions?: Maybe<Array<Maybe<SurveyQuestionInput>>>;
};

export type SurveyQuestionOption = {
  __typename?: "SurveyQuestionOption";
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SurveyQuestionOptionInput = {
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SurveyQuestionOtherAnswerResponse = {
  __typename?: "SurveyQuestionOtherAnswerResponse";
  results?: Maybe<Array<Maybe<SurveyResponse>>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum SurveyQuestionTypeEnum {
  Text = "TEXT",
  TextArea = "TEXT_AREA",
  Number = "NUMBER",
  Currency = "CURRENCY",
  Phone = "PHONE",
  Date = "DATE",
  Attachments = "ATTACHMENTS",
  MultipleChoice = "MULTIPLE_CHOICE",
  SingleChoice = "SINGLE_CHOICE",
  Rating = "RATING",
  YesNo = "YES_NO",
  Naics = "NAICS",
  Year = "YEAR",
  BusinessId = "BUSINESS_ID",
  Percent = "PERCENT",
  SectionHeader = "SECTION_HEADER",
  Matrix = "MATRIX",
}

export type SurveyResponse = {
  __typename?: "SurveyResponse";
  id?: Maybe<Scalars["String"]>;
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  attributionDate?: Maybe<Scalars["String"]>;
  answers?: Maybe<Array<Maybe<SurveyResponseAnswer>>>;
  business?: Maybe<Business>;
};

export type SurveyResponseAnswer = {
  __typename?: "SurveyResponseAnswer";
  surveyQuestionId?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  other?: Maybe<Scalars["String"]>;
  childQuestions?: Maybe<Array<Maybe<SurveyResponseAnswer>>>;
};

export type SurveyResponseAnswerInput = {
  surveyQuestionId?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  other?: Maybe<Scalars["String"]>;
  /** all answers are stored as an array, that way we can handle multipe-choice answers and singular answers with one schema field */
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SurveyResultInput = {
  stringifiedAnswers: Scalars["String"];
  answeredBy?: Maybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
  template: Scalars["JSON"];
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  referrerId?: Maybe<Scalars["ID"]>;
  surveyCampaignItemId?: Maybe<Scalars["ID"]>;
  surveyCampaignId?: Maybe<Scalars["ID"]>;
};

export type SurveyTemplateAnswers = {
  __typename?: "SurveyTemplateAnswers";
  id: Scalars["ID"];
  customerId: Scalars["ID"];
  surveyTemplateId: Scalars["ID"];
  template: Scalars["JSON"];
  answeredBy?: Maybe<Scalars["ID"]>;
  stringifiedAnswers: Scalars["String"];
  contact?: Maybe<Contact>;
  business?: Maybe<Business>;
  createdAt: Scalars["String"];
};

export type SurveyTemplateInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  questions?: Maybe<Array<Maybe<SurveyQuestionInput>>>;
};

export type SurveyTemplateNew = {
  __typename?: "SurveyTemplateNew";
  id: Scalars["ID"];
  customerId: Scalars["ID"];
  createdBy: Scalars["ID"];
  template: Scalars["JSON"];
  title: Scalars["String"];
};

export type SurveyTemplateNewInput = {
  id?: Maybe<Scalars["ID"]>;
  template: Scalars["JSON"];
};

export type Tag = {
  __typename?: "Tag";
  id: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<TagTypeEnum>;
};

export type TagParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<TagTypeEnum>;
};

export type TagSearchResult = {
  __typename?: "TagSearchResult";
  organizations?: Maybe<Array<Maybe<Organization>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  todos?: Maybe<Array<Maybe<Todo>>>;
  sites?: Maybe<Array<Maybe<Site>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  cases?: Maybe<Array<Maybe<Case>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  businesses?: Maybe<Array<Maybe<Business>>>;
};

export enum TagTypeEnum {
  Business = "business",
  Contact = "contact",
  Todo = "todo",
  Any = "any",
}

export type TagsSearchResponse = {
  __typename?: "TagsSearchResponse";
  tags?: Maybe<Array<Tag>>;
  count?: Maybe<Scalars["Int"]>;
};

export type Todo = {
  __typename?: "Todo";
  id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  businessId?: Maybe<Scalars["ID"]>;
  business?: Maybe<Business>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars["ID"]>;
  assignedTo?: Maybe<UserProfile>;
  assignedToId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  dueDate?: Maybe<Scalars["String"]>;
  listId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["ID"]>;
  contact?: Maybe<Contact>;
  case?: Maybe<Case>;
  caseId?: Maybe<Scalars["ID"]>;
  done?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<Maybe<Todo>>>;
  projectId?: Maybe<Scalars["String"]>;
  project?: Maybe<Project>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type TodoList = {
  __typename?: "TodoList";
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  todos?: Maybe<Array<Maybe<Todo>>>;
};

export type TodoListTodosArgs = {
  sortBy?: Maybe<TodoListSortByEnum>;
  searchText?: Maybe<Scalars["String"]>;
};

export type TodoListParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum TodoListSortByEnum {
  Priority = "priority",
  CreatedAt = "createdAt",
  DueDate = "dueDate",
}

export type TodoListsResponse = {
  __typename?: "TodoListsResponse";
  todoLists?: Maybe<Array<Maybe<TodoList>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type TodoParams = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  dueDate?: Maybe<Scalars["String"]>;
  done?: Maybe<Scalars["Boolean"]>;
  contactId?: Maybe<Scalars["ID"]>;
  caseId?: Maybe<Scalars["ID"]>;
  listId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
  businessId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["String"]>;
  siteId?: Maybe<Scalars["ID"]>;
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export enum TodoSortBy {
  PriorityAsc = "priorityAsc",
  PriorityDesc = "priorityDesc",
  DueDateAsc = "dueDateAsc",
  DueDateDesc = "dueDateDesc",
}

export type TodosResponse = {
  __typename?: "TodosResponse";
  todos?: Maybe<Array<Todo>>;
  count?: Maybe<Scalars["Int"]>;
};

export type Tokens = {
  __typename?: "Tokens";
  refreshToken?: Maybe<Scalars["String"]>;
  accessToken?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKey = {
  __typename?: "TwoFactorSecretKey";
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export enum UpdateArrayActionEnum {
  Add = "add",
  Remove = "remove",
}

export type UpdatedCountResponse = {
  __typename?: "UpdatedCountResponse";
  success?: Maybe<Scalars["Boolean"]>;
  count?: Maybe<UpdatedCounts>;
  error?: Maybe<Scalars["String"]>;
};

export type UpdatedCounts = {
  __typename?: "UpdatedCounts";
  enrichedBusinesses?: Maybe<Scalars["Float"]>;
  enrichedContacts?: Maybe<Scalars["Float"]>;
  createdContacts?: Maybe<Scalars["Float"]>;
  createdBusinesses?: Maybe<Scalars["Float"]>;
};

export type UploadBusinessInput = {
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  /** Shortened comain name */
  domain?: Maybe<Scalars["String"]>;
  /** URL to the image we got from google places. Could be used as a logo. */
  thumbnail?: Maybe<Scalars["String"]>;
  /** Should be a base64 string of the logo, if it exists */
  logo?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  lng?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  customerId: Scalars["ID"];
  naicsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  naics_codes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primaryContact?: Maybe<ContactInput>;
  googleRating?: Maybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  emails?: Maybe<Array<EmailRecord>>;
  username?: Maybe<Scalars["String"]>;
};

export type UserGroup = {
  __typename?: "UserGroup";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<UserProfile>>;
};

export type UserGroupInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type UserParams = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  image?: Maybe<AttachmentParams>;
  avatar?: Maybe<AttachmentParams>;
  permission?: Maybe<UserPermissionEnum>;
  notificationSettings?: Maybe<Array<Maybe<NotificationSettingInput>>>;
};

export enum UserPermissionEnum {
  Administrator = "administrator",
  Member = "member",
  Viewer = "viewer",
}

/** The main User object used for authentication etc */
export type UserProfile = {
  __typename?: "UserProfile";
  id: Scalars["String"];
  customer?: Maybe<Customer>;
  firstName: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  avatar?: Maybe<Attachment>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  customerId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emails?: Maybe<Array<Maybe<EmailRecord>>>;
  business?: Maybe<Business>;
  organizationName?: Maybe<Scalars["String"]>;
  permission?: Maybe<UserPermissionEnum>;
  notificationSettings?: Maybe<Array<Maybe<NotificationSetting>>>;
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  recentlySentInvite?: Maybe<Scalars["String"]>;
  /** The date the last sent invite will expire */
  lastInviteExpiration?: Maybe<Scalars["String"]>;
  deactivated?: Maybe<Scalars["Boolean"]>;
};

export type UserSearchParams = {
  searchText?: Maybe<Scalars["String"]>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type UsersSearchResponse = {
  __typename?: "UsersSearchResponse";
  users?: Maybe<Array<Maybe<UserProfile>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type WorkItem = Case | Todo;

export type YourWorkResponse = {
  __typename?: "YourWorkResponse";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<WorkItem>>;
};

export enum YourWorkTypeEnum {
  AssignedToMe = "assignedToMe",
  CreatedByMe = "createdByMe",
  TeamsWork = "teamsWork",
}

export type GetManyEmailCampaignsResponse = {
  __typename?: "getManyEmailCampaignsResponse";
  results?: Maybe<Array<EmailCampaign>>;
  count: Scalars["Int"];
};

export type ActivityItemFragmentFragment = {
  __typename?: "ActivityItem";
} & Pick<
  ActivityItem,
  | "id"
  | "title"
  | "description"
  | "activityType"
  | "parent"
  | "parentId"
  | "sourceId"
  | "sourceType"
  | "createdAt"
  | "sortDate"
> & {
    interaction?: Maybe<
      { __typename?: "Interaction" } & InteractionLongFragmentFragment
    >;
    case?: Maybe<{ __typename?: "Case" } & CaseFragmentShortFragment>;
  };

export type AttachmentFragmentFragment = { __typename: "Attachment" } & Pick<
  Attachment,
  | "id"
  | "filename"
  | "encoding"
  | "mimetype"
  | "url"
  | "key"
  | "createdAt"
  | "createdByName"
>;

export type AutomationFragmentFragment = { __typename?: "Automation" } & Pick<
  Automation,
  | "id"
  | "name"
  | "description"
  | "conditions"
  | "events"
  | "trigger"
  | "lastRun"
>;

export type BusinessByIdFragmentFragment = { __typename?: "Business" } & Pick<
  Business,
  "annualRevenue" | "contactIds" | "email" | "businessSectorId" | "tradeName"
> & {
    projects?: Maybe<
      Array<
        { __typename?: "Project" } & Pick<Project, "id" | "title"> & {
            image?: Maybe<
              { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
            >;
          }
      >
    >;
    primaryContact?: Maybe<
      { __typename?: "Contact" } & ContactShortFragmentFragment
    >;
    businessSector?: Maybe<
      { __typename?: "BusinessSector" } & Pick<BusinessSector, "id" | "title">
    >;
    cases?: Maybe<Array<{ __typename?: "Case" } & CaseFragmentShortFragment>>;
    owner?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName" | "email"
      >
    >;
    companyInvestments?: Maybe<
      Array<
        Maybe<
          { __typename?: "CompanyInvestment" } & Pick<
            CompanyInvestment,
            "id" | "amount" | "description" | "createdAt" | "investedOn"
          >
        >
      >
    >;
    contacts?: Maybe<
      Array<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "id"
          | "email"
          | "firstName"
          | "lastName"
          | "title"
          | "website"
          | "workPhone"
        > & {
            avatar?: Maybe<
              { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
            >;
          }
      >
    >;
  } & BusinessFragmentFragment;

export type BusinessFragmentFragment = { __typename: "Business" } & Pick<
  Business,
  | "id"
  | "title"
  | "optResult"
  | "description"
  | "email"
  | "phone"
  | "twitter"
  | "instagram"
  | "linkedin"
  | "facebook"
  | "businessSectorId"
  | "tradeName"
  | "fax"
  | "website"
  | "businessStage"
  | "clusters"
  | "serviceNeeds"
  | "surveyPermission"
  | "naicsIds"
  | "diversity"
  | "amenities"
  | "employeeCount"
  | "businessType"
  | "closed"
  | "opened"
  | "status"
  | "createdAt"
  | "lat"
  | "lng"
  | "placeId"
  | "fullAddress"
  | "street"
  | "street2"
  | "postal"
  | "country"
  | "state"
  | "city"
  | "annualRevenue"
> & {
    businessSector?: Maybe<
      { __typename?: "BusinessSector" } & Pick<BusinessSector, "id" | "title">
    >;
    logo?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
      >
    >;
    attachments?: Maybe<
      Array<Maybe<{ __typename?: "Attachment" } & AttachmentFragmentFragment>>
    >;
    naics?: Maybe<
      Array<{ __typename?: "Naic" } & Pick<Naic, "id" | "title" | "code">>
    >;
    tags?: Maybe<Array<{ __typename?: "Tag" } & TagFragmentFragment>>;
    primaryContact?: Maybe<
      { __typename?: "Contact" } & ContactShortFragmentFragment
    >;
  };

export type BusinessShortFragmentFragment = { __typename?: "Business" } & Pick<
  Business,
  | "id"
  | "title"
  | "email"
  | "description"
  | "phone"
  | "industry"
  | "twitter"
  | "instagram"
  | "linkedin"
  | "contactIds"
  | "facebook"
  | "fax"
  | "lat"
  | "lng"
  | "city"
  | "country"
  | "website"
  | "businessStage"
  | "street"
  | "postal"
  | "state"
  | "clusters"
  | "serviceNeeds"
  | "surveyPermission"
  | "createdAt"
  | "businessType"
  | "closed"
  | "opened"
  | "status"
  | "diversity"
  | "amenities"
  | "employeeCount"
> & {
    businessSector?: Maybe<
      { __typename?: "BusinessSector" } & Pick<BusinessSector, "id" | "title">
    >;
    logo?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "url" | "key" | "createdAt"
      > & { id: Attachment["url"] }
    >;
    primaryContact?: Maybe<
      { __typename?: "Contact" } & ContactShortFragmentFragment
    >;
    naics?: Maybe<
      Array<{ __typename?: "Naic" } & Pick<Naic, "title" | "code">>
    >;
    tags?: Maybe<Array<{ __typename?: "Tag" } & TagFragmentFragment>>;
  };

export type CaseFragmentFragment = { __typename?: "Case" } & Pick<
  Case,
  | "id"
  | "title"
  | "key"
  | "description"
  | "caseStatusId"
  | "createdAt"
  | "updatedAt"
  | "tagIds"
  | "dueDate"
  | "resolvedOn"
  | "resolutionNotes"
  | "reporterId"
  | "assignedToId"
  | "caseCategoryId"
  | "serviceIds"
  | "businessId"
  | "contactId"
  | "openTodos"
  | "closedTodos"
> & {
    tags?: Maybe<
      Array<Maybe<{ __typename?: "Tag" } & Pick<Tag, "id" | "title">>>
    >;
    caseOutcomes?: Maybe<
      Array<Maybe<{ __typename?: "CaseOutcome" } & CaseOutcomeFragmentFragment>>
    >;
    reporter?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName"
      > & {
          avatar?: Maybe<
            { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
          >;
        }
    >;
    caseStatus?: Maybe<
      { __typename?: "CaseStatus" } & Pick<CaseStatus, "id" | "title" | "order">
    >;
    project?: Maybe<{ __typename?: "Project" } & ProjectFragmentShortFragment>;
    attachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            "id" | "url" | "filename"
          >
        >
      >
    >;
    assignedTo?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
    caseCategory?: Maybe<
      { __typename?: "CaseCategory" } & Pick<CaseCategory, "id" | "title">
    >;
    services?: Maybe<
      Array<
        Maybe<
          { __typename?: "CaseService" } & Pick<CaseService, "id" | "title">
        >
      >
    >;
    business?: Maybe<
      { __typename: "Business" } & Pick<
        Business,
        "id" | "title" | "description" | "phone"
      > & {
          logo?: Maybe<
            { __typename?: "Attachment" } & Pick<
              Attachment,
              "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
            >
          >;
        }
    >;
    contact?: Maybe<
      { __typename?: "Contact" } & Pick<
        Contact,
        | "id"
        | "email"
        | "firstName"
        | "lastName"
        | "title"
        | "website"
        | "workPhone"
      > & {
          avatar?: Maybe<
            { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
          >;
        }
    >;
  };

export type CaseFragmentShortFragment = { __typename?: "Case" } & Pick<
  Case,
  "id" | "title" | "createdAt" | "resolutionNotes"
> & {
    caseStatus?: Maybe<
      { __typename?: "CaseStatus" } & Pick<
        CaseStatus,
        "id" | "title" | "isFinalStep"
      >
    >;
    assignedTo?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName"
      > & {
          avatar?: Maybe<
            { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
          >;
        }
    >;
    caseOutcomes?: Maybe<
      Array<
        Maybe<
          { __typename?: "CaseOutcome" } & Pick<CaseOutcome, "id" | "title">
        >
      >
    >;
    reporter?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName"
      > & {
          avatar?: Maybe<
            { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
          >;
        }
    >;
  };

export type CaseOutcomeFragmentFragment = { __typename?: "CaseOutcome" } & Pick<
  CaseOutcome,
  "id" | "title" | "description"
>;

export type CaseTemplateFragmentFragment = {
  __typename?: "CaseTemplate";
} & Pick<
  CaseTemplate,
  "id" | "templateName" | "title" | "caseCategoryId" | "description"
> & {
    todos?: Maybe<
      Array<
        { __typename?: "CaseTemplateTodo" } & Pick<
          CaseTemplateTodo,
          "id" | "title" | "description" | "assignedTo"
        >
      >
    >;
  };

export type CommentFragmentFragment = { __typename?: "Comment" } & Pick<
  Comment,
  "id" | "content" | "createdAt" | "updatedAt"
> & {
    createdByUser?: Maybe<
      { __typename?: "UserProfile" } & UserFragmentFragment
    >;
  };

export type ContactFragmentFragment = { __typename?: "Contact" } & Pick<
  Contact,
  | "id"
  | "email"
  | "department"
  | "optResult"
  | "twitter"
  | "instagram"
  | "linkedin"
  | "facebook"
  | "firstName"
  | "lastName"
  | "title"
  | "status"
  | "website"
  | "workPhone"
  | "cellPhone"
  | "homePhone"
  | "lat"
  | "lng"
  | "placeId"
  | "fullAddress"
  | "street"
  | "postal"
  | "country"
  | "state"
  | "city"
> & {
    avatar?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
      >
    >;
    attachments?: Maybe<
      Array<{ __typename?: "Attachment" } & AttachmentFragmentFragment>
    >;
    businesses?: Maybe<
      Array<{ __typename?: "Business" } & BusinessFragmentFragment>
    >;
    tags?: Maybe<
      Array<{ __typename?: "Tag" } & Pick<Tag, "id" | "title" | "description">>
    >;
  };

export type ContactShortFragmentFragment = { __typename?: "Contact" } & Pick<
  Contact,
  | "id"
  | "email"
  | "department"
  | "firstName"
  | "lastName"
  | "title"
  | "status"
  | "website"
  | "workPhone"
  | "cellPhone"
  | "homePhone"
> & {
    avatar?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
      >
    >;
  };

export type CurrentUserFragmentFragment = { __typename: "UserProfile" } & Pick<
  UserProfile,
  | "id"
  | "customerId"
  | "permission"
  | "title"
  | "email"
  | "firstName"
  | "lastName"
  | "roles"
> & {
    emails?: Maybe<
      Array<
        Maybe<{ __typename?: "EmailRecord" } & Pick<EmailRecord, "address">>
      >
    >;
    customer?: Maybe<
      { __typename?: "Customer" } & CustomerShortFragmentFragment
    >;
    notificationSettings?: Maybe<
      Array<
        Maybe<
          { __typename?: "NotificationSetting" } & Pick<
            NotificationSetting,
            "notificationType" | "email"
          >
        >
      >
    >;
    avatar?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "filename" | "encoding" | "mimetype" | "url" | "key"
      >
    >;
  };

export type CustomerFeatureSettingFragmentFragment = {
  __typename?: "CustomerFeatureSetting";
} & Pick<CustomerFeatureSetting, "title" | "key" | "active" | "available"> & {
    featureSettings?: Maybe<
      Array<
        Maybe<
          { __typename?: "SubFeatureSetting" } & Pick<
            SubFeatureSetting,
            "key"
          > & {
              extraFields?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "SiteExtraField" } & Pick<
                      SiteExtraField,
                      "fieldName" | "value" | "fieldType"
                    >
                  >
                >
              >;
            }
        >
      >
    >;
  };

export type CustomerIntegrationsFragmentFragment = {
  __typename?: "CustomerIntegrations";
} & {
  constantContact?: Maybe<
    { __typename?: "ConstantContactCustomerIntegration" } & Pick<
      ConstantContactCustomerIntegration,
      "exists"
    >
  >;
};

export type CustomerLongFragmentFragment = { __typename?: "Customer" } & Pick<
  Customer,
  "businessCount" | "contactsCount" | "integrationCount"
> & {
    users?: Maybe<
      Array<
        Maybe<
          { __typename?: "UserProfile" } & Pick<
            UserProfile,
            "id" | "email" | "firstName" | "lastName"
          >
        >
      >
    >;
    integrations?: Maybe<
      {
        __typename?: "CustomerIntegrations";
      } & CustomerIntegrationsFragmentFragment
    >;
    sharingSettings?: Maybe<
      Array<
        {
          __typename?: "SharingCustomerSetting";
        } & CustomerSharingSettingsFragmentFragment
      >
    >;
  } & CustomerShortFragmentFragment;

export type CustomerSharingSettingsFragmentFragment = {
  __typename?: "SharingCustomerSetting";
} & Pick<SharingCustomerSetting, "key" | "enabled">;

export type CustomerShortFragmentFragment = { __typename?: "Customer" } & Pick<
  Customer,
  | "id"
  | "title"
  | "customIntakePageUrl"
  | "maximumEmailsPerDay"
  | "maximumEmailsPerMinute"
> & {
    integrations?: Maybe<
      { __typename?: "CustomerIntegrations" } & {
        constantContact?: Maybe<
          { __typename?: "ConstantContactCustomerIntegration" } & Pick<
            ConstantContactCustomerIntegration,
            "exists"
          >
        >;
      }
    >;
    features?: Maybe<
      Array<
        Maybe<
          {
            __typename?: "CustomerFeatureSetting";
          } & CustomerFeatureSettingFragmentFragment
        >
      >
    >;
    sharingSettings?: Maybe<
      Array<
        {
          __typename?: "SharingCustomerSetting";
        } & CustomerSharingSettingsFragmentFragment
      >
    >;
  };

export type DataDiscoveryBusinessFragmentFragment = {
  __typename?: "DataDiscoveryBusiness";
} & Pick<
  DataDiscoveryBusiness,
  | "id"
  | "address"
  | "city"
  | "description"
  | "domain"
  | "latitude"
  | "longitude"
  | "naics_code"
  | "naics_definition"
  | "google_places_id"
  | "operating_hours"
  | "phone"
  | "state"
  | "status"
  | "thumbnail"
  | "title"
  | "website"
  | "logo"
  | "ignored"
  | "existingBusinessId"
  | "contacts"
  | "merged"
>;

export type DataDiscoveryRunFragmentFragment = {
  __typename?: "DataDiscoveryRun";
} & Pick<
  DataDiscoveryRun,
  | "id"
  | "createdAt"
  | "businessesFound"
  | "newBusinessesFound"
  | "mergedAt"
  | "mergedBusinesses"
  | "mergedContacts"
  | "contactsFound"
  | "searchTerms"
  | "status"
>;

export type EmailCampaignFragmentFragment = {
  __typename?: "EmailCampaign";
} & Pick<
  EmailCampaign,
  | "id"
  | "title"
  | "description"
  | "subject"
  | "fromAddress"
  | "replyToAddress"
  | "fromName"
  | "emailTemplate"
  | "campaignType"
  | "createdAt"
  | "updatedAt"
  | "delivered"
  | "recipients"
  | "failed"
  | "bounced"
  | "bounceRate"
  | "openRate"
  | "status"
  | "scheduledAt"
  | "surveyTemplateId"
> & {
    recipientReferences?: Maybe<
      Array<
        { __typename?: "EmailCampaignRecipientReference" } & Pick<
          EmailCampaignRecipientReference,
          "id" | "title" | "referenceType"
        >
      >
    >;
  };

export type EmailCampaignItemFragmentFragment = {
  __typename?: "EmailCampaignItem";
} & Pick<
  EmailCampaignItem,
  | "id"
  | "email"
  | "status"
  | "scheduledAt"
  | "contactId"
  | "body"
  | "subject"
  | "toEmail"
  | "failedReason"
>;

export type EmailIntegrationFragmentFragment = {
  __typename?: "EmailIntegration";
} & Pick<EmailIntegration, "id" | "provider" | "email" | "invalid">;

export type EventFragmentFragment = { __typename?: "Event" } & Pick<
  Event,
  | "id"
  | "title"
  | "description"
  | "type"
  | "date"
  | "lat"
  | "lng"
  | "placeId"
  | "fullAddress"
  | "businessStages"
  | "street"
  | "postal"
  | "country"
  | "state"
  | "city"
> & {
    organization?: Maybe<
      { __typename?: "Organization" } & OrganizationFragmentFragment
    >;
  };

export type FormQuestionFragmentFragment = {
  __typename?: "FormQuestion";
} & Pick<
  FormQuestion,
  | "id"
  | "label"
  | "placeholder"
  | "description"
  | "questionType"
  | "showIfConditions"
> & {
    validation?: Maybe<
      { __typename?: "FormQuestionValidation" } & Pick<
        FormQuestionValidation,
        "required"
      >
    >;
    options?: Maybe<
      Array<
        { __typename?: "FormQuestionOption" } & Pick<
          FormQuestionOption,
          "label" | "value" | "isOther"
        >
      >
    >;
    subQuestions?: Maybe<
      Array<
        { __typename?: "FormQuestion" } & Pick<
          FormQuestion,
          | "id"
          | "label"
          | "placeholder"
          | "description"
          | "questionType"
          | "showIfConditions"
        > & {
            validation?: Maybe<
              { __typename?: "FormQuestionValidation" } & Pick<
                FormQuestionValidation,
                "required"
              >
            >;
            options?: Maybe<
              Array<
                { __typename?: "FormQuestionOption" } & Pick<
                  FormQuestionOption,
                  "label" | "value" | "isOther"
                >
              >
            >;
          }
      >
    >;
  };

export type FormSubmissionFragmentFragment = {
  __typename?: "FormSubmission";
} & Pick<
  FormSubmission,
  | "id"
  | "formTemplateId"
  | "respondentType"
  | "respondentId"
  | "attributionDate"
> & {
    business?: Maybe<{ __typename?: "Business" } & Pick<Business, "id">>;
    answers?: Maybe<
      Array<
        { __typename?: "FormSubmissionAnswer" } & Pick<
          FormSubmissionAnswer,
          "questionId" | "label" | "answer" | "other"
        >
      >
    >;
    formTemplate?: Maybe<
      { __typename?: "FormTemplate" } & Pick<FormTemplate, "id" | "title"> & {
          questions: Array<
            { __typename?: "FormQuestion" } & FormQuestionFragmentFragment
          >;
        }
    >;
  };

export type FormTemplateFragmentFragment = {
  __typename?: "FormTemplate";
} & Pick<FormTemplate, "id" | "title" | "description"> & {
    questions: Array<
      { __typename?: "FormQuestion" } & FormQuestionFragmentFragment
    >;
  };

export type InteractionFragmentFragment = { __typename: "Interaction" } & Pick<
  Interaction,
  | "id"
  | "title"
  | "description"
  | "type"
  | "createdAt"
  | "date"
  | "businessId"
  | "contactId"
  | "projectId"
  | "direction"
  | "organizationId"
> & {
    project?: Maybe<{ __typename?: "Project" } & Pick<Project, "id" | "title">>;
    emailCampaign?: Maybe<
      { __typename?: "EmailCampaign" } & Pick<EmailCampaign, "id" | "title">
    >;
    contact?: Maybe<
      { __typename?: "Contact" } & Pick<Contact, "id" | "fullName" | "email">
    >;
    createdBy?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName"
      >
    >;
    attachments?: Maybe<
      Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "id" | "url" | "filename"
        >
      >
    >;
  };

export type InteractionLongFragmentFragment = { __typename?: "Interaction" } & {
  business?: Maybe<
    { __typename?: "Business" } & Pick<Business, "id" | "title">
  >;
  organization?: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id" | "title">
  >;
  email?: Maybe<
    { __typename?: "InteractionEmail" } & Pick<
      InteractionEmail,
      "from" | "to" | "subject" | "body"
    > & {
        attachments?: Maybe<
          Array<
            Maybe<
              { __typename?: "Attachment" } & Pick<
                Attachment,
                | "id"
                | "filename"
                | "encoding"
                | "mimetype"
                | "url"
                | "key"
                | "createdAt"
                | "createdByName"
              >
            >
          >
        >;
      }
  >;
} & InteractionFragmentFragment;

export type ListFragmentFragment = { __typename?: "List" } & Pick<
  List,
  "id" | "name" | "numberOfRecords" | "updatedAt"
> & {
    createdBy?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName" | "email"
      >
    >;
  };

export type ListItemFragmentFragment = { __typename?: "ListItem" } & Pick<
  ListItem,
  "id"
> & {
    contact?: Maybe<{ __typename?: "Contact" } & ContactShortFragmentFragment>;
    business?: Maybe<
      { __typename?: "Business" } & BusinessShortFragmentFragment
    >;
  };

export type NoteFragmentFragment = { __typename: "Note" } & Pick<
  Note,
  | "id"
  | "title"
  | "description"
  | "createdAt"
  | "businessId"
  | "contactId"
  | "siteId"
  | "projectId"
> & {
    project?: Maybe<{ __typename?: "Project" } & Pick<Project, "id" | "title">>;
    createdByUser?: Maybe<
      { __typename?: "UserProfile" } & UserFragmentFragment
    >;
    attachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            | "id"
            | "filename"
            | "encoding"
            | "mimetype"
            | "url"
            | "key"
            | "createdAt"
            | "createdByName"
          >
        >
      >
    >;
  };

export type NotificationFragmentFragment = {
  __typename?: "Notification";
} & Pick<
  Notification,
  "id" | "actorId" | "content" | "read" | "actionType" | "createdAt"
> & {
    entity?: Maybe<
      | ({ __typename: "SurveyResponse" } & Pick<SurveyResponse, "id"> & {
            business?: Maybe<
              { __typename?: "Business" } & Pick<Business, "id">
            >;
          })
      | ({ __typename: "Todo" } & Pick<Todo, "id" | "title">)
      | ({ __typename: "Case" } & Pick<Case, "id" | "title">)
    >;
  };

export type ObjectPermissionFragmentFragment = {
  __typename?: "ObjectPermission";
} & Pick<ObjectPermission, "access" | "subjectId" | "subjectType"> & {
    id: ObjectPermission["subjectId"];
  } & { subject: { __typename?: "UserGroup" } & UserGroupFragmentFragment };

export type OrganizationFragmentFragment = {
  __typename?: "Organization";
} & Pick<
  Organization,
  | "id"
  | "title"
  | "description"
  | "businessStages"
  | "clusters"
  | "diversity"
  | "orgType"
  | "serviceTypes"
  | "tagIds"
  | "contactIds"
> & {
    contacts?: Maybe<
      Array<
        Maybe<
          { __typename?: "Contact" } & Pick<
            Contact,
            | "id"
            | "email"
            | "firstName"
            | "lastName"
            | "title"
            | "website"
            | "workPhone"
          > & {
              avatar?: Maybe<
                { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
              >;
            }
        >
      >
    >;
  };

export type PipelineStageFragmentFragment = {
  __typename?: "PipelineStage";
} & Pick<PipelineStage, "id" | "label" | "order">;

export type PipelineFragmentFragment = { __typename?: "Pipeline" } & Pick<
  Pipeline,
  "id" | "title"
> & {
    stages?: Maybe<
      Array<{ __typename?: "PipelineStage" } & PipelineStageFragmentFragment>
    >;
  };

export type ProjectFragmentFragment = { __typename: "Project" } & Pick<
  Project,
  | "id"
  | "title"
  | "description"
  | "projectType"
  | "pipeline"
  | "order"
  | "amount"
  | "stageId"
  | "updatedAt"
  | "createdAt"
  | "businessIds"
  | "contactIds"
  | "siteIds"
> & {
    image?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
      >
    >;
    extraFields?: Maybe<
      Array<
        { __typename?: "ExtraField" } & Pick<
          ExtraField,
          "value" | "fieldName" | "fieldType"
        >
      >
    >;
    customFields?: Maybe<
      Array<
        { __typename?: "ExtraField" } & Pick<
          ExtraField,
          "value" | "fieldName" | "fieldType"
        >
      >
    >;
  };

export type ProjectFragmentLongFragment = { __typename?: "Project" } & Pick<
  Project,
  "pipelineName"
> & {
    pipelineStage?: Maybe<
      { __typename?: "PipelineStage" } & Pick<
        PipelineStage,
        "id" | "label" | "order"
      >
    >;
    contacts?: Maybe<
      Array<
        { __typename?: "Contact" } & Pick<
          Contact,
          "id" | "firstName" | "lastName" | "email"
        > & {
            avatar?: Maybe<
              { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
            >;
          }
      >
    >;
    cases?: Maybe<
      Array<Maybe<{ __typename?: "Case" } & CaseFragmentShortFragment>>
    >;
    sites?: Maybe<Array<{ __typename?: "Site" } & SiteFragmentFragment>>;
    businesses?: Maybe<
      Array<
        { __typename?: "Business" } & Pick<
          Business,
          "id" | "title" | "phone" | "website"
        > & {
            logo?: Maybe<
              { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
            >;
          }
      >
    >;
  } & ProjectFragmentFragment;

export type ProjectFragmentShortFragment = { __typename: "Project" } & Pick<
  Project,
  | "id"
  | "title"
  | "description"
  | "projectType"
  | "pipeline"
  | "order"
  | "amount"
  | "stageId"
  | "updatedAt"
  | "createdAt"
> & {
    image?: Maybe<
      { __typename?: "Attachment" } & Pick<
        Attachment,
        "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
      >
    >;
  };

export type ProjectTypeFragmentFragment = { __typename: "ProjectType" } & Pick<
  ProjectType,
  "id" | "title"
> & {
    extraFields?: Maybe<
      Array<
        { __typename?: "ExtraField" } & Pick<
          ExtraField,
          "fieldName" | "fieldType" | "value"
        >
      >
    >;
    customFields?: Maybe<
      Array<
        { __typename?: "ExtraField" } & Pick<
          ExtraField,
          "fieldName" | "fieldType" | "value"
        >
      >
    >;
  };

export type ResourceFragmentFragment = { __typename?: "Resource" } & Pick<
  Resource,
  | "id"
  | "title"
  | "description"
  | "businessStages"
  | "type"
  | "clusters"
  | "supportType"
  | "fundingType"
  | "diversityTypes"
  | "tagIds"
> & {
    organization?: Maybe<
      { __typename?: "Organization" } & OrganizationFragmentFragment
    >;
  };

export type SiteFragmentFragment = { __typename?: "Site" } & Pick<
  Site,
  | "id"
  | "title"
  | "description"
  | "type"
  | "size"
  | "lat"
  | "lng"
  | "url"
  | "placeId"
  | "fullAddress"
  | "street"
  | "postal"
  | "country"
  | "archived"
  | "tagIds"
  | "state"
  | "city"
  | "contactId"
> & {
    projects?: Maybe<
      Array<{ __typename?: "Project" } & ProjectFragmentShortFragment>
    >;
    attachments?: Maybe<
      Array<{ __typename?: "Attachment" } & AttachmentFragmentFragment>
    >;
    contacts?: Maybe<
      Array<{ __typename?: "Contact" } & ContactFragmentFragment>
    >;
    contact?: Maybe<{ __typename?: "Contact" } & ContactFragmentFragment>;
    extraFields?: Maybe<
      Array<
        { __typename?: "SiteExtraField" } & Pick<
          SiteExtraField,
          "fieldName" | "value" | "fieldType"
        >
      >
    >;
  };

export type SurveyCampaignFragmentFragment = {
  __typename?: "SurveyCampaign";
} & Pick<
  SurveyCampaign,
  "id" | "name" | "numberOfRecords" | "surveyTemplateId" | "updatedAt"
> & {
    surveyTemplate?: Maybe<
      { __typename?: "SurveyTemplateNew" } & Pick<
        SurveyTemplateNew,
        "id" | "title"
      >
    >;
    createdBy?: Maybe<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        "id" | "firstName" | "lastName" | "email"
      >
    >;
  };

export type SurveyQuestionFragmentFragment = {
  __typename?: "SurveyQuestion";
} & Pick<
  SurveyQuestion,
  | "id"
  | "label"
  | "description"
  | "customerId"
  | "isDefault"
  | "category"
  | "key"
  | "showIfKey"
  | "showIfAnswer"
  | "questionType"
> & {
    options?: Maybe<
      Array<
        Maybe<
          { __typename?: "SurveyQuestionOption" } & Pick<
            SurveyQuestionOption,
            "label" | "value"
          >
        >
      >
    >;
  };

export type SurveyTemplateNewFragmentFragment = {
  __typename?: "SurveyTemplateNew";
} & Pick<
  SurveyTemplateNew,
  "id" | "customerId" | "createdBy" | "template" | "title"
>;

export type TagFragmentFragment = { __typename?: "Tag" } & Pick<
  Tag,
  "id" | "title" | "description" | "type"
>;

export type TodoFragmentFragment = { __typename: "Todo" } & Pick<
  Todo,
  | "id"
  | "key"
  | "title"
  | "description"
  | "dueDate"
  | "priority"
  | "done"
  | "assignedToId"
  | "tagIds"
  | "contactId"
  | "businessId"
  | "caseId"
  | "projectId"
  | "createdAt"
  | "updatedAt"
> & {
    assignedTo?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
    tags?: Maybe<
      Array<Maybe<{ __typename?: "Tag" } & Pick<Tag, "id" | "title">>>
    >;
    contact?: Maybe<{ __typename?: "Contact" } & ContactFragmentFragment>;
    site?: Maybe<
      { __typename?: "Site" } & Pick<Site, "id" | "title" | "fullAddress">
    >;
    business?: Maybe<
      { __typename?: "Business" } & Pick<Business, "id" | "title"> & {
          logo?: Maybe<
            { __typename?: "Attachment" } & Pick<
              Attachment,
              "id" | "url" | "filename"
            >
          >;
        }
    >;
    case?: Maybe<{ __typename?: "Case" } & Pick<Case, "id" | "title">>;
    project?: Maybe<
      { __typename?: "Project" } & Pick<Project, "id" | "title"> & {
          image?: Maybe<
            { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
          >;
        }
    >;
    attachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "Attachment" } & Pick<
            Attachment,
            | "id"
            | "filename"
            | "encoding"
            | "mimetype"
            | "url"
            | "key"
            | "createdAt"
            | "createdByName"
          >
        >
      >
    >;
  };

export type TodoListFragmentFragment = { __typename: "TodoList" } & Pick<
  TodoList,
  "id" | "title" | "description" | "archived"
> & {
    todos?: Maybe<Array<Maybe<{ __typename?: "Todo" } & TodoFragmentFragment>>>;
  };

export type UserFragmentFragment = { __typename: "UserProfile" } & Pick<
  UserProfile,
  | "id"
  | "email"
  | "firstName"
  | "lastName"
  | "customerId"
  | "roles"
  | "permission"
> & {
    avatar?: Maybe<
      { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
    >;
  };

export type UserGroupFragmentFragment = { __typename?: "UserGroup" } & Pick<
  UserGroup,
  "id" | "title" | "description"
> & {
    users?: Maybe<Array<{ __typename?: "UserProfile" } & UserFragmentFragment>>;
  };

export type AddCompanyInvestmentMutationVariables = Exact<{
  amount?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  investedOn?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
}>;

export type AddCompanyInvestmentMutation = { __typename?: "Mutation" } & {
  addCompanyInvestment?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type AddEntityToBusinessMutationVariables = Exact<{
  businessId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
}>;

export type AddEntityToBusinessMutation = { __typename?: "Mutation" } & {
  addEntityToBusiness?: Maybe<{ __typename?: "Contact" } & Pick<Contact, "id">>;
};

export type AddEntityToProjectMutationVariables = Exact<{
  projectId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
}>;

export type AddEntityToProjectMutation = { __typename?: "Mutation" } & {
  addEntityToProject?: Maybe<{ __typename?: "Project" } & Pick<Project, "id">>;
};

export type AddManyItemsToListMutationVariables = Exact<{
  listId: Scalars["ID"];
  itemIds: Array<Scalars["ID"]> | Scalars["ID"];
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
}>;

export type AddManyItemsToListMutation = { __typename?: "Mutation" } & {
  addManyItemsToList?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type AddManyItemsToSurveyCampaignMutationVariables = Exact<{
  listId?: Maybe<Scalars["ID"]>;
  surveyCampaignId: Scalars["ID"];
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
  itemIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type AddManyItemsToSurveyCampaignMutation = {
  __typename?: "Mutation";
} & {
  addManyItemsToSurveyCampaign?: Maybe<
    { __typename?: "SurveyCampaign" } & SurveyCampaignFragmentFragment
  >;
};

export type AddPipelineColMutationVariables = Exact<{
  params?: Maybe<PipelineStageInput>;
}>;

export type AddPipelineColMutation = { __typename?: "Mutation" } & {
  addPipelineCol?: Maybe<
    { __typename?: "Pipeline" } & PipelineFragmentFragment
  >;
};

export type AddUsersToUserGroupMutationVariables = Exact<{
  userIds: Array<Scalars["ID"]> | Scalars["ID"];
  userGroupId: Scalars["ID"];
}>;

export type AddUsersToUserGroupMutation = { __typename?: "Mutation" } & {
  addUsersToUserGroup?: Maybe<
    { __typename?: "UserGroup" } & UserGroupFragmentFragment
  >;
};

export type ArchiveOneFormTemplateMutationVariables = Exact<{
  formTemplateId: Scalars["ID"];
}>;

export type ArchiveOneFormTemplateMutation = { __typename?: "Mutation" } & {
  archiveOneFormTemplate?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type ArchiveSiteMutationVariables = Exact<{
  siteId: Scalars["ID"];
  archived: Scalars["Boolean"];
}>;

export type ArchiveSiteMutation = { __typename?: "Mutation" } & {
  archiveSite?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type AuthenticateMutationVariables = Exact<{
  params: AuthenticateParamsInput;
}>;

export type AuthenticateMutation = { __typename?: "Mutation" } & {
  authenticate?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type CancelOneEmailCampaignMutationVariables = Exact<{
  emailCampaignId: Scalars["ID"];
}>;

export type CancelOneEmailCampaignMutation = { __typename?: "Mutation" } & {
  cancelOneEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "changePassword"
>;

export type CompleteMultiUploadMutationVariables = Exact<{
  type: MultipartUploadCompleteEnum;
  uploadId: Scalars["String"];
  key: Scalars["String"];
  parts?: Maybe<Array<MultipartUploadPartInput> | MultipartUploadPartInput>;
}>;

export type CompleteMultiUploadMutation = { __typename?: "Mutation" } & {
  completeMultiUpload?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type ContactSupportMutationVariables = Exact<{
  subject?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
}>;

export type ContactSupportMutation = { __typename?: "Mutation" } & {
  contactSupport?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type CreateCustomerMutationVariables = Exact<{
  title: Scalars["String"];
}>;

export type CreateCustomerMutation = { __typename?: "Mutation" } & {
  createCustomer?: Maybe<{ __typename?: "Customer" } & Pick<Customer, "id">>;
};

export type CreateCustomerAdminMutationVariables = Exact<{
  customerId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  permission: UserPermissionEnum;
}>;

export type CreateCustomerAdminMutation = { __typename?: "Mutation" } & {
  createCustomerAdmin?: Maybe<
    { __typename?: "UserProfile" } & Pick<UserProfile, "id">
  >;
};

export type CreateNylasAuthMutationVariables = Exact<{ [key: string]: never }>;

export type CreateNylasAuthMutation = { __typename?: "Mutation" } & {
  createNylasAuth?: Maybe<
    { __typename?: "CreateNyasAuthResponse" } & Pick<
      CreateNyasAuthResponse,
      "url"
    >
  >;
};

export type CreateNylasGrantMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type CreateNylasGrantMutation = { __typename?: "Mutation" } & {
  createNylasGrant?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type CreateOneAutomationMutationVariables = Exact<{
  params: AutomationInput;
}>;

export type CreateOneAutomationMutation = { __typename?: "Mutation" } & {
  createOneAutomation?: Maybe<
    { __typename?: "Automation" } & AutomationFragmentFragment
  >;
};

export type CreateOneCaseTemplateMutationVariables = Exact<{
  params?: Maybe<CaseTemplateInput>;
}>;

export type CreateOneCaseTemplateMutation = { __typename?: "Mutation" } & {
  createOneCaseTemplate?: Maybe<
    { __typename?: "CaseTemplate" } & CaseTemplateFragmentFragment
  >;
};

export type CreateOneEmailCampaignMutationVariables = Exact<{
  params?: Maybe<EmailCampaignInput>;
}>;

export type CreateOneEmailCampaignMutation = { __typename?: "Mutation" } & {
  createOneEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type CreateOneFormSubmissionMutationVariables = Exact<{
  params: FormSubmissionInput;
}>;

export type CreateOneFormSubmissionMutation = { __typename?: "Mutation" } & {
  createOneFormSubmission?: Maybe<
    { __typename?: "FormSubmission" } & Pick<FormSubmission, "id">
  >;
};

export type CreateOneFormTemplateMutationVariables = Exact<{
  params: FormTemplateInput;
}>;

export type CreateOneFormTemplateMutation = { __typename?: "Mutation" } & {
  createOneFormTemplate?: Maybe<
    { __typename?: "FormTemplate" } & FormTemplateFragmentFragment
  >;
};

export type CreateOneListMutationVariables = Exact<{
  name: Scalars["String"];
  listType: ListTypeEnum;
  listItemIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type CreateOneListMutation = { __typename?: "Mutation" } & {
  createOneList?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type CreateOneSurveyCampaignMutationVariables = Exact<{
  name: Scalars["String"];
  surveyTemplateId: Scalars["ID"];
}>;

export type CreateOneSurveyCampaignMutation = { __typename?: "Mutation" } & {
  createOneSurveyCampaign?: Maybe<
    { __typename?: "SurveyCampaign" } & SurveyCampaignFragmentFragment
  >;
};

export type CreateOneUserGroupMutationVariables = Exact<{
  params: UserGroupInput;
}>;

export type CreateOneUserGroupMutation = { __typename?: "Mutation" } & {
  createOneUserGroup?: Maybe<
    { __typename?: "UserGroup" } & UserGroupFragmentFragment
  >;
};

export type CreateUserProfileMutationVariables = Exact<{
  userId: Scalars["ID"];
  role?: Maybe<Scalars["String"]>;
}>;

export type CreateUserProfileMutation = { __typename?: "Mutation" } & {
  createUserProfile?: Maybe<
    { __typename?: "UserProfile" } & UserFragmentFragment
  >;
};

export type DeleteCaseMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCaseMutation = { __typename?: "Mutation" } & {
  deleteCase?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCommentMutation = { __typename?: "Mutation" } & {
  deleteComment?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteCompanyInvestmentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCompanyInvestmentMutation = { __typename?: "Mutation" } & {
  deleteCompanyInvestment?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteCustomerMutationVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type DeleteCustomerMutation = { __typename?: "Mutation" } & {
  deleteCustomer?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteEmailIntegrationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteEmailIntegrationMutation = { __typename?: "Mutation" } & {
  deleteEmailIntegration?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteInteractionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteInteractionMutation = { __typename?: "Mutation" } & {
  deleteInteraction?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteNoteMutation = { __typename?: "Mutation" } & {
  deleteNote?: Maybe<{ __typename?: "Note" } & NoteFragmentFragment>;
};

export type DeleteOneBusinessMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneBusinessMutation = { __typename?: "Mutation" } & {
  deleteOneBusiness?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneCaseTemplateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneCaseTemplateMutation = { __typename?: "Mutation" } & {
  deleteOneCaseTemplate?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneContactMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneContactMutation = { __typename?: "Mutation" } & {
  deleteOneContact?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneDefaultSurveyTemplateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneDefaultSurveyTemplateMutation = {
  __typename?: "Mutation";
} & {
  deleteOneDefaultSurveyTemplate: { __typename?: "MutationResponse" } & Pick<
    MutationResponse,
    "success"
  >;
};

export type DeleteOneListMutationVariables = Exact<{
  listId: Scalars["ID"];
}>;

export type DeleteOneListMutation = { __typename?: "Mutation" } & {
  deleteOneList?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneUserGroupMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneUserGroupMutation = { __typename?: "Mutation" } & {
  deleteOneUserGroup?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOrganizationMutation = { __typename?: "Mutation" } & {
  deleteOrganization?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type DeleteResourceMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteResourceMutation = { __typename?: "Mutation" } & {
  deleteResource?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type DeleteSiteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteSiteMutation = { __typename?: "Mutation" } & {
  deleteSite?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteTagMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTagMutation = { __typename?: "Mutation" } & {
  deleteTag?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type DeleteTeamMemberMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTeamMemberMutation = { __typename?: "Mutation" } & {
  deleteTeamMember?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type DeleteTodoMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTodoMutation = { __typename?: "Mutation" } & {
  deleteTodo?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteTodoListMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTodoListMutation = { __typename?: "Mutation" } & {
  deleteTodoList?: Maybe<{ __typename?: "TodoList" } & Pick<TodoList, "id">>;
};

export type DeleteHomeMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteHomeMutation = { __typename?: "Mutation" } & {
  deleteUser?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
};

export type EditTeamMemberMutationVariables = Exact<{
  id: Scalars["ID"];
  permission: UserPermissionEnum;
}>;

export type EditTeamMemberMutation = { __typename?: "Mutation" } & {
  editTeamMember?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
};

export type GetPresignedUploadMultiPartMutationVariables = Exact<{
  keyPath?: Maybe<Scalars["String"]>;
  contentType: Scalars["String"];
  parts: Scalars["Int"];
}>;

export type GetPresignedUploadMultiPartMutation = {
  __typename?: "Mutation";
} & {
  getPresignedUploadMultiPart?: Maybe<
    { __typename?: "PresignedMultipartResult" } & Pick<
      PresignedMultipartResult,
      "key" | "uploadId" | "urls"
    >
  >;
};

export type GetPresignedUploadUrlMutationVariables = Exact<{
  contentType: Scalars["String"];
}>;

export type GetPresignedUploadUrlMutation = { __typename?: "Mutation" } & {
  getPresignedUploadUrl?: Maybe<
    { __typename?: "PresignedUploadResponse" } & Pick<
      PresignedUploadResponse,
      "url" | "fields"
    >
  >;
};

export type IgnoreDataDiscoveryBusinessMutationVariables = Exact<{
  businessId: Scalars["ID"];
}>;

export type IgnoreDataDiscoveryBusinessMutation = {
  __typename?: "Mutation";
} & {
  ignoreDataDiscoveryBusiness?: Maybe<
    {
      __typename?: "DataDiscoveryBusiness";
    } & DataDiscoveryBusinessFragmentFragment
  >;
};

export type IgnoreOneDataDiscoveryContactMutationVariables = Exact<{
  discoveryBusinessId: Scalars["ID"];
  discoveryContactEmail: Scalars["String"];
}>;

export type IgnoreOneDataDiscoveryContactMutation = {
  __typename?: "Mutation";
} & {
  ignoreOneDataDiscoveryContact?: Maybe<
    {
      __typename?: "DataDiscoveryBusiness";
    } & DataDiscoveryBusinessFragmentFragment
  >;
};

export type ImpersonateCustomerMutationVariables = Exact<{
  customerId: Scalars["String"];
  turnOff?: Maybe<Scalars["Boolean"]>;
}>;

export type ImpersonateCustomerMutation = { __typename?: "Mutation" } & {
  impersonateCustomer?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type Integrate_ConstantContactMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type Integrate_ConstantContactMutation = { __typename?: "Mutation" } & {
  integrate_ConstantContact?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type Integrate_DisconnectIntegrationMutationVariables = Exact<{
  service: IntegrationServiceEnum;
}>;

export type Integrate_DisconnectIntegrationMutation = {
  __typename?: "Mutation";
} & {
  integrate_disconnectIntegration?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type InviteUserMutationVariables = Exact<{
  customerId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  permission: UserPermissionEnum;
}>;

export type InviteUserMutation = { __typename?: "Mutation" } & {
  inviteUser?: Maybe<{ __typename?: "UserProfile" } & Pick<UserProfile, "id">>;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "logout"
>;

export type MarkNotificationAsReadMutationVariables = Exact<{
  notifId?: Maybe<Scalars["ID"]>;
  markAllAsRead?: Maybe<Scalars["Boolean"]>;
}>;

export type MarkNotificationAsReadMutation = { __typename?: "Mutation" } & {
  markNotificationAsRead?: Maybe<
    { __typename?: "Notification" } & NotificationFragmentFragment
  >;
};

export type MergeDiscoveredBusinessesMutationVariables = Exact<{
  runId: Scalars["ID"];
}>;

export type MergeDiscoveredBusinessesMutation = { __typename?: "Mutation" } & {
  mergeDiscoveredBusinesses?: Maybe<
    { __typename?: "DataDiscoveryRun" } & DataDiscoveryRunFragmentFragment
  >;
};

export type ReActivateUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ReActivateUserMutation = { __typename?: "Mutation" } & {
  reActivateUser?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type RemoveEntityFromBusinessMutationVariables = Exact<{
  businessId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
}>;

export type RemoveEntityFromBusinessMutation = { __typename?: "Mutation" } & {
  removeEntityFromBusiness?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type RemoveEntityFromProjectMutationVariables = Exact<{
  projectId: Scalars["String"];
  entityId: Scalars["String"];
  entityType?: Maybe<EntityTypeEnum>;
}>;

export type RemoveEntityFromProjectMutation = { __typename?: "Mutation" } & {
  removeEntityFromProject?: Maybe<
    { __typename?: "Project" } & Pick<Project, "id">
  >;
};

export type RemoveManyItemsFromListMutationVariables = Exact<{
  listId: Scalars["ID"];
  addFromContactsQuery?: Maybe<ContactSearchParams>;
  addFromBusinessesQuery?: Maybe<BusinessSearchParams>;
  itemIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type RemoveManyItemsFromListMutation = { __typename?: "Mutation" } & {
  removeManyItemsFromList?: Maybe<
    { __typename?: "List" } & ListFragmentFragment
  >;
};

export type RemoveOneFromSurveyCampaignMutationVariables = Exact<{
  surveyCampaignItemId: Scalars["ID"];
}>;

export type RemoveOneFromSurveyCampaignMutation = {
  __typename?: "Mutation";
} & {
  removeOneFromSurveyCampaign?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type RemoveUsersFromUserGroupMutationVariables = Exact<{
  userIds: Array<Scalars["ID"]> | Scalars["ID"];
  userGroupId: Scalars["ID"];
}>;

export type RemoveUsersFromUserGroupMutation = { __typename?: "Mutation" } & {
  removeUsersFromUserGroup?: Maybe<
    { __typename?: "UserGroup" } & UserGroupFragmentFragment
  >;
};

export type RescheduleManyEmailCampaignItemsMutationVariables = Exact<{
  emailCampaignId: Scalars["ID"];
  scheduledAt: Scalars["Float"];
}>;

export type RescheduleManyEmailCampaignItemsMutation = {
  __typename?: "Mutation";
} & {
  rescheduleManyEmailCampaignItems?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type RescheduleOneEmailCampaignItemMutationVariables = Exact<{
  emailCampaignItemId: Scalars["ID"];
  scheduledAt: Scalars["Float"];
}>;

export type RescheduleOneEmailCampaignItemMutation = {
  __typename?: "Mutation";
} & {
  rescheduleOneEmailCampaignItem?: Maybe<
    { __typename?: "EmailCampaignItem" } & EmailCampaignItemFragmentFragment
  >;
};

export type ResendTeamInviteMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ResendTeamInviteMutation = { __typename?: "Mutation" } & {
  resendTeamInvite?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type SaveAttachmentMutationVariables = Exact<{
  params?: Maybe<AttachmentParams>;
}>;

export type SaveAttachmentMutation = { __typename?: "Mutation" } & {
  saveAttachment?: Maybe<
    { __typename?: "Attachment" } & Pick<Attachment, "id">
  >;
};

export type SaveBusinessMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<BusinessParams>;
}>;

export type SaveBusinessMutation = { __typename?: "Mutation" } & {
  saveBusiness?: Maybe<{ __typename?: "Business" } & BusinessFragmentFragment>;
};

export type SaveCaseMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseInput>;
}>;

export type SaveCaseMutation = { __typename?: "Mutation" } & {
  saveCase?: Maybe<{ __typename?: "Case" } & CaseFragmentFragment>;
};

export type SaveCaseCategoryMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseCategoryInput>;
}>;

export type SaveCaseCategoryMutation = { __typename?: "Mutation" } & {
  saveCaseCategory?: Maybe<
    { __typename?: "CaseCategory" } & Pick<
      CaseCategory,
      "id" | "title" | "description"
    >
  >;
};

export type SaveCaseServiceMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseServiceInput>;
}>;

export type SaveCaseServiceMutation = { __typename?: "Mutation" } & {
  saveCaseService?: Maybe<
    { __typename?: "CaseService" } & Pick<
      CaseService,
      "id" | "title" | "description"
    >
  >;
};

export type SaveCaseStatusMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CaseStatusInput>;
}>;

export type SaveCaseStatusMutation = { __typename?: "Mutation" } & {
  saveCaseStatus?: Maybe<
    { __typename?: "CaseStatus" } & Pick<
      CaseStatus,
      "id" | "title" | "description"
    >
  >;
};

export type SaveCommentMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<CommentParams>;
}>;

export type SaveCommentMutation = { __typename?: "Mutation" } & {
  saveComment?: Maybe<{ __typename?: "Comment" } & CommentFragmentFragment>;
};

export type SaveContactMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ContactInput>;
}>;

export type SaveContactMutation = { __typename?: "Mutation" } & {
  saveContact?: Maybe<{ __typename?: "Contact" } & ContactFragmentFragment>;
};

export type SaveCustomerMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: Maybe<CustomerInput>;
}>;

export type SaveCustomerMutation = { __typename?: "Mutation" } & {
  saveCustomer?: Maybe<
    { __typename?: "Customer" } & Pick<Customer, "id" | "title"> & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "UserProfile" } & Pick<
                UserProfile,
                "id" | "firstName" | "email" | "lastName"
              >
            >
          >
        >;
        features?: Maybe<
          Array<
            Maybe<
              { __typename?: "CustomerFeatureSetting" } & Pick<
                CustomerFeatureSetting,
                "title" | "key" | "active" | "available"
              >
            >
          >
        >;
      }
  >;
};

export type SaveEventMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<EventParams>;
}>;

export type SaveEventMutation = { __typename?: "Mutation" } & {
  saveEvent?: Maybe<{ __typename?: "Event" } & EventFragmentFragment>;
};

export type SaveFundingProgramMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ResourceParams>;
}>;

export type SaveFundingProgramMutation = { __typename?: "Mutation" } & {
  saveFundingProgram?: Maybe<
    { __typename?: "Resource" } & ResourceFragmentFragment
  >;
};

export type SaveInteractionMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<InteractionParams>;
}>;

export type SaveInteractionMutation = { __typename?: "Mutation" } & {
  saveInteraction?: Maybe<
    { __typename?: "Interaction" } & InteractionFragmentFragment
  >;
};

export type SaveDefaultSurveyTemplateMutationVariables = Exact<{
  params: SurveyTemplateNewInput;
}>;

export type SaveDefaultSurveyTemplateMutation = { __typename?: "Mutation" } & {
  saveDefaultSurveyTemplate: { __typename?: "DefaultSurveyTemplate" } & Pick<
    DefaultSurveyTemplate,
    "id" | "createdBy" | "template" | "title"
  >;
};

export type SaveNoteMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<NoteParams>;
}>;

export type SaveNoteMutation = { __typename?: "Mutation" } & {
  saveNote?: Maybe<{ __typename?: "Note" } & NoteFragmentFragment>;
};

export type SaveOneFormTemplateMutationVariables = Exact<{
  formTemplateId: Scalars["ID"];
  params: FormTemplateInput;
}>;

export type SaveOneFormTemplateMutation = { __typename?: "Mutation" } & {
  saveOneFormTemplate?: Maybe<
    { __typename?: "FormTemplate" } & FormTemplateFragmentFragment
  >;
};

export type SaveOrganizationMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<OrganizationParams>;
}>;

export type SaveOrganizationMutation = { __typename?: "Mutation" } & {
  saveOrganization?: Maybe<
    { __typename?: "Organization" } & OrganizationFragmentFragment
  >;
};

export type SavePipelineMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<PipelineParams>;
}>;

export type SavePipelineMutation = { __typename?: "Mutation" } & {
  savePipeline?: Maybe<
    { __typename?: "Pipeline" } & Pick<Pipeline, "id" | "title" | "description">
  >;
};

export type SaveProgramMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ResourceParams>;
}>;

export type SaveProgramMutation = { __typename?: "Mutation" } & {
  saveProgram?: Maybe<{ __typename?: "Resource" } & ResourceFragmentFragment>;
};

export type SaveProjectMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params: ProjectParams;
}>;

export type SaveProjectMutation = { __typename?: "Mutation" } & {
  saveProject?: Maybe<
    { __typename?: "Project" } & {
      businesses?: Maybe<
        Array<
          { __typename?: "Business" } & Pick<
            Business,
            "id" | "title" | "phone" | "website"
          > & {
              logo?: Maybe<
                { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
              >;
            }
        >
      >;
      contacts?: Maybe<Array<{ __typename?: "Contact" } & Pick<Contact, "id">>>;
    } & ProjectFragmentLongFragment
  >;
};

export type SaveProjectTypeMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<ProjectTypeParams>;
}>;

export type SaveProjectTypeMutation = { __typename?: "Mutation" } & {
  saveProjectType?: Maybe<
    { __typename?: "ProjectType" } & ProjectTypeFragmentFragment
  >;
};

export type SaveSiteMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<SiteInput>;
}>;

export type SaveSiteMutation = { __typename?: "Mutation" } & {
  saveSite?: Maybe<{ __typename?: "Site" } & SiteFragmentFragment>;
};

export type SaveSurveyResultMutationVariables = Exact<{
  params: SurveyResultInput;
}>;

export type SaveSurveyResultMutation = { __typename?: "Mutation" } & {
  saveSurveyResult: { __typename?: "MutationResponse" } & Pick<
    MutationResponse,
    "success" | "errors"
  >;
};

export type SaveSurveyTemplateNewMutationVariables = Exact<{
  params: SurveyTemplateNewInput;
}>;

export type SaveSurveyTemplateNewMutation = { __typename?: "Mutation" } & {
  saveSurveyTemplateNew: { __typename?: "SurveyTemplateNew" } & Pick<
    SurveyTemplateNew,
    "id" | "customerId" | "createdBy" | "template" | "title"
  >;
};

export type SaveTagMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TagParams>;
}>;

export type SaveTagMutation = { __typename?: "Mutation" } & {
  saveTag?: Maybe<{ __typename?: "Tag" } & TagFragmentFragment>;
};

export type SaveTodoMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TodoParams>;
}>;

export type SaveTodoMutation = { __typename?: "Mutation" } & {
  saveTodo?: Maybe<{ __typename?: "Todo" } & TodoFragmentFragment>;
};

export type SaveTodoListMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<TodoListParams>;
}>;

export type SaveTodoListMutation = { __typename?: "Mutation" } & {
  saveTodoList?: Maybe<
    { __typename: "TodoList" } & Pick<
      TodoList,
      "id" | "title" | "description" | "archived"
    >
  >;
};

export type SaveUserMutationVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  params?: Maybe<UserParams>;
}>;

export type SaveUserMutation = { __typename?: "Mutation" } & {
  saveUser?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendResetPasswordEmailMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "sendResetPasswordEmail"
>;

export type StartEmailCampaignMutationVariables = Exact<{
  emailCampaignId: Scalars["ID"];
}>;

export type StartEmailCampaignMutation = { __typename?: "Mutation" } & {
  startEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type StopOneScheduledEmailMutationVariables = Exact<{
  emailCampaignItemId: Scalars["ID"];
}>;

export type StopOneScheduledEmailMutation = { __typename?: "Mutation" } & {
  stopOneScheduledEmail?: Maybe<
    { __typename?: "EmailCampaignItem" } & EmailCampaignItemFragmentFragment
  >;
};

export type SubmitPublicBusinessSurveyResponseMutationVariables = Exact<{
  surveyTemplateId: Scalars["ID"];
  businessId?: Maybe<Scalars["ID"]>;
  answers?: Maybe<
    Array<Maybe<SurveyResponseAnswerInput>> | Maybe<SurveyResponseAnswerInput>
  >;
  referrerId?: Maybe<Scalars["ID"]>;
}>;

export type SubmitPublicBusinessSurveyResponseMutation = {
  __typename?: "Mutation";
} & {
  submitPublicBusinessSurveyResponse?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type SubmitSurveyResponseMutationVariables = Exact<{
  surveyTemplateId: Scalars["ID"];
  businessId?: Maybe<Scalars["ID"]>;
  answers?: Maybe<
    Array<Maybe<SurveyResponseAnswerInput>> | Maybe<SurveyResponseAnswerInput>
  >;
  attributionDate: Scalars["String"];
  createdById: Scalars["ID"];
}>;

export type SubmitSurveyResponseMutation = { __typename?: "Mutation" } & {
  submitSurveyResponse?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type TestSendEmailTemplateMutationVariables = Exact<{
  contactId?: Maybe<Scalars["ID"]>;
  businessId?: Maybe<Scalars["ID"]>;
  toEmailIntegrationId: Scalars["ID"];
  emailCampaignId: Scalars["ID"];
}>;

export type TestSendEmailTemplateMutation = { __typename?: "Mutation" } & {
  testSendEmailTemplate?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type TrackEventMutationVariables = Exact<{
  key: EventLogKeyEnum;
  content: Scalars["String"];
  meta?: Maybe<Scalars["String"]>;
}>;

export type TrackEventMutation = { __typename?: "Mutation" } & {
  trackEvent?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type UnsubscribeOneContactFromEmailCampaignMutationVariables = Exact<{
  email: Scalars["String"];
  customerId: Scalars["ID"];
}>;

export type UnsubscribeOneContactFromEmailCampaignMutation = {
  __typename?: "Mutation";
} & {
  unsubscribeOneContactFromEmailCampaign?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type UpdateOneCustomerSharingSettingMutationVariables = Exact<{
  key: SharingCustomerSettingKeyEnum;
  enabled: Scalars["Boolean"];
}>;

export type UpdateOneCustomerSharingSettingMutation = {
  __typename?: "Mutation";
} & {
  updateOneCustomerSharingSetting?: Maybe<
    { __typename?: "Customer" } & CustomerShortFragmentFragment
  >;
};

export type UpdateOneEmailCampaignMutationVariables = Exact<{
  id: Scalars["ID"];
  params: EmailCampaignInput;
}>;

export type UpdateOneEmailCampaignMutation = { __typename?: "Mutation" } & {
  updateOneEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type UpdateOneObjectsPermissionsMutationVariables = Exact<{
  type: ObjectTypeEnum;
  id: Scalars["ID"];
  permission: ObjectPermissionInput;
  action: UpdateArrayActionEnum;
}>;

export type UpdateOneObjectsPermissionsMutation = {
  __typename?: "Mutation";
} & {
  updateOneObjectsPermissions?: Maybe<
    Array<
      { __typename?: "ObjectPermission" } & ObjectPermissionFragmentFragment
    >
  >;
};

export type UpdateOneUserGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  params: UserGroupInput;
}>;

export type UpdateOneUserGroupMutation = { __typename?: "Mutation" } & {
  updateOneUserGroup?: Maybe<
    { __typename?: "UserGroup" } & UserGroupFragmentFragment
  >;
};

export type UpdatePipelineColMutationVariables = Exact<{
  params?: Maybe<PipelineStageInput>;
}>;

export type UpdatePipelineColMutation = { __typename?: "Mutation" } & {
  updatePipelineCol?: Maybe<
    { __typename?: "Pipeline" } & PipelineFragmentFragment
  >;
};

export type UploadBusinessesMutationVariables = Exact<{
  businesses?: Maybe<
    Array<Maybe<UploadBusinessInput>> | Maybe<UploadBusinessInput>
  >;
  customerId: Scalars["ID"];
}>;

export type UploadBusinessesMutation = { __typename?: "Mutation" } & {
  uploadBusinesses?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type UploadContactCsvContentMutationVariables = Exact<{
  customerId: Scalars["ID"];
  params: Array<Maybe<ContactInput>> | Maybe<ContactInput>;
}>;

export type UploadContactCsvContentMutation = { __typename?: "Mutation" } & {
  uploadContactCSVContent?: Maybe<
    { __typename?: "UpdatedCountResponse" } & Pick<
      UpdatedCountResponse,
      "success" | "error"
    > & {
        count?: Maybe<
          { __typename?: "UpdatedCounts" } & Pick<
            UpdatedCounts,
            | "enrichedBusinesses"
            | "enrichedContacts"
            | "createdContacts"
            | "createdBusinesses"
          >
        >;
      }
  >;
};

export type ActivityItemsQueryVariables = Exact<{
  parentId: Scalars["ID"];
  parentType?: Maybe<NoteParentTypeEnum>;
}>;

export type ActivityItemsQuery = { __typename?: "Query" } & {
  activityItems?: Maybe<
    Array<{ __typename?: "ActivityItem" } & ActivityItemFragmentFragment>
  >;
};

export type AttachmentsQueryVariables = Exact<{
  parentId: Scalars["ID"];
}>;

export type AttachmentsQuery = { __typename?: "Query" } & {
  attachments?: Maybe<
    Array<
      Maybe<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          | "id"
          | "filename"
          | "encoding"
          | "mimetype"
          | "url"
          | "key"
          | "createdAt"
          | "createdByName"
        >
      >
    >
  >;
};

export type BusinessByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BusinessByIdQuery = { __typename?: "Query" } & {
  businessById?: Maybe<
    { __typename?: "Business" } & BusinessByIdFragmentFragment
  >;
};

export type BusinessSectorsQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessSectorsQuery = { __typename?: "Query" } & {
  businessSectors?: Maybe<
    Array<
      { __typename?: "BusinessSector" } & Pick<BusinessSector, "id" | "title">
    >
  >;
};

export type BusinessesQueryVariables = Exact<{
  params?: Maybe<BusinessSearchParams>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  businessIds?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  includesAtleastBusinessIds?: Maybe<
    Array<Scalars["String"]> | Scalars["String"]
  >;
  sort?: Maybe<Array<SortByInput> | SortByInput>;
}>;

export type BusinessesQuery = { __typename?: "Query" } & {
  businesses?: Maybe<
    { __typename?: "BusinessesSearchResponse" } & Pick<
      BusinessesSearchResponse,
      "count"
    > & {
        businesses?: Maybe<
          Array<{ __typename?: "Business" } & BusinessShortFragmentFragment>
        >;
      }
  >;
};

export type CaseByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CaseByIdQuery = { __typename?: "Query" } & {
  caseById?: Maybe<{ __typename?: "Case" } & CaseFragmentFragment>;
};

export type CaseCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CaseCategoriesQuery = { __typename?: "Query" } & {
  caseCategories?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseCategory" } & Pick<
          CaseCategory,
          "id" | "title" | "description"
        >
      >
    >
  >;
};

export type CaseServicesQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  caseCategoryId?: Maybe<Scalars["ID"]>;
}>;

export type CaseServicesQuery = { __typename?: "Query" } & {
  caseServices?: Maybe<
    Array<
      { __typename?: "CaseService" } & Pick<
        CaseService,
        "id" | "title" | "description"
      >
    >
  >;
};

export type CaseStatusReportQueryVariables = Exact<{ [key: string]: never }>;

export type CaseStatusReportQuery = { __typename?: "Query" } & {
  caseStatusReport?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseStatusReportResult" } & Pick<
          CaseStatusReportResult,
          "id" | "total"
        > & {
            status?: Maybe<
              { __typename?: "CaseStatus" } & Pick<
                CaseStatus,
                "id" | "title" | "description" | "isFinalStep"
              >
            >;
          }
      >
    >
  >;
};

export type CaseStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type CaseStatusesQuery = { __typename?: "Query" } & {
  caseStatuses?: Maybe<
    Array<
      Maybe<
        { __typename?: "CaseStatus" } & Pick<
          CaseStatus,
          "id" | "title" | "description" | "isFinalStep"
        >
      >
    >
  >;
};

export type CasesQueryVariables = Exact<{
  businessId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  caseStatusIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  caseCategoryId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  searchText?: Maybe<Scalars["String"]>;
  caseIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  caseOutcomeIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type CasesQuery = { __typename?: "Query" } & {
  cases?: Maybe<
    { __typename?: "CasesResponse" } & Pick<CasesResponse, "count"> & {
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: "Case" } & Pick<
                Case,
                | "id"
                | "title"
                | "description"
                | "dueDate"
                | "resolvedOn"
                | "openTodos"
                | "closedTodos"
              > & {
                  business?: Maybe<
                    { __typename?: "Business" } & Pick<
                      Business,
                      "id" | "title"
                    > & {
                        logo?: Maybe<
                          { __typename?: "Attachment" } & Pick<
                            Attachment,
                            | "id"
                            | "filename"
                            | "mimetype"
                            | "encoding"
                            | "url"
                            | "key"
                          >
                        >;
                      }
                  >;
                  contact?: Maybe<
                    { __typename?: "Contact" } & Pick<
                      Contact,
                      "id" | "firstName" | "lastName" | "email"
                    > & {
                        avatar?: Maybe<
                          { __typename?: "Attachment" } & Pick<
                            Attachment,
                            | "id"
                            | "filename"
                            | "mimetype"
                            | "encoding"
                            | "url"
                            | "key"
                          >
                        >;
                      }
                  >;
                  assignedTo?: Maybe<
                    { __typename?: "UserProfile" } & Pick<
                      UserProfile,
                      "id" | "firstName" | "lastName" | "email"
                    > & {
                        avatar?: Maybe<
                          { __typename?: "Attachment" } & Pick<
                            Attachment,
                            | "id"
                            | "filename"
                            | "mimetype"
                            | "encoding"
                            | "url"
                            | "key"
                          >
                        >;
                      }
                  >;
                  caseStatus?: Maybe<
                    { __typename?: "CaseStatus" } & Pick<
                      CaseStatus,
                      "id" | "title"
                    >
                  >;
                  caseCategory?: Maybe<
                    { __typename?: "CaseCategory" } & Pick<
                      CaseCategory,
                      "id" | "title"
                    >
                  >;
                  services?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "CaseService" } & Pick<
                          CaseService,
                          "id" | "title"
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CheckResetQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type CheckResetQuery = { __typename?: "Query" } & {
  checkReset?: Maybe<
    { __typename?: "CheckResetResponse" } & Pick<CheckResetResponse, "valid">
  >;
};

export type CommentsQueryVariables = Exact<{
  todoId?: Maybe<Scalars["String"]>;
  caseId?: Maybe<Scalars["String"]>;
  noteId?: Maybe<Scalars["String"]>;
}>;

export type CommentsQuery = { __typename?: "Query" } & {
  comments?: Maybe<
    Array<Maybe<{ __typename?: "Comment" } & CommentFragmentFragment>>
  >;
};

export type ContactByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContactByIdQuery = { __typename?: "Query" } & {
  contactById?: Maybe<
    { __typename?: "Contact" } & {
      projects?: Maybe<
        Array<
          { __typename?: "Project" } & Pick<Project, "id" | "title"> & {
              image?: Maybe<
                { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
              >;
            }
        >
      >;
    } & ContactFragmentFragment
  >;
};

export type ContactsQueryVariables = Exact<{
  params?: Maybe<ContactSearchParams>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type ContactsQuery = { __typename?: "Query" } & {
  contacts?: Maybe<
    { __typename?: "ContactsSearchResponse" } & Pick<
      ContactsSearchResponse,
      "count"
    > & {
        contacts?: Maybe<
          Array<Maybe<{ __typename?: "Contact" } & ContactFragmentFragment>>
        >;
      }
  >;
};

export type CsvDownload_BusinessesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CsvDownload_BusinessesQuery = { __typename?: "Query" } & {
  csvDownload_businesses?: Maybe<
    Array<
      Maybe<
        { __typename: "Business" } & Pick<
          Business,
          | "id"
          | "title"
          | "description"
          | "phone"
          | "twitter"
          | "instagram"
          | "linkedin"
          | "facebook"
          | "website"
          | "industry"
          | "fax"
          | "serviceNeeds"
          | "industries"
          | "clusters"
          | "diversity"
          | "amenities"
          | "employeeCount"
          | "businessStage"
          | "businessType"
          | "status"
          | "opened"
          | "closed"
          | "annualRevenue"
          | "createdAt"
          | "lat"
          | "lng"
          | "placeId"
          | "fullAddress"
          | "street"
          | "postal"
          | "country"
          | "state"
          | "city"
        > & {
            tags?: Maybe<
              Array<{ __typename?: "Tag" } & Pick<Tag, "id" | "title">>
            >;
            naics?: Maybe<
              Array<
                { __typename?: "Naic" } & Pick<Naic, "id" | "title" | "code">
              >
            >;
            businessSector?: Maybe<
              { __typename?: "BusinessSector" } & Pick<
                BusinessSector,
                "id" | "title"
              >
            >;
            owner?: Maybe<
              { __typename?: "UserProfile" } & Pick<
                UserProfile,
                "id" | "firstName" | "lastName" | "email"
              >
            >;
          }
      >
    >
  >;
};

export type CsvDownload_CasesQueryVariables = Exact<{ [key: string]: never }>;

export type CsvDownload_CasesQuery = { __typename?: "Query" } & {
  csvDownload_cases?: Maybe<
    Array<Maybe<{ __typename?: "Case" } & CaseFragmentFragment>>
  >;
};

export type CsvDownload_ContactsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CsvDownload_ContactsQuery = { __typename?: "Query" } & {
  csvDownload_contacts?: Maybe<
    Array<
      Maybe<
        { __typename?: "Contact" } & Pick<
          Contact,
          | "id"
          | "email"
          | "department"
          | "twitter"
          | "instagram"
          | "linkedin"
          | "facebook"
          | "firstName"
          | "lastName"
          | "title"
          | "status"
          | "website"
          | "workPhone"
          | "cellPhone"
          | "homePhone"
          | "lat"
          | "lng"
          | "placeId"
          | "fullAddress"
          | "street"
          | "postal"
          | "country"
          | "state"
          | "city"
        > & {
            tags?: Maybe<
              Array<
                { __typename?: "Tag" } & Pick<
                  Tag,
                  "id" | "title" | "description"
                >
              >
            >;
          }
      >
    >
  >;
};

export type CsvDownload_SurveysQueryVariables = Exact<{ [key: string]: never }>;

export type CsvDownload_SurveysQuery = { __typename?: "Query" } & {
  csvDownload_surveys?: Maybe<
    Array<
      Maybe<
        { __typename?: "SurveyResponse" } & Pick<
          SurveyResponse,
          "id" | "surveyTemplateId" | "attributionDate"
        > & {
            answers?: Maybe<
              Array<
                Maybe<
                  { __typename?: "SurveyResponseAnswer" } & Pick<
                    SurveyResponseAnswer,
                    "surveyQuestionId" | "label" | "answer"
                  >
                >
              >
            >;
            business?: Maybe<
              { __typename?: "Business" } & Pick<Business, "id" | "title">
            >;
          }
      >
    >
  >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: "Query" } & {
  currentUser?: Maybe<
    { __typename?: "UserProfile" } & CurrentUserFragmentFragment
  >;
};

export type CustomerByIdShortQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CustomerByIdShortQuery = { __typename?: "Query" } & {
  customerById?: Maybe<
    { __typename?: "Customer" } & CustomerShortFragmentFragment
  >;
};

export type CustomerByIdLongQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CustomerByIdLongQuery = { __typename?: "Query" } & {
  customerById?: Maybe<
    { __typename?: "Customer" } & CustomerLongFragmentFragment
  >;
};

export type CustomersQueryVariables = Exact<{ [key: string]: never }>;

export type CustomersQuery = { __typename?: "Query" } & {
  customers?: Maybe<
    { __typename?: "CustomerResponse" } & Pick<CustomerResponse, "count"> & {
        customers?: Maybe<
          Array<
            Maybe<
              { __typename?: "Customer" } & Pick<
                Customer,
                | "id"
                | "title"
                | "businessCount"
                | "contactsCount"
                | "integrationCount"
              >
            >
          >
        >;
      }
  >;
};

export type EventByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EventByIdQuery = { __typename?: "Query" } & {
  eventById?: Maybe<{ __typename?: "Event" } & EventFragmentFragment>;
};

export type EventLogsQueryVariables = Exact<{
  keys?: Maybe<Array<EventLogKeyEnum> | EventLogKeyEnum>;
  userIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type EventLogsQuery = { __typename?: "Query" } & {
  eventLogs?: Maybe<
    { __typename?: "EventLogsResult" } & Pick<EventLogsResult, "count"> & {
        result?: Maybe<
          Array<
            { __typename?: "EventLog" } & Pick<
              EventLog,
              "id" | "content" | "key" | "createdAt"
            > & {
                user?: Maybe<
                  { __typename?: "UserProfile" } & Pick<
                    UserProfile,
                    "id" | "firstName" | "lastName" | "email"
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type EventsQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
}>;

export type EventsQuery = { __typename?: "Query" } & {
  events?: Maybe<
    { __typename?: "EventsSearchResponse" } & Pick<
      EventsSearchResponse,
      "count"
    > & {
        events?: Maybe<Array<{ __typename?: "Event" } & EventFragmentFragment>>;
      }
  >;
};

export type FormSubmissionReportQueryVariables = Exact<{
  formTemplateId: Scalars["ID"];
}>;

export type FormSubmissionReportQuery = { __typename?: "Query" } & {
  formSubmissionReport?: Maybe<
    Array<
      { __typename?: "FormSubmissionReportItem" } & Pick<
        FormSubmissionReportItem,
        "id" | "formTemplateId" | "respondentIds" | "totalFormRespondents"
      > & {
          question: {
            __typename?: "FormQuestion";
          } & FormQuestionFragmentFragment;
          responses?: Maybe<
            Array<
              { __typename?: "FormSubmissionReportResponse" } & Pick<
                FormSubmissionReportResponse,
                "answers" | "value" | "label" | "respondentIds"
              >
            >
          >;
        }
    >
  >;
};

export type FundingProgramsQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  supportTypes?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  businessStages?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  diversityTypes?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
}>;

export type FundingProgramsQuery = { __typename?: "Query" } & {
  fundingPrograms?: Maybe<
    { __typename?: "FundingProgramSearchResponse" } & Pick<
      FundingProgramSearchResponse,
      "count"
    > & {
        fundingPrograms?: Maybe<
          Array<{ __typename?: "Resource" } & ResourceFragmentFragment>
        >;
      }
  >;
};

export type GetCompanyByDomainQueryVariables = Exact<{
  domain: Scalars["String"];
}>;

export type GetCompanyByDomainQuery = { __typename?: "Query" } & {
  getCompanyByDomain?: Maybe<
    { __typename?: "CompanyAPIResult" } & Pick<
      CompanyApiResult,
      | "businessId"
      | "country"
      | "street"
      | "employees"
      | "revenue"
      | "state"
      | "postal"
      | "description"
      | "facebook"
      | "city"
      | "industry"
      | "title"
      | "website"
      | "twitter"
      | "phone"
      | "founded"
      | "logo"
      | "exists"
    >
  >;
};

export type GetEstimatedRecipientsForEmailCampaignQueryVariables = Exact<{
  recipientReferences?: Maybe<
    | Array<EmailCampaignRecipientReferenceInput>
    | EmailCampaignRecipientReferenceInput
  >;
  days?: Maybe<Scalars["Int"]>;
}>;

export type GetEstimatedRecipientsForEmailCampaignQuery = {
  __typename?: "Query";
} & {
  getEstimatedRecipientsForEmailCampaign?: Maybe<
    { __typename?: "GetEstimatedRecipientsForEmailCampaignResponse" } & Pick<
      GetEstimatedRecipientsForEmailCampaignResponse,
      "count" | "emailsSent"
    >
  >;
};

export type GetFormQuestionsOtherAnswersQueryVariables = Exact<{
  formTemplateId: Scalars["ID"];
  questionId: Scalars["ID"];
  otherOptionValue: Scalars["ID"];
}>;

export type GetFormQuestionsOtherAnswersQuery = { __typename?: "Query" } & {
  getFormQuestionsOtherAnswers?: Maybe<
    Array<
      { __typename?: "FormQuestionOtherAnswer" } & Pick<
        FormQuestionOtherAnswer,
        "id" | "textValue"
      >
    >
  >;
};

export type GetManyAnswersQueryVariables = Exact<{
  templateId?: Maybe<Scalars["String"]>;
  businessId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  answerId?: Maybe<Scalars["String"]>;
  surveyCampaignId?: Maybe<Scalars["ID"]>;
}>;

export type GetManyAnswersQuery = { __typename?: "Query" } & {
  getManyAnswers: Array<
    { __typename?: "SurveyTemplateAnswers" } & Pick<
      SurveyTemplateAnswers,
      | "id"
      | "customerId"
      | "surveyTemplateId"
      | "template"
      | "answeredBy"
      | "stringifiedAnswers"
      | "createdAt"
    >
  >;
};

export type GetManyAutomationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyAutomationsQuery = { __typename?: "Query" } & {
  getManyAutomations?: Maybe<
    Array<Maybe<{ __typename?: "Automation" } & AutomationFragmentFragment>>
  >;
};

export type GetManyCaseOutcomesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyCaseOutcomesQuery = { __typename?: "Query" } & {
  getManyCaseOutcomes?: Maybe<
    Array<Maybe<{ __typename?: "CaseOutcome" } & CaseOutcomeFragmentFragment>>
  >;
};

export type GetManyCaseTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyCaseTemplatesQuery = { __typename?: "Query" } & {
  getManyCaseTemplates?: Maybe<
    Array<Maybe<{ __typename?: "CaseTemplate" } & CaseTemplateFragmentFragment>>
  >;
};

export type GetManyConstantContactListsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyConstantContactListsQuery = { __typename?: "Query" } & {
  getManyConstantContactLists?: Maybe<
    Array<
      { __typename?: "ConstantContactList" } & Pick<
        ConstantContactList,
        "list_id" | "name" | "favorite" | "created_at" | "updated_at"
      >
    >
  >;
};

export type GetManyCustomerFeaturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyCustomerFeaturesQuery = { __typename?: "Query" } & Pick<
  Query,
  "getManyCustomerFeatures"
>;

export type GetManyDataDiscoveryRunsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyDataDiscoveryRunsQuery = { __typename?: "Query" } & {
  getManyDataDiscoveryRuns?: Maybe<
    { __typename?: "GetManyDataDiscoveryRunsResponse" } & Pick<
      GetManyDataDiscoveryRunsResponse,
      "count"
    > & {
        results?: Maybe<
          Array<
            {
              __typename?: "DataDiscoveryRun";
            } & DataDiscoveryRunFragmentFragment
          >
        >;
      }
  >;
};

export type GetManyDefaultFormTemplateQuestionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyDefaultFormTemplateQuestionsQuery = {
  __typename?: "Query";
} & {
  getManyDefaultFormTemplateQuestions?: Maybe<
    Array<
      { __typename?: "DefaultFormTemplateQuestionCategory" } & Pick<
        DefaultFormTemplateQuestionCategory,
        "id"
      > & {
          questions?: Maybe<
            Array<
              { __typename?: "FormQuestion" } & FormQuestionFragmentFragment
            >
          >;
        }
    >
  >;
};

export type GetManyDefaultSurveyTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyDefaultSurveyTemplatesQuery = { __typename?: "Query" } & {
  getManyDefaultSurveyTemplates: Array<
    { __typename?: "DefaultSurveyTemplate" } & Pick<
      DefaultSurveyTemplate,
      "id" | "createdBy" | "template" | "title"
    >
  >;
};

export type GetManyEmailCampaignsQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;

export type GetManyEmailCampaignsQuery = { __typename?: "Query" } & {
  getManyEmailCampaigns?: Maybe<
    { __typename?: "getManyEmailCampaignsResponse" } & Pick<
      GetManyEmailCampaignsResponse,
      "count"
    > & {
        results?: Maybe<
          Array<
            { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
          >
        >;
      }
  >;
};

export type GetManyEmailThreadsQueryVariables = Exact<{
  itemId: Scalars["ID"];
  pagination?: Maybe<PaginationInput>;
}>;

export type GetManyEmailThreadsQuery = { __typename?: "Query" } & {
  getManyEmailThreads?: Maybe<
    Array<
      { __typename?: "EmailThread" } & Pick<EmailThread, "id" | "subject"> & {
          interactions?: Maybe<
            Array<{ __typename?: "Interaction" } & InteractionFragmentFragment>
          >;
        }
    >
  >;
};

export type GetManyFormTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyFormTemplatesQuery = { __typename?: "Query" } & {
  getManyFormTemplates?: Maybe<
    Array<{ __typename?: "FormTemplate" } & FormTemplateFragmentFragment>
  >;
};

export type GetManyIntegrationsQueryVariables = Exact<{
  includeInvalidGrants?: Maybe<Scalars["Boolean"]>;
}>;

export type GetManyIntegrationsQuery = { __typename?: "Query" } & {
  getManyIntegrations?: Maybe<
    Array<
      Maybe<
        { __typename?: "EmailIntegration" } & EmailIntegrationFragmentFragment
      >
    >
  >;
};

export type GetManyInteractionCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyInteractionCategoriesQuery = { __typename?: "Query" } & {
  getManyInteractionCategories?: Maybe<
    Array<
      Maybe<
        { __typename?: "InteractionCategory" } & Pick<
          InteractionCategory,
          "id" | "title"
        >
      >
    >
  >;
};

export type GetManyListsQueryVariables = Exact<{
  listType: ListTypeEnum;
  ownerType: GetManyListsOwnerTypeEnum;
  pagination?: Maybe<PaginationInput>;
}>;

export type GetManyListsQuery = { __typename?: "Query" } & {
  getManyLists?: Maybe<
    { __typename?: "GetManyListsResponse" } & Pick<
      GetManyListsResponse,
      "count"
    > & {
        results?: Maybe<Array<{ __typename?: "List" } & ListFragmentFragment>>;
      }
  >;
};

export type GetManyNaicsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyNaicsQuery = { __typename?: "Query" } & {
  getManyNaics: Array<
    Maybe<{ __typename?: "Naic" } & Pick<Naic, "id" | "title" | "code">>
  >;
};

export type GetManyObjectPermissionsQueryVariables = Exact<{
  type: ObjectTypeEnum;
  id: Scalars["ID"];
}>;

export type GetManyObjectPermissionsQuery = { __typename?: "Query" } & {
  getManyObjectPermissions?: Maybe<
    Array<
      { __typename?: "ObjectPermission" } & ObjectPermissionFragmentFragment
    >
  >;
};

export type SurveyCampaignItemFragmentFragment = {
  __typename?: "SurveyCampaignItem";
} & Pick<
  SurveyCampaignItem,
  "id" | "status" | "surveyResultId" | "surveyResultAt"
> & {
    contact?: Maybe<
      { __typename?: "Contact" } & Pick<
        Contact,
        "id" | "firstName" | "lastName" | "email"
      > & {
          businesses?: Maybe<
            Array<{ __typename?: "Business" } & Pick<Business, "id" | "title">>
          >;
        }
    >;
  };

export type GetManySurveyCampaignItemsQueryVariables = Exact<{
  surveyCampaignId: Scalars["ID"];
  pagination: PaginationInput;
}>;

export type GetManySurveyCampaignItemsQuery = { __typename?: "Query" } & {
  getManySurveyCampaignItems?: Maybe<
    { __typename?: "GetManySurveyCampaignItemsResponse" } & Pick<
      GetManySurveyCampaignItemsResponse,
      "count"
    > & {
        campaign?: Maybe<
          { __typename?: "SurveyCampaign" } & SurveyCampaignFragmentFragment
        >;
        results?: Maybe<
          Array<
            {
              __typename?: "SurveyCampaignItem";
            } & SurveyCampaignItemFragmentFragment
          >
        >;
      }
  >;
};

export type GetManySurveyCampaignsQueryVariables = Exact<{
  listType: ListTypeEnum;
}>;

export type GetManySurveyCampaignsQuery = { __typename?: "Query" } & {
  getManySurveyCampaigns?: Maybe<
    Array<{ __typename?: "SurveyCampaign" } & SurveyCampaignFragmentFragment>
  >;
};

export type GetManySurveyTemplatesNewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManySurveyTemplatesNewQuery = { __typename?: "Query" } & {
  getManySurveyTemplatesNew: Array<
    { __typename?: "SurveyTemplateNew" } & SurveyTemplateNewFragmentFragment
  >;
};

export type GetManyUserGroupsQueryVariables = Exact<{
  not?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  includeCustomerDefault?: Maybe<Scalars["Boolean"]>;
}>;

export type GetManyUserGroupsQuery = { __typename?: "Query" } & {
  getManyUserGroups?: Maybe<
    Array<{ __typename?: "UserGroup" } & UserGroupFragmentFragment>
  >;
};

export type GetOneDataDiscoveryRunQueryVariables = Exact<{
  runId: Scalars["ID"];
  ignored?: Maybe<Scalars["Boolean"]>;
  newBusinesses?: Maybe<Scalars["Boolean"]>;
  hasContacts?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationInput>;
}>;

export type GetOneDataDiscoveryRunQuery = { __typename?: "Query" } & {
  getOneDataDiscoveryRun?: Maybe<
    { __typename?: "DataDiscoveryRun" } & Pick<
      DataDiscoveryRun,
      | "id"
      | "createdAt"
      | "status"
      | "mergedAt"
      | "mergedBusinesses"
      | "mergedContacts"
      | "contactsFound"
      | "searchTerms"
      | "businessesFound"
      | "newBusinessesFound"
    > & {
        businesses?: Maybe<
          { __typename?: "DataDiscoveryRunBusinesses" } & Pick<
            DataDiscoveryRunBusinesses,
            "count"
          > & {
              results?: Maybe<
                Array<
                  {
                    __typename?: "DataDiscoveryBusiness";
                  } & DataDiscoveryBusinessFragmentFragment
                >
              >;
            }
        >;
      }
  >;
};

export type GetOneDefaultSurveyTemplateQueryVariables = Exact<{
  defaultTemplateId: Scalars["String"];
}>;

export type GetOneDefaultSurveyTemplateQuery = { __typename?: "Query" } & {
  getOneDefaultSurveyTemplate: { __typename?: "DefaultSurveyTemplate" } & Pick<
    DefaultSurveyTemplate,
    "id" | "createdBy" | "template" | "title"
  >;
};

export type GetOneEmailCampaignQueryVariables = Exact<{
  emailCampaignId: Scalars["ID"];
}>;

export type GetOneEmailCampaignQuery = { __typename?: "Query" } & {
  getOneEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & EmailCampaignFragmentFragment
  >;
};

export type GetOneEmailCampaignWithItemsQueryVariables = Exact<{
  emailCampaignId: Scalars["ID"];
  campaignItemsPagination?: Maybe<PaginationInput>;
}>;

export type GetOneEmailCampaignWithItemsQuery = { __typename?: "Query" } & {
  getOneEmailCampaign?: Maybe<
    { __typename?: "EmailCampaign" } & {
      emailCampaignItems?: Maybe<
        { __typename?: "EmailCampaignItemsResponse" } & Pick<
          EmailCampaignItemsResponse,
          "count"
        > & {
            results?: Maybe<
              Array<
                {
                  __typename?: "EmailCampaignItem";
                } & EmailCampaignItemFragmentFragment
              >
            >;
          }
      >;
    } & EmailCampaignFragmentFragment
  >;
};

export type GetOneFormTemplateQueryVariables = Exact<{
  formTemplateId: Scalars["ID"];
}>;

export type GetOneFormTemplateQuery = { __typename?: "Query" } & {
  getOneFormTemplate?: Maybe<
    { __typename?: "FormTemplate" } & FormTemplateFragmentFragment
  >;
};

export type GetOneInteractionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneInteractionQuery = { __typename?: "Query" } & {
  getOneInteraction?: Maybe<
    { __typename?: "Interaction" } & InteractionFragmentFragment
  >;
};

export type GetOneListWithListItemsQueryVariables = Exact<{
  id: Scalars["ID"];
  listItemPagination?: Maybe<PaginationInput>;
}>;

export type GetOneListWithListItemsQuery = { __typename?: "Query" } & {
  getOneList?: Maybe<
    { __typename?: "List" } & Pick<
      List,
      "id" | "name" | "listType" | "numberOfRecords" | "updatedAt"
    > & {
        createdBy?: Maybe<
          { __typename?: "UserProfile" } & Pick<
            UserProfile,
            "firstName" | "lastName"
          >
        >;
        listItems?: Maybe<
          { __typename?: "ListItemsResponse" } & Pick<
            ListItemsResponse,
            "count"
          > & {
              results?: Maybe<
                Array<{ __typename?: "ListItem" } & ListItemFragmentFragment>
              >;
            }
        >;
      }
  >;
};

export type GetOneNoteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneNoteQuery = { __typename?: "Query" } & {
  getOneNote?: Maybe<{ __typename?: "Note" } & NoteFragmentFragment>;
};

export type GetOneSurveyResultQueryVariables = Exact<{
  answerId: Scalars["String"];
}>;

export type GetOneSurveyResultQuery = { __typename?: "Query" } & {
  getManyAnswers: Array<
    { __typename?: "SurveyTemplateAnswers" } & Pick<
      SurveyTemplateAnswers,
      | "id"
      | "customerId"
      | "surveyTemplateId"
      | "template"
      | "answeredBy"
      | "stringifiedAnswers"
      | "createdAt"
    > & {
        business?: Maybe<
          { __typename?: "Business" } & Pick<Business, "id" | "title">
        >;
        contact?: Maybe<
          { __typename?: "Contact" } & Pick<
            Contact,
            "id" | "email" | "firstName" | "lastName"
          >
        >;
      }
  >;
};

export type GetOneSurveyTemplateNewQueryVariables = Exact<{
  templateId: Scalars["ID"];
}>;

export type GetOneSurveyTemplateNewQuery = { __typename?: "Query" } & {
  getOneSurveyTemplateNew: {
    __typename?: "SurveyTemplateNew";
  } & SurveyTemplateNewFragmentFragment;
};

export type GetProjectsByIdsQueryVariables = Exact<{
  projectIds?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
}>;

export type GetProjectsByIdsQuery = { __typename?: "Query" } & {
  getProjectsByIds?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & Pick<Project, "pipelineName"> & {
            pipelineStage?: Maybe<
              { __typename?: "PipelineStage" } & Pick<
                PipelineStage,
                "id" | "label" | "order"
              >
            >;
            businesses?: Maybe<
              Array<
                { __typename?: "Business" } & Pick<
                  Business,
                  "id" | "title" | "phone" | "website"
                > & {
                    logo?: Maybe<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "id" | "url"
                      >
                    >;
                  }
              >
            >;
          } & ProjectFragmentFragment
      >
    >
  >;
};

export type GetSurveyQuestionOtherAnswersQueryVariables = Exact<{
  surveyTemplateId?: Maybe<Scalars["ID"]>;
  surveyQuestionId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
}>;

export type GetSurveyQuestionOtherAnswersQuery = { __typename?: "Query" } & {
  getSurveyQuestionOtherAnswers?: Maybe<
    { __typename?: "SurveyQuestionOtherAnswerResponse" } & Pick<
      SurveyQuestionOtherAnswerResponse,
      "count"
    > & {
        results?: Maybe<
          Array<
            Maybe<
              { __typename?: "SurveyResponse" } & Pick<SurveyResponse, "id"> & {
                  answers?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "SurveyResponseAnswer" } & Pick<
                          SurveyResponseAnswer,
                          "surveyQuestionId" | "other"
                        >
                      >
                    >
                  >;
                  business?: Maybe<
                    { __typename?: "Business" } & Pick<Business, "id" | "title">
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GroupFormSubmissionsByRecipientQueryVariables = Exact<{
  recipientId: Scalars["ID"];
}>;

export type GroupFormSubmissionsByRecipientQuery = { __typename?: "Query" } & {
  groupFormSubmissionsByRecipient?: Maybe<
    Array<{ __typename?: "FormSubmission" } & FormSubmissionFragmentFragment>
  >;
};

export type GroupedSurveysByBusinessIdQueryVariables = Exact<{
  businessId: Scalars["ID"];
}>;

export type GroupedSurveysByBusinessIdQuery = { __typename?: "Query" } & {
  groupedSurveysByBusinessId?: Maybe<
    Array<
      Maybe<
        { __typename?: "GroupedSurveyByBusinessResult" } & Pick<
          GroupedSurveyByBusinessResult,
          "id" | "surveyTemplateName"
        > & {
            responses?: Maybe<
              Array<
                Maybe<
                  { __typename?: "SurveyResponse" } & Pick<
                    SurveyResponse,
                    "id" | "surveyTemplateId" | "businessId" | "attributionDate"
                  > & {
                      answers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "SurveyResponseAnswer" } & Pick<
                              SurveyResponseAnswer,
                              "surveyQuestionId" | "label" | "answer"
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type InteractionsQueryVariables = Exact<{
  projectId?: Maybe<Scalars["String"]>;
  businessId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  direction?: Maybe<InteractionDirectionEnum>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type InteractionsQuery = { __typename?: "Query" } & {
  interactions?: Maybe<
    { __typename?: "InteractionsResponse" } & Pick<
      InteractionsResponse,
      "count"
    > & {
        results?: Maybe<
          Array<
            { __typename?: "Interaction" } & InteractionLongFragmentFragment
          >
        >;
      }
  >;
};

export type MyNotificationsQueryVariables = Exact<{
  status?: Maybe<MyNotificationsStatusEnum>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
}>;

export type MyNotificationsQuery = { __typename?: "Query" } & {
  myNotifications?: Maybe<
    { __typename?: "MyNotificationsResult" } & Pick<
      MyNotificationsResult,
      "unreadCount" | "count"
    > & {
        result?: Maybe<
          Array<{ __typename?: "Notification" } & NotificationFragmentFragment>
        >;
      }
  >;
};

export type NaicsSearchQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
}>;

export type NaicsSearchQuery = { __typename?: "Query" } & {
  naicsSearch?: Maybe<
    Array<Maybe<{ __typename?: "Naic" } & Pick<Naic, "id" | "title" | "code">>>
  >;
};

export type NotesQueryVariables = Exact<{
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
}>;

export type NotesQuery = { __typename?: "Query" } & {
  notes?: Maybe<
    { __typename?: "NotesResponse" } & Pick<NotesResponse, "count"> & {
        notes?: Maybe<Array<{ __typename?: "Note" } & NoteFragmentFragment>>;
      }
  >;
};

export type OrganizationAcitvitiesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
}>;

export type OrganizationAcitvitiesQuery = { __typename?: "Query" } & {
  organizationAcitvities?: Maybe<
    { __typename?: "OrganizationActivitiesResult" } & Pick<
      OrganizationActivitiesResult,
      "count"
    > & {
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: "ActivityItem" } & {
                interaction?: Maybe<
                  { __typename?: "Interaction" } & InteractionFragmentFragment
                >;
              } & ActivityItemFragmentFragment
            >
          >
        >;
      }
  >;
};

export type OrganizationByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrganizationByIdQuery = { __typename?: "Query" } & {
  organizationById?: Maybe<
    { __typename?: "Organization" } & {
      programs?: Maybe<
        Array<Maybe<{ __typename?: "Resource" } & ResourceFragmentFragment>>
      >;
      events?: Maybe<
        Array<Maybe<{ __typename?: "Event" } & EventFragmentFragment>>
      >;
      fundingPrograms?: Maybe<
        Array<Maybe<{ __typename?: "Resource" } & ResourceFragmentFragment>>
      >;
    } & OrganizationFragmentFragment
  >;
};

export type OrganizationsQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  includesAtleastOrganizationIds?: Maybe<
    Array<Scalars["String"]> | Scalars["String"]
  >;
  organiationIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type OrganizationsQuery = { __typename?: "Query" } & {
  organizations?: Maybe<
    { __typename?: "OrganizationSearchResponse" } & Pick<
      OrganizationSearchResponse,
      "count"
    > & {
        organizations?: Maybe<
          Array<
            Maybe<
              { __typename?: "Organization" } & OrganizationFragmentFragment
            >
          >
        >;
      }
  >;
};

export type PipelinesByCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type PipelinesByCustomerQuery = { __typename?: "Query" } & {
  pipelinesByCustomer?: Maybe<
    Array<{ __typename?: "Pipeline" } & PipelineFragmentFragment>
  >;
};

export type ProgramsQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  supportTypes?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  businessStages?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  diversityTypes?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
}>;

export type ProgramsQuery = { __typename?: "Query" } & {
  programs?: Maybe<
    { __typename?: "ProgramSearchResponse" } & Pick<
      ProgramSearchResponse,
      "count"
    > & {
        programs?: Maybe<
          Array<{ __typename?: "Resource" } & ResourceFragmentFragment>
        >;
      }
  >;
};

export type ProjectAttachmentsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectAttachmentsQuery = { __typename?: "Query" } & {
  projectAttachments?: Maybe<
    { __typename?: "Project" } & Pick<Project, "id"> & {
        attachments?: Maybe<
          Array<
            { __typename?: "Attachment" } & Pick<
              Attachment,
              | "id"
              | "filename"
              | "encoding"
              | "mimetype"
              | "url"
              | "key"
              | "createdAt"
              | "createdByName"
            >
          >
        >;
      }
  >;
};

export type ProjectByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectByIdQuery = { __typename?: "Query" } & {
  projectById?: Maybe<{ __typename?: "Project" } & ProjectFragmentLongFragment>;
};

export type ProjectStagesByPiplineQueryVariables = Exact<{
  pipelineId: Scalars["ID"];
}>;

export type ProjectStagesByPiplineQuery = { __typename?: "Query" } & {
  pipelineById?: Maybe<
    { __typename?: "Pipeline" } & Pick<Pipeline, "id"> & {
        stages?: Maybe<
          Array<
            { __typename?: "PipelineStage" } & Pick<
              PipelineStage,
              "id" | "label" | "order"
            >
          >
        >;
      }
  >;
};

export type ProjectTypeByPipelineQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectTypeByPipelineQuery = { __typename?: "Query" } & {
  pipelineById?: Maybe<
    { __typename?: "Pipeline" } & Pick<Pipeline, "id"> & {
        projectType?: Maybe<
          { __typename?: "ProjectType" } & ProjectTypeFragmentFragment
        >;
      }
  >;
};

export type ProjectTypesByCustomerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProjectTypesByCustomerQuery = { __typename?: "Query" } & {
  projectTypesByCustomer?: Maybe<
    Array<Maybe<{ __typename?: "ProjectType" } & ProjectTypeFragmentFragment>>
  >;
};

export type ProjectsQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  projectIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
}>;

export type ProjectsQuery = { __typename?: "Query" } & {
  projects?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & {
          businesses?: Maybe<
            Array<
              { __typename?: "Business" } & Pick<
                Business,
                "id" | "title" | "phone" | "website"
              > & {
                  logo?: Maybe<
                    { __typename?: "Attachment" } & Pick<
                      Attachment,
                      "id" | "url"
                    >
                  >;
                }
            >
          >;
        } & ProjectFragmentFragment
      >
    >
  >;
};

export type ProjectsByCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectsByCustomerQuery = { __typename?: "Query" } & {
  projectsByCustomer?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & Pick<Project, "pipelineName"> & {
            pipelineStage?: Maybe<
              { __typename?: "PipelineStage" } & Pick<
                PipelineStage,
                "id" | "label" | "order"
              >
            >;
          } & ProjectFragmentFragment
      >
    >
  >;
};

export type ProjectsByStageQueryVariables = Exact<{
  pipelineId: Scalars["ID"];
  stageId: Scalars["String"];
}>;

export type ProjectsByStageQuery = { __typename?: "Query" } & {
  projectsByStage?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & {
          businesses?: Maybe<
            Array<
              { __typename?: "Business" } & Pick<
                Business,
                "id" | "title" | "phone" | "website"
              > & {
                  logo?: Maybe<
                    { __typename?: "Attachment" } & Pick<
                      Attachment,
                      "id" | "url"
                    >
                  >;
                }
            >
          >;
        } & ProjectFragmentFragment
      >
    >
  >;
};

export type ReportsTestQueryVariables = Exact<{
  surveyTemplateId?: Maybe<Scalars["ID"]>;
}>;

export type ReportsTestQuery = { __typename?: "Query" } & {
  reportsTest?: Maybe<
    Array<
      Maybe<
        { __typename?: "ReportsTestResponse" } & Pick<
          ReportsTestResponse,
          | "id"
          | "businessIds"
          | "totalRespondents"
          | "surveyTemplateId"
          | "questionResponses"
        > & {
            question?: Maybe<
              { __typename?: "SurveyQuestion" } & {
                childQuestions?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "SurveyQuestion" } & Pick<
                        SurveyQuestion,
                        | "id"
                        | "label"
                        | "description"
                        | "customerId"
                        | "isDefault"
                        | "category"
                        | "key"
                        | "showIfKey"
                        | "showIfAnswer"
                        | "questionType"
                      > & {
                          options?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "SurveyQuestionOption" } & Pick<
                                  SurveyQuestionOption,
                                  "label" | "value"
                                >
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              } & SurveyQuestionFragmentFragment
            >;
            responses?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ReportsTestResponsesArray" } & Pick<
                    ReportsTestResponsesArray,
                    "answers" | "label"
                  >
                >
              >
            >;
            matrixResults?: Maybe<
              Array<
                Maybe<
                  { __typename?: "ReportsTestResponse" } & Pick<
                    ReportsTestResponse,
                    | "id"
                    | "businessIds"
                    | "totalRespondents"
                    | "surveyTemplateId"
                    | "questionResponses"
                  > & {
                      question?: Maybe<
                        { __typename?: "SurveyQuestion" } & Pick<
                          SurveyQuestion,
                          | "id"
                          | "label"
                          | "description"
                          | "customerId"
                          | "isDefault"
                          | "category"
                          | "key"
                          | "showIfKey"
                          | "showIfAnswer"
                          | "questionType"
                        > & {
                            options?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: "SurveyQuestionOption";
                                  } & Pick<
                                    SurveyQuestionOption,
                                    "label" | "value"
                                  >
                                >
                              >
                            >;
                          }
                      >;
                      responses?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "ReportsTestResponsesArray" } & Pick<
                              ReportsTestResponsesArray,
                              "answers" | "label"
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type Reports_BusinessAssistedByServiceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_BusinessAssistedByServiceQuery = {
  __typename?: "Query";
} & {
  reports_BusinessAssistedByService?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type Reports_BusinessesByClusterQueryVariables = Exact<{
  top?: Maybe<Scalars["Int"]>;
}>;

export type Reports_BusinessesByClusterQuery = { __typename?: "Query" } & {
  reports_BusinessesByCluster?: Maybe<
    Array<
      Maybe<
        { __typename?: "BusinessesByClusterResponse" } & Pick<
          BusinessesByClusterResponse,
          "title" | "numberOfBusinesses"
        >
      >
    >
  >;
};

export type Reports_BusinessesByNaicsQueryVariables = Exact<{
  top?: Maybe<Scalars["Int"]>;
}>;

export type Reports_BusinessesByNaicsQuery = { __typename?: "Query" } & {
  reports_BusinessesByNaics?: Maybe<
    Array<
      Maybe<
        { __typename?: "BusinessesByNaicsResponse" } & Pick<
          BusinessesByNaicsResponse,
          "title" | "code" | "numberOfBusinesses"
        >
      >
    >
  >;
};

export type Reports_BusinessesBySectorQueryVariables = Exact<{
  top?: Maybe<Scalars["Int"]>;
}>;

export type Reports_BusinessesBySectorQuery = { __typename?: "Query" } & {
  reports_BusinessesBySector?: Maybe<
    Array<
      { __typename?: "BusinessesByClusterResponse" } & Pick<
        BusinessesByClusterResponse,
        "title" | "numberOfBusinesses"
      >
    >
  >;
};

export type Reports_CasesResolvedByDateRangeQueryVariables = Exact<{
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
}>;

export type Reports_CasesResolvedByDateRangeQuery = {
  __typename?: "Query";
} & Pick<Query, "reports_CasesResolvedByDateRange">;

export type Reports_CasesResolvedByMonthQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_CasesResolvedByMonthQuery = { __typename?: "Query" } & {
  reports_CasesResolvedByMonth?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type Reports_InteractionsByMonthQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_InteractionsByMonthQuery = { __typename?: "Query" } & {
  reports_InteractionsByMonth?: Maybe<
    Array<
      Maybe<
        { __typename?: "InteractionsByMonthResult" } & Pick<
          InteractionsByMonthResult,
          "id" | "title" | "email" | "phone" | "visit"
        >
      >
    >
  >;
};

export type Reports_NumberOfBusinessesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_NumberOfBusinessesQuery = { __typename?: "Query" } & Pick<
  Query,
  "reports_NumberOfBusinesses"
>;

export type Reports_NumberOfBusinessesAssistedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_NumberOfBusinessesAssistedQuery = {
  __typename?: "Query";
} & Pick<Query, "reports_NumberOfBusinessesAssisted">;

export type Reports_OrganizationsByTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_OrganizationsByTypeQuery = { __typename?: "Query" } & {
  reports_OrganizationsByType?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type Reports_ProjectsByStageQueryVariables = Exact<{
  pipelineId: Scalars["ID"];
}>;

export type Reports_ProjectsByStageQuery = { __typename?: "Query" } & {
  reports_ProjectsByStage?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type Reports_SupportProgramsByStageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_SupportProgramsByStageQuery = { __typename?: "Query" } & {
  reports_SupportProgramsByStage?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type Reports_SupportProgramsByTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Reports_SupportProgramsByTypeQuery = { __typename?: "Query" } & {
  reports_SupportProgramsByType?: Maybe<
    Array<
      Maybe<
        { __typename?: "GenericChartResult" } & Pick<
          GenericChartResult,
          "title" | "total"
        >
      >
    >
  >;
};

export type ResourceByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ResourceByIdQuery = { __typename?: "Query" } & {
  resourceById?: Maybe<{ __typename?: "Resource" } & ResourceFragmentFragment>;
};

export type SearchQueryVariables = Exact<{
  searchText: Scalars["String"];
  tagIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type SearchQuery = { __typename?: "Query" } & {
  search?: Maybe<
    { __typename?: "SearchResult" } & {
      projects?: Maybe<
        Array<
          { __typename?: "Project" } & Pick<Project, "pipelineName"> &
            ProjectFragmentFragment
        >
      >;
      businesses?: Maybe<
        Array<
          { __typename?: "Business" } & Pick<
            Business,
            "id" | "title" | "street" | "fullAddress"
          > & {
              logo?: Maybe<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
                >
              >;
            }
        >
      >;
      contacts?: Maybe<
        Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            "id" | "firstName" | "lastName" | "email" | "title"
          > & {
              avatar?: Maybe<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
                >
              >;
              businesses?: Maybe<
                Array<
                  { __typename?: "Business" } & Pick<Business, "id" | "title">
                >
              >;
            }
        >
      >;
      todos?: Maybe<
        Array<{ __typename?: "Todo" } & Pick<Todo, "id" | "key" | "title">>
      >;
      resources?: Maybe<
        Array<{ __typename?: "Resource" } & ResourceFragmentFragment>
      >;
      sites?: Maybe<Array<{ __typename?: "Site" } & SiteFragmentFragment>>;
      organizations?: Maybe<
        Array<
          { __typename?: "Organization" } & Pick<Organization, "id" | "title">
        >
      >;
      cases?: Maybe<
        Array<{ __typename?: "Case" } & Pick<Case, "id" | "key" | "title">>
      >;
    }
  >;
};

export type SearchUsersQueryVariables = Exact<{
  params?: Maybe<UserSearchParams>;
}>;

export type SearchUsersQuery = { __typename?: "Query" } & {
  searchUsers?: Maybe<
    Array<
      Maybe<
        { __typename?: "UserProfile" } & Pick<
          UserProfile,
          "id" | "email" | "roles" | "firstName" | "lastName"
        > & {
            avatar?: Maybe<
              { __typename?: "Attachment" } & Pick<Attachment, "id" | "url">
            >;
          }
      >
    >
  >;
};

export type SiteByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SiteByIdQuery = { __typename?: "Query" } & {
  siteById?: Maybe<
    { __typename?: "Site" } & {
      contacts?: Maybe<
        Array<
          { __typename?: "Contact" } & Pick<
            Contact,
            "id" | "firstName" | "lastName" | "title" | "email"
          >
        >
      >;
      businesses?: Maybe<
        Array<
          { __typename?: "Business" } & Pick<
            Business,
            "id" | "title" | "website" | "phone"
          > & {
              logo?: Maybe<
                { __typename?: "Attachment" } & Pick<
                  Attachment,
                  "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
                >
              >;
            }
        >
      >;
    } & SiteFragmentFragment
  >;
};

export type SitesQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  siteIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type SitesQuery = { __typename?: "Query" } & {
  sites?: Maybe<
    { __typename?: "SiteSearchResponse" } & Pick<
      SiteSearchResponse,
      "count"
    > & { sites?: Maybe<Array<{ __typename?: "Site" } & SiteFragmentFragment>> }
  >;
};

export type SuggestedResourcesQueryVariables = Exact<{
  businessId: Scalars["ID"];
}>;

export type SuggestedResourcesQuery = { __typename?: "Query" } & {
  suggestedResources?: Maybe<
    Array<Maybe<{ __typename?: "Resource" } & ResourceFragmentFragment>>
  >;
};

export type SurveyResponseByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SurveyResponseByIdQuery = { __typename?: "Query" } & {
  surveyResponseById?: Maybe<
    { __typename?: "SurveyResponse" } & Pick<
      SurveyResponse,
      "id" | "surveyTemplateId" | "businessId" | "attributionDate"
    > & {
        answers?: Maybe<
          Array<
            Maybe<
              { __typename?: "SurveyResponseAnswer" } & Pick<
                SurveyResponseAnswer,
                "surveyQuestionId" | "label" | "answer"
              >
            >
          >
        >;
      }
  >;
};

export type SurveyResponsesQueryVariables = Exact<{
  businessIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  surveyTemplateId: Scalars["ID"];
}>;

export type SurveyResponsesQuery = { __typename?: "Query" } & {
  surveyResponses?: Maybe<
    Array<
      Maybe<
        { __typename?: "SurveyResponse" } & Pick<
          SurveyResponse,
          "id" | "surveyTemplateId" | "businessId"
        > & {
            business?: Maybe<
              { __typename?: "Business" } & Pick<Business, "id" | "title">
            >;
            answers?: Maybe<
              Array<
                Maybe<
                  { __typename?: "SurveyResponseAnswer" } & Pick<
                    SurveyResponseAnswer,
                    "surveyQuestionId" | "label" | "answer"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type TagSearchQueryVariables = Exact<{
  tagIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type TagSearchQuery = { __typename?: "Query" } & {
  tagSearch?: Maybe<
    { __typename?: "TagSearchResult" } & {
      projects?: Maybe<
        Array<
          Maybe<
            { __typename?: "Project" } & Pick<
              Project,
              "id" | "title" | "pipelineName"
            >
          >
        >
      >;
      businesses?: Maybe<
        Array<
          Maybe<
            { __typename?: "Business" } & Pick<Business, "id" | "title"> & {
                logo?: Maybe<
                  { __typename?: "Attachment" } & Pick<
                    Attachment,
                    "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
                  >
                >;
              }
          >
        >
      >;
      contacts?: Maybe<
        Array<
          Maybe<
            { __typename?: "Contact" } & Pick<
              Contact,
              "id" | "firstName" | "lastName" | "email" | "title"
            > & {
                avatar?: Maybe<
                  { __typename?: "Attachment" } & Pick<
                    Attachment,
                    "id" | "filename" | "mimetype" | "encoding" | "url" | "key"
                  >
                >;
                businesses?: Maybe<
                  Array<
                    { __typename?: "Business" } & Pick<Business, "id" | "title">
                  >
                >;
              }
          >
        >
      >;
      todos?: Maybe<
        Array<
          Maybe<{ __typename?: "Todo" } & Pick<Todo, "id" | "key" | "title">>
        >
      >;
      resources?: Maybe<
        Array<
          Maybe<{ __typename?: "Resource" } & Pick<Resource, "id" | "title">>
        >
      >;
      sites?: Maybe<
        Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "title">>>
      >;
      organizations?: Maybe<
        Array<
          Maybe<
            { __typename?: "Organization" } & Pick<Organization, "id" | "title">
          >
        >
      >;
      cases?: Maybe<
        Array<
          Maybe<{ __typename?: "Case" } & Pick<Case, "id" | "key" | "title">>
        >
      >;
    }
  >;
};

export type TagsQueryVariables = Exact<{ [key: string]: never }>;

export type TagsQuery = { __typename?: "Query" } & {
  tags?: Maybe<
    { __typename?: "TagsSearchResponse" } & Pick<
      TagsSearchResponse,
      "count"
    > & { tags?: Maybe<Array<{ __typename?: "Tag" } & TagFragmentFragment>> }
  >;
};

export type TeamQueryVariables = Exact<{
  deactivated?: Maybe<Scalars["Boolean"]>;
  doNotInclude?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type TeamQuery = { __typename?: "Query" } & {
  team?: Maybe<
    Array<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "acceptedInvite"
        | "recentlySentInvite"
        | "lastInviteExpiration"
        | "deactivated"
      > &
        UserFragmentFragment
    >
  >;
};

export type TodoByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TodoByIdQuery = { __typename?: "Query" } & {
  todoById?: Maybe<{ __typename?: "Todo" } & TodoFragmentFragment>;
};

export type TodoListsQueryVariables = Exact<{
  sortBy?: Maybe<TodoListSortByEnum>;
  archived?: Maybe<Scalars["Boolean"]>;
  searchText?: Maybe<Scalars["String"]>;
}>;

export type TodoListsQuery = { __typename?: "Query" } & {
  todoLists?: Maybe<
    { __typename?: "TodoListsResponse" } & Pick<TodoListsResponse, "count"> & {
        todoLists?: Maybe<
          Array<Maybe<{ __typename?: "TodoList" } & TodoListFragmentFragment>>
        >;
      }
  >;
};

export type TodosQueryVariables = Exact<{
  businessId?: Maybe<Scalars["ID"]>;
  contactId?: Maybe<Scalars["ID"]>;
  done?: Maybe<Scalars["Boolean"]>;
  assignedToId?: Maybe<Scalars["ID"]>;
  projectId?: Maybe<Scalars["ID"]>;
  caseId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
}>;

export type TodosQuery = { __typename?: "Query" } & {
  todos?: Maybe<
    { __typename?: "TodosResponse" } & Pick<TodosResponse, "count"> & {
        todos?: Maybe<
          Array<
            { __typename?: "Todo" } & {
              project?: Maybe<
                { __typename?: "Project" } & Pick<Project, "id" | "title"> & {
                    image?: Maybe<
                      { __typename?: "Attachment" } & Pick<
                        Attachment,
                        "id" | "url"
                      >
                    >;
                  }
              >;
            } & TodoFragmentFragment
          >
        >;
      }
  >;
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserByIdQuery = { __typename?: "Query" } & {
  userById?: Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>;
};

export type UsersQueryVariables = Exact<{
  params?: Maybe<UserSearchParams>;
}>;

export type UsersQuery = { __typename?: "Query" } & {
  users?: Maybe<
    { __typename?: "UsersSearchResponse" } & Pick<
      UsersSearchResponse,
      "count"
    > & {
        users?: Maybe<
          Array<Maybe<{ __typename?: "UserProfile" } & UserFragmentFragment>>
        >;
      }
  >;
};

export type YourWorkQueryVariables = Exact<{
  type?: Maybe<YourWorkTypeEnum>;
  assignedToIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  pagination?: Maybe<PaginationInput>;
}>;

export type YourWorkQuery = { __typename?: "Query" } & {
  yourWork?: Maybe<
    { __typename?: "YourWorkResponse" } & Pick<YourWorkResponse, "count"> & {
        results?: Maybe<
          Array<
            | ({ __typename: "Case" } & CaseFragmentFragment)
            | ({ __typename: "Todo" } & TodoFragmentFragment)
          >
        >;
      }
  >;
};

export type RefreshTokensMutationVariables = Exact<{
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
}>;

export type RefreshTokensMutation = { __typename?: "Mutation" } & {
  refreshTokens?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export const InteractionFragmentFragmentDoc = gql`
  fragment interactionFragment on Interaction {
    id
    __typename
    title
    description
    type
    createdAt
    date
    businessId
    contactId
    projectId
    direction
    organizationId
    project {
      id
      title
    }
    emailCampaign {
      id
      title
    }
    contact {
      id
      fullName
      email
    }
    createdBy {
      id
      firstName
      lastName
    }
    direction
    attachments {
      id
      url
      filename
    }
  }
`;
export const InteractionLongFragmentFragmentDoc = gql`
  fragment interactionLongFragment on Interaction {
    ...interactionFragment
    business {
      id
      title
    }
    organization {
      id
      title
    }
    email {
      from
      to
      subject
      body
      attachments {
        id
        filename
        encoding
        mimetype
        url
        key
        createdAt
        createdByName
      }
    }
  }
  ${InteractionFragmentFragmentDoc}
`;
export const CaseFragmentShortFragmentDoc = gql`
  fragment caseFragmentShort on Case {
    id
    title
    createdAt
    caseStatus {
      id
      title
      isFinalStep
    }
    assignedTo {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
    caseOutcomes {
      id
      title
    }
    resolutionNotes
    reporter {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
  }
`;
export const ActivityItemFragmentFragmentDoc = gql`
  fragment activityItemFragment on ActivityItem {
    id
    title
    description
    activityType
    parent
    parentId
    sourceId
    sourceType
    createdAt
    sortDate
    interaction {
      ...interactionLongFragment
    }
    case {
      ...caseFragmentShort
    }
  }
  ${InteractionLongFragmentFragmentDoc}
  ${CaseFragmentShortFragmentDoc}
`;
export const AutomationFragmentFragmentDoc = gql`
  fragment automationFragment on Automation {
    id
    name
    description
    conditions
    events
    trigger
    lastRun
  }
`;
export const AttachmentFragmentFragmentDoc = gql`
  fragment attachmentFragment on Attachment {
    id
    __typename
    filename
    encoding
    mimetype
    url
    key
    createdAt
    createdByName
  }
`;
export const TagFragmentFragmentDoc = gql`
  fragment tagFragment on Tag {
    id
    title
    description
    type
  }
`;
export const ContactShortFragmentFragmentDoc = gql`
  fragment contactShortFragment on Contact {
    id
    email
    department
    firstName
    lastName
    title
    status
    website
    workPhone
    cellPhone
    homePhone
    avatar {
      id
      filename
      mimetype
      encoding
      url
      key
    }
  }
`;
export const BusinessFragmentFragmentDoc = gql`
  fragment businessFragment on Business {
    id
    __typename
    title
    optResult
    description
    email
    phone
    twitter
    instagram
    linkedin
    facebook
    businessSectorId
    tradeName
    businessSector {
      id
      title
    }
    logo {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    attachments {
      ...attachmentFragment
    }
    fax
    website
    businessStage
    clusters
    serviceNeeds
    surveyPermission
    naics {
      id
      title
      code
    }
    naicsIds
    diversity
    amenities
    employeeCount
    tags {
      ...tagFragment
    }
    businessType
    closed
    opened
    status
    createdAt
    primaryContact {
      ...contactShortFragment
    }
    lat
    lng
    placeId
    fullAddress
    street
    street2
    postal
    country
    state
    city
    annualRevenue
  }
  ${AttachmentFragmentFragmentDoc}
  ${TagFragmentFragmentDoc}
  ${ContactShortFragmentFragmentDoc}
`;
export const BusinessByIdFragmentFragmentDoc = gql`
  fragment businessByIdFragment on Business {
    ...businessFragment
    annualRevenue
    contactIds
    email
    projects {
      id
      title
      image {
        id
        url
      }
    }
    primaryContact {
      ...contactShortFragment
    }
    businessSectorId
    tradeName
    businessSector {
      id
      title
    }
    cases {
      ...caseFragmentShort
    }
    owner {
      id
      firstName
      lastName
      email
    }
    companyInvestments {
      id
      amount
      description
      createdAt
      investedOn
    }
    contacts {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }
  ${BusinessFragmentFragmentDoc}
  ${ContactShortFragmentFragmentDoc}
  ${CaseFragmentShortFragmentDoc}
`;
export const CaseOutcomeFragmentFragmentDoc = gql`
  fragment caseOutcomeFragment on CaseOutcome {
    id
    title
    description
  }
`;
export const ProjectFragmentShortFragmentDoc = gql`
  fragment projectFragmentShort on Project {
    id
    __typename
    title
    description
    projectType
    pipeline
    order
    amount
    stageId
    image {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    updatedAt
    createdAt
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on UserProfile {
    id
    __typename
    email
    firstName
    lastName
    customerId
    avatar {
      id
      url
    }
    roles
    permission
  }
`;
export const CaseFragmentFragmentDoc = gql`
  fragment caseFragment on Case {
    id
    title
    key
    description
    caseStatusId
    createdAt
    updatedAt
    tagIds
    tags {
      id
      title
    }
    dueDate
    resolvedOn
    resolutionNotes
    caseOutcomes {
      ...caseOutcomeFragment
    }
    reporter {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
    reporterId
    caseStatus {
      id
      title
      order
    }
    project {
      ...projectFragmentShort
    }
    attachments {
      id
      url
      filename
    }
    assignedToId
    assignedTo {
      ...userFragment
    }
    caseCategoryId
    caseCategory {
      id
      title
    }
    serviceIds
    services {
      id
      title
    }
    businessId
    business {
      id
      __typename
      title
      description
      phone
      logo {
        id
        filename
        mimetype
        encoding
        url
        key
      }
    }
    contactId
    openTodos
    closedTodos
    contact {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }
  ${CaseOutcomeFragmentFragmentDoc}
  ${ProjectFragmentShortFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const CaseTemplateFragmentFragmentDoc = gql`
  fragment caseTemplateFragment on CaseTemplate {
    id
    templateName
    title
    caseCategoryId
    description
    todos {
      id
      title
      description
      assignedTo
    }
  }
`;
export const CommentFragmentFragmentDoc = gql`
  fragment commentFragment on Comment {
    id
    content
    createdAt
    updatedAt
    createdByUser {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const CustomerFeatureSettingFragmentFragmentDoc = gql`
  fragment customerFeatureSettingFragment on CustomerFeatureSetting {
    title
    key
    active
    available
    featureSettings {
      key
      extraFields {
        fieldName
        value
        fieldType
      }
    }
  }
`;
export const CustomerSharingSettingsFragmentFragmentDoc = gql`
  fragment customerSharingSettingsFragment on SharingCustomerSetting {
    key
    enabled
  }
`;
export const CustomerShortFragmentFragmentDoc = gql`
  fragment customerShortFragment on Customer {
    id
    title
    customIntakePageUrl
    integrations {
      constantContact {
        exists
      }
    }
    features {
      ...customerFeatureSettingFragment
    }
    maximumEmailsPerDay
    maximumEmailsPerMinute
    sharingSettings {
      ...customerSharingSettingsFragment
    }
  }
  ${CustomerFeatureSettingFragmentFragmentDoc}
  ${CustomerSharingSettingsFragmentFragmentDoc}
`;
export const CurrentUserFragmentFragmentDoc = gql`
  fragment currentUserFragment on UserProfile {
    id
    __typename
    emails {
      address
    }
    customerId
    customer {
      ...customerShortFragment
    }
    permission
    title
    notificationSettings {
      notificationType
      email
    }
    avatar {
      filename
      encoding
      mimetype
      url
      key
    }
    email
    firstName
    lastName
    roles
  }
  ${CustomerShortFragmentFragmentDoc}
`;
export const CustomerIntegrationsFragmentFragmentDoc = gql`
  fragment customerIntegrationsFragment on CustomerIntegrations {
    constantContact {
      exists
    }
  }
`;
export const CustomerLongFragmentFragmentDoc = gql`
  fragment customerLongFragment on Customer {
    ...customerShortFragment
    users {
      id
      email
      firstName
      lastName
    }
    integrations {
      ...customerIntegrationsFragment
    }
    businessCount
    contactsCount
    integrationCount
    sharingSettings {
      ...customerSharingSettingsFragment
    }
  }
  ${CustomerShortFragmentFragmentDoc}
  ${CustomerIntegrationsFragmentFragmentDoc}
  ${CustomerSharingSettingsFragmentFragmentDoc}
`;
export const DataDiscoveryBusinessFragmentFragmentDoc = gql`
  fragment dataDiscoveryBusinessFragment on DataDiscoveryBusiness {
    id
    address
    city
    description
    domain
    latitude
    longitude
    naics_code
    naics_definition
    google_places_id
    operating_hours
    phone
    state
    status
    thumbnail
    title
    website
    logo
    ignored
    existingBusinessId
    contacts
    merged
  }
`;
export const DataDiscoveryRunFragmentFragmentDoc = gql`
  fragment dataDiscoveryRunFragment on DataDiscoveryRun {
    id
    createdAt
    businessesFound
    newBusinessesFound
    mergedAt
    mergedBusinesses
    mergedContacts
    contactsFound
    searchTerms
    status
  }
`;
export const EmailCampaignFragmentFragmentDoc = gql`
  fragment emailCampaignFragment on EmailCampaign {
    id
    title
    description
    subject
    fromAddress
    replyToAddress
    fromName
    emailTemplate
    campaignType
    createdAt
    updatedAt
    delivered
    recipients
    failed
    bounced
    bounceRate
    openRate
    status
    scheduledAt
    surveyTemplateId
    recipientReferences {
      id
      title
      referenceType
    }
  }
`;
export const EmailCampaignItemFragmentFragmentDoc = gql`
  fragment emailCampaignItemFragment on EmailCampaignItem {
    id
    email
    status
    scheduledAt
    contactId
    body
    subject
    toEmail
    failedReason
  }
`;
export const EmailIntegrationFragmentFragmentDoc = gql`
  fragment emailIntegrationFragment on EmailIntegration {
    id
    provider
    email
    invalid
  }
`;
export const OrganizationFragmentFragmentDoc = gql`
  fragment organizationFragment on Organization {
    id
    title
    description
    businessStages
    clusters
    diversity
    orgType
    serviceTypes
    tagIds
    contactIds
    contacts {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }
`;
export const EventFragmentFragmentDoc = gql`
  fragment eventFragment on Event {
    id
    title
    description
    type
    date
    lat
    lng
    placeId
    fullAddress
    businessStages
    street
    postal
    country
    state
    city
    organization {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;
export const FormQuestionFragmentFragmentDoc = gql`
  fragment formQuestionFragment on FormQuestion {
    id
    label
    placeholder
    description
    questionType
    validation {
      required
    }
    showIfConditions
    options {
      label
      value
      isOther
    }
    subQuestions {
      id
      label
      placeholder
      description
      questionType
      validation {
        required
      }
      showIfConditions
      options {
        label
        value
        isOther
      }
    }
  }
`;
export const FormSubmissionFragmentFragmentDoc = gql`
  fragment formSubmissionFragment on FormSubmission {
    id
    formTemplateId
    respondentType
    respondentId
    attributionDate
    business {
      id
    }
    answers {
      questionId
      label
      answer
      other
    }
    formTemplate {
      id
      title
      questions {
        ...formQuestionFragment
      }
    }
  }
  ${FormQuestionFragmentFragmentDoc}
`;
export const FormTemplateFragmentFragmentDoc = gql`
  fragment formTemplateFragment on FormTemplate {
    id
    title
    description
    questions {
      ...formQuestionFragment
    }
  }
  ${FormQuestionFragmentFragmentDoc}
`;
export const ListFragmentFragmentDoc = gql`
  fragment listFragment on List {
    id
    name
    numberOfRecords
    updatedAt
    createdBy {
      id
      firstName
      lastName
      email
    }
  }
`;
export const BusinessShortFragmentFragmentDoc = gql`
  fragment businessShortFragment on Business {
    id
    title
    businessSector {
      id
      title
    }
    email
    logo {
      id: url
      url
      key
      createdAt
    }
    description
    phone
    industry
    twitter
    instagram
    linkedin
    contactIds
    facebook
    fax
    lat
    lng
    city
    country
    website
    businessStage
    street
    postal
    city
    state
    clusters
    serviceNeeds
    surveyPermission
    createdAt
    primaryContact {
      ...contactShortFragment
    }
    naics {
      title
      code
    }
    businessType
    closed
    opened
    status
    diversity
    amenities
    employeeCount
    tags {
      ...tagFragment
    }
  }
  ${ContactShortFragmentFragmentDoc}
  ${TagFragmentFragmentDoc}
`;
export const ListItemFragmentFragmentDoc = gql`
  fragment listItemFragment on ListItem {
    id
    contact {
      ...contactShortFragment
    }
    business {
      ...businessShortFragment
    }
  }
  ${ContactShortFragmentFragmentDoc}
  ${BusinessShortFragmentFragmentDoc}
`;
export const NoteFragmentFragmentDoc = gql`
  fragment noteFragment on Note {
    id
    __typename
    title
    description
    createdAt
    businessId
    contactId
    siteId
    project {
      id
      title
    }
    projectId
    createdByUser {
      ...userFragment
    }
    attachments {
      id
      filename
      encoding
      mimetype
      url
      key
      createdAt
      createdByName
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const NotificationFragmentFragmentDoc = gql`
  fragment notificationFragment on Notification {
    id
    actorId
    content
    read
    actionType
    createdAt
    entity {
      ... on SurveyResponse {
        id
        __typename
        business {
          id
        }
      }
      ... on Todo {
        id
        __typename
        title
      }
      ... on Case {
        id
        __typename
        title
      }
    }
  }
`;
export const UserGroupFragmentFragmentDoc = gql`
  fragment userGroupFragment on UserGroup {
    id
    title
    description
    users {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const ObjectPermissionFragmentFragmentDoc = gql`
  fragment objectPermissionFragment on ObjectPermission {
    id: subjectId
    access
    subjectId
    subjectType
    subject {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;
export const PipelineStageFragmentFragmentDoc = gql`
  fragment pipelineStageFragment on PipelineStage {
    id
    label
    order
  }
`;
export const PipelineFragmentFragmentDoc = gql`
  fragment pipelineFragment on Pipeline {
    id
    title
    stages {
      ...pipelineStageFragment
    }
  }
  ${PipelineStageFragmentFragmentDoc}
`;
export const ProjectFragmentFragmentDoc = gql`
  fragment projectFragment on Project {
    id
    __typename
    title
    description
    projectType
    pipeline
    order
    amount
    stageId
    image {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    updatedAt
    createdAt
    businessIds
    contactIds
    siteIds
    extraFields {
      value
      fieldName
      fieldType
    }
    customFields {
      value
      fieldName
      fieldType
    }
  }
`;
export const ContactFragmentFragmentDoc = gql`
  fragment contactFragment on Contact {
    id
    email
    department
    optResult
    avatar {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    attachments {
      ...attachmentFragment
    }
    twitter
    instagram
    linkedin
    facebook
    firstName
    lastName
    title
    status
    website
    workPhone
    cellPhone
    homePhone
    businesses {
      ...businessFragment
    }
    tags {
      id
      title
      description
    }
    lat
    lng
    placeId
    fullAddress
    street
    postal
    country
    state
    city
  }
  ${AttachmentFragmentFragmentDoc}
  ${BusinessFragmentFragmentDoc}
`;
export const SiteFragmentFragmentDoc = gql`
  fragment siteFragment on Site {
    id
    title
    description
    type
    size
    lat
    lng
    url
    placeId
    fullAddress
    street
    postal
    country
    archived
    tagIds
    state
    city
    projects {
      ...projectFragmentShort
    }
    attachments {
      ...attachmentFragment
    }
    contactId
    contacts {
      ...contactFragment
    }
    contact {
      ...contactFragment
    }
    extraFields {
      fieldName
      value
      fieldType
    }
  }
  ${ProjectFragmentShortFragmentDoc}
  ${AttachmentFragmentFragmentDoc}
  ${ContactFragmentFragmentDoc}
`;
export const ProjectFragmentLongFragmentDoc = gql`
  fragment projectFragmentLong on Project {
    ...projectFragment
    pipelineStage {
      id
      label
      order
    }
    pipelineName
    contacts {
      id
      firstName
      lastName
      email
      avatar {
        id
        url
      }
    }
    cases {
      ...caseFragmentShort
    }
    sites {
      ...siteFragment
    }
    businesses {
      id
      title
      phone
      website
      logo {
        id
        url
      }
    }
  }
  ${ProjectFragmentFragmentDoc}
  ${CaseFragmentShortFragmentDoc}
  ${SiteFragmentFragmentDoc}
`;
export const ProjectTypeFragmentFragmentDoc = gql`
  fragment projectTypeFragment on ProjectType {
    id
    __typename
    title
    extraFields {
      fieldName
      fieldType
      value
    }
    customFields {
      fieldName
      fieldType
      value
    }
  }
`;
export const ResourceFragmentFragmentDoc = gql`
  fragment resourceFragment on Resource {
    id
    title
    description
    businessStages
    type
    clusters
    supportType
    fundingType
    diversityTypes
    tagIds
    organization {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;
export const SurveyCampaignFragmentFragmentDoc = gql`
  fragment surveyCampaignFragment on SurveyCampaign {
    id
    name
    numberOfRecords
    surveyTemplateId
    surveyTemplate {
      id
      title
    }
    updatedAt
    createdBy {
      id
      firstName
      lastName
      email
    }
  }
`;
export const SurveyQuestionFragmentFragmentDoc = gql`
  fragment surveyQuestionFragment on SurveyQuestion {
    id
    label
    description
    customerId
    isDefault
    category
    key
    showIfKey
    showIfAnswer
    options {
      label
      value
    }
    questionType
  }
`;
export const SurveyTemplateNewFragmentFragmentDoc = gql`
  fragment surveyTemplateNewFragment on SurveyTemplateNew {
    id
    customerId
    createdBy
    template
    title
  }
`;
export const TodoFragmentFragmentDoc = gql`
  fragment todoFragment on Todo {
    id
    key
    __typename
    title
    description
    dueDate
    priority
    done
    assignedToId
    assignedTo {
      ...userFragment
    }
    tagIds
    tags {
      id
      title
    }
    contactId
    contact {
      ...contactFragment
    }
    site {
      id
      title
      fullAddress
    }
    businessId
    business {
      id
      title
      logo {
        id
        url
        filename
      }
    }
    caseId
    case {
      id
      title
    }
    projectId
    project {
      id
      title
      image {
        id
        url
      }
    }
    attachments {
      id
      filename
      encoding
      mimetype
      url
      key
      createdAt
      createdByName
    }
    createdAt
    updatedAt
  }
  ${UserFragmentFragmentDoc}
  ${ContactFragmentFragmentDoc}
`;
export const TodoListFragmentFragmentDoc = gql`
  fragment todoListFragment on TodoList {
    id
    __typename
    title
    description
    archived
    todos(sortBy: $sortBy, searchText: $searchText) {
      ...todoFragment
    }
  }
  ${TodoFragmentFragmentDoc}
`;
export const SurveyCampaignItemFragmentFragmentDoc = gql`
  fragment surveyCampaignItemFragment on SurveyCampaignItem {
    id
    status
    surveyResultId
    surveyResultAt
    contact {
      id
      firstName
      lastName
      email
      businesses {
        id
        title
      }
    }
  }
`;
export const AddCompanyInvestmentDocument = gql`
  mutation addCompanyInvestment(
    $amount: Int
    $description: String
    $investedOn: String
    $businessId: ID!
  ) {
    addCompanyInvestment(
      amount: $amount
      description: $description
      investedOn: $investedOn
      businessId: $businessId
    ) {
      success
    }
  }
`;
export type AddCompanyInvestmentMutationFn = Apollo.MutationFunction<
  AddCompanyInvestmentMutation,
  AddCompanyInvestmentMutationVariables
>;

/**
 * __useAddCompanyInvestmentMutation__
 *
 * To run a mutation, you first call `useAddCompanyInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyInvestmentMutation, { data, loading, error }] = useAddCompanyInvestmentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *      investedOn: // value for 'investedOn'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useAddCompanyInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyInvestmentMutation,
    AddCompanyInvestmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompanyInvestmentMutation,
    AddCompanyInvestmentMutationVariables
  >(AddCompanyInvestmentDocument, options);
}
export type AddCompanyInvestmentMutationHookResult = ReturnType<
  typeof useAddCompanyInvestmentMutation
>;
export type AddCompanyInvestmentMutationResult =
  Apollo.MutationResult<AddCompanyInvestmentMutation>;
export type AddCompanyInvestmentMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyInvestmentMutation,
  AddCompanyInvestmentMutationVariables
>;
export const AddEntityToBusinessDocument = gql`
  mutation addEntityToBusiness(
    $businessId: String!
    $entityId: String!
    $entityType: EntityTypeEnum
  ) {
    addEntityToBusiness(
      businessId: $businessId
      entityId: $entityId
      entityType: $entityType
    ) {
      id
    }
  }
`;
export type AddEntityToBusinessMutationFn = Apollo.MutationFunction<
  AddEntityToBusinessMutation,
  AddEntityToBusinessMutationVariables
>;

/**
 * __useAddEntityToBusinessMutation__
 *
 * To run a mutation, you first call `useAddEntityToBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntityToBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntityToBusinessMutation, { data, loading, error }] = useAddEntityToBusinessMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useAddEntityToBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEntityToBusinessMutation,
    AddEntityToBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEntityToBusinessMutation,
    AddEntityToBusinessMutationVariables
  >(AddEntityToBusinessDocument, options);
}
export type AddEntityToBusinessMutationHookResult = ReturnType<
  typeof useAddEntityToBusinessMutation
>;
export type AddEntityToBusinessMutationResult =
  Apollo.MutationResult<AddEntityToBusinessMutation>;
export type AddEntityToBusinessMutationOptions = Apollo.BaseMutationOptions<
  AddEntityToBusinessMutation,
  AddEntityToBusinessMutationVariables
>;
export const AddEntityToProjectDocument = gql`
  mutation addEntityToProject(
    $projectId: String!
    $entityId: String!
    $entityType: EntityTypeEnum
  ) {
    addEntityToProject(
      projectId: $projectId
      entityId: $entityId
      entityType: $entityType
    ) {
      id
    }
  }
`;
export type AddEntityToProjectMutationFn = Apollo.MutationFunction<
  AddEntityToProjectMutation,
  AddEntityToProjectMutationVariables
>;

/**
 * __useAddEntityToProjectMutation__
 *
 * To run a mutation, you first call `useAddEntityToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntityToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntityToProjectMutation, { data, loading, error }] = useAddEntityToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useAddEntityToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEntityToProjectMutation,
    AddEntityToProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEntityToProjectMutation,
    AddEntityToProjectMutationVariables
  >(AddEntityToProjectDocument, options);
}
export type AddEntityToProjectMutationHookResult = ReturnType<
  typeof useAddEntityToProjectMutation
>;
export type AddEntityToProjectMutationResult =
  Apollo.MutationResult<AddEntityToProjectMutation>;
export type AddEntityToProjectMutationOptions = Apollo.BaseMutationOptions<
  AddEntityToProjectMutation,
  AddEntityToProjectMutationVariables
>;
export const AddManyItemsToListDocument = gql`
  mutation addManyItemsToList(
    $listId: ID!
    $itemIds: [ID!]!
    $addFromContactsQuery: ContactSearchParams
    $addFromBusinessesQuery: BusinessSearchParams
  ) {
    addManyItemsToList(
      listId: $listId
      itemIds: $itemIds
      addFromContactsQuery: $addFromContactsQuery
      addFromBusinessesQuery: $addFromBusinessesQuery
    ) {
      success
    }
  }
`;
export type AddManyItemsToListMutationFn = Apollo.MutationFunction<
  AddManyItemsToListMutation,
  AddManyItemsToListMutationVariables
>;

/**
 * __useAddManyItemsToListMutation__
 *
 * To run a mutation, you first call `useAddManyItemsToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManyItemsToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManyItemsToListMutation, { data, loading, error }] = useAddManyItemsToListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      itemIds: // value for 'itemIds'
 *      addFromContactsQuery: // value for 'addFromContactsQuery'
 *      addFromBusinessesQuery: // value for 'addFromBusinessesQuery'
 *   },
 * });
 */
export function useAddManyItemsToListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddManyItemsToListMutation,
    AddManyItemsToListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddManyItemsToListMutation,
    AddManyItemsToListMutationVariables
  >(AddManyItemsToListDocument, options);
}
export type AddManyItemsToListMutationHookResult = ReturnType<
  typeof useAddManyItemsToListMutation
>;
export type AddManyItemsToListMutationResult =
  Apollo.MutationResult<AddManyItemsToListMutation>;
export type AddManyItemsToListMutationOptions = Apollo.BaseMutationOptions<
  AddManyItemsToListMutation,
  AddManyItemsToListMutationVariables
>;
export const AddManyItemsToSurveyCampaignDocument = gql`
  mutation addManyItemsToSurveyCampaign(
    $listId: ID
    $surveyCampaignId: ID!
    $addFromContactsQuery: ContactSearchParams
    $addFromBusinessesQuery: BusinessSearchParams
    $itemIds: [ID!]
  ) {
    addManyItemsToSurveyCampaign(
      listId: $listId
      surveyCampaignId: $surveyCampaignId
      itemIds: $itemIds
      addFromBusinessesQuery: $addFromBusinessesQuery
      addFromContactsQuery: $addFromContactsQuery
    ) {
      ...surveyCampaignFragment
    }
  }
  ${SurveyCampaignFragmentFragmentDoc}
`;
export type AddManyItemsToSurveyCampaignMutationFn = Apollo.MutationFunction<
  AddManyItemsToSurveyCampaignMutation,
  AddManyItemsToSurveyCampaignMutationVariables
>;

/**
 * __useAddManyItemsToSurveyCampaignMutation__
 *
 * To run a mutation, you first call `useAddManyItemsToSurveyCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManyItemsToSurveyCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManyItemsToSurveyCampaignMutation, { data, loading, error }] = useAddManyItemsToSurveyCampaignMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      surveyCampaignId: // value for 'surveyCampaignId'
 *      addFromContactsQuery: // value for 'addFromContactsQuery'
 *      addFromBusinessesQuery: // value for 'addFromBusinessesQuery'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useAddManyItemsToSurveyCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddManyItemsToSurveyCampaignMutation,
    AddManyItemsToSurveyCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddManyItemsToSurveyCampaignMutation,
    AddManyItemsToSurveyCampaignMutationVariables
  >(AddManyItemsToSurveyCampaignDocument, options);
}
export type AddManyItemsToSurveyCampaignMutationHookResult = ReturnType<
  typeof useAddManyItemsToSurveyCampaignMutation
>;
export type AddManyItemsToSurveyCampaignMutationResult =
  Apollo.MutationResult<AddManyItemsToSurveyCampaignMutation>;
export type AddManyItemsToSurveyCampaignMutationOptions =
  Apollo.BaseMutationOptions<
    AddManyItemsToSurveyCampaignMutation,
    AddManyItemsToSurveyCampaignMutationVariables
  >;
export const AddPipelineColDocument = gql`
  mutation addPipelineCol($params: PipelineStageInput) {
    addPipelineCol(params: $params) {
      ...pipelineFragment
    }
  }
  ${PipelineFragmentFragmentDoc}
`;
export type AddPipelineColMutationFn = Apollo.MutationFunction<
  AddPipelineColMutation,
  AddPipelineColMutationVariables
>;

/**
 * __useAddPipelineColMutation__
 *
 * To run a mutation, you first call `useAddPipelineColMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineColMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineColMutation, { data, loading, error }] = useAddPipelineColMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddPipelineColMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPipelineColMutation,
    AddPipelineColMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPipelineColMutation,
    AddPipelineColMutationVariables
  >(AddPipelineColDocument, options);
}
export type AddPipelineColMutationHookResult = ReturnType<
  typeof useAddPipelineColMutation
>;
export type AddPipelineColMutationResult =
  Apollo.MutationResult<AddPipelineColMutation>;
export type AddPipelineColMutationOptions = Apollo.BaseMutationOptions<
  AddPipelineColMutation,
  AddPipelineColMutationVariables
>;
export const AddUsersToUserGroupDocument = gql`
  mutation addUsersToUserGroup($userIds: [ID!]!, $userGroupId: ID!) {
    addUsersToUserGroup(userIds: $userIds, userGroupId: $userGroupId) {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;
export type AddUsersToUserGroupMutationFn = Apollo.MutationFunction<
  AddUsersToUserGroupMutation,
  AddUsersToUserGroupMutationVariables
>;

/**
 * __useAddUsersToUserGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToUserGroupMutation, { data, loading, error }] = useAddUsersToUserGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useAddUsersToUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUsersToUserGroupMutation,
    AddUsersToUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUsersToUserGroupMutation,
    AddUsersToUserGroupMutationVariables
  >(AddUsersToUserGroupDocument, options);
}
export type AddUsersToUserGroupMutationHookResult = ReturnType<
  typeof useAddUsersToUserGroupMutation
>;
export type AddUsersToUserGroupMutationResult =
  Apollo.MutationResult<AddUsersToUserGroupMutation>;
export type AddUsersToUserGroupMutationOptions = Apollo.BaseMutationOptions<
  AddUsersToUserGroupMutation,
  AddUsersToUserGroupMutationVariables
>;
export const ArchiveOneFormTemplateDocument = gql`
  mutation archiveOneFormTemplate($formTemplateId: ID!) {
    archiveOneFormTemplate(formTemplateId: $formTemplateId) {
      success
    }
  }
`;
export type ArchiveOneFormTemplateMutationFn = Apollo.MutationFunction<
  ArchiveOneFormTemplateMutation,
  ArchiveOneFormTemplateMutationVariables
>;

/**
 * __useArchiveOneFormTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveOneFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOneFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOneFormTemplateMutation, { data, loading, error }] = useArchiveOneFormTemplateMutation({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useArchiveOneFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveOneFormTemplateMutation,
    ArchiveOneFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveOneFormTemplateMutation,
    ArchiveOneFormTemplateMutationVariables
  >(ArchiveOneFormTemplateDocument, options);
}
export type ArchiveOneFormTemplateMutationHookResult = ReturnType<
  typeof useArchiveOneFormTemplateMutation
>;
export type ArchiveOneFormTemplateMutationResult =
  Apollo.MutationResult<ArchiveOneFormTemplateMutation>;
export type ArchiveOneFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveOneFormTemplateMutation,
  ArchiveOneFormTemplateMutationVariables
>;
export const ArchiveSiteDocument = gql`
  mutation archiveSite($siteId: ID!, $archived: Boolean!) {
    archiveSite(siteId: $siteId, archived: $archived) {
      success
    }
  }
`;
export type ArchiveSiteMutationFn = Apollo.MutationFunction<
  ArchiveSiteMutation,
  ArchiveSiteMutationVariables
>;

/**
 * __useArchiveSiteMutation__
 *
 * To run a mutation, you first call `useArchiveSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSiteMutation, { data, loading, error }] = useArchiveSiteMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSiteMutation,
    ArchiveSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveSiteMutation, ArchiveSiteMutationVariables>(
    ArchiveSiteDocument,
    options
  );
}
export type ArchiveSiteMutationHookResult = ReturnType<
  typeof useArchiveSiteMutation
>;
export type ArchiveSiteMutationResult =
  Apollo.MutationResult<ArchiveSiteMutation>;
export type ArchiveSiteMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSiteMutation,
  ArchiveSiteMutationVariables
>;
export const AuthenticateDocument = gql`
  mutation authenticate($params: AuthenticateParamsInput!) {
    authenticate(serviceName: "password", params: $params) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAuthenticateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<
  typeof useAuthenticateMutation
>;
export type AuthenticateMutationResult =
  Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;
export const CancelOneEmailCampaignDocument = gql`
  mutation cancelOneEmailCampaign($emailCampaignId: ID!) {
    cancelOneEmailCampaign(emailCampaignId: $emailCampaignId) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;
export type CancelOneEmailCampaignMutationFn = Apollo.MutationFunction<
  CancelOneEmailCampaignMutation,
  CancelOneEmailCampaignMutationVariables
>;

/**
 * __useCancelOneEmailCampaignMutation__
 *
 * To run a mutation, you first call `useCancelOneEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOneEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOneEmailCampaignMutation, { data, loading, error }] = useCancelOneEmailCampaignMutation({
 *   variables: {
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useCancelOneEmailCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOneEmailCampaignMutation,
    CancelOneEmailCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelOneEmailCampaignMutation,
    CancelOneEmailCampaignMutationVariables
  >(CancelOneEmailCampaignDocument, options);
}
export type CancelOneEmailCampaignMutationHookResult = ReturnType<
  typeof useCancelOneEmailCampaignMutation
>;
export type CancelOneEmailCampaignMutationResult =
  Apollo.MutationResult<CancelOneEmailCampaignMutation>;
export type CancelOneEmailCampaignMutationOptions = Apollo.BaseMutationOptions<
  CancelOneEmailCampaignMutation,
  CancelOneEmailCampaignMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CompleteMultiUploadDocument = gql`
  mutation completeMultiUpload(
    $type: MultipartUploadCompleteEnum!
    $uploadId: String!
    $key: String!
    $parts: [MultipartUploadPartInput!]
  ) {
    completeMultiUpload(
      type: $type
      uploadId: $uploadId
      key: $key
      parts: $parts
    ) {
      success
    }
  }
`;
export type CompleteMultiUploadMutationFn = Apollo.MutationFunction<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>;

/**
 * __useCompleteMultiUploadMutation__
 *
 * To run a mutation, you first call `useCompleteMultiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMultiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMultiUploadMutation, { data, loading, error }] = useCompleteMultiUploadMutation({
 *   variables: {
 *      type: // value for 'type'
 *      uploadId: // value for 'uploadId'
 *      key: // value for 'key'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCompleteMultiUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteMultiUploadMutation,
    CompleteMultiUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteMultiUploadMutation,
    CompleteMultiUploadMutationVariables
  >(CompleteMultiUploadDocument, options);
}
export type CompleteMultiUploadMutationHookResult = ReturnType<
  typeof useCompleteMultiUploadMutation
>;
export type CompleteMultiUploadMutationResult =
  Apollo.MutationResult<CompleteMultiUploadMutation>;
export type CompleteMultiUploadMutationOptions = Apollo.BaseMutationOptions<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>;
export const ContactSupportDocument = gql`
  mutation contactSupport($subject: String, $message: String!) {
    contactSupport(subject: $subject, message: $message) {
      success
    }
  }
`;
export type ContactSupportMutationFn = Apollo.MutationFunction<
  ContactSupportMutation,
  ContactSupportMutationVariables
>;

/**
 * __useContactSupportMutation__
 *
 * To run a mutation, you first call `useContactSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactSupportMutation, { data, loading, error }] = useContactSupportMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactSupportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactSupportMutation,
    ContactSupportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContactSupportMutation,
    ContactSupportMutationVariables
  >(ContactSupportDocument, options);
}
export type ContactSupportMutationHookResult = ReturnType<
  typeof useContactSupportMutation
>;
export type ContactSupportMutationResult =
  Apollo.MutationResult<ContactSupportMutation>;
export type ContactSupportMutationOptions = Apollo.BaseMutationOptions<
  ContactSupportMutation,
  ContactSupportMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($title: String!) {
    createCustomer(title: $title) {
      id
    }
  }
`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult =
  Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CreateCustomerAdminDocument = gql`
  mutation createCustomerAdmin(
    $customerId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $permission: UserPermissionEnum!
  ) {
    createCustomerAdmin(
      customerId: $customerId
      firstName: $firstName
      lastName: $lastName
      email: $email
      permission: $permission
    ) {
      id
    }
  }
`;
export type CreateCustomerAdminMutationFn = Apollo.MutationFunction<
  CreateCustomerAdminMutation,
  CreateCustomerAdminMutationVariables
>;

/**
 * __useCreateCustomerAdminMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAdminMutation, { data, loading, error }] = useCreateCustomerAdminMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useCreateCustomerAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerAdminMutation,
    CreateCustomerAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerAdminMutation,
    CreateCustomerAdminMutationVariables
  >(CreateCustomerAdminDocument, options);
}
export type CreateCustomerAdminMutationHookResult = ReturnType<
  typeof useCreateCustomerAdminMutation
>;
export type CreateCustomerAdminMutationResult =
  Apollo.MutationResult<CreateCustomerAdminMutation>;
export type CreateCustomerAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerAdminMutation,
  CreateCustomerAdminMutationVariables
>;
export const CreateNylasAuthDocument = gql`
  mutation createNylasAuth {
    createNylasAuth {
      url
    }
  }
`;
export type CreateNylasAuthMutationFn = Apollo.MutationFunction<
  CreateNylasAuthMutation,
  CreateNylasAuthMutationVariables
>;

/**
 * __useCreateNylasAuthMutation__
 *
 * To run a mutation, you first call `useCreateNylasAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNylasAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNylasAuthMutation, { data, loading, error }] = useCreateNylasAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNylasAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNylasAuthMutation,
    CreateNylasAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNylasAuthMutation,
    CreateNylasAuthMutationVariables
  >(CreateNylasAuthDocument, options);
}
export type CreateNylasAuthMutationHookResult = ReturnType<
  typeof useCreateNylasAuthMutation
>;
export type CreateNylasAuthMutationResult =
  Apollo.MutationResult<CreateNylasAuthMutation>;
export type CreateNylasAuthMutationOptions = Apollo.BaseMutationOptions<
  CreateNylasAuthMutation,
  CreateNylasAuthMutationVariables
>;
export const CreateNylasGrantDocument = gql`
  mutation createNylasGrant($code: String!) {
    createNylasGrant(code: $code) {
      success
    }
  }
`;
export type CreateNylasGrantMutationFn = Apollo.MutationFunction<
  CreateNylasGrantMutation,
  CreateNylasGrantMutationVariables
>;

/**
 * __useCreateNylasGrantMutation__
 *
 * To run a mutation, you first call `useCreateNylasGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNylasGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNylasGrantMutation, { data, loading, error }] = useCreateNylasGrantMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateNylasGrantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNylasGrantMutation,
    CreateNylasGrantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNylasGrantMutation,
    CreateNylasGrantMutationVariables
  >(CreateNylasGrantDocument, options);
}
export type CreateNylasGrantMutationHookResult = ReturnType<
  typeof useCreateNylasGrantMutation
>;
export type CreateNylasGrantMutationResult =
  Apollo.MutationResult<CreateNylasGrantMutation>;
export type CreateNylasGrantMutationOptions = Apollo.BaseMutationOptions<
  CreateNylasGrantMutation,
  CreateNylasGrantMutationVariables
>;
export const CreateOneAutomationDocument = gql`
  mutation createOneAutomation($params: AutomationInput!) {
    createOneAutomation(params: $params) {
      ...automationFragment
    }
  }
  ${AutomationFragmentFragmentDoc}
`;
export type CreateOneAutomationMutationFn = Apollo.MutationFunction<
  CreateOneAutomationMutation,
  CreateOneAutomationMutationVariables
>;

/**
 * __useCreateOneAutomationMutation__
 *
 * To run a mutation, you first call `useCreateOneAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAutomationMutation, { data, loading, error }] = useCreateOneAutomationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneAutomationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneAutomationMutation,
    CreateOneAutomationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneAutomationMutation,
    CreateOneAutomationMutationVariables
  >(CreateOneAutomationDocument, options);
}
export type CreateOneAutomationMutationHookResult = ReturnType<
  typeof useCreateOneAutomationMutation
>;
export type CreateOneAutomationMutationResult =
  Apollo.MutationResult<CreateOneAutomationMutation>;
export type CreateOneAutomationMutationOptions = Apollo.BaseMutationOptions<
  CreateOneAutomationMutation,
  CreateOneAutomationMutationVariables
>;
export const CreateOneCaseTemplateDocument = gql`
  mutation createOneCaseTemplate($params: CaseTemplateInput) {
    createOneCaseTemplate(params: $params) {
      ...caseTemplateFragment
    }
  }
  ${CaseTemplateFragmentFragmentDoc}
`;
export type CreateOneCaseTemplateMutationFn = Apollo.MutationFunction<
  CreateOneCaseTemplateMutation,
  CreateOneCaseTemplateMutationVariables
>;

/**
 * __useCreateOneCaseTemplateMutation__
 *
 * To run a mutation, you first call `useCreateOneCaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCaseTemplateMutation, { data, loading, error }] = useCreateOneCaseTemplateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneCaseTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneCaseTemplateMutation,
    CreateOneCaseTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneCaseTemplateMutation,
    CreateOneCaseTemplateMutationVariables
  >(CreateOneCaseTemplateDocument, options);
}
export type CreateOneCaseTemplateMutationHookResult = ReturnType<
  typeof useCreateOneCaseTemplateMutation
>;
export type CreateOneCaseTemplateMutationResult =
  Apollo.MutationResult<CreateOneCaseTemplateMutation>;
export type CreateOneCaseTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateOneCaseTemplateMutation,
  CreateOneCaseTemplateMutationVariables
>;
export const CreateOneEmailCampaignDocument = gql`
  mutation createOneEmailCampaign($params: EmailCampaignInput) {
    createOneEmailCampaign(params: $params) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;
export type CreateOneEmailCampaignMutationFn = Apollo.MutationFunction<
  CreateOneEmailCampaignMutation,
  CreateOneEmailCampaignMutationVariables
>;

/**
 * __useCreateOneEmailCampaignMutation__
 *
 * To run a mutation, you first call `useCreateOneEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneEmailCampaignMutation, { data, loading, error }] = useCreateOneEmailCampaignMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneEmailCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneEmailCampaignMutation,
    CreateOneEmailCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneEmailCampaignMutation,
    CreateOneEmailCampaignMutationVariables
  >(CreateOneEmailCampaignDocument, options);
}
export type CreateOneEmailCampaignMutationHookResult = ReturnType<
  typeof useCreateOneEmailCampaignMutation
>;
export type CreateOneEmailCampaignMutationResult =
  Apollo.MutationResult<CreateOneEmailCampaignMutation>;
export type CreateOneEmailCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateOneEmailCampaignMutation,
  CreateOneEmailCampaignMutationVariables
>;
export const CreateOneFormSubmissionDocument = gql`
  mutation createOneFormSubmission($params: FormSubmissionInput!) {
    createOneFormSubmission(params: $params) {
      id
    }
  }
`;
export type CreateOneFormSubmissionMutationFn = Apollo.MutationFunction<
  CreateOneFormSubmissionMutation,
  CreateOneFormSubmissionMutationVariables
>;

/**
 * __useCreateOneFormSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateOneFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneFormSubmissionMutation, { data, loading, error }] = useCreateOneFormSubmissionMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneFormSubmissionMutation,
    CreateOneFormSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneFormSubmissionMutation,
    CreateOneFormSubmissionMutationVariables
  >(CreateOneFormSubmissionDocument, options);
}
export type CreateOneFormSubmissionMutationHookResult = ReturnType<
  typeof useCreateOneFormSubmissionMutation
>;
export type CreateOneFormSubmissionMutationResult =
  Apollo.MutationResult<CreateOneFormSubmissionMutation>;
export type CreateOneFormSubmissionMutationOptions = Apollo.BaseMutationOptions<
  CreateOneFormSubmissionMutation,
  CreateOneFormSubmissionMutationVariables
>;
export const CreateOneFormTemplateDocument = gql`
  mutation createOneFormTemplate($params: FormTemplateInput!) {
    createOneFormTemplate(params: $params) {
      ...formTemplateFragment
    }
  }
  ${FormTemplateFragmentFragmentDoc}
`;
export type CreateOneFormTemplateMutationFn = Apollo.MutationFunction<
  CreateOneFormTemplateMutation,
  CreateOneFormTemplateMutationVariables
>;

/**
 * __useCreateOneFormTemplateMutation__
 *
 * To run a mutation, you first call `useCreateOneFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneFormTemplateMutation, { data, loading, error }] = useCreateOneFormTemplateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneFormTemplateMutation,
    CreateOneFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneFormTemplateMutation,
    CreateOneFormTemplateMutationVariables
  >(CreateOneFormTemplateDocument, options);
}
export type CreateOneFormTemplateMutationHookResult = ReturnType<
  typeof useCreateOneFormTemplateMutation
>;
export type CreateOneFormTemplateMutationResult =
  Apollo.MutationResult<CreateOneFormTemplateMutation>;
export type CreateOneFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateOneFormTemplateMutation,
  CreateOneFormTemplateMutationVariables
>;
export const CreateOneListDocument = gql`
  mutation createOneList(
    $name: String!
    $listType: ListTypeEnum!
    $listItemIds: [ID!]
  ) {
    createOneList(name: $name, listType: $listType, listItemIds: $listItemIds) {
      success
    }
  }
`;
export type CreateOneListMutationFn = Apollo.MutationFunction<
  CreateOneListMutation,
  CreateOneListMutationVariables
>;

/**
 * __useCreateOneListMutation__
 *
 * To run a mutation, you first call `useCreateOneListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneListMutation, { data, loading, error }] = useCreateOneListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      listType: // value for 'listType'
 *      listItemIds: // value for 'listItemIds'
 *   },
 * });
 */
export function useCreateOneListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneListMutation,
    CreateOneListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneListMutation,
    CreateOneListMutationVariables
  >(CreateOneListDocument, options);
}
export type CreateOneListMutationHookResult = ReturnType<
  typeof useCreateOneListMutation
>;
export type CreateOneListMutationResult =
  Apollo.MutationResult<CreateOneListMutation>;
export type CreateOneListMutationOptions = Apollo.BaseMutationOptions<
  CreateOneListMutation,
  CreateOneListMutationVariables
>;
export const CreateOneSurveyCampaignDocument = gql`
  mutation createOneSurveyCampaign($name: String!, $surveyTemplateId: ID!) {
    createOneSurveyCampaign(name: $name, surveyTemplateId: $surveyTemplateId) {
      ...surveyCampaignFragment
    }
  }
  ${SurveyCampaignFragmentFragmentDoc}
`;
export type CreateOneSurveyCampaignMutationFn = Apollo.MutationFunction<
  CreateOneSurveyCampaignMutation,
  CreateOneSurveyCampaignMutationVariables
>;

/**
 * __useCreateOneSurveyCampaignMutation__
 *
 * To run a mutation, you first call `useCreateOneSurveyCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSurveyCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSurveyCampaignMutation, { data, loading, error }] = useCreateOneSurveyCampaignMutation({
 *   variables: {
 *      name: // value for 'name'
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useCreateOneSurveyCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneSurveyCampaignMutation,
    CreateOneSurveyCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneSurveyCampaignMutation,
    CreateOneSurveyCampaignMutationVariables
  >(CreateOneSurveyCampaignDocument, options);
}
export type CreateOneSurveyCampaignMutationHookResult = ReturnType<
  typeof useCreateOneSurveyCampaignMutation
>;
export type CreateOneSurveyCampaignMutationResult =
  Apollo.MutationResult<CreateOneSurveyCampaignMutation>;
export type CreateOneSurveyCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateOneSurveyCampaignMutation,
  CreateOneSurveyCampaignMutationVariables
>;
export const CreateOneUserGroupDocument = gql`
  mutation createOneUserGroup($params: UserGroupInput!) {
    createOneUserGroup(params: $params) {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;
export type CreateOneUserGroupMutationFn = Apollo.MutationFunction<
  CreateOneUserGroupMutation,
  CreateOneUserGroupMutationVariables
>;

/**
 * __useCreateOneUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateOneUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserGroupMutation, { data, loading, error }] = useCreateOneUserGroupMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneUserGroupMutation,
    CreateOneUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneUserGroupMutation,
    CreateOneUserGroupMutationVariables
  >(CreateOneUserGroupDocument, options);
}
export type CreateOneUserGroupMutationHookResult = ReturnType<
  typeof useCreateOneUserGroupMutation
>;
export type CreateOneUserGroupMutationResult =
  Apollo.MutationResult<CreateOneUserGroupMutation>;
export type CreateOneUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateOneUserGroupMutation,
  CreateOneUserGroupMutationVariables
>;
export const CreateUserProfileDocument = gql`
  mutation createUserProfile($userId: ID!, $role: String) {
    createUserProfile(userId: $userId, role: $role) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserProfileMutationFn = Apollo.MutationFunction<
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables
>;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
  >(CreateUserProfileDocument, options);
}
export type CreateUserProfileMutationHookResult = ReturnType<
  typeof useCreateUserProfileMutation
>;
export type CreateUserProfileMutationResult =
  Apollo.MutationResult<CreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables
>;
export const DeleteCaseDocument = gql`
  mutation deleteCase($id: ID!) {
    deleteCase(id: $id) {
      success
    }
  }
`;
export type DeleteCaseMutationFn = Apollo.MutationFunction<
  DeleteCaseMutation,
  DeleteCaseMutationVariables
>;

/**
 * __useDeleteCaseMutation__
 *
 * To run a mutation, you first call `useDeleteCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseMutation, { data, loading, error }] = useDeleteCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCaseMutation,
    DeleteCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCaseMutation, DeleteCaseMutationVariables>(
    DeleteCaseDocument,
    options
  );
}
export type DeleteCaseMutationHookResult = ReturnType<
  typeof useDeleteCaseMutation
>;
export type DeleteCaseMutationResult =
  Apollo.MutationResult<DeleteCaseMutation>;
export type DeleteCaseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCaseMutation,
  DeleteCaseMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      success
    }
  }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const DeleteCompanyInvestmentDocument = gql`
  mutation deleteCompanyInvestment($id: ID!) {
    deleteCompanyInvestment(id: $id) {
      success
    }
  }
`;
export type DeleteCompanyInvestmentMutationFn = Apollo.MutationFunction<
  DeleteCompanyInvestmentMutation,
  DeleteCompanyInvestmentMutationVariables
>;

/**
 * __useDeleteCompanyInvestmentMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyInvestmentMutation, { data, loading, error }] = useDeleteCompanyInvestmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyInvestmentMutation,
    DeleteCompanyInvestmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCompanyInvestmentMutation,
    DeleteCompanyInvestmentMutationVariables
  >(DeleteCompanyInvestmentDocument, options);
}
export type DeleteCompanyInvestmentMutationHookResult = ReturnType<
  typeof useDeleteCompanyInvestmentMutation
>;
export type DeleteCompanyInvestmentMutationResult =
  Apollo.MutationResult<DeleteCompanyInvestmentMutation>;
export type DeleteCompanyInvestmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyInvestmentMutation,
  DeleteCompanyInvestmentMutationVariables
>;
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($customerId: ID!) {
    deleteCustomer(customerId: $customerId) {
      success
    }
  }
`;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
  >(DeleteCustomerDocument, options);
}
export type DeleteCustomerMutationHookResult = ReturnType<
  typeof useDeleteCustomerMutation
>;
export type DeleteCustomerMutationResult =
  Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const DeleteEmailIntegrationDocument = gql`
  mutation DeleteEmailIntegration($id: ID!) {
    deleteEmailIntegration(id: $id) {
      success
    }
  }
`;
export type DeleteEmailIntegrationMutationFn = Apollo.MutationFunction<
  DeleteEmailIntegrationMutation,
  DeleteEmailIntegrationMutationVariables
>;

/**
 * __useDeleteEmailIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteEmailIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailIntegrationMutation, { data, loading, error }] = useDeleteEmailIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmailIntegrationMutation,
    DeleteEmailIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmailIntegrationMutation,
    DeleteEmailIntegrationMutationVariables
  >(DeleteEmailIntegrationDocument, options);
}
export type DeleteEmailIntegrationMutationHookResult = ReturnType<
  typeof useDeleteEmailIntegrationMutation
>;
export type DeleteEmailIntegrationMutationResult =
  Apollo.MutationResult<DeleteEmailIntegrationMutation>;
export type DeleteEmailIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailIntegrationMutation,
  DeleteEmailIntegrationMutationVariables
>;
export const DeleteInteractionDocument = gql`
  mutation deleteInteraction($id: ID!) {
    deleteInteraction(id: $id) {
      success
    }
  }
`;
export type DeleteInteractionMutationFn = Apollo.MutationFunction<
  DeleteInteractionMutation,
  DeleteInteractionMutationVariables
>;

/**
 * __useDeleteInteractionMutation__
 *
 * To run a mutation, you first call `useDeleteInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInteractionMutation, { data, loading, error }] = useDeleteInteractionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInteractionMutation,
    DeleteInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInteractionMutation,
    DeleteInteractionMutationVariables
  >(DeleteInteractionDocument, options);
}
export type DeleteInteractionMutationHookResult = ReturnType<
  typeof useDeleteInteractionMutation
>;
export type DeleteInteractionMutationResult =
  Apollo.MutationResult<DeleteInteractionMutation>;
export type DeleteInteractionMutationOptions = Apollo.BaseMutationOptions<
  DeleteInteractionMutation,
  DeleteInteractionMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation deleteNote($id: ID!) {
    deleteNote(id: $id) {
      ...noteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options
  );
}
export type DeleteNoteMutationHookResult = ReturnType<
  typeof useDeleteNoteMutation
>;
export type DeleteNoteMutationResult =
  Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const DeleteOneBusinessDocument = gql`
  mutation deleteOneBusiness($id: ID!) {
    deleteOneBusiness(id: $id) {
      success
    }
  }
`;
export type DeleteOneBusinessMutationFn = Apollo.MutationFunction<
  DeleteOneBusinessMutation,
  DeleteOneBusinessMutationVariables
>;

/**
 * __useDeleteOneBusinessMutation__
 *
 * To run a mutation, you first call `useDeleteOneBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneBusinessMutation, { data, loading, error }] = useDeleteOneBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneBusinessMutation,
    DeleteOneBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneBusinessMutation,
    DeleteOneBusinessMutationVariables
  >(DeleteOneBusinessDocument, options);
}
export type DeleteOneBusinessMutationHookResult = ReturnType<
  typeof useDeleteOneBusinessMutation
>;
export type DeleteOneBusinessMutationResult =
  Apollo.MutationResult<DeleteOneBusinessMutation>;
export type DeleteOneBusinessMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneBusinessMutation,
  DeleteOneBusinessMutationVariables
>;
export const DeleteOneCaseTemplateDocument = gql`
  mutation deleteOneCaseTemplate($id: ID!) {
    deleteOneCaseTemplate(id: $id) {
      success
    }
  }
`;
export type DeleteOneCaseTemplateMutationFn = Apollo.MutationFunction<
  DeleteOneCaseTemplateMutation,
  DeleteOneCaseTemplateMutationVariables
>;

/**
 * __useDeleteOneCaseTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteOneCaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneCaseTemplateMutation, { data, loading, error }] = useDeleteOneCaseTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneCaseTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneCaseTemplateMutation,
    DeleteOneCaseTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneCaseTemplateMutation,
    DeleteOneCaseTemplateMutationVariables
  >(DeleteOneCaseTemplateDocument, options);
}
export type DeleteOneCaseTemplateMutationHookResult = ReturnType<
  typeof useDeleteOneCaseTemplateMutation
>;
export type DeleteOneCaseTemplateMutationResult =
  Apollo.MutationResult<DeleteOneCaseTemplateMutation>;
export type DeleteOneCaseTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneCaseTemplateMutation,
  DeleteOneCaseTemplateMutationVariables
>;
export const DeleteOneContactDocument = gql`
  mutation deleteOneContact($id: ID!) {
    deleteOneContact(id: $id) {
      success
    }
  }
`;
export type DeleteOneContactMutationFn = Apollo.MutationFunction<
  DeleteOneContactMutation,
  DeleteOneContactMutationVariables
>;

/**
 * __useDeleteOneContactMutation__
 *
 * To run a mutation, you first call `useDeleteOneContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContactMutation, { data, loading, error }] = useDeleteOneContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneContactMutation,
    DeleteOneContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneContactMutation,
    DeleteOneContactMutationVariables
  >(DeleteOneContactDocument, options);
}
export type DeleteOneContactMutationHookResult = ReturnType<
  typeof useDeleteOneContactMutation
>;
export type DeleteOneContactMutationResult =
  Apollo.MutationResult<DeleteOneContactMutation>;
export type DeleteOneContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneContactMutation,
  DeleteOneContactMutationVariables
>;
export const DeleteOneDefaultSurveyTemplateDocument = gql`
  mutation deleteOneDefaultSurveyTemplate($id: ID!) {
    deleteOneDefaultSurveyTemplate(id: $id) {
      success
    }
  }
`;
export type DeleteOneDefaultSurveyTemplateMutationFn = Apollo.MutationFunction<
  DeleteOneDefaultSurveyTemplateMutation,
  DeleteOneDefaultSurveyTemplateMutationVariables
>;

/**
 * __useDeleteOneDefaultSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteOneDefaultSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneDefaultSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneDefaultSurveyTemplateMutation, { data, loading, error }] = useDeleteOneDefaultSurveyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneDefaultSurveyTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneDefaultSurveyTemplateMutation,
    DeleteOneDefaultSurveyTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneDefaultSurveyTemplateMutation,
    DeleteOneDefaultSurveyTemplateMutationVariables
  >(DeleteOneDefaultSurveyTemplateDocument, options);
}
export type DeleteOneDefaultSurveyTemplateMutationHookResult = ReturnType<
  typeof useDeleteOneDefaultSurveyTemplateMutation
>;
export type DeleteOneDefaultSurveyTemplateMutationResult =
  Apollo.MutationResult<DeleteOneDefaultSurveyTemplateMutation>;
export type DeleteOneDefaultSurveyTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteOneDefaultSurveyTemplateMutation,
    DeleteOneDefaultSurveyTemplateMutationVariables
  >;
export const DeleteOneListDocument = gql`
  mutation deleteOneList($listId: ID!) {
    deleteOneList(listId: $listId) {
      success
    }
  }
`;
export type DeleteOneListMutationFn = Apollo.MutationFunction<
  DeleteOneListMutation,
  DeleteOneListMutationVariables
>;

/**
 * __useDeleteOneListMutation__
 *
 * To run a mutation, you first call `useDeleteOneListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneListMutation, { data, loading, error }] = useDeleteOneListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useDeleteOneListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneListMutation,
    DeleteOneListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneListMutation,
    DeleteOneListMutationVariables
  >(DeleteOneListDocument, options);
}
export type DeleteOneListMutationHookResult = ReturnType<
  typeof useDeleteOneListMutation
>;
export type DeleteOneListMutationResult =
  Apollo.MutationResult<DeleteOneListMutation>;
export type DeleteOneListMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneListMutation,
  DeleteOneListMutationVariables
>;
export const DeleteOneUserGroupDocument = gql`
  mutation deleteOneUserGroup($id: ID!) {
    deleteOneUserGroup(id: $id) {
      success
    }
  }
`;
export type DeleteOneUserGroupMutationFn = Apollo.MutationFunction<
  DeleteOneUserGroupMutation,
  DeleteOneUserGroupMutationVariables
>;

/**
 * __useDeleteOneUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserGroupMutation, { data, loading, error }] = useDeleteOneUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneUserGroupMutation,
    DeleteOneUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneUserGroupMutation,
    DeleteOneUserGroupMutationVariables
  >(DeleteOneUserGroupDocument, options);
}
export type DeleteOneUserGroupMutationHookResult = ReturnType<
  typeof useDeleteOneUserGroupMutation
>;
export type DeleteOneUserGroupMutationResult =
  Apollo.MutationResult<DeleteOneUserGroupMutation>;
export type DeleteOneUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneUserGroupMutation,
  DeleteOneUserGroupMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation deleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      success
      errors
    }
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
  Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const DeleteResourceDocument = gql`
  mutation deleteResource($id: ID!) {
    deleteResource(id: $id) {
      success
      errors
    }
  }
`;
export type DeleteResourceMutationFn = Apollo.MutationFunction<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResourceMutation,
    DeleteResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteResourceMutation,
    DeleteResourceMutationVariables
  >(DeleteResourceDocument, options);
}
export type DeleteResourceMutationHookResult = ReturnType<
  typeof useDeleteResourceMutation
>;
export type DeleteResourceMutationResult =
  Apollo.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;
export const DeleteSiteDocument = gql`
  mutation deleteSite($id: ID!) {
    deleteSite(id: $id) {
      success
    }
  }
`;
export type DeleteSiteMutationFn = Apollo.MutationFunction<
  DeleteSiteMutation,
  DeleteSiteMutationVariables
>;

/**
 * __useDeleteSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteMutation, { data, loading, error }] = useDeleteSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSiteMutation,
    DeleteSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSiteMutation, DeleteSiteMutationVariables>(
    DeleteSiteDocument,
    options
  );
}
export type DeleteSiteMutationHookResult = ReturnType<
  typeof useDeleteSiteMutation
>;
export type DeleteSiteMutationResult =
  Apollo.MutationResult<DeleteSiteMutation>;
export type DeleteSiteMutationOptions = Apollo.BaseMutationOptions<
  DeleteSiteMutation,
  DeleteSiteMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      success
      errors
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
    DeleteTagDocument,
    options
  );
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const DeleteTeamMemberDocument = gql`
  mutation deleteTeamMember($id: ID!) {
    deleteTeamMember(id: $id) {
      success
      errors
    }
  }
`;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamMemberMutation,
    DeleteTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTeamMemberMutation,
    DeleteTeamMemberMutationVariables
  >(DeleteTeamMemberDocument, options);
}
export type DeleteTeamMemberMutationHookResult = ReturnType<
  typeof useDeleteTeamMemberMutation
>;
export type DeleteTeamMemberMutationResult =
  Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;
export const DeleteTodoDocument = gql`
  mutation deleteTodo($id: ID!) {
    deleteTodo(id: $id) {
      success
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<
  DeleteTodoMutation,
  DeleteTodoMutationVariables
>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTodoMutation,
    DeleteTodoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(
    DeleteTodoDocument,
    options
  );
}
export type DeleteTodoMutationHookResult = ReturnType<
  typeof useDeleteTodoMutation
>;
export type DeleteTodoMutationResult =
  Apollo.MutationResult<DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<
  DeleteTodoMutation,
  DeleteTodoMutationVariables
>;
export const DeleteTodoListDocument = gql`
  mutation deleteTodoList($id: ID!) {
    deleteTodoList(id: $id) {
      id
    }
  }
`;
export type DeleteTodoListMutationFn = Apollo.MutationFunction<
  DeleteTodoListMutation,
  DeleteTodoListMutationVariables
>;

/**
 * __useDeleteTodoListMutation__
 *
 * To run a mutation, you first call `useDeleteTodoListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoListMutation, { data, loading, error }] = useDeleteTodoListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTodoListMutation,
    DeleteTodoListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTodoListMutation,
    DeleteTodoListMutationVariables
  >(DeleteTodoListDocument, options);
}
export type DeleteTodoListMutationHookResult = ReturnType<
  typeof useDeleteTodoListMutation
>;
export type DeleteTodoListMutationResult =
  Apollo.MutationResult<DeleteTodoListMutation>;
export type DeleteTodoListMutationOptions = Apollo.BaseMutationOptions<
  DeleteTodoListMutation,
  DeleteTodoListMutationVariables
>;
export const DeleteHomeDocument = gql`
  mutation deleteHome($id: ID!) {
    deleteUser(id: $id) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type DeleteHomeMutationFn = Apollo.MutationFunction<
  DeleteHomeMutation,
  DeleteHomeMutationVariables
>;

/**
 * __useDeleteHomeMutation__
 *
 * To run a mutation, you first call `useDeleteHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHomeMutation, { data, loading, error }] = useDeleteHomeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHomeMutation,
    DeleteHomeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHomeMutation, DeleteHomeMutationVariables>(
    DeleteHomeDocument,
    options
  );
}
export type DeleteHomeMutationHookResult = ReturnType<
  typeof useDeleteHomeMutation
>;
export type DeleteHomeMutationResult =
  Apollo.MutationResult<DeleteHomeMutation>;
export type DeleteHomeMutationOptions = Apollo.BaseMutationOptions<
  DeleteHomeMutation,
  DeleteHomeMutationVariables
>;
export const EditTeamMemberDocument = gql`
  mutation editTeamMember($id: ID!, $permission: UserPermissionEnum!) {
    editTeamMember(id: $id, permission: $permission) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type EditTeamMemberMutationFn = Apollo.MutationFunction<
  EditTeamMemberMutation,
  EditTeamMemberMutationVariables
>;

/**
 * __useEditTeamMemberMutation__
 *
 * To run a mutation, you first call `useEditTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMemberMutation, { data, loading, error }] = useEditTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useEditTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTeamMemberMutation,
    EditTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditTeamMemberMutation,
    EditTeamMemberMutationVariables
  >(EditTeamMemberDocument, options);
}
export type EditTeamMemberMutationHookResult = ReturnType<
  typeof useEditTeamMemberMutation
>;
export type EditTeamMemberMutationResult =
  Apollo.MutationResult<EditTeamMemberMutation>;
export type EditTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  EditTeamMemberMutation,
  EditTeamMemberMutationVariables
>;
export const GetPresignedUploadMultiPartDocument = gql`
  mutation getPresignedUploadMultiPart(
    $keyPath: String
    $contentType: String!
    $parts: Int!
  ) {
    getPresignedUploadMultiPart(
      keyPath: $keyPath
      contentType: $contentType
      parts: $parts
    ) {
      key
      uploadId
      urls
    }
  }
`;
export type GetPresignedUploadMultiPartMutationFn = Apollo.MutationFunction<
  GetPresignedUploadMultiPartMutation,
  GetPresignedUploadMultiPartMutationVariables
>;

/**
 * __useGetPresignedUploadMultiPartMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadMultiPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadMultiPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadMultiPartMutation, { data, loading, error }] = useGetPresignedUploadMultiPartMutation({
 *   variables: {
 *      keyPath: // value for 'keyPath'
 *      contentType: // value for 'contentType'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useGetPresignedUploadMultiPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >(GetPresignedUploadMultiPartDocument, options);
}
export type GetPresignedUploadMultiPartMutationHookResult = ReturnType<
  typeof useGetPresignedUploadMultiPartMutation
>;
export type GetPresignedUploadMultiPartMutationResult =
  Apollo.MutationResult<GetPresignedUploadMultiPartMutation>;
export type GetPresignedUploadMultiPartMutationOptions =
  Apollo.BaseMutationOptions<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >;
export const GetPresignedUploadUrlDocument = gql`
  mutation getPresignedUploadUrl($contentType: String!) {
    getPresignedUploadUrl(contentType: $contentType) {
      url
      fields
    }
  }
`;
export type GetPresignedUploadUrlMutationFn = Apollo.MutationFunction<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>;

/**
 * __useGetPresignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadUrlMutation, { data, loading, error }] = useGetPresignedUploadUrlMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetPresignedUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPresignedUploadUrlMutation,
    GetPresignedUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetPresignedUploadUrlMutation,
    GetPresignedUploadUrlMutationVariables
  >(GetPresignedUploadUrlDocument, options);
}
export type GetPresignedUploadUrlMutationHookResult = ReturnType<
  typeof useGetPresignedUploadUrlMutation
>;
export type GetPresignedUploadUrlMutationResult =
  Apollo.MutationResult<GetPresignedUploadUrlMutation>;
export type GetPresignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>;
export const IgnoreDataDiscoveryBusinessDocument = gql`
  mutation ignoreDataDiscoveryBusiness($businessId: ID!) {
    ignoreDataDiscoveryBusiness(businessId: $businessId) {
      ...dataDiscoveryBusinessFragment
    }
  }
  ${DataDiscoveryBusinessFragmentFragmentDoc}
`;
export type IgnoreDataDiscoveryBusinessMutationFn = Apollo.MutationFunction<
  IgnoreDataDiscoveryBusinessMutation,
  IgnoreDataDiscoveryBusinessMutationVariables
>;

/**
 * __useIgnoreDataDiscoveryBusinessMutation__
 *
 * To run a mutation, you first call `useIgnoreDataDiscoveryBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreDataDiscoveryBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreDataDiscoveryBusinessMutation, { data, loading, error }] = useIgnoreDataDiscoveryBusinessMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useIgnoreDataDiscoveryBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnoreDataDiscoveryBusinessMutation,
    IgnoreDataDiscoveryBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IgnoreDataDiscoveryBusinessMutation,
    IgnoreDataDiscoveryBusinessMutationVariables
  >(IgnoreDataDiscoveryBusinessDocument, options);
}
export type IgnoreDataDiscoveryBusinessMutationHookResult = ReturnType<
  typeof useIgnoreDataDiscoveryBusinessMutation
>;
export type IgnoreDataDiscoveryBusinessMutationResult =
  Apollo.MutationResult<IgnoreDataDiscoveryBusinessMutation>;
export type IgnoreDataDiscoveryBusinessMutationOptions =
  Apollo.BaseMutationOptions<
    IgnoreDataDiscoveryBusinessMutation,
    IgnoreDataDiscoveryBusinessMutationVariables
  >;
export const IgnoreOneDataDiscoveryContactDocument = gql`
  mutation ignoreOneDataDiscoveryContact(
    $discoveryBusinessId: ID!
    $discoveryContactEmail: String!
  ) {
    ignoreOneDataDiscoveryContact(
      discoveryBusinessId: $discoveryBusinessId
      discoveryContactEmail: $discoveryContactEmail
    ) {
      ...dataDiscoveryBusinessFragment
    }
  }
  ${DataDiscoveryBusinessFragmentFragmentDoc}
`;
export type IgnoreOneDataDiscoveryContactMutationFn = Apollo.MutationFunction<
  IgnoreOneDataDiscoveryContactMutation,
  IgnoreOneDataDiscoveryContactMutationVariables
>;

/**
 * __useIgnoreOneDataDiscoveryContactMutation__
 *
 * To run a mutation, you first call `useIgnoreOneDataDiscoveryContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreOneDataDiscoveryContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreOneDataDiscoveryContactMutation, { data, loading, error }] = useIgnoreOneDataDiscoveryContactMutation({
 *   variables: {
 *      discoveryBusinessId: // value for 'discoveryBusinessId'
 *      discoveryContactEmail: // value for 'discoveryContactEmail'
 *   },
 * });
 */
export function useIgnoreOneDataDiscoveryContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnoreOneDataDiscoveryContactMutation,
    IgnoreOneDataDiscoveryContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IgnoreOneDataDiscoveryContactMutation,
    IgnoreOneDataDiscoveryContactMutationVariables
  >(IgnoreOneDataDiscoveryContactDocument, options);
}
export type IgnoreOneDataDiscoveryContactMutationHookResult = ReturnType<
  typeof useIgnoreOneDataDiscoveryContactMutation
>;
export type IgnoreOneDataDiscoveryContactMutationResult =
  Apollo.MutationResult<IgnoreOneDataDiscoveryContactMutation>;
export type IgnoreOneDataDiscoveryContactMutationOptions =
  Apollo.BaseMutationOptions<
    IgnoreOneDataDiscoveryContactMutation,
    IgnoreOneDataDiscoveryContactMutationVariables
  >;
export const ImpersonateCustomerDocument = gql`
  mutation impersonateCustomer($customerId: String!, $turnOff: Boolean) {
    impersonateCustomer(customerId: $customerId, turnOff: $turnOff) {
      success
      errors
    }
  }
`;
export type ImpersonateCustomerMutationFn = Apollo.MutationFunction<
  ImpersonateCustomerMutation,
  ImpersonateCustomerMutationVariables
>;

/**
 * __useImpersonateCustomerMutation__
 *
 * To run a mutation, you first call `useImpersonateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateCustomerMutation, { data, loading, error }] = useImpersonateCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      turnOff: // value for 'turnOff'
 *   },
 * });
 */
export function useImpersonateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImpersonateCustomerMutation,
    ImpersonateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImpersonateCustomerMutation,
    ImpersonateCustomerMutationVariables
  >(ImpersonateCustomerDocument, options);
}
export type ImpersonateCustomerMutationHookResult = ReturnType<
  typeof useImpersonateCustomerMutation
>;
export type ImpersonateCustomerMutationResult =
  Apollo.MutationResult<ImpersonateCustomerMutation>;
export type ImpersonateCustomerMutationOptions = Apollo.BaseMutationOptions<
  ImpersonateCustomerMutation,
  ImpersonateCustomerMutationVariables
>;
export const Integrate_ConstantContactDocument = gql`
  mutation integrate_ConstantContact($code: String!) {
    integrate_ConstantContact(code: $code) {
      success
      errors
    }
  }
`;
export type Integrate_ConstantContactMutationFn = Apollo.MutationFunction<
  Integrate_ConstantContactMutation,
  Integrate_ConstantContactMutationVariables
>;

/**
 * __useIntegrate_ConstantContactMutation__
 *
 * To run a mutation, you first call `useIntegrate_ConstantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrate_ConstantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrateConstantContactMutation, { data, loading, error }] = useIntegrate_ConstantContactMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useIntegrate_ConstantContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Integrate_ConstantContactMutation,
    Integrate_ConstantContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Integrate_ConstantContactMutation,
    Integrate_ConstantContactMutationVariables
  >(Integrate_ConstantContactDocument, options);
}
export type Integrate_ConstantContactMutationHookResult = ReturnType<
  typeof useIntegrate_ConstantContactMutation
>;
export type Integrate_ConstantContactMutationResult =
  Apollo.MutationResult<Integrate_ConstantContactMutation>;
export type Integrate_ConstantContactMutationOptions =
  Apollo.BaseMutationOptions<
    Integrate_ConstantContactMutation,
    Integrate_ConstantContactMutationVariables
  >;
export const Integrate_DisconnectIntegrationDocument = gql`
  mutation integrate_disconnectIntegration($service: IntegrationServiceEnum!) {
    integrate_disconnectIntegration(service: $service) {
      success
      errors
    }
  }
`;
export type Integrate_DisconnectIntegrationMutationFn = Apollo.MutationFunction<
  Integrate_DisconnectIntegrationMutation,
  Integrate_DisconnectIntegrationMutationVariables
>;

/**
 * __useIntegrate_DisconnectIntegrationMutation__
 *
 * To run a mutation, you first call `useIntegrate_DisconnectIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrate_DisconnectIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrateDisconnectIntegrationMutation, { data, loading, error }] = useIntegrate_DisconnectIntegrationMutation({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useIntegrate_DisconnectIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Integrate_DisconnectIntegrationMutation,
    Integrate_DisconnectIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Integrate_DisconnectIntegrationMutation,
    Integrate_DisconnectIntegrationMutationVariables
  >(Integrate_DisconnectIntegrationDocument, options);
}
export type Integrate_DisconnectIntegrationMutationHookResult = ReturnType<
  typeof useIntegrate_DisconnectIntegrationMutation
>;
export type Integrate_DisconnectIntegrationMutationResult =
  Apollo.MutationResult<Integrate_DisconnectIntegrationMutation>;
export type Integrate_DisconnectIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    Integrate_DisconnectIntegrationMutation,
    Integrate_DisconnectIntegrationMutationVariables
  >;
export const InviteUserDocument = gql`
  mutation inviteUser(
    $customerId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $permission: UserPermissionEnum!
  ) {
    inviteUser(
      customerId: $customerId
      firstName: $firstName
      lastName: $lastName
      email: $email
      permission: $permission
    ) {
      id
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const MarkNotificationAsReadDocument = gql`
  mutation markNotificationAsRead($notifId: ID, $markAllAsRead: Boolean) {
    markNotificationAsRead(notifId: $notifId, markAllAsRead: $markAllAsRead) {
      ...notificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      notifId: // value for 'notifId'
 *      markAllAsRead: // value for 'markAllAsRead'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MarkNotificationAsReadDocument, options);
}
export type MarkNotificationAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationAsReadMutation
>;
export type MarkNotificationAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;
export const MergeDiscoveredBusinessesDocument = gql`
  mutation mergeDiscoveredBusinesses($runId: ID!) {
    mergeDiscoveredBusinesses(runId: $runId) {
      ...dataDiscoveryRunFragment
    }
  }
  ${DataDiscoveryRunFragmentFragmentDoc}
`;
export type MergeDiscoveredBusinessesMutationFn = Apollo.MutationFunction<
  MergeDiscoveredBusinessesMutation,
  MergeDiscoveredBusinessesMutationVariables
>;

/**
 * __useMergeDiscoveredBusinessesMutation__
 *
 * To run a mutation, you first call `useMergeDiscoveredBusinessesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeDiscoveredBusinessesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeDiscoveredBusinessesMutation, { data, loading, error }] = useMergeDiscoveredBusinessesMutation({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useMergeDiscoveredBusinessesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeDiscoveredBusinessesMutation,
    MergeDiscoveredBusinessesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergeDiscoveredBusinessesMutation,
    MergeDiscoveredBusinessesMutationVariables
  >(MergeDiscoveredBusinessesDocument, options);
}
export type MergeDiscoveredBusinessesMutationHookResult = ReturnType<
  typeof useMergeDiscoveredBusinessesMutation
>;
export type MergeDiscoveredBusinessesMutationResult =
  Apollo.MutationResult<MergeDiscoveredBusinessesMutation>;
export type MergeDiscoveredBusinessesMutationOptions =
  Apollo.BaseMutationOptions<
    MergeDiscoveredBusinessesMutation,
    MergeDiscoveredBusinessesMutationVariables
  >;
export const ReActivateUserDocument = gql`
  mutation reActivateUser($userId: ID!) {
    reActivateUser(userId: $userId) {
      success
    }
  }
`;
export type ReActivateUserMutationFn = Apollo.MutationFunction<
  ReActivateUserMutation,
  ReActivateUserMutationVariables
>;

/**
 * __useReActivateUserMutation__
 *
 * To run a mutation, you first call `useReActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reActivateUserMutation, { data, loading, error }] = useReActivateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReActivateUserMutation,
    ReActivateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReActivateUserMutation,
    ReActivateUserMutationVariables
  >(ReActivateUserDocument, options);
}
export type ReActivateUserMutationHookResult = ReturnType<
  typeof useReActivateUserMutation
>;
export type ReActivateUserMutationResult =
  Apollo.MutationResult<ReActivateUserMutation>;
export type ReActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ReActivateUserMutation,
  ReActivateUserMutationVariables
>;
export const RemoveEntityFromBusinessDocument = gql`
  mutation removeEntityFromBusiness(
    $businessId: String!
    $entityId: String!
    $entityType: EntityTypeEnum
  ) {
    removeEntityFromBusiness(
      businessId: $businessId
      entityId: $entityId
      entityType: $entityType
    ) {
      success
    }
  }
`;
export type RemoveEntityFromBusinessMutationFn = Apollo.MutationFunction<
  RemoveEntityFromBusinessMutation,
  RemoveEntityFromBusinessMutationVariables
>;

/**
 * __useRemoveEntityFromBusinessMutation__
 *
 * To run a mutation, you first call `useRemoveEntityFromBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEntityFromBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEntityFromBusinessMutation, { data, loading, error }] = useRemoveEntityFromBusinessMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useRemoveEntityFromBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEntityFromBusinessMutation,
    RemoveEntityFromBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEntityFromBusinessMutation,
    RemoveEntityFromBusinessMutationVariables
  >(RemoveEntityFromBusinessDocument, options);
}
export type RemoveEntityFromBusinessMutationHookResult = ReturnType<
  typeof useRemoveEntityFromBusinessMutation
>;
export type RemoveEntityFromBusinessMutationResult =
  Apollo.MutationResult<RemoveEntityFromBusinessMutation>;
export type RemoveEntityFromBusinessMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveEntityFromBusinessMutation,
    RemoveEntityFromBusinessMutationVariables
  >;
export const RemoveEntityFromProjectDocument = gql`
  mutation removeEntityFromProject(
    $projectId: String!
    $entityId: String!
    $entityType: EntityTypeEnum
  ) {
    removeEntityFromProject(
      projectId: $projectId
      entityId: $entityId
      entityType: $entityType
    ) {
      id
    }
  }
`;
export type RemoveEntityFromProjectMutationFn = Apollo.MutationFunction<
  RemoveEntityFromProjectMutation,
  RemoveEntityFromProjectMutationVariables
>;

/**
 * __useRemoveEntityFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveEntityFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEntityFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEntityFromProjectMutation, { data, loading, error }] = useRemoveEntityFromProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useRemoveEntityFromProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEntityFromProjectMutation,
    RemoveEntityFromProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEntityFromProjectMutation,
    RemoveEntityFromProjectMutationVariables
  >(RemoveEntityFromProjectDocument, options);
}
export type RemoveEntityFromProjectMutationHookResult = ReturnType<
  typeof useRemoveEntityFromProjectMutation
>;
export type RemoveEntityFromProjectMutationResult =
  Apollo.MutationResult<RemoveEntityFromProjectMutation>;
export type RemoveEntityFromProjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveEntityFromProjectMutation,
  RemoveEntityFromProjectMutationVariables
>;
export const RemoveManyItemsFromListDocument = gql`
  mutation removeManyItemsFromList(
    $listId: ID!
    $addFromContactsQuery: ContactSearchParams
    $addFromBusinessesQuery: BusinessSearchParams
    $itemIds: [ID!]
  ) {
    removeManyItemsFromList(
      listId: $listId
      itemIds: $itemIds
      addFromBusinessesQuery: $addFromBusinessesQuery
      addFromContactsQuery: $addFromContactsQuery
    ) {
      ...listFragment
    }
  }
  ${ListFragmentFragmentDoc}
`;
export type RemoveManyItemsFromListMutationFn = Apollo.MutationFunction<
  RemoveManyItemsFromListMutation,
  RemoveManyItemsFromListMutationVariables
>;

/**
 * __useRemoveManyItemsFromListMutation__
 *
 * To run a mutation, you first call `useRemoveManyItemsFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManyItemsFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManyItemsFromListMutation, { data, loading, error }] = useRemoveManyItemsFromListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      addFromContactsQuery: // value for 'addFromContactsQuery'
 *      addFromBusinessesQuery: // value for 'addFromBusinessesQuery'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useRemoveManyItemsFromListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveManyItemsFromListMutation,
    RemoveManyItemsFromListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveManyItemsFromListMutation,
    RemoveManyItemsFromListMutationVariables
  >(RemoveManyItemsFromListDocument, options);
}
export type RemoveManyItemsFromListMutationHookResult = ReturnType<
  typeof useRemoveManyItemsFromListMutation
>;
export type RemoveManyItemsFromListMutationResult =
  Apollo.MutationResult<RemoveManyItemsFromListMutation>;
export type RemoveManyItemsFromListMutationOptions = Apollo.BaseMutationOptions<
  RemoveManyItemsFromListMutation,
  RemoveManyItemsFromListMutationVariables
>;
export const RemoveOneFromSurveyCampaignDocument = gql`
  mutation removeOneFromSurveyCampaign($surveyCampaignItemId: ID!) {
    removeOneFromSurveyCampaign(surveyCampaignItemId: $surveyCampaignItemId) {
      success
    }
  }
`;
export type RemoveOneFromSurveyCampaignMutationFn = Apollo.MutationFunction<
  RemoveOneFromSurveyCampaignMutation,
  RemoveOneFromSurveyCampaignMutationVariables
>;

/**
 * __useRemoveOneFromSurveyCampaignMutation__
 *
 * To run a mutation, you first call `useRemoveOneFromSurveyCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneFromSurveyCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOneFromSurveyCampaignMutation, { data, loading, error }] = useRemoveOneFromSurveyCampaignMutation({
 *   variables: {
 *      surveyCampaignItemId: // value for 'surveyCampaignItemId'
 *   },
 * });
 */
export function useRemoveOneFromSurveyCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOneFromSurveyCampaignMutation,
    RemoveOneFromSurveyCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOneFromSurveyCampaignMutation,
    RemoveOneFromSurveyCampaignMutationVariables
  >(RemoveOneFromSurveyCampaignDocument, options);
}
export type RemoveOneFromSurveyCampaignMutationHookResult = ReturnType<
  typeof useRemoveOneFromSurveyCampaignMutation
>;
export type RemoveOneFromSurveyCampaignMutationResult =
  Apollo.MutationResult<RemoveOneFromSurveyCampaignMutation>;
export type RemoveOneFromSurveyCampaignMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveOneFromSurveyCampaignMutation,
    RemoveOneFromSurveyCampaignMutationVariables
  >;
export const RemoveUsersFromUserGroupDocument = gql`
  mutation removeUsersFromUserGroup($userIds: [ID!]!, $userGroupId: ID!) {
    removeUsersFromUserGroup(userIds: $userIds, userGroupId: $userGroupId) {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;
export type RemoveUsersFromUserGroupMutationFn = Apollo.MutationFunction<
  RemoveUsersFromUserGroupMutation,
  RemoveUsersFromUserGroupMutationVariables
>;

/**
 * __useRemoveUsersFromUserGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromUserGroupMutation, { data, loading, error }] = useRemoveUsersFromUserGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useRemoveUsersFromUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUsersFromUserGroupMutation,
    RemoveUsersFromUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUsersFromUserGroupMutation,
    RemoveUsersFromUserGroupMutationVariables
  >(RemoveUsersFromUserGroupDocument, options);
}
export type RemoveUsersFromUserGroupMutationHookResult = ReturnType<
  typeof useRemoveUsersFromUserGroupMutation
>;
export type RemoveUsersFromUserGroupMutationResult =
  Apollo.MutationResult<RemoveUsersFromUserGroupMutation>;
export type RemoveUsersFromUserGroupMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveUsersFromUserGroupMutation,
    RemoveUsersFromUserGroupMutationVariables
  >;
export const RescheduleManyEmailCampaignItemsDocument = gql`
  mutation rescheduleManyEmailCampaignItems(
    $emailCampaignId: ID!
    $scheduledAt: Float!
  ) {
    rescheduleManyEmailCampaignItems(
      emailCampaignId: $emailCampaignId
      scheduledAt: $scheduledAt
    ) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;
export type RescheduleManyEmailCampaignItemsMutationFn =
  Apollo.MutationFunction<
    RescheduleManyEmailCampaignItemsMutation,
    RescheduleManyEmailCampaignItemsMutationVariables
  >;

/**
 * __useRescheduleManyEmailCampaignItemsMutation__
 *
 * To run a mutation, you first call `useRescheduleManyEmailCampaignItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleManyEmailCampaignItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleManyEmailCampaignItemsMutation, { data, loading, error }] = useRescheduleManyEmailCampaignItemsMutation({
 *   variables: {
 *      emailCampaignId: // value for 'emailCampaignId'
 *      scheduledAt: // value for 'scheduledAt'
 *   },
 * });
 */
export function useRescheduleManyEmailCampaignItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleManyEmailCampaignItemsMutation,
    RescheduleManyEmailCampaignItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleManyEmailCampaignItemsMutation,
    RescheduleManyEmailCampaignItemsMutationVariables
  >(RescheduleManyEmailCampaignItemsDocument, options);
}
export type RescheduleManyEmailCampaignItemsMutationHookResult = ReturnType<
  typeof useRescheduleManyEmailCampaignItemsMutation
>;
export type RescheduleManyEmailCampaignItemsMutationResult =
  Apollo.MutationResult<RescheduleManyEmailCampaignItemsMutation>;
export type RescheduleManyEmailCampaignItemsMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleManyEmailCampaignItemsMutation,
    RescheduleManyEmailCampaignItemsMutationVariables
  >;
export const RescheduleOneEmailCampaignItemDocument = gql`
  mutation rescheduleOneEmailCampaignItem(
    $emailCampaignItemId: ID!
    $scheduledAt: Float!
  ) {
    rescheduleOneEmailCampaignItem(
      emailCampaignItemId: $emailCampaignItemId
      scheduledAt: $scheduledAt
    ) {
      ...emailCampaignItemFragment
    }
  }
  ${EmailCampaignItemFragmentFragmentDoc}
`;
export type RescheduleOneEmailCampaignItemMutationFn = Apollo.MutationFunction<
  RescheduleOneEmailCampaignItemMutation,
  RescheduleOneEmailCampaignItemMutationVariables
>;

/**
 * __useRescheduleOneEmailCampaignItemMutation__
 *
 * To run a mutation, you first call `useRescheduleOneEmailCampaignItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleOneEmailCampaignItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleOneEmailCampaignItemMutation, { data, loading, error }] = useRescheduleOneEmailCampaignItemMutation({
 *   variables: {
 *      emailCampaignItemId: // value for 'emailCampaignItemId'
 *      scheduledAt: // value for 'scheduledAt'
 *   },
 * });
 */
export function useRescheduleOneEmailCampaignItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleOneEmailCampaignItemMutation,
    RescheduleOneEmailCampaignItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleOneEmailCampaignItemMutation,
    RescheduleOneEmailCampaignItemMutationVariables
  >(RescheduleOneEmailCampaignItemDocument, options);
}
export type RescheduleOneEmailCampaignItemMutationHookResult = ReturnType<
  typeof useRescheduleOneEmailCampaignItemMutation
>;
export type RescheduleOneEmailCampaignItemMutationResult =
  Apollo.MutationResult<RescheduleOneEmailCampaignItemMutation>;
export type RescheduleOneEmailCampaignItemMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleOneEmailCampaignItemMutation,
    RescheduleOneEmailCampaignItemMutationVariables
  >;
export const ResendTeamInviteDocument = gql`
  mutation resendTeamInvite($userId: ID!) {
    resendTeamInvite(userId: $userId) {
      success
    }
  }
`;
export type ResendTeamInviteMutationFn = Apollo.MutationFunction<
  ResendTeamInviteMutation,
  ResendTeamInviteMutationVariables
>;

/**
 * __useResendTeamInviteMutation__
 *
 * To run a mutation, you first call `useResendTeamInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTeamInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTeamInviteMutation, { data, loading, error }] = useResendTeamInviteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendTeamInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendTeamInviteMutation,
    ResendTeamInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendTeamInviteMutation,
    ResendTeamInviteMutationVariables
  >(ResendTeamInviteDocument, options);
}
export type ResendTeamInviteMutationHookResult = ReturnType<
  typeof useResendTeamInviteMutation
>;
export type ResendTeamInviteMutationResult =
  Apollo.MutationResult<ResendTeamInviteMutation>;
export type ResendTeamInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendTeamInviteMutation,
  ResendTeamInviteMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SaveAttachmentDocument = gql`
  mutation saveAttachment($params: AttachmentParams) {
    saveAttachment(params: $params) {
      id
    }
  }
`;
export type SaveAttachmentMutationFn = Apollo.MutationFunction<
  SaveAttachmentMutation,
  SaveAttachmentMutationVariables
>;

/**
 * __useSaveAttachmentMutation__
 *
 * To run a mutation, you first call `useSaveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAttachmentMutation, { data, loading, error }] = useSaveAttachmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAttachmentMutation,
    SaveAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAttachmentMutation,
    SaveAttachmentMutationVariables
  >(SaveAttachmentDocument, options);
}
export type SaveAttachmentMutationHookResult = ReturnType<
  typeof useSaveAttachmentMutation
>;
export type SaveAttachmentMutationResult =
  Apollo.MutationResult<SaveAttachmentMutation>;
export type SaveAttachmentMutationOptions = Apollo.BaseMutationOptions<
  SaveAttachmentMutation,
  SaveAttachmentMutationVariables
>;
export const SaveBusinessDocument = gql`
  mutation saveBusiness($id: ID, $params: BusinessParams) {
    saveBusiness(id: $id, params: $params) {
      ...businessFragment
    }
  }
  ${BusinessFragmentFragmentDoc}
`;
export type SaveBusinessMutationFn = Apollo.MutationFunction<
  SaveBusinessMutation,
  SaveBusinessMutationVariables
>;

/**
 * __useSaveBusinessMutation__
 *
 * To run a mutation, you first call `useSaveBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBusinessMutation, { data, loading, error }] = useSaveBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveBusinessMutation,
    SaveBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveBusinessMutation,
    SaveBusinessMutationVariables
  >(SaveBusinessDocument, options);
}
export type SaveBusinessMutationHookResult = ReturnType<
  typeof useSaveBusinessMutation
>;
export type SaveBusinessMutationResult =
  Apollo.MutationResult<SaveBusinessMutation>;
export type SaveBusinessMutationOptions = Apollo.BaseMutationOptions<
  SaveBusinessMutation,
  SaveBusinessMutationVariables
>;
export const SaveCaseDocument = gql`
  mutation saveCase($id: ID, $params: CaseInput) {
    saveCase(id: $id, params: $params) {
      ...caseFragment
    }
  }
  ${CaseFragmentFragmentDoc}
`;
export type SaveCaseMutationFn = Apollo.MutationFunction<
  SaveCaseMutation,
  SaveCaseMutationVariables
>;

/**
 * __useSaveCaseMutation__
 *
 * To run a mutation, you first call `useSaveCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCaseMutation, { data, loading, error }] = useSaveCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCaseMutation,
    SaveCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveCaseMutation, SaveCaseMutationVariables>(
    SaveCaseDocument,
    options
  );
}
export type SaveCaseMutationHookResult = ReturnType<typeof useSaveCaseMutation>;
export type SaveCaseMutationResult = Apollo.MutationResult<SaveCaseMutation>;
export type SaveCaseMutationOptions = Apollo.BaseMutationOptions<
  SaveCaseMutation,
  SaveCaseMutationVariables
>;
export const SaveCaseCategoryDocument = gql`
  mutation saveCaseCategory($id: ID, $params: CaseCategoryInput) {
    saveCaseCategory(id: $id, params: $params) {
      id
      title
      description
    }
  }
`;
export type SaveCaseCategoryMutationFn = Apollo.MutationFunction<
  SaveCaseCategoryMutation,
  SaveCaseCategoryMutationVariables
>;

/**
 * __useSaveCaseCategoryMutation__
 *
 * To run a mutation, you first call `useSaveCaseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCaseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCaseCategoryMutation, { data, loading, error }] = useSaveCaseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCaseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCaseCategoryMutation,
    SaveCaseCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCaseCategoryMutation,
    SaveCaseCategoryMutationVariables
  >(SaveCaseCategoryDocument, options);
}
export type SaveCaseCategoryMutationHookResult = ReturnType<
  typeof useSaveCaseCategoryMutation
>;
export type SaveCaseCategoryMutationResult =
  Apollo.MutationResult<SaveCaseCategoryMutation>;
export type SaveCaseCategoryMutationOptions = Apollo.BaseMutationOptions<
  SaveCaseCategoryMutation,
  SaveCaseCategoryMutationVariables
>;
export const SaveCaseServiceDocument = gql`
  mutation saveCaseService($id: ID, $params: CaseServiceInput) {
    saveCaseService(id: $id, params: $params) {
      id
      title
      description
    }
  }
`;
export type SaveCaseServiceMutationFn = Apollo.MutationFunction<
  SaveCaseServiceMutation,
  SaveCaseServiceMutationVariables
>;

/**
 * __useSaveCaseServiceMutation__
 *
 * To run a mutation, you first call `useSaveCaseServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCaseServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCaseServiceMutation, { data, loading, error }] = useSaveCaseServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCaseServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCaseServiceMutation,
    SaveCaseServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCaseServiceMutation,
    SaveCaseServiceMutationVariables
  >(SaveCaseServiceDocument, options);
}
export type SaveCaseServiceMutationHookResult = ReturnType<
  typeof useSaveCaseServiceMutation
>;
export type SaveCaseServiceMutationResult =
  Apollo.MutationResult<SaveCaseServiceMutation>;
export type SaveCaseServiceMutationOptions = Apollo.BaseMutationOptions<
  SaveCaseServiceMutation,
  SaveCaseServiceMutationVariables
>;
export const SaveCaseStatusDocument = gql`
  mutation saveCaseStatus($id: ID, $params: CaseStatusInput) {
    saveCaseStatus(id: $id, params: $params) {
      id
      title
      description
    }
  }
`;
export type SaveCaseStatusMutationFn = Apollo.MutationFunction<
  SaveCaseStatusMutation,
  SaveCaseStatusMutationVariables
>;

/**
 * __useSaveCaseStatusMutation__
 *
 * To run a mutation, you first call `useSaveCaseStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCaseStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCaseStatusMutation, { data, loading, error }] = useSaveCaseStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCaseStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCaseStatusMutation,
    SaveCaseStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCaseStatusMutation,
    SaveCaseStatusMutationVariables
  >(SaveCaseStatusDocument, options);
}
export type SaveCaseStatusMutationHookResult = ReturnType<
  typeof useSaveCaseStatusMutation
>;
export type SaveCaseStatusMutationResult =
  Apollo.MutationResult<SaveCaseStatusMutation>;
export type SaveCaseStatusMutationOptions = Apollo.BaseMutationOptions<
  SaveCaseStatusMutation,
  SaveCaseStatusMutationVariables
>;
export const SaveCommentDocument = gql`
  mutation saveComment($id: ID, $params: CommentParams) {
    saveComment(id: $id, params: $params) {
      ...commentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;
export type SaveCommentMutationFn = Apollo.MutationFunction<
  SaveCommentMutation,
  SaveCommentMutationVariables
>;

/**
 * __useSaveCommentMutation__
 *
 * To run a mutation, you first call `useSaveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCommentMutation, { data, loading, error }] = useSaveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCommentMutation,
    SaveCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveCommentMutation, SaveCommentMutationVariables>(
    SaveCommentDocument,
    options
  );
}
export type SaveCommentMutationHookResult = ReturnType<
  typeof useSaveCommentMutation
>;
export type SaveCommentMutationResult =
  Apollo.MutationResult<SaveCommentMutation>;
export type SaveCommentMutationOptions = Apollo.BaseMutationOptions<
  SaveCommentMutation,
  SaveCommentMutationVariables
>;
export const SaveContactDocument = gql`
  mutation saveContact($id: ID, $params: ContactInput) {
    saveContact(id: $id, params: $params) {
      ...contactFragment
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type SaveContactMutationFn = Apollo.MutationFunction<
  SaveContactMutation,
  SaveContactMutationVariables
>;

/**
 * __useSaveContactMutation__
 *
 * To run a mutation, you first call `useSaveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContactMutation, { data, loading, error }] = useSaveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveContactMutation,
    SaveContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveContactMutation, SaveContactMutationVariables>(
    SaveContactDocument,
    options
  );
}
export type SaveContactMutationHookResult = ReturnType<
  typeof useSaveContactMutation
>;
export type SaveContactMutationResult =
  Apollo.MutationResult<SaveContactMutation>;
export type SaveContactMutationOptions = Apollo.BaseMutationOptions<
  SaveContactMutation,
  SaveContactMutationVariables
>;
export const SaveCustomerDocument = gql`
  mutation saveCustomer($id: ID!, $params: CustomerInput) {
    saveCustomer(id: $id, params: $params) {
      id
      title
      users {
        id
        firstName
        email
        lastName
      }
      features {
        title
        key
        active
        available
      }
    }
  }
`;
export type SaveCustomerMutationFn = Apollo.MutationFunction<
  SaveCustomerMutation,
  SaveCustomerMutationVariables
>;

/**
 * __useSaveCustomerMutation__
 *
 * To run a mutation, you first call `useSaveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerMutation, { data, loading, error }] = useSaveCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCustomerMutation,
    SaveCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCustomerMutation,
    SaveCustomerMutationVariables
  >(SaveCustomerDocument, options);
}
export type SaveCustomerMutationHookResult = ReturnType<
  typeof useSaveCustomerMutation
>;
export type SaveCustomerMutationResult =
  Apollo.MutationResult<SaveCustomerMutation>;
export type SaveCustomerMutationOptions = Apollo.BaseMutationOptions<
  SaveCustomerMutation,
  SaveCustomerMutationVariables
>;
export const SaveEventDocument = gql`
  mutation saveEvent($id: ID, $params: EventParams) {
    saveEvent(id: $id, params: $params) {
      ...eventFragment
    }
  }
  ${EventFragmentFragmentDoc}
`;
export type SaveEventMutationFn = Apollo.MutationFunction<
  SaveEventMutation,
  SaveEventMutationVariables
>;

/**
 * __useSaveEventMutation__
 *
 * To run a mutation, you first call `useSaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEventMutation, { data, loading, error }] = useSaveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEventMutation,
    SaveEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveEventMutation, SaveEventMutationVariables>(
    SaveEventDocument,
    options
  );
}
export type SaveEventMutationHookResult = ReturnType<
  typeof useSaveEventMutation
>;
export type SaveEventMutationResult = Apollo.MutationResult<SaveEventMutation>;
export type SaveEventMutationOptions = Apollo.BaseMutationOptions<
  SaveEventMutation,
  SaveEventMutationVariables
>;
export const SaveFundingProgramDocument = gql`
  mutation saveFundingProgram($id: ID, $params: ResourceParams) {
    saveFundingProgram(id: $id, params: $params) {
      ...resourceFragment
    }
  }
  ${ResourceFragmentFragmentDoc}
`;
export type SaveFundingProgramMutationFn = Apollo.MutationFunction<
  SaveFundingProgramMutation,
  SaveFundingProgramMutationVariables
>;

/**
 * __useSaveFundingProgramMutation__
 *
 * To run a mutation, you first call `useSaveFundingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFundingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFundingProgramMutation, { data, loading, error }] = useSaveFundingProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveFundingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveFundingProgramMutation,
    SaveFundingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveFundingProgramMutation,
    SaveFundingProgramMutationVariables
  >(SaveFundingProgramDocument, options);
}
export type SaveFundingProgramMutationHookResult = ReturnType<
  typeof useSaveFundingProgramMutation
>;
export type SaveFundingProgramMutationResult =
  Apollo.MutationResult<SaveFundingProgramMutation>;
export type SaveFundingProgramMutationOptions = Apollo.BaseMutationOptions<
  SaveFundingProgramMutation,
  SaveFundingProgramMutationVariables
>;
export const SaveInteractionDocument = gql`
  mutation saveInteraction($id: ID, $params: InteractionParams) {
    saveInteraction(id: $id, params: $params) {
      ...interactionFragment
    }
  }
  ${InteractionFragmentFragmentDoc}
`;
export type SaveInteractionMutationFn = Apollo.MutationFunction<
  SaveInteractionMutation,
  SaveInteractionMutationVariables
>;

/**
 * __useSaveInteractionMutation__
 *
 * To run a mutation, you first call `useSaveInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInteractionMutation, { data, loading, error }] = useSaveInteractionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveInteractionMutation,
    SaveInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveInteractionMutation,
    SaveInteractionMutationVariables
  >(SaveInteractionDocument, options);
}
export type SaveInteractionMutationHookResult = ReturnType<
  typeof useSaveInteractionMutation
>;
export type SaveInteractionMutationResult =
  Apollo.MutationResult<SaveInteractionMutation>;
export type SaveInteractionMutationOptions = Apollo.BaseMutationOptions<
  SaveInteractionMutation,
  SaveInteractionMutationVariables
>;
export const SaveDefaultSurveyTemplateDocument = gql`
  mutation saveDefaultSurveyTemplate($params: SurveyTemplateNewInput!) {
    saveDefaultSurveyTemplate(params: $params) {
      id
      createdBy
      template
      title
    }
  }
`;
export type SaveDefaultSurveyTemplateMutationFn = Apollo.MutationFunction<
  SaveDefaultSurveyTemplateMutation,
  SaveDefaultSurveyTemplateMutationVariables
>;

/**
 * __useSaveDefaultSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useSaveDefaultSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDefaultSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDefaultSurveyTemplateMutation, { data, loading, error }] = useSaveDefaultSurveyTemplateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveDefaultSurveyTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDefaultSurveyTemplateMutation,
    SaveDefaultSurveyTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveDefaultSurveyTemplateMutation,
    SaveDefaultSurveyTemplateMutationVariables
  >(SaveDefaultSurveyTemplateDocument, options);
}
export type SaveDefaultSurveyTemplateMutationHookResult = ReturnType<
  typeof useSaveDefaultSurveyTemplateMutation
>;
export type SaveDefaultSurveyTemplateMutationResult =
  Apollo.MutationResult<SaveDefaultSurveyTemplateMutation>;
export type SaveDefaultSurveyTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    SaveDefaultSurveyTemplateMutation,
    SaveDefaultSurveyTemplateMutationVariables
  >;
export const SaveNoteDocument = gql`
  mutation saveNote($id: ID, $params: NoteParams) {
    saveNote(id: $id, params: $params) {
      ...noteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type SaveNoteMutationFn = Apollo.MutationFunction<
  SaveNoteMutation,
  SaveNoteMutationVariables
>;

/**
 * __useSaveNoteMutation__
 *
 * To run a mutation, you first call `useSaveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoteMutation, { data, loading, error }] = useSaveNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNoteMutation,
    SaveNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNoteMutation, SaveNoteMutationVariables>(
    SaveNoteDocument,
    options
  );
}
export type SaveNoteMutationHookResult = ReturnType<typeof useSaveNoteMutation>;
export type SaveNoteMutationResult = Apollo.MutationResult<SaveNoteMutation>;
export type SaveNoteMutationOptions = Apollo.BaseMutationOptions<
  SaveNoteMutation,
  SaveNoteMutationVariables
>;
export const SaveOneFormTemplateDocument = gql`
  mutation saveOneFormTemplate(
    $formTemplateId: ID!
    $params: FormTemplateInput!
  ) {
    saveOneFormTemplate(formTemplateId: $formTemplateId, params: $params) {
      ...formTemplateFragment
    }
  }
  ${FormTemplateFragmentFragmentDoc}
`;
export type SaveOneFormTemplateMutationFn = Apollo.MutationFunction<
  SaveOneFormTemplateMutation,
  SaveOneFormTemplateMutationVariables
>;

/**
 * __useSaveOneFormTemplateMutation__
 *
 * To run a mutation, you first call `useSaveOneFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOneFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOneFormTemplateMutation, { data, loading, error }] = useSaveOneFormTemplateMutation({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveOneFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveOneFormTemplateMutation,
    SaveOneFormTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveOneFormTemplateMutation,
    SaveOneFormTemplateMutationVariables
  >(SaveOneFormTemplateDocument, options);
}
export type SaveOneFormTemplateMutationHookResult = ReturnType<
  typeof useSaveOneFormTemplateMutation
>;
export type SaveOneFormTemplateMutationResult =
  Apollo.MutationResult<SaveOneFormTemplateMutation>;
export type SaveOneFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveOneFormTemplateMutation,
  SaveOneFormTemplateMutationVariables
>;
export const SaveOrganizationDocument = gql`
  mutation saveOrganization($id: ID, $params: OrganizationParams) {
    saveOrganization(id: $id, params: $params) {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;
export type SaveOrganizationMutationFn = Apollo.MutationFunction<
  SaveOrganizationMutation,
  SaveOrganizationMutationVariables
>;

/**
 * __useSaveOrganizationMutation__
 *
 * To run a mutation, you first call `useSaveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrganizationMutation, { data, loading, error }] = useSaveOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveOrganizationMutation,
    SaveOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveOrganizationMutation,
    SaveOrganizationMutationVariables
  >(SaveOrganizationDocument, options);
}
export type SaveOrganizationMutationHookResult = ReturnType<
  typeof useSaveOrganizationMutation
>;
export type SaveOrganizationMutationResult =
  Apollo.MutationResult<SaveOrganizationMutation>;
export type SaveOrganizationMutationOptions = Apollo.BaseMutationOptions<
  SaveOrganizationMutation,
  SaveOrganizationMutationVariables
>;
export const SavePipelineDocument = gql`
  mutation savePipeline($id: ID, $params: PipelineParams) {
    savePipeline(id: $id, params: $params) {
      id
      title
      description
    }
  }
`;
export type SavePipelineMutationFn = Apollo.MutationFunction<
  SavePipelineMutation,
  SavePipelineMutationVariables
>;

/**
 * __useSavePipelineMutation__
 *
 * To run a mutation, you first call `useSavePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePipelineMutation, { data, loading, error }] = useSavePipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSavePipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePipelineMutation,
    SavePipelineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePipelineMutation,
    SavePipelineMutationVariables
  >(SavePipelineDocument, options);
}
export type SavePipelineMutationHookResult = ReturnType<
  typeof useSavePipelineMutation
>;
export type SavePipelineMutationResult =
  Apollo.MutationResult<SavePipelineMutation>;
export type SavePipelineMutationOptions = Apollo.BaseMutationOptions<
  SavePipelineMutation,
  SavePipelineMutationVariables
>;
export const SaveProgramDocument = gql`
  mutation saveProgram($id: ID, $params: ResourceParams) {
    saveProgram(id: $id, params: $params) {
      ...resourceFragment
    }
  }
  ${ResourceFragmentFragmentDoc}
`;
export type SaveProgramMutationFn = Apollo.MutationFunction<
  SaveProgramMutation,
  SaveProgramMutationVariables
>;

/**
 * __useSaveProgramMutation__
 *
 * To run a mutation, you first call `useSaveProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProgramMutation, { data, loading, error }] = useSaveProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProgramMutation,
    SaveProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveProgramMutation, SaveProgramMutationVariables>(
    SaveProgramDocument,
    options
  );
}
export type SaveProgramMutationHookResult = ReturnType<
  typeof useSaveProgramMutation
>;
export type SaveProgramMutationResult =
  Apollo.MutationResult<SaveProgramMutation>;
export type SaveProgramMutationOptions = Apollo.BaseMutationOptions<
  SaveProgramMutation,
  SaveProgramMutationVariables
>;
export const SaveProjectDocument = gql`
  mutation saveProject($id: ID, $params: ProjectParams!) {
    saveProject(id: $id, params: $params) {
      ...projectFragmentLong
      businesses {
        id
        title
        phone
        logo {
          id
          url
        }
        website
      }
      contacts {
        id
      }
    }
  }
  ${ProjectFragmentLongFragmentDoc}
`;
export type SaveProjectMutationFn = Apollo.MutationFunction<
  SaveProjectMutation,
  SaveProjectMutationVariables
>;

/**
 * __useSaveProjectMutation__
 *
 * To run a mutation, you first call `useSaveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectMutation, { data, loading, error }] = useSaveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProjectMutation,
    SaveProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveProjectMutation, SaveProjectMutationVariables>(
    SaveProjectDocument,
    options
  );
}
export type SaveProjectMutationHookResult = ReturnType<
  typeof useSaveProjectMutation
>;
export type SaveProjectMutationResult =
  Apollo.MutationResult<SaveProjectMutation>;
export type SaveProjectMutationOptions = Apollo.BaseMutationOptions<
  SaveProjectMutation,
  SaveProjectMutationVariables
>;
export const SaveProjectTypeDocument = gql`
  mutation saveProjectType($id: ID, $params: ProjectTypeParams) {
    saveProjectType(id: $id, params: $params) {
      ...projectTypeFragment
    }
  }
  ${ProjectTypeFragmentFragmentDoc}
`;
export type SaveProjectTypeMutationFn = Apollo.MutationFunction<
  SaveProjectTypeMutation,
  SaveProjectTypeMutationVariables
>;

/**
 * __useSaveProjectTypeMutation__
 *
 * To run a mutation, you first call `useSaveProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectTypeMutation, { data, loading, error }] = useSaveProjectTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveProjectTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProjectTypeMutation,
    SaveProjectTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProjectTypeMutation,
    SaveProjectTypeMutationVariables
  >(SaveProjectTypeDocument, options);
}
export type SaveProjectTypeMutationHookResult = ReturnType<
  typeof useSaveProjectTypeMutation
>;
export type SaveProjectTypeMutationResult =
  Apollo.MutationResult<SaveProjectTypeMutation>;
export type SaveProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  SaveProjectTypeMutation,
  SaveProjectTypeMutationVariables
>;
export const SaveSiteDocument = gql`
  mutation saveSite($id: ID, $params: SiteInput) {
    saveSite(id: $id, params: $params) {
      ...siteFragment
    }
  }
  ${SiteFragmentFragmentDoc}
`;
export type SaveSiteMutationFn = Apollo.MutationFunction<
  SaveSiteMutation,
  SaveSiteMutationVariables
>;

/**
 * __useSaveSiteMutation__
 *
 * To run a mutation, you first call `useSaveSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSiteMutation, { data, loading, error }] = useSaveSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSiteMutation,
    SaveSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSiteMutation, SaveSiteMutationVariables>(
    SaveSiteDocument,
    options
  );
}
export type SaveSiteMutationHookResult = ReturnType<typeof useSaveSiteMutation>;
export type SaveSiteMutationResult = Apollo.MutationResult<SaveSiteMutation>;
export type SaveSiteMutationOptions = Apollo.BaseMutationOptions<
  SaveSiteMutation,
  SaveSiteMutationVariables
>;
export const SaveSurveyResultDocument = gql`
  mutation saveSurveyResult($params: SurveyResultInput!) {
    saveSurveyResult(params: $params) {
      success
      errors
    }
  }
`;
export type SaveSurveyResultMutationFn = Apollo.MutationFunction<
  SaveSurveyResultMutation,
  SaveSurveyResultMutationVariables
>;

/**
 * __useSaveSurveyResultMutation__
 *
 * To run a mutation, you first call `useSaveSurveyResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyResultMutation, { data, loading, error }] = useSaveSurveyResultMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveSurveyResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSurveyResultMutation,
    SaveSurveyResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSurveyResultMutation,
    SaveSurveyResultMutationVariables
  >(SaveSurveyResultDocument, options);
}
export type SaveSurveyResultMutationHookResult = ReturnType<
  typeof useSaveSurveyResultMutation
>;
export type SaveSurveyResultMutationResult =
  Apollo.MutationResult<SaveSurveyResultMutation>;
export type SaveSurveyResultMutationOptions = Apollo.BaseMutationOptions<
  SaveSurveyResultMutation,
  SaveSurveyResultMutationVariables
>;
export const SaveSurveyTemplateNewDocument = gql`
  mutation saveSurveyTemplateNew($params: SurveyTemplateNewInput!) {
    saveSurveyTemplateNew(params: $params) {
      id
      customerId
      createdBy
      template
      title
    }
  }
`;
export type SaveSurveyTemplateNewMutationFn = Apollo.MutationFunction<
  SaveSurveyTemplateNewMutation,
  SaveSurveyTemplateNewMutationVariables
>;

/**
 * __useSaveSurveyTemplateNewMutation__
 *
 * To run a mutation, you first call `useSaveSurveyTemplateNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyTemplateNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyTemplateNewMutation, { data, loading, error }] = useSaveSurveyTemplateNewMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveSurveyTemplateNewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSurveyTemplateNewMutation,
    SaveSurveyTemplateNewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSurveyTemplateNewMutation,
    SaveSurveyTemplateNewMutationVariables
  >(SaveSurveyTemplateNewDocument, options);
}
export type SaveSurveyTemplateNewMutationHookResult = ReturnType<
  typeof useSaveSurveyTemplateNewMutation
>;
export type SaveSurveyTemplateNewMutationResult =
  Apollo.MutationResult<SaveSurveyTemplateNewMutation>;
export type SaveSurveyTemplateNewMutationOptions = Apollo.BaseMutationOptions<
  SaveSurveyTemplateNewMutation,
  SaveSurveyTemplateNewMutationVariables
>;
export const SaveTagDocument = gql`
  mutation saveTag($id: ID, $params: TagParams) {
    saveTag(id: $id, params: $params) {
      ...tagFragment
    }
  }
  ${TagFragmentFragmentDoc}
`;
export type SaveTagMutationFn = Apollo.MutationFunction<
  SaveTagMutation,
  SaveTagMutationVariables
>;

/**
 * __useSaveTagMutation__
 *
 * To run a mutation, you first call `useSaveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTagMutation, { data, loading, error }] = useSaveTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTagMutation,
    SaveTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveTagMutation, SaveTagMutationVariables>(
    SaveTagDocument,
    options
  );
}
export type SaveTagMutationHookResult = ReturnType<typeof useSaveTagMutation>;
export type SaveTagMutationResult = Apollo.MutationResult<SaveTagMutation>;
export type SaveTagMutationOptions = Apollo.BaseMutationOptions<
  SaveTagMutation,
  SaveTagMutationVariables
>;
export const SaveTodoDocument = gql`
  mutation saveTodo($id: ID, $params: TodoParams) {
    saveTodo(id: $id, params: $params) {
      ...todoFragment
    }
  }
  ${TodoFragmentFragmentDoc}
`;
export type SaveTodoMutationFn = Apollo.MutationFunction<
  SaveTodoMutation,
  SaveTodoMutationVariables
>;

/**
 * __useSaveTodoMutation__
 *
 * To run a mutation, you first call `useSaveTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTodoMutation, { data, loading, error }] = useSaveTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTodoMutation,
    SaveTodoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveTodoMutation, SaveTodoMutationVariables>(
    SaveTodoDocument,
    options
  );
}
export type SaveTodoMutationHookResult = ReturnType<typeof useSaveTodoMutation>;
export type SaveTodoMutationResult = Apollo.MutationResult<SaveTodoMutation>;
export type SaveTodoMutationOptions = Apollo.BaseMutationOptions<
  SaveTodoMutation,
  SaveTodoMutationVariables
>;
export const SaveTodoListDocument = gql`
  mutation saveTodoList($id: ID, $params: TodoListParams) {
    saveTodoList(id: $id, params: $params) {
      id
      __typename
      title
      description
      archived
    }
  }
`;
export type SaveTodoListMutationFn = Apollo.MutationFunction<
  SaveTodoListMutation,
  SaveTodoListMutationVariables
>;

/**
 * __useSaveTodoListMutation__
 *
 * To run a mutation, you first call `useSaveTodoListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTodoListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTodoListMutation, { data, loading, error }] = useSaveTodoListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveTodoListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTodoListMutation,
    SaveTodoListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveTodoListMutation,
    SaveTodoListMutationVariables
  >(SaveTodoListDocument, options);
}
export type SaveTodoListMutationHookResult = ReturnType<
  typeof useSaveTodoListMutation
>;
export type SaveTodoListMutationResult =
  Apollo.MutationResult<SaveTodoListMutation>;
export type SaveTodoListMutationOptions = Apollo.BaseMutationOptions<
  SaveTodoListMutation,
  SaveTodoListMutationVariables
>;
export const SaveUserDocument = gql`
  mutation saveUser($id: ID, $params: UserParams) {
    saveUser(id: $id, params: $params) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type SaveUserMutationFn = Apollo.MutationFunction<
  SaveUserMutation,
  SaveUserMutationVariables
>;

/**
 * __useSaveUserMutation__
 *
 * To run a mutation, you first call `useSaveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserMutation, { data, loading, error }] = useSaveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserMutation,
    SaveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveUserMutation, SaveUserMutationVariables>(
    SaveUserDocument,
    options
  );
}
export type SaveUserMutationHookResult = ReturnType<typeof useSaveUserMutation>;
export type SaveUserMutationResult = Apollo.MutationResult<SaveUserMutation>;
export type SaveUserMutationOptions = Apollo.BaseMutationOptions<
  SaveUserMutation,
  SaveUserMutationVariables
>;
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >(SendResetPasswordEmailDocument, options);
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<
  typeof useSendResetPasswordEmailMutation
>;
export type SendResetPasswordEmailMutationResult =
  Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;
export const StartEmailCampaignDocument = gql`
  mutation startEmailCampaign($emailCampaignId: ID!) {
    startEmailCampaign(emailCampaignId: $emailCampaignId) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;
export type StartEmailCampaignMutationFn = Apollo.MutationFunction<
  StartEmailCampaignMutation,
  StartEmailCampaignMutationVariables
>;

/**
 * __useStartEmailCampaignMutation__
 *
 * To run a mutation, you first call `useStartEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEmailCampaignMutation, { data, loading, error }] = useStartEmailCampaignMutation({
 *   variables: {
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useStartEmailCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartEmailCampaignMutation,
    StartEmailCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartEmailCampaignMutation,
    StartEmailCampaignMutationVariables
  >(StartEmailCampaignDocument, options);
}
export type StartEmailCampaignMutationHookResult = ReturnType<
  typeof useStartEmailCampaignMutation
>;
export type StartEmailCampaignMutationResult =
  Apollo.MutationResult<StartEmailCampaignMutation>;
export type StartEmailCampaignMutationOptions = Apollo.BaseMutationOptions<
  StartEmailCampaignMutation,
  StartEmailCampaignMutationVariables
>;
export const StopOneScheduledEmailDocument = gql`
  mutation stopOneScheduledEmail($emailCampaignItemId: ID!) {
    stopOneScheduledEmail(emailCampaignItemId: $emailCampaignItemId) {
      ...emailCampaignItemFragment
    }
  }
  ${EmailCampaignItemFragmentFragmentDoc}
`;
export type StopOneScheduledEmailMutationFn = Apollo.MutationFunction<
  StopOneScheduledEmailMutation,
  StopOneScheduledEmailMutationVariables
>;

/**
 * __useStopOneScheduledEmailMutation__
 *
 * To run a mutation, you first call `useStopOneScheduledEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopOneScheduledEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopOneScheduledEmailMutation, { data, loading, error }] = useStopOneScheduledEmailMutation({
 *   variables: {
 *      emailCampaignItemId: // value for 'emailCampaignItemId'
 *   },
 * });
 */
export function useStopOneScheduledEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopOneScheduledEmailMutation,
    StopOneScheduledEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopOneScheduledEmailMutation,
    StopOneScheduledEmailMutationVariables
  >(StopOneScheduledEmailDocument, options);
}
export type StopOneScheduledEmailMutationHookResult = ReturnType<
  typeof useStopOneScheduledEmailMutation
>;
export type StopOneScheduledEmailMutationResult =
  Apollo.MutationResult<StopOneScheduledEmailMutation>;
export type StopOneScheduledEmailMutationOptions = Apollo.BaseMutationOptions<
  StopOneScheduledEmailMutation,
  StopOneScheduledEmailMutationVariables
>;
export const SubmitPublicBusinessSurveyResponseDocument = gql`
  mutation submitPublicBusinessSurveyResponse(
    $surveyTemplateId: ID!
    $businessId: ID
    $answers: [SurveyResponseAnswerInput]
    $referrerId: ID
  ) {
    submitPublicBusinessSurveyResponse(
      surveyTemplateId: $surveyTemplateId
      businessId: $businessId
      answers: $answers
      referrerId: $referrerId
    ) {
      success
    }
  }
`;
export type SubmitPublicBusinessSurveyResponseMutationFn =
  Apollo.MutationFunction<
    SubmitPublicBusinessSurveyResponseMutation,
    SubmitPublicBusinessSurveyResponseMutationVariables
  >;

/**
 * __useSubmitPublicBusinessSurveyResponseMutation__
 *
 * To run a mutation, you first call `useSubmitPublicBusinessSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPublicBusinessSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPublicBusinessSurveyResponseMutation, { data, loading, error }] = useSubmitPublicBusinessSurveyResponseMutation({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *      businessId: // value for 'businessId'
 *      answers: // value for 'answers'
 *      referrerId: // value for 'referrerId'
 *   },
 * });
 */
export function useSubmitPublicBusinessSurveyResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitPublicBusinessSurveyResponseMutation,
    SubmitPublicBusinessSurveyResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitPublicBusinessSurveyResponseMutation,
    SubmitPublicBusinessSurveyResponseMutationVariables
  >(SubmitPublicBusinessSurveyResponseDocument, options);
}
export type SubmitPublicBusinessSurveyResponseMutationHookResult = ReturnType<
  typeof useSubmitPublicBusinessSurveyResponseMutation
>;
export type SubmitPublicBusinessSurveyResponseMutationResult =
  Apollo.MutationResult<SubmitPublicBusinessSurveyResponseMutation>;
export type SubmitPublicBusinessSurveyResponseMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitPublicBusinessSurveyResponseMutation,
    SubmitPublicBusinessSurveyResponseMutationVariables
  >;
export const SubmitSurveyResponseDocument = gql`
  mutation submitSurveyResponse(
    $surveyTemplateId: ID!
    $businessId: ID
    $answers: [SurveyResponseAnswerInput]
    $attributionDate: String!
    $createdById: ID!
  ) {
    submitSurveyResponse(
      surveyTemplateId: $surveyTemplateId
      businessId: $businessId
      answers: $answers
      attributionDate: $attributionDate
      createdById: $createdById
    ) {
      success
    }
  }
`;
export type SubmitSurveyResponseMutationFn = Apollo.MutationFunction<
  SubmitSurveyResponseMutation,
  SubmitSurveyResponseMutationVariables
>;

/**
 * __useSubmitSurveyResponseMutation__
 *
 * To run a mutation, you first call `useSubmitSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSurveyResponseMutation, { data, loading, error }] = useSubmitSurveyResponseMutation({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *      businessId: // value for 'businessId'
 *      answers: // value for 'answers'
 *      attributionDate: // value for 'attributionDate'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function useSubmitSurveyResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSurveyResponseMutation,
    SubmitSurveyResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitSurveyResponseMutation,
    SubmitSurveyResponseMutationVariables
  >(SubmitSurveyResponseDocument, options);
}
export type SubmitSurveyResponseMutationHookResult = ReturnType<
  typeof useSubmitSurveyResponseMutation
>;
export type SubmitSurveyResponseMutationResult =
  Apollo.MutationResult<SubmitSurveyResponseMutation>;
export type SubmitSurveyResponseMutationOptions = Apollo.BaseMutationOptions<
  SubmitSurveyResponseMutation,
  SubmitSurveyResponseMutationVariables
>;
export const TestSendEmailTemplateDocument = gql`
  mutation testSendEmailTemplate(
    $contactId: ID
    $businessId: ID
    $toEmailIntegrationId: ID!
    $emailCampaignId: ID!
  ) {
    testSendEmailTemplate(
      contactId: $contactId
      businessId: $businessId
      toEmailIntegrationId: $toEmailIntegrationId
      emailCampaignId: $emailCampaignId
    ) {
      success
    }
  }
`;
export type TestSendEmailTemplateMutationFn = Apollo.MutationFunction<
  TestSendEmailTemplateMutation,
  TestSendEmailTemplateMutationVariables
>;

/**
 * __useTestSendEmailTemplateMutation__
 *
 * To run a mutation, you first call `useTestSendEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSendEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSendEmailTemplateMutation, { data, loading, error }] = useTestSendEmailTemplateMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      businessId: // value for 'businessId'
 *      toEmailIntegrationId: // value for 'toEmailIntegrationId'
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useTestSendEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TestSendEmailTemplateMutation,
    TestSendEmailTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TestSendEmailTemplateMutation,
    TestSendEmailTemplateMutationVariables
  >(TestSendEmailTemplateDocument, options);
}
export type TestSendEmailTemplateMutationHookResult = ReturnType<
  typeof useTestSendEmailTemplateMutation
>;
export type TestSendEmailTemplateMutationResult =
  Apollo.MutationResult<TestSendEmailTemplateMutation>;
export type TestSendEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  TestSendEmailTemplateMutation,
  TestSendEmailTemplateMutationVariables
>;
export const TrackEventDocument = gql`
  mutation trackEvent(
    $key: EventLogKeyEnum!
    $content: String!
    $meta: String
  ) {
    trackEvent(key: $key, content: $content, meta: $meta) {
      success
    }
  }
`;
export type TrackEventMutationFn = Apollo.MutationFunction<
  TrackEventMutation,
  TrackEventMutationVariables
>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      key: // value for 'key'
 *      content: // value for 'content'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useTrackEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackEventMutation,
    TrackEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(
    TrackEventDocument,
    options
  );
}
export type TrackEventMutationHookResult = ReturnType<
  typeof useTrackEventMutation
>;
export type TrackEventMutationResult =
  Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<
  TrackEventMutation,
  TrackEventMutationVariables
>;
export const UnsubscribeOneContactFromEmailCampaignDocument = gql`
  mutation unsubscribeOneContactFromEmailCampaign(
    $email: String!
    $customerId: ID!
  ) {
    unsubscribeOneContactFromEmailCampaign(
      email: $email
      customerId: $customerId
    ) {
      success
      errors
    }
  }
`;
export type UnsubscribeOneContactFromEmailCampaignMutationFn =
  Apollo.MutationFunction<
    UnsubscribeOneContactFromEmailCampaignMutation,
    UnsubscribeOneContactFromEmailCampaignMutationVariables
  >;

/**
 * __useUnsubscribeOneContactFromEmailCampaignMutation__
 *
 * To run a mutation, you first call `useUnsubscribeOneContactFromEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeOneContactFromEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeOneContactFromEmailCampaignMutation, { data, loading, error }] = useUnsubscribeOneContactFromEmailCampaignMutation({
 *   variables: {
 *      email: // value for 'email'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUnsubscribeOneContactFromEmailCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeOneContactFromEmailCampaignMutation,
    UnsubscribeOneContactFromEmailCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeOneContactFromEmailCampaignMutation,
    UnsubscribeOneContactFromEmailCampaignMutationVariables
  >(UnsubscribeOneContactFromEmailCampaignDocument, options);
}
export type UnsubscribeOneContactFromEmailCampaignMutationHookResult =
  ReturnType<typeof useUnsubscribeOneContactFromEmailCampaignMutation>;
export type UnsubscribeOneContactFromEmailCampaignMutationResult =
  Apollo.MutationResult<UnsubscribeOneContactFromEmailCampaignMutation>;
export type UnsubscribeOneContactFromEmailCampaignMutationOptions =
  Apollo.BaseMutationOptions<
    UnsubscribeOneContactFromEmailCampaignMutation,
    UnsubscribeOneContactFromEmailCampaignMutationVariables
  >;
export const UpdateOneCustomerSharingSettingDocument = gql`
  mutation updateOneCustomerSharingSetting(
    $key: SharingCustomerSettingKeyEnum!
    $enabled: Boolean!
  ) {
    updateOneCustomerSharingSetting(key: $key, enabled: $enabled) {
      ...customerShortFragment
    }
  }
  ${CustomerShortFragmentFragmentDoc}
`;
export type UpdateOneCustomerSharingSettingMutationFn = Apollo.MutationFunction<
  UpdateOneCustomerSharingSettingMutation,
  UpdateOneCustomerSharingSettingMutationVariables
>;

/**
 * __useUpdateOneCustomerSharingSettingMutation__
 *
 * To run a mutation, you first call `useUpdateOneCustomerSharingSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCustomerSharingSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneCustomerSharingSettingMutation, { data, loading, error }] = useUpdateOneCustomerSharingSettingMutation({
 *   variables: {
 *      key: // value for 'key'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateOneCustomerSharingSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneCustomerSharingSettingMutation,
    UpdateOneCustomerSharingSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneCustomerSharingSettingMutation,
    UpdateOneCustomerSharingSettingMutationVariables
  >(UpdateOneCustomerSharingSettingDocument, options);
}
export type UpdateOneCustomerSharingSettingMutationHookResult = ReturnType<
  typeof useUpdateOneCustomerSharingSettingMutation
>;
export type UpdateOneCustomerSharingSettingMutationResult =
  Apollo.MutationResult<UpdateOneCustomerSharingSettingMutation>;
export type UpdateOneCustomerSharingSettingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOneCustomerSharingSettingMutation,
    UpdateOneCustomerSharingSettingMutationVariables
  >;
export const UpdateOneEmailCampaignDocument = gql`
  mutation updateOneEmailCampaign($id: ID!, $params: EmailCampaignInput!) {
    updateOneEmailCampaign(id: $id, params: $params) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;
export type UpdateOneEmailCampaignMutationFn = Apollo.MutationFunction<
  UpdateOneEmailCampaignMutation,
  UpdateOneEmailCampaignMutationVariables
>;

/**
 * __useUpdateOneEmailCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateOneEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEmailCampaignMutation, { data, loading, error }] = useUpdateOneEmailCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneEmailCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneEmailCampaignMutation,
    UpdateOneEmailCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneEmailCampaignMutation,
    UpdateOneEmailCampaignMutationVariables
  >(UpdateOneEmailCampaignDocument, options);
}
export type UpdateOneEmailCampaignMutationHookResult = ReturnType<
  typeof useUpdateOneEmailCampaignMutation
>;
export type UpdateOneEmailCampaignMutationResult =
  Apollo.MutationResult<UpdateOneEmailCampaignMutation>;
export type UpdateOneEmailCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneEmailCampaignMutation,
  UpdateOneEmailCampaignMutationVariables
>;
export const UpdateOneObjectsPermissionsDocument = gql`
  mutation updateOneObjectsPermissions(
    $type: ObjectTypeEnum!
    $id: ID!
    $permission: ObjectPermissionInput!
    $action: UpdateArrayActionEnum!
  ) {
    updateOneObjectsPermissions(
      type: $type
      id: $id
      permission: $permission
      action: $action
    ) {
      ...objectPermissionFragment
    }
  }
  ${ObjectPermissionFragmentFragmentDoc}
`;
export type UpdateOneObjectsPermissionsMutationFn = Apollo.MutationFunction<
  UpdateOneObjectsPermissionsMutation,
  UpdateOneObjectsPermissionsMutationVariables
>;

/**
 * __useUpdateOneObjectsPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateOneObjectsPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneObjectsPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneObjectsPermissionsMutation, { data, loading, error }] = useUpdateOneObjectsPermissionsMutation({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useUpdateOneObjectsPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneObjectsPermissionsMutation,
    UpdateOneObjectsPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneObjectsPermissionsMutation,
    UpdateOneObjectsPermissionsMutationVariables
  >(UpdateOneObjectsPermissionsDocument, options);
}
export type UpdateOneObjectsPermissionsMutationHookResult = ReturnType<
  typeof useUpdateOneObjectsPermissionsMutation
>;
export type UpdateOneObjectsPermissionsMutationResult =
  Apollo.MutationResult<UpdateOneObjectsPermissionsMutation>;
export type UpdateOneObjectsPermissionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOneObjectsPermissionsMutation,
    UpdateOneObjectsPermissionsMutationVariables
  >;
export const UpdateOneUserGroupDocument = gql`
  mutation updateOneUserGroup($id: ID!, $params: UserGroupInput!) {
    updateOneUserGroup(id: $id, params: $params) {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;
export type UpdateOneUserGroupMutationFn = Apollo.MutationFunction<
  UpdateOneUserGroupMutation,
  UpdateOneUserGroupMutationVariables
>;

/**
 * __useUpdateOneUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserGroupMutation, { data, loading, error }] = useUpdateOneUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneUserGroupMutation,
    UpdateOneUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneUserGroupMutation,
    UpdateOneUserGroupMutationVariables
  >(UpdateOneUserGroupDocument, options);
}
export type UpdateOneUserGroupMutationHookResult = ReturnType<
  typeof useUpdateOneUserGroupMutation
>;
export type UpdateOneUserGroupMutationResult =
  Apollo.MutationResult<UpdateOneUserGroupMutation>;
export type UpdateOneUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneUserGroupMutation,
  UpdateOneUserGroupMutationVariables
>;
export const UpdatePipelineColDocument = gql`
  mutation updatePipelineCol($params: PipelineStageInput) {
    updatePipelineCol(params: $params) {
      ...pipelineFragment
    }
  }
  ${PipelineFragmentFragmentDoc}
`;
export type UpdatePipelineColMutationFn = Apollo.MutationFunction<
  UpdatePipelineColMutation,
  UpdatePipelineColMutationVariables
>;

/**
 * __useUpdatePipelineColMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineColMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineColMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineColMutation, { data, loading, error }] = useUpdatePipelineColMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdatePipelineColMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePipelineColMutation,
    UpdatePipelineColMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePipelineColMutation,
    UpdatePipelineColMutationVariables
  >(UpdatePipelineColDocument, options);
}
export type UpdatePipelineColMutationHookResult = ReturnType<
  typeof useUpdatePipelineColMutation
>;
export type UpdatePipelineColMutationResult =
  Apollo.MutationResult<UpdatePipelineColMutation>;
export type UpdatePipelineColMutationOptions = Apollo.BaseMutationOptions<
  UpdatePipelineColMutation,
  UpdatePipelineColMutationVariables
>;
export const UploadBusinessesDocument = gql`
  mutation uploadBusinesses(
    $businesses: [UploadBusinessInput]
    $customerId: ID!
  ) {
    uploadBusinesses(businesses: $businesses, customerId: $customerId) {
      success
    }
  }
`;
export type UploadBusinessesMutationFn = Apollo.MutationFunction<
  UploadBusinessesMutation,
  UploadBusinessesMutationVariables
>;

/**
 * __useUploadBusinessesMutation__
 *
 * To run a mutation, you first call `useUploadBusinessesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBusinessesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBusinessesMutation, { data, loading, error }] = useUploadBusinessesMutation({
 *   variables: {
 *      businesses: // value for 'businesses'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUploadBusinessesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadBusinessesMutation,
    UploadBusinessesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadBusinessesMutation,
    UploadBusinessesMutationVariables
  >(UploadBusinessesDocument, options);
}
export type UploadBusinessesMutationHookResult = ReturnType<
  typeof useUploadBusinessesMutation
>;
export type UploadBusinessesMutationResult =
  Apollo.MutationResult<UploadBusinessesMutation>;
export type UploadBusinessesMutationOptions = Apollo.BaseMutationOptions<
  UploadBusinessesMutation,
  UploadBusinessesMutationVariables
>;
export const UploadContactCsvContentDocument = gql`
  mutation uploadContactCSVContent($customerId: ID!, $params: [ContactInput]!) {
    uploadContactCSVContent(customerId: $customerId, params: $params) {
      success
      count {
        enrichedBusinesses
        enrichedContacts
        createdContacts
        createdBusinesses
      }
      error
    }
  }
`;
export type UploadContactCsvContentMutationFn = Apollo.MutationFunction<
  UploadContactCsvContentMutation,
  UploadContactCsvContentMutationVariables
>;

/**
 * __useUploadContactCsvContentMutation__
 *
 * To run a mutation, you first call `useUploadContactCsvContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContactCsvContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContactCsvContentMutation, { data, loading, error }] = useUploadContactCsvContentMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUploadContactCsvContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadContactCsvContentMutation,
    UploadContactCsvContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadContactCsvContentMutation,
    UploadContactCsvContentMutationVariables
  >(UploadContactCsvContentDocument, options);
}
export type UploadContactCsvContentMutationHookResult = ReturnType<
  typeof useUploadContactCsvContentMutation
>;
export type UploadContactCsvContentMutationResult =
  Apollo.MutationResult<UploadContactCsvContentMutation>;
export type UploadContactCsvContentMutationOptions = Apollo.BaseMutationOptions<
  UploadContactCsvContentMutation,
  UploadContactCsvContentMutationVariables
>;
export const ActivityItemsDocument = gql`
  query activityItems($parentId: ID!, $parentType: NoteParentTypeEnum) {
    activityItems(parentId: $parentId, parentType: $parentType) {
      ...activityItemFragment
    }
  }
  ${ActivityItemFragmentFragmentDoc}
`;

/**
 * __useActivityItemsQuery__
 *
 * To run a query within a React component, call `useActivityItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityItemsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      parentType: // value for 'parentType'
 *   },
 * });
 */
export function useActivityItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityItemsQuery,
    ActivityItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityItemsQuery, ActivityItemsQueryVariables>(
    ActivityItemsDocument,
    options
  );
}
export function useActivityItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityItemsQuery,
    ActivityItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityItemsQuery, ActivityItemsQueryVariables>(
    ActivityItemsDocument,
    options
  );
}
export type ActivityItemsQueryHookResult = ReturnType<
  typeof useActivityItemsQuery
>;
export type ActivityItemsLazyQueryHookResult = ReturnType<
  typeof useActivityItemsLazyQuery
>;
export type ActivityItemsQueryResult = Apollo.QueryResult<
  ActivityItemsQuery,
  ActivityItemsQueryVariables
>;
export const AttachmentsDocument = gql`
  query attachments($parentId: ID!) {
    attachments(parentId: $parentId) {
      id
      filename
      encoding
      mimetype
      url
      key
      createdAt
      createdByName
    }
  }
`;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a React component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AttachmentsQuery,
    AttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttachmentsQuery, AttachmentsQueryVariables>(
    AttachmentsDocument,
    options
  );
}
export function useAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AttachmentsQuery,
    AttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttachmentsQuery, AttachmentsQueryVariables>(
    AttachmentsDocument,
    options
  );
}
export type AttachmentsQueryHookResult = ReturnType<typeof useAttachmentsQuery>;
export type AttachmentsLazyQueryHookResult = ReturnType<
  typeof useAttachmentsLazyQuery
>;
export type AttachmentsQueryResult = Apollo.QueryResult<
  AttachmentsQuery,
  AttachmentsQueryVariables
>;
export const BusinessByIdDocument = gql`
  query businessById($id: ID!) {
    businessById(id: $id) {
      ...businessByIdFragment
    }
  }
  ${BusinessByIdFragmentFragmentDoc}
`;

/**
 * __useBusinessByIdQuery__
 *
 * To run a query within a React component, call `useBusinessByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessByIdQuery,
    BusinessByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessByIdQuery, BusinessByIdQueryVariables>(
    BusinessByIdDocument,
    options
  );
}
export function useBusinessByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessByIdQuery,
    BusinessByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessByIdQuery, BusinessByIdQueryVariables>(
    BusinessByIdDocument,
    options
  );
}
export type BusinessByIdQueryHookResult = ReturnType<
  typeof useBusinessByIdQuery
>;
export type BusinessByIdLazyQueryHookResult = ReturnType<
  typeof useBusinessByIdLazyQuery
>;
export type BusinessByIdQueryResult = Apollo.QueryResult<
  BusinessByIdQuery,
  BusinessByIdQueryVariables
>;
export const BusinessSectorsDocument = gql`
  query businessSectors {
    businessSectors {
      id
      title
    }
  }
`;

/**
 * __useBusinessSectorsQuery__
 *
 * To run a query within a React component, call `useBusinessSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessSectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessSectorsQuery,
    BusinessSectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessSectorsQuery, BusinessSectorsQueryVariables>(
    BusinessSectorsDocument,
    options
  );
}
export function useBusinessSectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessSectorsQuery,
    BusinessSectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BusinessSectorsQuery,
    BusinessSectorsQueryVariables
  >(BusinessSectorsDocument, options);
}
export type BusinessSectorsQueryHookResult = ReturnType<
  typeof useBusinessSectorsQuery
>;
export type BusinessSectorsLazyQueryHookResult = ReturnType<
  typeof useBusinessSectorsLazyQuery
>;
export type BusinessSectorsQueryResult = Apollo.QueryResult<
  BusinessSectorsQuery,
  BusinessSectorsQueryVariables
>;
export const BusinessesDocument = gql`
  query businesses(
    $params: BusinessSearchParams
    $skip: Int
    $limit: Int
    $businessIds: [String]
    $includesAtleastBusinessIds: [String!]
    $sort: [SortByInput!]
  ) {
    businesses(
      params: $params
      skip: $skip
      limit: $limit
      businessIds: $businessIds
      includesAtleastBusinessIds: $includesAtleastBusinessIds
      sort: $sort
    ) {
      count
      businesses {
        ...businessShortFragment
      }
    }
  }
  ${BusinessShortFragmentFragmentDoc}
`;

/**
 * __useBusinessesQuery__
 *
 * To run a query within a React component, call `useBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      businessIds: // value for 'businessIds'
 *      includesAtleastBusinessIds: // value for 'includesAtleastBusinessIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessesQuery, BusinessesQueryVariables>(
    BusinessesDocument,
    options
  );
}
export function useBusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessesQuery, BusinessesQueryVariables>(
    BusinessesDocument,
    options
  );
}
export type BusinessesQueryHookResult = ReturnType<typeof useBusinessesQuery>;
export type BusinessesLazyQueryHookResult = ReturnType<
  typeof useBusinessesLazyQuery
>;
export type BusinessesQueryResult = Apollo.QueryResult<
  BusinessesQuery,
  BusinessesQueryVariables
>;
export const CaseByIdDocument = gql`
  query caseById($id: ID!) {
    caseById(id: $id) {
      ...caseFragment
    }
  }
  ${CaseFragmentFragmentDoc}
`;

/**
 * __useCaseByIdQuery__
 *
 * To run a query within a React component, call `useCaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CaseByIdQuery, CaseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseByIdQuery, CaseByIdQueryVariables>(
    CaseByIdDocument,
    options
  );
}
export function useCaseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseByIdQuery,
    CaseByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseByIdQuery, CaseByIdQueryVariables>(
    CaseByIdDocument,
    options
  );
}
export type CaseByIdQueryHookResult = ReturnType<typeof useCaseByIdQuery>;
export type CaseByIdLazyQueryHookResult = ReturnType<
  typeof useCaseByIdLazyQuery
>;
export type CaseByIdQueryResult = Apollo.QueryResult<
  CaseByIdQuery,
  CaseByIdQueryVariables
>;
export const CaseCategoriesDocument = gql`
  query caseCategories {
    caseCategories {
      id
      title
      description
    }
  }
`;

/**
 * __useCaseCategoriesQuery__
 *
 * To run a query within a React component, call `useCaseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseCategoriesQuery,
    CaseCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseCategoriesQuery, CaseCategoriesQueryVariables>(
    CaseCategoriesDocument,
    options
  );
}
export function useCaseCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseCategoriesQuery,
    CaseCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseCategoriesQuery, CaseCategoriesQueryVariables>(
    CaseCategoriesDocument,
    options
  );
}
export type CaseCategoriesQueryHookResult = ReturnType<
  typeof useCaseCategoriesQuery
>;
export type CaseCategoriesLazyQueryHookResult = ReturnType<
  typeof useCaseCategoriesLazyQuery
>;
export type CaseCategoriesQueryResult = Apollo.QueryResult<
  CaseCategoriesQuery,
  CaseCategoriesQueryVariables
>;
export const CaseServicesDocument = gql`
  query caseServices($ids: [ID!], $caseCategoryId: ID) {
    caseServices(ids: $ids, caseCategoryId: $caseCategoryId) {
      id
      title
      description
    }
  }
`;

/**
 * __useCaseServicesQuery__
 *
 * To run a query within a React component, call `useCaseServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseServicesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      caseCategoryId: // value for 'caseCategoryId'
 *   },
 * });
 */
export function useCaseServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseServicesQuery,
    CaseServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseServicesQuery, CaseServicesQueryVariables>(
    CaseServicesDocument,
    options
  );
}
export function useCaseServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseServicesQuery,
    CaseServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseServicesQuery, CaseServicesQueryVariables>(
    CaseServicesDocument,
    options
  );
}
export type CaseServicesQueryHookResult = ReturnType<
  typeof useCaseServicesQuery
>;
export type CaseServicesLazyQueryHookResult = ReturnType<
  typeof useCaseServicesLazyQuery
>;
export type CaseServicesQueryResult = Apollo.QueryResult<
  CaseServicesQuery,
  CaseServicesQueryVariables
>;
export const CaseStatusReportDocument = gql`
  query caseStatusReport {
    caseStatusReport {
      id
      status {
        id
        title
        description
        isFinalStep
      }
      total
    }
  }
`;

/**
 * __useCaseStatusReportQuery__
 *
 * To run a query within a React component, call `useCaseStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStatusReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseStatusReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseStatusReportQuery,
    CaseStatusReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseStatusReportQuery, CaseStatusReportQueryVariables>(
    CaseStatusReportDocument,
    options
  );
}
export function useCaseStatusReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseStatusReportQuery,
    CaseStatusReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CaseStatusReportQuery,
    CaseStatusReportQueryVariables
  >(CaseStatusReportDocument, options);
}
export type CaseStatusReportQueryHookResult = ReturnType<
  typeof useCaseStatusReportQuery
>;
export type CaseStatusReportLazyQueryHookResult = ReturnType<
  typeof useCaseStatusReportLazyQuery
>;
export type CaseStatusReportQueryResult = Apollo.QueryResult<
  CaseStatusReportQuery,
  CaseStatusReportQueryVariables
>;
export const CaseStatusesDocument = gql`
  query caseStatuses {
    caseStatuses {
      id
      title
      description
      isFinalStep
    }
  }
`;

/**
 * __useCaseStatusesQuery__
 *
 * To run a query within a React component, call `useCaseStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseStatusesQuery,
    CaseStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseStatusesQuery, CaseStatusesQueryVariables>(
    CaseStatusesDocument,
    options
  );
}
export function useCaseStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseStatusesQuery,
    CaseStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseStatusesQuery, CaseStatusesQueryVariables>(
    CaseStatusesDocument,
    options
  );
}
export type CaseStatusesQueryHookResult = ReturnType<
  typeof useCaseStatusesQuery
>;
export type CaseStatusesLazyQueryHookResult = ReturnType<
  typeof useCaseStatusesLazyQuery
>;
export type CaseStatusesQueryResult = Apollo.QueryResult<
  CaseStatusesQuery,
  CaseStatusesQueryVariables
>;
export const CasesDocument = gql`
  query cases(
    $businessId: String
    $contactId: String
    $caseStatusIds: [ID!]
    $caseCategoryId: String
    $startDate: String
    $endDate: String
    $assignedToId: ID
    $searchText: String
    $caseIds: [ID!]
    $limit: Int
    $skip: Int
    $caseOutcomeIds: [ID!]
  ) {
    cases(
      businessId: $businessId
      contactId: $contactId
      caseStatusIds: $caseStatusIds
      caseCategoryId: $caseCategoryId
      caseOutcomeIds: $caseOutcomeIds
      assignedToId: $assignedToId
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
      caseIds: $caseIds
      limit: $limit
      skip: $skip
    ) {
      result {
        id
        title
        description
        dueDate
        resolvedOn
        openTodos
        closedTodos
        business {
          id
          title
          logo {
            id
            filename
            mimetype
            encoding
            url
            key
          }
        }
        contact {
          id
          firstName
          lastName
          email
          avatar {
            id
            filename
            mimetype
            encoding
            url
            key
          }
        }
        assignedTo {
          id
          firstName
          lastName
          email
          avatar {
            id
            filename
            mimetype
            encoding
            url
            key
          }
        }
        caseStatus {
          id
          title
        }
        caseCategory {
          id
          title
        }
        services {
          id
          title
        }
      }
      count
    }
  }
`;

/**
 * __useCasesQuery__
 *
 * To run a query within a React component, call `useCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *      caseStatusIds: // value for 'caseStatusIds'
 *      caseCategoryId: // value for 'caseCategoryId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      assignedToId: // value for 'assignedToId'
 *      searchText: // value for 'searchText'
 *      caseIds: // value for 'caseIds'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      caseOutcomeIds: // value for 'caseOutcomeIds'
 *   },
 * });
 */
export function useCasesQuery(
  baseOptions?: Apollo.QueryHookOptions<CasesQuery, CasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CasesQuery, CasesQueryVariables>(
    CasesDocument,
    options
  );
}
export function useCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CasesQuery, CasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CasesQuery, CasesQueryVariables>(
    CasesDocument,
    options
  );
}
export type CasesQueryHookResult = ReturnType<typeof useCasesQuery>;
export type CasesLazyQueryHookResult = ReturnType<typeof useCasesLazyQuery>;
export type CasesQueryResult = Apollo.QueryResult<
  CasesQuery,
  CasesQueryVariables
>;
export const CheckResetDocument = gql`
  query checkReset($token: String!) {
    checkReset(token: $token) {
      valid
    }
  }
`;

/**
 * __useCheckResetQuery__
 *
 * To run a query within a React component, call `useCheckResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckResetQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckResetQuery,
    CheckResetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckResetQuery, CheckResetQueryVariables>(
    CheckResetDocument,
    options
  );
}
export function useCheckResetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckResetQuery,
    CheckResetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckResetQuery, CheckResetQueryVariables>(
    CheckResetDocument,
    options
  );
}
export type CheckResetQueryHookResult = ReturnType<typeof useCheckResetQuery>;
export type CheckResetLazyQueryHookResult = ReturnType<
  typeof useCheckResetLazyQuery
>;
export type CheckResetQueryResult = Apollo.QueryResult<
  CheckResetQuery,
  CheckResetQueryVariables
>;
export const CommentsDocument = gql`
  query comments($todoId: String, $caseId: String, $noteId: String) {
    comments(todoId: $todoId, caseId: $caseId, noteId: $noteId) {
      ...commentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      todoId: // value for 'todoId'
 *      caseId: // value for 'caseId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  );
}
export function useCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentsQuery,
    CommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(
    CommentsDocument,
    options
  );
}
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<
  typeof useCommentsLazyQuery
>;
export type CommentsQueryResult = Apollo.QueryResult<
  CommentsQuery,
  CommentsQueryVariables
>;
export const ContactByIdDocument = gql`
  query contactById($id: ID!) {
    contactById(id: $id) {
      ...contactFragment
      projects {
        id
        title
        image {
          id
          url
        }
      }
    }
  }
  ${ContactFragmentFragmentDoc}
`;

/**
 * __useContactByIdQuery__
 *
 * To run a query within a React component, call `useContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactByIdQuery,
    ContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactByIdQuery, ContactByIdQueryVariables>(
    ContactByIdDocument,
    options
  );
}
export function useContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactByIdQuery,
    ContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactByIdQuery, ContactByIdQueryVariables>(
    ContactByIdDocument,
    options
  );
}
export type ContactByIdQueryHookResult = ReturnType<typeof useContactByIdQuery>;
export type ContactByIdLazyQueryHookResult = ReturnType<
  typeof useContactByIdLazyQuery
>;
export type ContactByIdQueryResult = Apollo.QueryResult<
  ContactByIdQuery,
  ContactByIdQueryVariables
>;
export const ContactsDocument = gql`
  query contacts($params: ContactSearchParams, $skip: Int, $limit: Int) {
    contacts(params: $params, skip: $skip, limit: $limit) {
      count
      contacts {
        ...contactFragment
      }
    }
  }
  ${ContactFragmentFragmentDoc}
`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options
  );
}
export function useContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options
  );
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<
  typeof useContactsLazyQuery
>;
export type ContactsQueryResult = Apollo.QueryResult<
  ContactsQuery,
  ContactsQueryVariables
>;
export const CsvDownload_BusinessesDocument = gql`
  query csvDownload_businesses {
    csvDownload_businesses {
      id
      __typename
      title
      description
      phone
      twitter
      instagram
      linkedin
      facebook
      website
      industry
      fax
      serviceNeeds
      industries
      clusters
      diversity
      amenities
      employeeCount
      tags {
        id
        title
      }
      naics {
        id
        title
        code
      }
      businessSector {
        id
        title
      }
      businessStage
      businessType
      status
      opened
      closed
      annualRevenue
      createdAt
      lat
      lng
      placeId
      fullAddress
      street
      postal
      country
      state
      city
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

/**
 * __useCsvDownload_BusinessesQuery__
 *
 * To run a query within a React component, call `useCsvDownload_BusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvDownload_BusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvDownload_BusinessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsvDownload_BusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CsvDownload_BusinessesQuery,
    CsvDownload_BusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CsvDownload_BusinessesQuery,
    CsvDownload_BusinessesQueryVariables
  >(CsvDownload_BusinessesDocument, options);
}
export function useCsvDownload_BusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvDownload_BusinessesQuery,
    CsvDownload_BusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CsvDownload_BusinessesQuery,
    CsvDownload_BusinessesQueryVariables
  >(CsvDownload_BusinessesDocument, options);
}
export type CsvDownload_BusinessesQueryHookResult = ReturnType<
  typeof useCsvDownload_BusinessesQuery
>;
export type CsvDownload_BusinessesLazyQueryHookResult = ReturnType<
  typeof useCsvDownload_BusinessesLazyQuery
>;
export type CsvDownload_BusinessesQueryResult = Apollo.QueryResult<
  CsvDownload_BusinessesQuery,
  CsvDownload_BusinessesQueryVariables
>;
export const CsvDownload_CasesDocument = gql`
  query csvDownload_cases {
    csvDownload_cases {
      ...caseFragment
    }
  }
  ${CaseFragmentFragmentDoc}
`;

/**
 * __useCsvDownload_CasesQuery__
 *
 * To run a query within a React component, call `useCsvDownload_CasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvDownload_CasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvDownload_CasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsvDownload_CasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CsvDownload_CasesQuery,
    CsvDownload_CasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CsvDownload_CasesQuery,
    CsvDownload_CasesQueryVariables
  >(CsvDownload_CasesDocument, options);
}
export function useCsvDownload_CasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvDownload_CasesQuery,
    CsvDownload_CasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CsvDownload_CasesQuery,
    CsvDownload_CasesQueryVariables
  >(CsvDownload_CasesDocument, options);
}
export type CsvDownload_CasesQueryHookResult = ReturnType<
  typeof useCsvDownload_CasesQuery
>;
export type CsvDownload_CasesLazyQueryHookResult = ReturnType<
  typeof useCsvDownload_CasesLazyQuery
>;
export type CsvDownload_CasesQueryResult = Apollo.QueryResult<
  CsvDownload_CasesQuery,
  CsvDownload_CasesQueryVariables
>;
export const CsvDownload_ContactsDocument = gql`
  query csvDownload_contacts {
    csvDownload_contacts {
      id
      email
      department
      twitter
      instagram
      linkedin
      facebook
      firstName
      lastName
      title
      status
      website
      workPhone
      cellPhone
      homePhone
      tags {
        id
        title
        description
      }
      lat
      lng
      placeId
      fullAddress
      street
      postal
      country
      state
      city
    }
  }
`;

/**
 * __useCsvDownload_ContactsQuery__
 *
 * To run a query within a React component, call `useCsvDownload_ContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvDownload_ContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvDownload_ContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsvDownload_ContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CsvDownload_ContactsQuery,
    CsvDownload_ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CsvDownload_ContactsQuery,
    CsvDownload_ContactsQueryVariables
  >(CsvDownload_ContactsDocument, options);
}
export function useCsvDownload_ContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvDownload_ContactsQuery,
    CsvDownload_ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CsvDownload_ContactsQuery,
    CsvDownload_ContactsQueryVariables
  >(CsvDownload_ContactsDocument, options);
}
export type CsvDownload_ContactsQueryHookResult = ReturnType<
  typeof useCsvDownload_ContactsQuery
>;
export type CsvDownload_ContactsLazyQueryHookResult = ReturnType<
  typeof useCsvDownload_ContactsLazyQuery
>;
export type CsvDownload_ContactsQueryResult = Apollo.QueryResult<
  CsvDownload_ContactsQuery,
  CsvDownload_ContactsQueryVariables
>;
export const CsvDownload_SurveysDocument = gql`
  query csvDownload_surveys {
    csvDownload_surveys {
      id
      surveyTemplateId
      attributionDate
      answers {
        surveyQuestionId
        label
        answer
      }
      business {
        id
        title
      }
    }
  }
`;

/**
 * __useCsvDownload_SurveysQuery__
 *
 * To run a query within a React component, call `useCsvDownload_SurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvDownload_SurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCsvDownload_SurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useCsvDownload_SurveysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CsvDownload_SurveysQuery,
    CsvDownload_SurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CsvDownload_SurveysQuery,
    CsvDownload_SurveysQueryVariables
  >(CsvDownload_SurveysDocument, options);
}
export function useCsvDownload_SurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CsvDownload_SurveysQuery,
    CsvDownload_SurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CsvDownload_SurveysQuery,
    CsvDownload_SurveysQueryVariables
  >(CsvDownload_SurveysDocument, options);
}
export type CsvDownload_SurveysQueryHookResult = ReturnType<
  typeof useCsvDownload_SurveysQuery
>;
export type CsvDownload_SurveysLazyQueryHookResult = ReturnType<
  typeof useCsvDownload_SurveysLazyQuery
>;
export type CsvDownload_SurveysQueryResult = Apollo.QueryResult<
  CsvDownload_SurveysQuery,
  CsvDownload_SurveysQueryVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const CustomerByIdShortDocument = gql`
  query customerByIdShort($id: ID!) {
    customerById(id: $id) {
      ...customerShortFragment
    }
  }
  ${CustomerShortFragmentFragmentDoc}
`;

/**
 * __useCustomerByIdShortQuery__
 *
 * To run a query within a React component, call `useCustomerByIdShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIdShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIdShortQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerByIdShortQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerByIdShortQuery,
    CustomerByIdShortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomerByIdShortQuery,
    CustomerByIdShortQueryVariables
  >(CustomerByIdShortDocument, options);
}
export function useCustomerByIdShortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerByIdShortQuery,
    CustomerByIdShortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerByIdShortQuery,
    CustomerByIdShortQueryVariables
  >(CustomerByIdShortDocument, options);
}
export type CustomerByIdShortQueryHookResult = ReturnType<
  typeof useCustomerByIdShortQuery
>;
export type CustomerByIdShortLazyQueryHookResult = ReturnType<
  typeof useCustomerByIdShortLazyQuery
>;
export type CustomerByIdShortQueryResult = Apollo.QueryResult<
  CustomerByIdShortQuery,
  CustomerByIdShortQueryVariables
>;
export const CustomerByIdLongDocument = gql`
  query customerByIdLong($id: ID!) {
    customerById(id: $id) {
      ...customerLongFragment
    }
  }
  ${CustomerLongFragmentFragmentDoc}
`;

/**
 * __useCustomerByIdLongQuery__
 *
 * To run a query within a React component, call `useCustomerByIdLongQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIdLongQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIdLongQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerByIdLongQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerByIdLongQuery,
    CustomerByIdLongQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerByIdLongQuery, CustomerByIdLongQueryVariables>(
    CustomerByIdLongDocument,
    options
  );
}
export function useCustomerByIdLongLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerByIdLongQuery,
    CustomerByIdLongQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerByIdLongQuery,
    CustomerByIdLongQueryVariables
  >(CustomerByIdLongDocument, options);
}
export type CustomerByIdLongQueryHookResult = ReturnType<
  typeof useCustomerByIdLongQuery
>;
export type CustomerByIdLongLazyQueryHookResult = ReturnType<
  typeof useCustomerByIdLongLazyQuery
>;
export type CustomerByIdLongQueryResult = Apollo.QueryResult<
  CustomerByIdLongQuery,
  CustomerByIdLongQueryVariables
>;
export const CustomersDocument = gql`
  query customers {
    customers {
      count
      customers {
        id
        title
        businessCount
        contactsCount
        integrationCount
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options
  );
}
export function useCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options
  );
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<
  typeof useCustomersLazyQuery
>;
export type CustomersQueryResult = Apollo.QueryResult<
  CustomersQuery,
  CustomersQueryVariables
>;
export const EventByIdDocument = gql`
  query eventById($id: ID!) {
    eventById(id: $id) {
      ...eventFragment
    }
  }
  ${EventFragmentFragmentDoc}
`;

/**
 * __useEventByIdQuery__
 *
 * To run a query within a React component, call `useEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<EventByIdQuery, EventByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventByIdQuery, EventByIdQueryVariables>(
    EventByIdDocument,
    options
  );
}
export function useEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventByIdQuery,
    EventByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventByIdQuery, EventByIdQueryVariables>(
    EventByIdDocument,
    options
  );
}
export type EventByIdQueryHookResult = ReturnType<typeof useEventByIdQuery>;
export type EventByIdLazyQueryHookResult = ReturnType<
  typeof useEventByIdLazyQuery
>;
export type EventByIdQueryResult = Apollo.QueryResult<
  EventByIdQuery,
  EventByIdQueryVariables
>;
export const EventLogsDocument = gql`
  query eventLogs(
    $keys: [EventLogKeyEnum!]
    $userIds: [ID!]
    $skip: Int
    $limit: Int
  ) {
    eventLogs(keys: $keys, userIds: $userIds, skip: $skip, limit: $limit) {
      result {
        id
        content
        key
        createdAt
        user {
          id
          firstName
          lastName
          email
        }
      }
      count
    }
  }
`;

/**
 * __useEventLogsQuery__
 *
 * To run a query within a React component, call `useEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventLogsQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *      userIds: // value for 'userIds'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventLogsQuery, EventLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventLogsQuery, EventLogsQueryVariables>(
    EventLogsDocument,
    options
  );
}
export function useEventLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventLogsQuery,
    EventLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventLogsQuery, EventLogsQueryVariables>(
    EventLogsDocument,
    options
  );
}
export type EventLogsQueryHookResult = ReturnType<typeof useEventLogsQuery>;
export type EventLogsLazyQueryHookResult = ReturnType<
  typeof useEventLogsLazyQuery
>;
export type EventLogsQueryResult = Apollo.QueryResult<
  EventLogsQuery,
  EventLogsQueryVariables
>;
export const EventsDocument = gql`
  query events($searchText: String) {
    events(searchText: $searchText) {
      count
      events {
        ...eventFragment
      }
    }
  }
  ${EventFragmentFragmentDoc}
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options
  );
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options
  );
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>;
export const FormSubmissionReportDocument = gql`
  query formSubmissionReport($formTemplateId: ID!) {
    formSubmissionReport(formTemplateId: $formTemplateId) {
      id
      formTemplateId
      respondentIds
      totalFormRespondents
      question {
        ...formQuestionFragment
      }
      responses {
        answers
        value
        label
        respondentIds
      }
    }
  }
  ${FormQuestionFragmentFragmentDoc}
`;

/**
 * __useFormSubmissionReportQuery__
 *
 * To run a query within a React component, call `useFormSubmissionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionReportQuery({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useFormSubmissionReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormSubmissionReportQuery,
    FormSubmissionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FormSubmissionReportQuery,
    FormSubmissionReportQueryVariables
  >(FormSubmissionReportDocument, options);
}
export function useFormSubmissionReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormSubmissionReportQuery,
    FormSubmissionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormSubmissionReportQuery,
    FormSubmissionReportQueryVariables
  >(FormSubmissionReportDocument, options);
}
export type FormSubmissionReportQueryHookResult = ReturnType<
  typeof useFormSubmissionReportQuery
>;
export type FormSubmissionReportLazyQueryHookResult = ReturnType<
  typeof useFormSubmissionReportLazyQuery
>;
export type FormSubmissionReportQueryResult = Apollo.QueryResult<
  FormSubmissionReportQuery,
  FormSubmissionReportQueryVariables
>;
export const FundingProgramsDocument = gql`
  query fundingPrograms(
    $searchText: String
    $skip: Int
    $limit: Int
    $supportTypes: [String]
    $businessStages: [String]
    $diversityTypes: [String]
  ) {
    fundingPrograms(
      searchText: $searchText
      skip: $skip
      limit: $limit
      supportTypes: $supportTypes
      businessStages: $businessStages
      diversityTypes: $diversityTypes
    ) {
      count
      fundingPrograms {
        ...resourceFragment
      }
    }
  }
  ${ResourceFragmentFragmentDoc}
`;

/**
 * __useFundingProgramsQuery__
 *
 * To run a query within a React component, call `useFundingProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingProgramsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      supportTypes: // value for 'supportTypes'
 *      businessStages: // value for 'businessStages'
 *      diversityTypes: // value for 'diversityTypes'
 *   },
 * });
 */
export function useFundingProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FundingProgramsQuery,
    FundingProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingProgramsQuery, FundingProgramsQueryVariables>(
    FundingProgramsDocument,
    options
  );
}
export function useFundingProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FundingProgramsQuery,
    FundingProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FundingProgramsQuery,
    FundingProgramsQueryVariables
  >(FundingProgramsDocument, options);
}
export type FundingProgramsQueryHookResult = ReturnType<
  typeof useFundingProgramsQuery
>;
export type FundingProgramsLazyQueryHookResult = ReturnType<
  typeof useFundingProgramsLazyQuery
>;
export type FundingProgramsQueryResult = Apollo.QueryResult<
  FundingProgramsQuery,
  FundingProgramsQueryVariables
>;
export const GetCompanyByDomainDocument = gql`
  query getCompanyByDomain($domain: String!) {
    getCompanyByDomain(domain: $domain) {
      businessId
      country
      street
      employees
      revenue
      state
      postal
      description
      facebook
      city
      industry
      title
      website
      twitter
      phone
      founded
      logo
      exists
    }
  }
`;

/**
 * __useGetCompanyByDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetCompanyByDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyByDomainQuery,
    GetCompanyByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyByDomainQuery,
    GetCompanyByDomainQueryVariables
  >(GetCompanyByDomainDocument, options);
}
export function useGetCompanyByDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyByDomainQuery,
    GetCompanyByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyByDomainQuery,
    GetCompanyByDomainQueryVariables
  >(GetCompanyByDomainDocument, options);
}
export type GetCompanyByDomainQueryHookResult = ReturnType<
  typeof useGetCompanyByDomainQuery
>;
export type GetCompanyByDomainLazyQueryHookResult = ReturnType<
  typeof useGetCompanyByDomainLazyQuery
>;
export type GetCompanyByDomainQueryResult = Apollo.QueryResult<
  GetCompanyByDomainQuery,
  GetCompanyByDomainQueryVariables
>;
export const GetEstimatedRecipientsForEmailCampaignDocument = gql`
  query getEstimatedRecipientsForEmailCampaign(
    $recipientReferences: [EmailCampaignRecipientReferenceInput!]
    $days: Int
  ) {
    getEstimatedRecipientsForEmailCampaign(
      recipientReferences: $recipientReferences
    ) {
      count
      emailsSent(days: $days)
    }
  }
`;

/**
 * __useGetEstimatedRecipientsForEmailCampaignQuery__
 *
 * To run a query within a React component, call `useGetEstimatedRecipientsForEmailCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstimatedRecipientsForEmailCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstimatedRecipientsForEmailCampaignQuery({
 *   variables: {
 *      recipientReferences: // value for 'recipientReferences'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetEstimatedRecipientsForEmailCampaignQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEstimatedRecipientsForEmailCampaignQuery,
    GetEstimatedRecipientsForEmailCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEstimatedRecipientsForEmailCampaignQuery,
    GetEstimatedRecipientsForEmailCampaignQueryVariables
  >(GetEstimatedRecipientsForEmailCampaignDocument, options);
}
export function useGetEstimatedRecipientsForEmailCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstimatedRecipientsForEmailCampaignQuery,
    GetEstimatedRecipientsForEmailCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEstimatedRecipientsForEmailCampaignQuery,
    GetEstimatedRecipientsForEmailCampaignQueryVariables
  >(GetEstimatedRecipientsForEmailCampaignDocument, options);
}
export type GetEstimatedRecipientsForEmailCampaignQueryHookResult = ReturnType<
  typeof useGetEstimatedRecipientsForEmailCampaignQuery
>;
export type GetEstimatedRecipientsForEmailCampaignLazyQueryHookResult =
  ReturnType<typeof useGetEstimatedRecipientsForEmailCampaignLazyQuery>;
export type GetEstimatedRecipientsForEmailCampaignQueryResult =
  Apollo.QueryResult<
    GetEstimatedRecipientsForEmailCampaignQuery,
    GetEstimatedRecipientsForEmailCampaignQueryVariables
  >;
export const GetFormQuestionsOtherAnswersDocument = gql`
  query getFormQuestionsOtherAnswers(
    $formTemplateId: ID!
    $questionId: ID!
    $otherOptionValue: ID!
  ) {
    getFormQuestionsOtherAnswers(
      formTemplateId: $formTemplateId
      questionId: $questionId
      otherOptionValue: $otherOptionValue
    ) {
      id
      textValue
    }
  }
`;

/**
 * __useGetFormQuestionsOtherAnswersQuery__
 *
 * To run a query within a React component, call `useGetFormQuestionsOtherAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuestionsOtherAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuestionsOtherAnswersQuery({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *      questionId: // value for 'questionId'
 *      otherOptionValue: // value for 'otherOptionValue'
 *   },
 * });
 */
export function useGetFormQuestionsOtherAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormQuestionsOtherAnswersQuery,
    GetFormQuestionsOtherAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormQuestionsOtherAnswersQuery,
    GetFormQuestionsOtherAnswersQueryVariables
  >(GetFormQuestionsOtherAnswersDocument, options);
}
export function useGetFormQuestionsOtherAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormQuestionsOtherAnswersQuery,
    GetFormQuestionsOtherAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormQuestionsOtherAnswersQuery,
    GetFormQuestionsOtherAnswersQueryVariables
  >(GetFormQuestionsOtherAnswersDocument, options);
}
export type GetFormQuestionsOtherAnswersQueryHookResult = ReturnType<
  typeof useGetFormQuestionsOtherAnswersQuery
>;
export type GetFormQuestionsOtherAnswersLazyQueryHookResult = ReturnType<
  typeof useGetFormQuestionsOtherAnswersLazyQuery
>;
export type GetFormQuestionsOtherAnswersQueryResult = Apollo.QueryResult<
  GetFormQuestionsOtherAnswersQuery,
  GetFormQuestionsOtherAnswersQueryVariables
>;
export const GetManyAnswersDocument = gql`
  query getManyAnswers(
    $templateId: String
    $businessId: String
    $contactId: String
    $answerId: String
    $surveyCampaignId: ID
  ) {
    getManyAnswers(
      templateId: $templateId
      businessId: $businessId
      contactId: $contactId
      answerId: $answerId
      surveyCampaignId: $surveyCampaignId
    ) {
      id
      customerId
      surveyTemplateId
      template
      answeredBy
      stringifiedAnswers
      createdAt
    }
  }
`;

/**
 * __useGetManyAnswersQuery__
 *
 * To run a query within a React component, call `useGetManyAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyAnswersQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *      answerId: // value for 'answerId'
 *      surveyCampaignId: // value for 'surveyCampaignId'
 *   },
 * });
 */
export function useGetManyAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyAnswersQuery,
    GetManyAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyAnswersQuery, GetManyAnswersQueryVariables>(
    GetManyAnswersDocument,
    options
  );
}
export function useGetManyAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyAnswersQuery,
    GetManyAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyAnswersQuery, GetManyAnswersQueryVariables>(
    GetManyAnswersDocument,
    options
  );
}
export type GetManyAnswersQueryHookResult = ReturnType<
  typeof useGetManyAnswersQuery
>;
export type GetManyAnswersLazyQueryHookResult = ReturnType<
  typeof useGetManyAnswersLazyQuery
>;
export type GetManyAnswersQueryResult = Apollo.QueryResult<
  GetManyAnswersQuery,
  GetManyAnswersQueryVariables
>;
export const GetManyAutomationsDocument = gql`
  query getManyAutomations {
    getManyAutomations {
      ...automationFragment
    }
  }
  ${AutomationFragmentFragmentDoc}
`;

/**
 * __useGetManyAutomationsQuery__
 *
 * To run a query within a React component, call `useGetManyAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyAutomationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyAutomationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyAutomationsQuery,
    GetManyAutomationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyAutomationsQuery,
    GetManyAutomationsQueryVariables
  >(GetManyAutomationsDocument, options);
}
export function useGetManyAutomationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyAutomationsQuery,
    GetManyAutomationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyAutomationsQuery,
    GetManyAutomationsQueryVariables
  >(GetManyAutomationsDocument, options);
}
export type GetManyAutomationsQueryHookResult = ReturnType<
  typeof useGetManyAutomationsQuery
>;
export type GetManyAutomationsLazyQueryHookResult = ReturnType<
  typeof useGetManyAutomationsLazyQuery
>;
export type GetManyAutomationsQueryResult = Apollo.QueryResult<
  GetManyAutomationsQuery,
  GetManyAutomationsQueryVariables
>;
export const GetManyCaseOutcomesDocument = gql`
  query getManyCaseOutcomes {
    getManyCaseOutcomes {
      ...caseOutcomeFragment
    }
  }
  ${CaseOutcomeFragmentFragmentDoc}
`;

/**
 * __useGetManyCaseOutcomesQuery__
 *
 * To run a query within a React component, call `useGetManyCaseOutcomesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCaseOutcomesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyCaseOutcomesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyCaseOutcomesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyCaseOutcomesQuery,
    GetManyCaseOutcomesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyCaseOutcomesQuery,
    GetManyCaseOutcomesQueryVariables
  >(GetManyCaseOutcomesDocument, options);
}
export function useGetManyCaseOutcomesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyCaseOutcomesQuery,
    GetManyCaseOutcomesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyCaseOutcomesQuery,
    GetManyCaseOutcomesQueryVariables
  >(GetManyCaseOutcomesDocument, options);
}
export type GetManyCaseOutcomesQueryHookResult = ReturnType<
  typeof useGetManyCaseOutcomesQuery
>;
export type GetManyCaseOutcomesLazyQueryHookResult = ReturnType<
  typeof useGetManyCaseOutcomesLazyQuery
>;
export type GetManyCaseOutcomesQueryResult = Apollo.QueryResult<
  GetManyCaseOutcomesQuery,
  GetManyCaseOutcomesQueryVariables
>;
export const GetManyCaseTemplatesDocument = gql`
  query getManyCaseTemplates {
    getManyCaseTemplates {
      ...caseTemplateFragment
    }
  }
  ${CaseTemplateFragmentFragmentDoc}
`;

/**
 * __useGetManyCaseTemplatesQuery__
 *
 * To run a query within a React component, call `useGetManyCaseTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCaseTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyCaseTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyCaseTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyCaseTemplatesQuery,
    GetManyCaseTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyCaseTemplatesQuery,
    GetManyCaseTemplatesQueryVariables
  >(GetManyCaseTemplatesDocument, options);
}
export function useGetManyCaseTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyCaseTemplatesQuery,
    GetManyCaseTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyCaseTemplatesQuery,
    GetManyCaseTemplatesQueryVariables
  >(GetManyCaseTemplatesDocument, options);
}
export type GetManyCaseTemplatesQueryHookResult = ReturnType<
  typeof useGetManyCaseTemplatesQuery
>;
export type GetManyCaseTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetManyCaseTemplatesLazyQuery
>;
export type GetManyCaseTemplatesQueryResult = Apollo.QueryResult<
  GetManyCaseTemplatesQuery,
  GetManyCaseTemplatesQueryVariables
>;
export const GetManyConstantContactListsDocument = gql`
  query getManyConstantContactLists {
    getManyConstantContactLists {
      list_id
      name
      favorite
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetManyConstantContactListsQuery__
 *
 * To run a query within a React component, call `useGetManyConstantContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyConstantContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyConstantContactListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyConstantContactListsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyConstantContactListsQuery,
    GetManyConstantContactListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyConstantContactListsQuery,
    GetManyConstantContactListsQueryVariables
  >(GetManyConstantContactListsDocument, options);
}
export function useGetManyConstantContactListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyConstantContactListsQuery,
    GetManyConstantContactListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyConstantContactListsQuery,
    GetManyConstantContactListsQueryVariables
  >(GetManyConstantContactListsDocument, options);
}
export type GetManyConstantContactListsQueryHookResult = ReturnType<
  typeof useGetManyConstantContactListsQuery
>;
export type GetManyConstantContactListsLazyQueryHookResult = ReturnType<
  typeof useGetManyConstantContactListsLazyQuery
>;
export type GetManyConstantContactListsQueryResult = Apollo.QueryResult<
  GetManyConstantContactListsQuery,
  GetManyConstantContactListsQueryVariables
>;
export const GetManyCustomerFeaturesDocument = gql`
  query getManyCustomerFeatures {
    getManyCustomerFeatures
  }
`;

/**
 * __useGetManyCustomerFeaturesQuery__
 *
 * To run a query within a React component, call `useGetManyCustomerFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCustomerFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyCustomerFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyCustomerFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyCustomerFeaturesQuery,
    GetManyCustomerFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyCustomerFeaturesQuery,
    GetManyCustomerFeaturesQueryVariables
  >(GetManyCustomerFeaturesDocument, options);
}
export function useGetManyCustomerFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyCustomerFeaturesQuery,
    GetManyCustomerFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyCustomerFeaturesQuery,
    GetManyCustomerFeaturesQueryVariables
  >(GetManyCustomerFeaturesDocument, options);
}
export type GetManyCustomerFeaturesQueryHookResult = ReturnType<
  typeof useGetManyCustomerFeaturesQuery
>;
export type GetManyCustomerFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetManyCustomerFeaturesLazyQuery
>;
export type GetManyCustomerFeaturesQueryResult = Apollo.QueryResult<
  GetManyCustomerFeaturesQuery,
  GetManyCustomerFeaturesQueryVariables
>;
export const GetManyDataDiscoveryRunsDocument = gql`
  query getManyDataDiscoveryRuns {
    getManyDataDiscoveryRuns {
      count
      results {
        ...dataDiscoveryRunFragment
      }
    }
  }
  ${DataDiscoveryRunFragmentFragmentDoc}
`;

/**
 * __useGetManyDataDiscoveryRunsQuery__
 *
 * To run a query within a React component, call `useGetManyDataDiscoveryRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDataDiscoveryRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDataDiscoveryRunsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyDataDiscoveryRunsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDataDiscoveryRunsQuery,
    GetManyDataDiscoveryRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDataDiscoveryRunsQuery,
    GetManyDataDiscoveryRunsQueryVariables
  >(GetManyDataDiscoveryRunsDocument, options);
}
export function useGetManyDataDiscoveryRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDataDiscoveryRunsQuery,
    GetManyDataDiscoveryRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDataDiscoveryRunsQuery,
    GetManyDataDiscoveryRunsQueryVariables
  >(GetManyDataDiscoveryRunsDocument, options);
}
export type GetManyDataDiscoveryRunsQueryHookResult = ReturnType<
  typeof useGetManyDataDiscoveryRunsQuery
>;
export type GetManyDataDiscoveryRunsLazyQueryHookResult = ReturnType<
  typeof useGetManyDataDiscoveryRunsLazyQuery
>;
export type GetManyDataDiscoveryRunsQueryResult = Apollo.QueryResult<
  GetManyDataDiscoveryRunsQuery,
  GetManyDataDiscoveryRunsQueryVariables
>;
export const GetManyDefaultFormTemplateQuestionsDocument = gql`
  query getManyDefaultFormTemplateQuestions {
    getManyDefaultFormTemplateQuestions {
      id
      questions {
        ...formQuestionFragment
      }
    }
  }
  ${FormQuestionFragmentFragmentDoc}
`;

/**
 * __useGetManyDefaultFormTemplateQuestionsQuery__
 *
 * To run a query within a React component, call `useGetManyDefaultFormTemplateQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDefaultFormTemplateQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDefaultFormTemplateQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyDefaultFormTemplateQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDefaultFormTemplateQuestionsQuery,
    GetManyDefaultFormTemplateQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDefaultFormTemplateQuestionsQuery,
    GetManyDefaultFormTemplateQuestionsQueryVariables
  >(GetManyDefaultFormTemplateQuestionsDocument, options);
}
export function useGetManyDefaultFormTemplateQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDefaultFormTemplateQuestionsQuery,
    GetManyDefaultFormTemplateQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDefaultFormTemplateQuestionsQuery,
    GetManyDefaultFormTemplateQuestionsQueryVariables
  >(GetManyDefaultFormTemplateQuestionsDocument, options);
}
export type GetManyDefaultFormTemplateQuestionsQueryHookResult = ReturnType<
  typeof useGetManyDefaultFormTemplateQuestionsQuery
>;
export type GetManyDefaultFormTemplateQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetManyDefaultFormTemplateQuestionsLazyQuery
>;
export type GetManyDefaultFormTemplateQuestionsQueryResult = Apollo.QueryResult<
  GetManyDefaultFormTemplateQuestionsQuery,
  GetManyDefaultFormTemplateQuestionsQueryVariables
>;
export const GetManyDefaultSurveyTemplatesDocument = gql`
  query getManyDefaultSurveyTemplates {
    getManyDefaultSurveyTemplates {
      id
      createdBy
      template
      title
    }
  }
`;

/**
 * __useGetManyDefaultSurveyTemplatesQuery__
 *
 * To run a query within a React component, call `useGetManyDefaultSurveyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDefaultSurveyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDefaultSurveyTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyDefaultSurveyTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDefaultSurveyTemplatesQuery,
    GetManyDefaultSurveyTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDefaultSurveyTemplatesQuery,
    GetManyDefaultSurveyTemplatesQueryVariables
  >(GetManyDefaultSurveyTemplatesDocument, options);
}
export function useGetManyDefaultSurveyTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDefaultSurveyTemplatesQuery,
    GetManyDefaultSurveyTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDefaultSurveyTemplatesQuery,
    GetManyDefaultSurveyTemplatesQueryVariables
  >(GetManyDefaultSurveyTemplatesDocument, options);
}
export type GetManyDefaultSurveyTemplatesQueryHookResult = ReturnType<
  typeof useGetManyDefaultSurveyTemplatesQuery
>;
export type GetManyDefaultSurveyTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetManyDefaultSurveyTemplatesLazyQuery
>;
export type GetManyDefaultSurveyTemplatesQueryResult = Apollo.QueryResult<
  GetManyDefaultSurveyTemplatesQuery,
  GetManyDefaultSurveyTemplatesQueryVariables
>;
export const GetManyEmailCampaignsDocument = gql`
  query getManyEmailCampaigns($pagination: PaginationInput) {
    getManyEmailCampaigns(pagination: $pagination) {
      results {
        ...emailCampaignFragment
      }
      count
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;

/**
 * __useGetManyEmailCampaignsQuery__
 *
 * To run a query within a React component, call `useGetManyEmailCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyEmailCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyEmailCampaignsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetManyEmailCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyEmailCampaignsQuery,
    GetManyEmailCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyEmailCampaignsQuery,
    GetManyEmailCampaignsQueryVariables
  >(GetManyEmailCampaignsDocument, options);
}
export function useGetManyEmailCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyEmailCampaignsQuery,
    GetManyEmailCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyEmailCampaignsQuery,
    GetManyEmailCampaignsQueryVariables
  >(GetManyEmailCampaignsDocument, options);
}
export type GetManyEmailCampaignsQueryHookResult = ReturnType<
  typeof useGetManyEmailCampaignsQuery
>;
export type GetManyEmailCampaignsLazyQueryHookResult = ReturnType<
  typeof useGetManyEmailCampaignsLazyQuery
>;
export type GetManyEmailCampaignsQueryResult = Apollo.QueryResult<
  GetManyEmailCampaignsQuery,
  GetManyEmailCampaignsQueryVariables
>;
export const GetManyEmailThreadsDocument = gql`
  query getManyEmailThreads($itemId: ID!, $pagination: PaginationInput) {
    getManyEmailThreads(itemId: $itemId, pagination: $pagination) {
      id
      subject
      interactions {
        ...interactionFragment
      }
    }
  }
  ${InteractionFragmentFragmentDoc}
`;

/**
 * __useGetManyEmailThreadsQuery__
 *
 * To run a query within a React component, call `useGetManyEmailThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyEmailThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyEmailThreadsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetManyEmailThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManyEmailThreadsQuery,
    GetManyEmailThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyEmailThreadsQuery,
    GetManyEmailThreadsQueryVariables
  >(GetManyEmailThreadsDocument, options);
}
export function useGetManyEmailThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyEmailThreadsQuery,
    GetManyEmailThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyEmailThreadsQuery,
    GetManyEmailThreadsQueryVariables
  >(GetManyEmailThreadsDocument, options);
}
export type GetManyEmailThreadsQueryHookResult = ReturnType<
  typeof useGetManyEmailThreadsQuery
>;
export type GetManyEmailThreadsLazyQueryHookResult = ReturnType<
  typeof useGetManyEmailThreadsLazyQuery
>;
export type GetManyEmailThreadsQueryResult = Apollo.QueryResult<
  GetManyEmailThreadsQuery,
  GetManyEmailThreadsQueryVariables
>;
export const GetManyFormTemplatesDocument = gql`
  query getManyFormTemplates {
    getManyFormTemplates {
      ...formTemplateFragment
    }
  }
  ${FormTemplateFragmentFragmentDoc}
`;

/**
 * __useGetManyFormTemplatesQuery__
 *
 * To run a query within a React component, call `useGetManyFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyFormTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyFormTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyFormTemplatesQuery,
    GetManyFormTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyFormTemplatesQuery,
    GetManyFormTemplatesQueryVariables
  >(GetManyFormTemplatesDocument, options);
}
export function useGetManyFormTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyFormTemplatesQuery,
    GetManyFormTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyFormTemplatesQuery,
    GetManyFormTemplatesQueryVariables
  >(GetManyFormTemplatesDocument, options);
}
export type GetManyFormTemplatesQueryHookResult = ReturnType<
  typeof useGetManyFormTemplatesQuery
>;
export type GetManyFormTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetManyFormTemplatesLazyQuery
>;
export type GetManyFormTemplatesQueryResult = Apollo.QueryResult<
  GetManyFormTemplatesQuery,
  GetManyFormTemplatesQueryVariables
>;
export const GetManyIntegrationsDocument = gql`
  query getManyIntegrations($includeInvalidGrants: Boolean) {
    getManyIntegrations(includeInvalidGrants: $includeInvalidGrants) {
      ...emailIntegrationFragment
    }
  }
  ${EmailIntegrationFragmentFragmentDoc}
`;

/**
 * __useGetManyIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetManyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyIntegrationsQuery({
 *   variables: {
 *      includeInvalidGrants: // value for 'includeInvalidGrants'
 *   },
 * });
 */
export function useGetManyIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyIntegrationsQuery,
    GetManyIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyIntegrationsQuery,
    GetManyIntegrationsQueryVariables
  >(GetManyIntegrationsDocument, options);
}
export function useGetManyIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyIntegrationsQuery,
    GetManyIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyIntegrationsQuery,
    GetManyIntegrationsQueryVariables
  >(GetManyIntegrationsDocument, options);
}
export type GetManyIntegrationsQueryHookResult = ReturnType<
  typeof useGetManyIntegrationsQuery
>;
export type GetManyIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGetManyIntegrationsLazyQuery
>;
export type GetManyIntegrationsQueryResult = Apollo.QueryResult<
  GetManyIntegrationsQuery,
  GetManyIntegrationsQueryVariables
>;
export const GetManyInteractionCategoriesDocument = gql`
  query getManyInteractionCategories {
    getManyInteractionCategories {
      id
      title
    }
  }
`;

/**
 * __useGetManyInteractionCategoriesQuery__
 *
 * To run a query within a React component, call `useGetManyInteractionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInteractionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInteractionCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyInteractionCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyInteractionCategoriesQuery,
    GetManyInteractionCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyInteractionCategoriesQuery,
    GetManyInteractionCategoriesQueryVariables
  >(GetManyInteractionCategoriesDocument, options);
}
export function useGetManyInteractionCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyInteractionCategoriesQuery,
    GetManyInteractionCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyInteractionCategoriesQuery,
    GetManyInteractionCategoriesQueryVariables
  >(GetManyInteractionCategoriesDocument, options);
}
export type GetManyInteractionCategoriesQueryHookResult = ReturnType<
  typeof useGetManyInteractionCategoriesQuery
>;
export type GetManyInteractionCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetManyInteractionCategoriesLazyQuery
>;
export type GetManyInteractionCategoriesQueryResult = Apollo.QueryResult<
  GetManyInteractionCategoriesQuery,
  GetManyInteractionCategoriesQueryVariables
>;
export const GetManyListsDocument = gql`
  query getManyLists(
    $listType: ListTypeEnum!
    $ownerType: GetManyListsOwnerTypeEnum!
    $pagination: PaginationInput
  ) {
    getManyLists(
      listType: $listType
      ownerType: $ownerType
      pagination: $pagination
    ) {
      results {
        ...listFragment
      }
      count
    }
  }
  ${ListFragmentFragmentDoc}
`;

/**
 * __useGetManyListsQuery__
 *
 * To run a query within a React component, call `useGetManyListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyListsQuery({
 *   variables: {
 *      listType: // value for 'listType'
 *      ownerType: // value for 'ownerType'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetManyListsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManyListsQuery,
    GetManyListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyListsQuery, GetManyListsQueryVariables>(
    GetManyListsDocument,
    options
  );
}
export function useGetManyListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyListsQuery,
    GetManyListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyListsQuery, GetManyListsQueryVariables>(
    GetManyListsDocument,
    options
  );
}
export type GetManyListsQueryHookResult = ReturnType<
  typeof useGetManyListsQuery
>;
export type GetManyListsLazyQueryHookResult = ReturnType<
  typeof useGetManyListsLazyQuery
>;
export type GetManyListsQueryResult = Apollo.QueryResult<
  GetManyListsQuery,
  GetManyListsQueryVariables
>;
export const GetManyNaicsDocument = gql`
  query getManyNaics {
    getManyNaics {
      id
      title
      code
    }
  }
`;

/**
 * __useGetManyNaicsQuery__
 *
 * To run a query within a React component, call `useGetManyNaicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyNaicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyNaicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyNaicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyNaicsQuery,
    GetManyNaicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyNaicsQuery, GetManyNaicsQueryVariables>(
    GetManyNaicsDocument,
    options
  );
}
export function useGetManyNaicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyNaicsQuery,
    GetManyNaicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyNaicsQuery, GetManyNaicsQueryVariables>(
    GetManyNaicsDocument,
    options
  );
}
export type GetManyNaicsQueryHookResult = ReturnType<
  typeof useGetManyNaicsQuery
>;
export type GetManyNaicsLazyQueryHookResult = ReturnType<
  typeof useGetManyNaicsLazyQuery
>;
export type GetManyNaicsQueryResult = Apollo.QueryResult<
  GetManyNaicsQuery,
  GetManyNaicsQueryVariables
>;
export const GetManyObjectPermissionsDocument = gql`
  query getManyObjectPermissions($type: ObjectTypeEnum!, $id: ID!) {
    getManyObjectPermissions(type: $type, id: $id) {
      ...objectPermissionFragment
    }
  }
  ${ObjectPermissionFragmentFragmentDoc}
`;

/**
 * __useGetManyObjectPermissionsQuery__
 *
 * To run a query within a React component, call `useGetManyObjectPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyObjectPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyObjectPermissionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManyObjectPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManyObjectPermissionsQuery,
    GetManyObjectPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyObjectPermissionsQuery,
    GetManyObjectPermissionsQueryVariables
  >(GetManyObjectPermissionsDocument, options);
}
export function useGetManyObjectPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyObjectPermissionsQuery,
    GetManyObjectPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyObjectPermissionsQuery,
    GetManyObjectPermissionsQueryVariables
  >(GetManyObjectPermissionsDocument, options);
}
export type GetManyObjectPermissionsQueryHookResult = ReturnType<
  typeof useGetManyObjectPermissionsQuery
>;
export type GetManyObjectPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetManyObjectPermissionsLazyQuery
>;
export type GetManyObjectPermissionsQueryResult = Apollo.QueryResult<
  GetManyObjectPermissionsQuery,
  GetManyObjectPermissionsQueryVariables
>;
export const GetManySurveyCampaignItemsDocument = gql`
  query getManySurveyCampaignItems(
    $surveyCampaignId: ID!
    $pagination: PaginationInput!
  ) {
    getManySurveyCampaignItems(
      surveyCampaignId: $surveyCampaignId
      pagination: $pagination
    ) {
      campaign {
        ...surveyCampaignFragment
      }
      results {
        ...surveyCampaignItemFragment
      }
      count
    }
  }
  ${SurveyCampaignFragmentFragmentDoc}
  ${SurveyCampaignItemFragmentFragmentDoc}
`;

/**
 * __useGetManySurveyCampaignItemsQuery__
 *
 * To run a query within a React component, call `useGetManySurveyCampaignItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySurveyCampaignItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySurveyCampaignItemsQuery({
 *   variables: {
 *      surveyCampaignId: // value for 'surveyCampaignId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetManySurveyCampaignItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManySurveyCampaignItemsQuery,
    GetManySurveyCampaignItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManySurveyCampaignItemsQuery,
    GetManySurveyCampaignItemsQueryVariables
  >(GetManySurveyCampaignItemsDocument, options);
}
export function useGetManySurveyCampaignItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySurveyCampaignItemsQuery,
    GetManySurveyCampaignItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySurveyCampaignItemsQuery,
    GetManySurveyCampaignItemsQueryVariables
  >(GetManySurveyCampaignItemsDocument, options);
}
export type GetManySurveyCampaignItemsQueryHookResult = ReturnType<
  typeof useGetManySurveyCampaignItemsQuery
>;
export type GetManySurveyCampaignItemsLazyQueryHookResult = ReturnType<
  typeof useGetManySurveyCampaignItemsLazyQuery
>;
export type GetManySurveyCampaignItemsQueryResult = Apollo.QueryResult<
  GetManySurveyCampaignItemsQuery,
  GetManySurveyCampaignItemsQueryVariables
>;
export const GetManySurveyCampaignsDocument = gql`
  query getManySurveyCampaigns($listType: ListTypeEnum!) {
    getManySurveyCampaigns(listType: $listType) {
      ...surveyCampaignFragment
    }
  }
  ${SurveyCampaignFragmentFragmentDoc}
`;

/**
 * __useGetManySurveyCampaignsQuery__
 *
 * To run a query within a React component, call `useGetManySurveyCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySurveyCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySurveyCampaignsQuery({
 *   variables: {
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useGetManySurveyCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManySurveyCampaignsQuery,
    GetManySurveyCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManySurveyCampaignsQuery,
    GetManySurveyCampaignsQueryVariables
  >(GetManySurveyCampaignsDocument, options);
}
export function useGetManySurveyCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySurveyCampaignsQuery,
    GetManySurveyCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySurveyCampaignsQuery,
    GetManySurveyCampaignsQueryVariables
  >(GetManySurveyCampaignsDocument, options);
}
export type GetManySurveyCampaignsQueryHookResult = ReturnType<
  typeof useGetManySurveyCampaignsQuery
>;
export type GetManySurveyCampaignsLazyQueryHookResult = ReturnType<
  typeof useGetManySurveyCampaignsLazyQuery
>;
export type GetManySurveyCampaignsQueryResult = Apollo.QueryResult<
  GetManySurveyCampaignsQuery,
  GetManySurveyCampaignsQueryVariables
>;
export const GetManySurveyTemplatesNewDocument = gql`
  query getManySurveyTemplatesNew {
    getManySurveyTemplatesNew {
      ...surveyTemplateNewFragment
    }
  }
  ${SurveyTemplateNewFragmentFragmentDoc}
`;

/**
 * __useGetManySurveyTemplatesNewQuery__
 *
 * To run a query within a React component, call `useGetManySurveyTemplatesNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySurveyTemplatesNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySurveyTemplatesNewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManySurveyTemplatesNewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManySurveyTemplatesNewQuery,
    GetManySurveyTemplatesNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManySurveyTemplatesNewQuery,
    GetManySurveyTemplatesNewQueryVariables
  >(GetManySurveyTemplatesNewDocument, options);
}
export function useGetManySurveyTemplatesNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySurveyTemplatesNewQuery,
    GetManySurveyTemplatesNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySurveyTemplatesNewQuery,
    GetManySurveyTemplatesNewQueryVariables
  >(GetManySurveyTemplatesNewDocument, options);
}
export type GetManySurveyTemplatesNewQueryHookResult = ReturnType<
  typeof useGetManySurveyTemplatesNewQuery
>;
export type GetManySurveyTemplatesNewLazyQueryHookResult = ReturnType<
  typeof useGetManySurveyTemplatesNewLazyQuery
>;
export type GetManySurveyTemplatesNewQueryResult = Apollo.QueryResult<
  GetManySurveyTemplatesNewQuery,
  GetManySurveyTemplatesNewQueryVariables
>;
export const GetManyUserGroupsDocument = gql`
  query getManyUserGroups($not: [ID!], $includeCustomerDefault: Boolean) {
    getManyUserGroups(
      not: $not
      includeCustomerDefault: $includeCustomerDefault
    ) {
      ...userGroupFragment
    }
  }
  ${UserGroupFragmentFragmentDoc}
`;

/**
 * __useGetManyUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetManyUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyUserGroupsQuery({
 *   variables: {
 *      not: // value for 'not'
 *      includeCustomerDefault: // value for 'includeCustomerDefault'
 *   },
 * });
 */
export function useGetManyUserGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyUserGroupsQuery,
    GetManyUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyUserGroupsQuery,
    GetManyUserGroupsQueryVariables
  >(GetManyUserGroupsDocument, options);
}
export function useGetManyUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyUserGroupsQuery,
    GetManyUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyUserGroupsQuery,
    GetManyUserGroupsQueryVariables
  >(GetManyUserGroupsDocument, options);
}
export type GetManyUserGroupsQueryHookResult = ReturnType<
  typeof useGetManyUserGroupsQuery
>;
export type GetManyUserGroupsLazyQueryHookResult = ReturnType<
  typeof useGetManyUserGroupsLazyQuery
>;
export type GetManyUserGroupsQueryResult = Apollo.QueryResult<
  GetManyUserGroupsQuery,
  GetManyUserGroupsQueryVariables
>;
export const GetOneDataDiscoveryRunDocument = gql`
  query getOneDataDiscoveryRun(
    $runId: ID!
    $ignored: Boolean
    $newBusinesses: Boolean
    $hasContacts: Boolean
    $pagination: PaginationInput
  ) {
    getOneDataDiscoveryRun(runId: $runId) {
      id
      createdAt
      status
      mergedAt
      mergedBusinesses
      mergedContacts
      contactsFound
      searchTerms
      businessesFound
      newBusinessesFound
      businesses(
        pagination: $pagination
        ignored: $ignored
        newBusinesses: $newBusinesses
        hasContacts: $hasContacts
      ) {
        results {
          ...dataDiscoveryBusinessFragment
        }
        count
      }
    }
  }
  ${DataDiscoveryBusinessFragmentFragmentDoc}
`;

/**
 * __useGetOneDataDiscoveryRunQuery__
 *
 * To run a query within a React component, call `useGetOneDataDiscoveryRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDataDiscoveryRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneDataDiscoveryRunQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *      ignored: // value for 'ignored'
 *      newBusinesses: // value for 'newBusinesses'
 *      hasContacts: // value for 'hasContacts'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetOneDataDiscoveryRunQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneDataDiscoveryRunQuery,
    GetOneDataDiscoveryRunQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneDataDiscoveryRunQuery,
    GetOneDataDiscoveryRunQueryVariables
  >(GetOneDataDiscoveryRunDocument, options);
}
export function useGetOneDataDiscoveryRunLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneDataDiscoveryRunQuery,
    GetOneDataDiscoveryRunQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneDataDiscoveryRunQuery,
    GetOneDataDiscoveryRunQueryVariables
  >(GetOneDataDiscoveryRunDocument, options);
}
export type GetOneDataDiscoveryRunQueryHookResult = ReturnType<
  typeof useGetOneDataDiscoveryRunQuery
>;
export type GetOneDataDiscoveryRunLazyQueryHookResult = ReturnType<
  typeof useGetOneDataDiscoveryRunLazyQuery
>;
export type GetOneDataDiscoveryRunQueryResult = Apollo.QueryResult<
  GetOneDataDiscoveryRunQuery,
  GetOneDataDiscoveryRunQueryVariables
>;
export const GetOneDefaultSurveyTemplateDocument = gql`
  query getOneDefaultSurveyTemplate($defaultTemplateId: String!) {
    getOneDefaultSurveyTemplate(defaultTemplateId: $defaultTemplateId) {
      id
      createdBy
      template
      title
    }
  }
`;

/**
 * __useGetOneDefaultSurveyTemplateQuery__
 *
 * To run a query within a React component, call `useGetOneDefaultSurveyTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDefaultSurveyTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneDefaultSurveyTemplateQuery({
 *   variables: {
 *      defaultTemplateId: // value for 'defaultTemplateId'
 *   },
 * });
 */
export function useGetOneDefaultSurveyTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneDefaultSurveyTemplateQuery,
    GetOneDefaultSurveyTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneDefaultSurveyTemplateQuery,
    GetOneDefaultSurveyTemplateQueryVariables
  >(GetOneDefaultSurveyTemplateDocument, options);
}
export function useGetOneDefaultSurveyTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneDefaultSurveyTemplateQuery,
    GetOneDefaultSurveyTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneDefaultSurveyTemplateQuery,
    GetOneDefaultSurveyTemplateQueryVariables
  >(GetOneDefaultSurveyTemplateDocument, options);
}
export type GetOneDefaultSurveyTemplateQueryHookResult = ReturnType<
  typeof useGetOneDefaultSurveyTemplateQuery
>;
export type GetOneDefaultSurveyTemplateLazyQueryHookResult = ReturnType<
  typeof useGetOneDefaultSurveyTemplateLazyQuery
>;
export type GetOneDefaultSurveyTemplateQueryResult = Apollo.QueryResult<
  GetOneDefaultSurveyTemplateQuery,
  GetOneDefaultSurveyTemplateQueryVariables
>;
export const GetOneEmailCampaignDocument = gql`
  query getOneEmailCampaign($emailCampaignId: ID!) {
    getOneEmailCampaign(emailCampaignId: $emailCampaignId) {
      ...emailCampaignFragment
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
`;

/**
 * __useGetOneEmailCampaignQuery__
 *
 * To run a query within a React component, call `useGetOneEmailCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneEmailCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneEmailCampaignQuery({
 *   variables: {
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useGetOneEmailCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneEmailCampaignQuery,
    GetOneEmailCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneEmailCampaignQuery,
    GetOneEmailCampaignQueryVariables
  >(GetOneEmailCampaignDocument, options);
}
export function useGetOneEmailCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneEmailCampaignQuery,
    GetOneEmailCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneEmailCampaignQuery,
    GetOneEmailCampaignQueryVariables
  >(GetOneEmailCampaignDocument, options);
}
export type GetOneEmailCampaignQueryHookResult = ReturnType<
  typeof useGetOneEmailCampaignQuery
>;
export type GetOneEmailCampaignLazyQueryHookResult = ReturnType<
  typeof useGetOneEmailCampaignLazyQuery
>;
export type GetOneEmailCampaignQueryResult = Apollo.QueryResult<
  GetOneEmailCampaignQuery,
  GetOneEmailCampaignQueryVariables
>;
export const GetOneEmailCampaignWithItemsDocument = gql`
  query getOneEmailCampaignWithItems(
    $emailCampaignId: ID!
    $campaignItemsPagination: PaginationInput
  ) {
    getOneEmailCampaign(emailCampaignId: $emailCampaignId) {
      ...emailCampaignFragment
      emailCampaignItems(pagination: $campaignItemsPagination) {
        results {
          ...emailCampaignItemFragment
        }
        count
      }
    }
  }
  ${EmailCampaignFragmentFragmentDoc}
  ${EmailCampaignItemFragmentFragmentDoc}
`;

/**
 * __useGetOneEmailCampaignWithItemsQuery__
 *
 * To run a query within a React component, call `useGetOneEmailCampaignWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneEmailCampaignWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneEmailCampaignWithItemsQuery({
 *   variables: {
 *      emailCampaignId: // value for 'emailCampaignId'
 *      campaignItemsPagination: // value for 'campaignItemsPagination'
 *   },
 * });
 */
export function useGetOneEmailCampaignWithItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneEmailCampaignWithItemsQuery,
    GetOneEmailCampaignWithItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneEmailCampaignWithItemsQuery,
    GetOneEmailCampaignWithItemsQueryVariables
  >(GetOneEmailCampaignWithItemsDocument, options);
}
export function useGetOneEmailCampaignWithItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneEmailCampaignWithItemsQuery,
    GetOneEmailCampaignWithItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneEmailCampaignWithItemsQuery,
    GetOneEmailCampaignWithItemsQueryVariables
  >(GetOneEmailCampaignWithItemsDocument, options);
}
export type GetOneEmailCampaignWithItemsQueryHookResult = ReturnType<
  typeof useGetOneEmailCampaignWithItemsQuery
>;
export type GetOneEmailCampaignWithItemsLazyQueryHookResult = ReturnType<
  typeof useGetOneEmailCampaignWithItemsLazyQuery
>;
export type GetOneEmailCampaignWithItemsQueryResult = Apollo.QueryResult<
  GetOneEmailCampaignWithItemsQuery,
  GetOneEmailCampaignWithItemsQueryVariables
>;
export const GetOneFormTemplateDocument = gql`
  query getOneFormTemplate($formTemplateId: ID!) {
    getOneFormTemplate(formTemplateId: $formTemplateId) {
      ...formTemplateFragment
    }
  }
  ${FormTemplateFragmentFragmentDoc}
`;

/**
 * __useGetOneFormTemplateQuery__
 *
 * To run a query within a React component, call `useGetOneFormTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneFormTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneFormTemplateQuery({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useGetOneFormTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneFormTemplateQuery,
    GetOneFormTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneFormTemplateQuery,
    GetOneFormTemplateQueryVariables
  >(GetOneFormTemplateDocument, options);
}
export function useGetOneFormTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneFormTemplateQuery,
    GetOneFormTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneFormTemplateQuery,
    GetOneFormTemplateQueryVariables
  >(GetOneFormTemplateDocument, options);
}
export type GetOneFormTemplateQueryHookResult = ReturnType<
  typeof useGetOneFormTemplateQuery
>;
export type GetOneFormTemplateLazyQueryHookResult = ReturnType<
  typeof useGetOneFormTemplateLazyQuery
>;
export type GetOneFormTemplateQueryResult = Apollo.QueryResult<
  GetOneFormTemplateQuery,
  GetOneFormTemplateQueryVariables
>;
export const GetOneInteractionDocument = gql`
  query getOneInteraction($id: ID!) {
    getOneInteraction(id: $id) {
      ...interactionFragment
    }
  }
  ${InteractionFragmentFragmentDoc}
`;

/**
 * __useGetOneInteractionQuery__
 *
 * To run a query within a React component, call `useGetOneInteractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneInteractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneInteractionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneInteractionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneInteractionQuery,
    GetOneInteractionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneInteractionQuery,
    GetOneInteractionQueryVariables
  >(GetOneInteractionDocument, options);
}
export function useGetOneInteractionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneInteractionQuery,
    GetOneInteractionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneInteractionQuery,
    GetOneInteractionQueryVariables
  >(GetOneInteractionDocument, options);
}
export type GetOneInteractionQueryHookResult = ReturnType<
  typeof useGetOneInteractionQuery
>;
export type GetOneInteractionLazyQueryHookResult = ReturnType<
  typeof useGetOneInteractionLazyQuery
>;
export type GetOneInteractionQueryResult = Apollo.QueryResult<
  GetOneInteractionQuery,
  GetOneInteractionQueryVariables
>;
export const GetOneListWithListItemsDocument = gql`
  query getOneListWithListItems(
    $id: ID!
    $listItemPagination: PaginationInput
  ) {
    getOneList(id: $id) {
      id
      name
      listType
      numberOfRecords
      createdBy {
        firstName
        lastName
      }
      updatedAt
      listItems(pagination: $listItemPagination) {
        results {
          ...listItemFragment
        }
        count
      }
    }
  }
  ${ListItemFragmentFragmentDoc}
`;

/**
 * __useGetOneListWithListItemsQuery__
 *
 * To run a query within a React component, call `useGetOneListWithListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneListWithListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneListWithListItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      listItemPagination: // value for 'listItemPagination'
 *   },
 * });
 */
export function useGetOneListWithListItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneListWithListItemsQuery,
    GetOneListWithListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneListWithListItemsQuery,
    GetOneListWithListItemsQueryVariables
  >(GetOneListWithListItemsDocument, options);
}
export function useGetOneListWithListItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneListWithListItemsQuery,
    GetOneListWithListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneListWithListItemsQuery,
    GetOneListWithListItemsQueryVariables
  >(GetOneListWithListItemsDocument, options);
}
export type GetOneListWithListItemsQueryHookResult = ReturnType<
  typeof useGetOneListWithListItemsQuery
>;
export type GetOneListWithListItemsLazyQueryHookResult = ReturnType<
  typeof useGetOneListWithListItemsLazyQuery
>;
export type GetOneListWithListItemsQueryResult = Apollo.QueryResult<
  GetOneListWithListItemsQuery,
  GetOneListWithListItemsQueryVariables
>;
export const GetOneNoteDocument = gql`
  query getOneNote($id: ID!) {
    getOneNote(id: $id) {
      ...noteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;

/**
 * __useGetOneNoteQuery__
 *
 * To run a query within a React component, call `useGetOneNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneNoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneNoteQuery,
    GetOneNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneNoteQuery, GetOneNoteQueryVariables>(
    GetOneNoteDocument,
    options
  );
}
export function useGetOneNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneNoteQuery,
    GetOneNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneNoteQuery, GetOneNoteQueryVariables>(
    GetOneNoteDocument,
    options
  );
}
export type GetOneNoteQueryHookResult = ReturnType<typeof useGetOneNoteQuery>;
export type GetOneNoteLazyQueryHookResult = ReturnType<
  typeof useGetOneNoteLazyQuery
>;
export type GetOneNoteQueryResult = Apollo.QueryResult<
  GetOneNoteQuery,
  GetOneNoteQueryVariables
>;
export const GetOneSurveyResultDocument = gql`
  query getOneSurveyResult($answerId: String!) {
    getManyAnswers(answerId: $answerId) {
      id
      customerId
      surveyTemplateId
      template
      answeredBy
      stringifiedAnswers
      createdAt
      business {
        id
        title
      }
      contact {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetOneSurveyResultQuery__
 *
 * To run a query within a React component, call `useGetOneSurveyResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneSurveyResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneSurveyResultQuery({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useGetOneSurveyResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneSurveyResultQuery,
    GetOneSurveyResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneSurveyResultQuery,
    GetOneSurveyResultQueryVariables
  >(GetOneSurveyResultDocument, options);
}
export function useGetOneSurveyResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneSurveyResultQuery,
    GetOneSurveyResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneSurveyResultQuery,
    GetOneSurveyResultQueryVariables
  >(GetOneSurveyResultDocument, options);
}
export type GetOneSurveyResultQueryHookResult = ReturnType<
  typeof useGetOneSurveyResultQuery
>;
export type GetOneSurveyResultLazyQueryHookResult = ReturnType<
  typeof useGetOneSurveyResultLazyQuery
>;
export type GetOneSurveyResultQueryResult = Apollo.QueryResult<
  GetOneSurveyResultQuery,
  GetOneSurveyResultQueryVariables
>;
export const GetOneSurveyTemplateNewDocument = gql`
  query getOneSurveyTemplateNew($templateId: ID!) {
    getOneSurveyTemplateNew(templateId: $templateId) {
      ...surveyTemplateNewFragment
    }
  }
  ${SurveyTemplateNewFragmentFragmentDoc}
`;

/**
 * __useGetOneSurveyTemplateNewQuery__
 *
 * To run a query within a React component, call `useGetOneSurveyTemplateNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneSurveyTemplateNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneSurveyTemplateNewQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetOneSurveyTemplateNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneSurveyTemplateNewQuery,
    GetOneSurveyTemplateNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneSurveyTemplateNewQuery,
    GetOneSurveyTemplateNewQueryVariables
  >(GetOneSurveyTemplateNewDocument, options);
}
export function useGetOneSurveyTemplateNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneSurveyTemplateNewQuery,
    GetOneSurveyTemplateNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneSurveyTemplateNewQuery,
    GetOneSurveyTemplateNewQueryVariables
  >(GetOneSurveyTemplateNewDocument, options);
}
export type GetOneSurveyTemplateNewQueryHookResult = ReturnType<
  typeof useGetOneSurveyTemplateNewQuery
>;
export type GetOneSurveyTemplateNewLazyQueryHookResult = ReturnType<
  typeof useGetOneSurveyTemplateNewLazyQuery
>;
export type GetOneSurveyTemplateNewQueryResult = Apollo.QueryResult<
  GetOneSurveyTemplateNewQuery,
  GetOneSurveyTemplateNewQueryVariables
>;
export const GetProjectsByIdsDocument = gql`
  query getProjectsByIds($projectIds: [String]) {
    getProjectsByIds(projectIds: $projectIds) {
      ...projectFragment
      pipelineStage {
        id
        label
        order
      }
      pipelineName
      businesses {
        id
        title
        phone
        website
        logo {
          id
          url
        }
      }
    }
  }
  ${ProjectFragmentFragmentDoc}
`;

/**
 * __useGetProjectsByIdsQuery__
 *
 * To run a query within a React component, call `useGetProjectsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByIdsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useGetProjectsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsByIdsQuery,
    GetProjectsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsByIdsQuery, GetProjectsByIdsQueryVariables>(
    GetProjectsByIdsDocument,
    options
  );
}
export function useGetProjectsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsByIdsQuery,
    GetProjectsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsByIdsQuery,
    GetProjectsByIdsQueryVariables
  >(GetProjectsByIdsDocument, options);
}
export type GetProjectsByIdsQueryHookResult = ReturnType<
  typeof useGetProjectsByIdsQuery
>;
export type GetProjectsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsByIdsLazyQuery
>;
export type GetProjectsByIdsQueryResult = Apollo.QueryResult<
  GetProjectsByIdsQuery,
  GetProjectsByIdsQueryVariables
>;
export const GetSurveyQuestionOtherAnswersDocument = gql`
  query getSurveyQuestionOtherAnswers(
    $surveyTemplateId: ID
    $surveyQuestionId: ID
    $limit: Int
    $skip: Int
  ) {
    getSurveyQuestionOtherAnswers(
      surveyTemplateId: $surveyTemplateId
      surveyQuestionId: $surveyQuestionId
      limit: $limit
      skip: $skip
    ) {
      results {
        id
        answers {
          surveyQuestionId
          other
        }
        business {
          id
          title
        }
      }
      count
    }
  }
`;

/**
 * __useGetSurveyQuestionOtherAnswersQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuestionOtherAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuestionOtherAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuestionOtherAnswersQuery({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *      surveyQuestionId: // value for 'surveyQuestionId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetSurveyQuestionOtherAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSurveyQuestionOtherAnswersQuery,
    GetSurveyQuestionOtherAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveyQuestionOtherAnswersQuery,
    GetSurveyQuestionOtherAnswersQueryVariables
  >(GetSurveyQuestionOtherAnswersDocument, options);
}
export function useGetSurveyQuestionOtherAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyQuestionOtherAnswersQuery,
    GetSurveyQuestionOtherAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyQuestionOtherAnswersQuery,
    GetSurveyQuestionOtherAnswersQueryVariables
  >(GetSurveyQuestionOtherAnswersDocument, options);
}
export type GetSurveyQuestionOtherAnswersQueryHookResult = ReturnType<
  typeof useGetSurveyQuestionOtherAnswersQuery
>;
export type GetSurveyQuestionOtherAnswersLazyQueryHookResult = ReturnType<
  typeof useGetSurveyQuestionOtherAnswersLazyQuery
>;
export type GetSurveyQuestionOtherAnswersQueryResult = Apollo.QueryResult<
  GetSurveyQuestionOtherAnswersQuery,
  GetSurveyQuestionOtherAnswersQueryVariables
>;
export const GroupFormSubmissionsByRecipientDocument = gql`
  query groupFormSubmissionsByRecipient($recipientId: ID!) {
    groupFormSubmissionsByRecipient(recipientId: $recipientId) {
      ...formSubmissionFragment
    }
  }
  ${FormSubmissionFragmentFragmentDoc}
`;

/**
 * __useGroupFormSubmissionsByRecipientQuery__
 *
 * To run a query within a React component, call `useGroupFormSubmissionsByRecipientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupFormSubmissionsByRecipientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupFormSubmissionsByRecipientQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useGroupFormSubmissionsByRecipientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupFormSubmissionsByRecipientQuery,
    GroupFormSubmissionsByRecipientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupFormSubmissionsByRecipientQuery,
    GroupFormSubmissionsByRecipientQueryVariables
  >(GroupFormSubmissionsByRecipientDocument, options);
}
export function useGroupFormSubmissionsByRecipientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupFormSubmissionsByRecipientQuery,
    GroupFormSubmissionsByRecipientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupFormSubmissionsByRecipientQuery,
    GroupFormSubmissionsByRecipientQueryVariables
  >(GroupFormSubmissionsByRecipientDocument, options);
}
export type GroupFormSubmissionsByRecipientQueryHookResult = ReturnType<
  typeof useGroupFormSubmissionsByRecipientQuery
>;
export type GroupFormSubmissionsByRecipientLazyQueryHookResult = ReturnType<
  typeof useGroupFormSubmissionsByRecipientLazyQuery
>;
export type GroupFormSubmissionsByRecipientQueryResult = Apollo.QueryResult<
  GroupFormSubmissionsByRecipientQuery,
  GroupFormSubmissionsByRecipientQueryVariables
>;
export const GroupedSurveysByBusinessIdDocument = gql`
  query groupedSurveysByBusinessId($businessId: ID!) {
    groupedSurveysByBusinessId(businessId: $businessId) {
      id
      surveyTemplateName
      responses {
        id
        surveyTemplateId
        businessId
        attributionDate
        answers {
          surveyQuestionId
          label
          answer
        }
      }
    }
  }
`;

/**
 * __useGroupedSurveysByBusinessIdQuery__
 *
 * To run a query within a React component, call `useGroupedSurveysByBusinessIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedSurveysByBusinessIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedSurveysByBusinessIdQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGroupedSurveysByBusinessIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupedSurveysByBusinessIdQuery,
    GroupedSurveysByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupedSurveysByBusinessIdQuery,
    GroupedSurveysByBusinessIdQueryVariables
  >(GroupedSurveysByBusinessIdDocument, options);
}
export function useGroupedSurveysByBusinessIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupedSurveysByBusinessIdQuery,
    GroupedSurveysByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupedSurveysByBusinessIdQuery,
    GroupedSurveysByBusinessIdQueryVariables
  >(GroupedSurveysByBusinessIdDocument, options);
}
export type GroupedSurveysByBusinessIdQueryHookResult = ReturnType<
  typeof useGroupedSurveysByBusinessIdQuery
>;
export type GroupedSurveysByBusinessIdLazyQueryHookResult = ReturnType<
  typeof useGroupedSurveysByBusinessIdLazyQuery
>;
export type GroupedSurveysByBusinessIdQueryResult = Apollo.QueryResult<
  GroupedSurveysByBusinessIdQuery,
  GroupedSurveysByBusinessIdQueryVariables
>;
export const InteractionsDocument = gql`
  query interactions(
    $projectId: String
    $businessId: String
    $contactId: String
    $organizationId: String
    $direction: InteractionDirectionEnum
    $startDate: String
    $endDate: String
    $skip: Int
    $limit: Int
  ) {
    interactions(
      projectId: $projectId
      organizationId: $organizationId
      businessId: $businessId
      contactId: $contactId
      direction: $direction
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
    ) {
      results {
        ...interactionLongFragment
      }
      count
    }
  }
  ${InteractionLongFragmentFragmentDoc}
`;

/**
 * __useInteractionsQuery__
 *
 * To run a query within a React component, call `useInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *      organizationId: // value for 'organizationId'
 *      direction: // value for 'direction'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInteractionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InteractionsQuery,
    InteractionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InteractionsQuery, InteractionsQueryVariables>(
    InteractionsDocument,
    options
  );
}
export function useInteractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InteractionsQuery,
    InteractionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InteractionsQuery, InteractionsQueryVariables>(
    InteractionsDocument,
    options
  );
}
export type InteractionsQueryHookResult = ReturnType<
  typeof useInteractionsQuery
>;
export type InteractionsLazyQueryHookResult = ReturnType<
  typeof useInteractionsLazyQuery
>;
export type InteractionsQueryResult = Apollo.QueryResult<
  InteractionsQuery,
  InteractionsQueryVariables
>;
export const MyNotificationsDocument = gql`
  query myNotifications(
    $status: MyNotificationsStatusEnum
    $limit: Int
    $skip: Int
  ) {
    myNotifications(status: $status, limit: $limit, skip: $skip) {
      unreadCount
      count
      result {
        ...notificationFragment
      }
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useMyNotificationsQuery__
 *
 * To run a query within a React component, call `useMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useMyNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyNotificationsQuery,
    MyNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(
    MyNotificationsDocument,
    options
  );
}
export function useMyNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyNotificationsQuery,
    MyNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyNotificationsQuery,
    MyNotificationsQueryVariables
  >(MyNotificationsDocument, options);
}
export type MyNotificationsQueryHookResult = ReturnType<
  typeof useMyNotificationsQuery
>;
export type MyNotificationsLazyQueryHookResult = ReturnType<
  typeof useMyNotificationsLazyQuery
>;
export type MyNotificationsQueryResult = Apollo.QueryResult<
  MyNotificationsQuery,
  MyNotificationsQueryVariables
>;
export const NaicsSearchDocument = gql`
  query naicsSearch($searchText: String) {
    naicsSearch(searchText: $searchText) {
      id
      title
      code
    }
  }
`;

/**
 * __useNaicsSearchQuery__
 *
 * To run a query within a React component, call `useNaicsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNaicsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNaicsSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useNaicsSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NaicsSearchQuery,
    NaicsSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NaicsSearchQuery, NaicsSearchQueryVariables>(
    NaicsSearchDocument,
    options
  );
}
export function useNaicsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NaicsSearchQuery,
    NaicsSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NaicsSearchQuery, NaicsSearchQueryVariables>(
    NaicsSearchDocument,
    options
  );
}
export type NaicsSearchQueryHookResult = ReturnType<typeof useNaicsSearchQuery>;
export type NaicsSearchLazyQueryHookResult = ReturnType<
  typeof useNaicsSearchLazyQuery
>;
export type NaicsSearchQueryResult = Apollo.QueryResult<
  NaicsSearchQuery,
  NaicsSearchQueryVariables
>;
export const NotesDocument = gql`
  query notes($businessId: ID, $contactId: ID, $projectId: ID, $siteId: ID) {
    notes(
      businessId: $businessId
      contactId: $contactId
      projectId: $projectId
      siteId: $siteId
    ) {
      count
      notes {
        ...noteFragment
      }
    }
  }
  ${NoteFragmentFragmentDoc}
`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *      projectId: // value for 'projectId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(
    NotesDocument,
    options
  );
}
export function useNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(
    NotesDocument,
    options
  );
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<
  NotesQuery,
  NotesQueryVariables
>;
export const OrganizationAcitvitiesDocument = gql`
  query organizationAcitvities($limit: Int, $skip: Int) {
    organizationAcitvities(limit: $limit, skip: $skip) {
      count
      result {
        ...activityItemFragment
        interaction {
          ...interactionFragment
        }
      }
    }
  }
  ${ActivityItemFragmentFragmentDoc}
  ${InteractionFragmentFragmentDoc}
`;

/**
 * __useOrganizationAcitvitiesQuery__
 *
 * To run a query within a React component, call `useOrganizationAcitvitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAcitvitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAcitvitiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useOrganizationAcitvitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationAcitvitiesQuery,
    OrganizationAcitvitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationAcitvitiesQuery,
    OrganizationAcitvitiesQueryVariables
  >(OrganizationAcitvitiesDocument, options);
}
export function useOrganizationAcitvitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationAcitvitiesQuery,
    OrganizationAcitvitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationAcitvitiesQuery,
    OrganizationAcitvitiesQueryVariables
  >(OrganizationAcitvitiesDocument, options);
}
export type OrganizationAcitvitiesQueryHookResult = ReturnType<
  typeof useOrganizationAcitvitiesQuery
>;
export type OrganizationAcitvitiesLazyQueryHookResult = ReturnType<
  typeof useOrganizationAcitvitiesLazyQuery
>;
export type OrganizationAcitvitiesQueryResult = Apollo.QueryResult<
  OrganizationAcitvitiesQuery,
  OrganizationAcitvitiesQueryVariables
>;
export const OrganizationByIdDocument = gql`
  query organizationById($id: ID!) {
    organizationById(id: $id) {
      ...organizationFragment
      programs {
        ...resourceFragment
      }
      events {
        ...eventFragment
      }
      fundingPrograms {
        ...resourceFragment
      }
    }
  }
  ${OrganizationFragmentFragmentDoc}
  ${ResourceFragmentFragmentDoc}
  ${EventFragmentFragmentDoc}
`;

/**
 * __useOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationByIdQuery, OrganizationByIdQueryVariables>(
    OrganizationByIdDocument,
    options
  );
}
export function useOrganizationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >(OrganizationByIdDocument, options);
}
export type OrganizationByIdQueryHookResult = ReturnType<
  typeof useOrganizationByIdQuery
>;
export type OrganizationByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationByIdLazyQuery
>;
export type OrganizationByIdQueryResult = Apollo.QueryResult<
  OrganizationByIdQuery,
  OrganizationByIdQueryVariables
>;
export const OrganizationsDocument = gql`
  query organizations(
    $searchText: String
    $includesAtleastOrganizationIds: [String!]
    $organiationIds: [String!]
    $skip: Int
    $limit: Int
  ) {
    organizations(
      searchText: $searchText
      includesAtleastOrganizationIds: $includesAtleastOrganizationIds
      organiationIds: $organiationIds
      skip: $skip
      limit: $limit
    ) {
      count
      organizations {
        ...organizationFragment
      }
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      includesAtleastOrganizationIds: // value for 'includesAtleastOrganizationIds'
 *      organiationIds: // value for 'organiationIds'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  );
}
export type OrganizationsQueryHookResult = ReturnType<
  typeof useOrganizationsQuery
>;
export type OrganizationsLazyQueryHookResult = ReturnType<
  typeof useOrganizationsLazyQuery
>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const PipelinesByCustomerDocument = gql`
  query pipelinesByCustomer {
    pipelinesByCustomer {
      ...pipelineFragment
    }
  }
  ${PipelineFragmentFragmentDoc}
`;

/**
 * __usePipelinesByCustomerQuery__
 *
 * To run a query within a React component, call `usePipelinesByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipelinesByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipelinesByCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePipelinesByCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PipelinesByCustomerQuery,
    PipelinesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PipelinesByCustomerQuery,
    PipelinesByCustomerQueryVariables
  >(PipelinesByCustomerDocument, options);
}
export function usePipelinesByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PipelinesByCustomerQuery,
    PipelinesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PipelinesByCustomerQuery,
    PipelinesByCustomerQueryVariables
  >(PipelinesByCustomerDocument, options);
}
export type PipelinesByCustomerQueryHookResult = ReturnType<
  typeof usePipelinesByCustomerQuery
>;
export type PipelinesByCustomerLazyQueryHookResult = ReturnType<
  typeof usePipelinesByCustomerLazyQuery
>;
export type PipelinesByCustomerQueryResult = Apollo.QueryResult<
  PipelinesByCustomerQuery,
  PipelinesByCustomerQueryVariables
>;
export const ProgramsDocument = gql`
  query programs(
    $searchText: String
    $skip: Int
    $limit: Int
    $supportTypes: [String]
    $businessStages: [String]
    $diversityTypes: [String]
  ) {
    programs(
      searchText: $searchText
      skip: $skip
      limit: $limit
      supportTypes: $supportTypes
      businessStages: $businessStages
      diversityTypes: $diversityTypes
    ) {
      count
      programs {
        ...resourceFragment
      }
    }
  }
  ${ResourceFragmentFragmentDoc}
`;

/**
 * __useProgramsQuery__
 *
 * To run a query within a React component, call `useProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      supportTypes: // value for 'supportTypes'
 *      businessStages: // value for 'businessStages'
 *      diversityTypes: // value for 'diversityTypes'
 *   },
 * });
 */
export function useProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramsQuery, ProgramsQueryVariables>(
    ProgramsDocument,
    options
  );
}
export function useProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramsQuery,
    ProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramsQuery, ProgramsQueryVariables>(
    ProgramsDocument,
    options
  );
}
export type ProgramsQueryHookResult = ReturnType<typeof useProgramsQuery>;
export type ProgramsLazyQueryHookResult = ReturnType<
  typeof useProgramsLazyQuery
>;
export type ProgramsQueryResult = Apollo.QueryResult<
  ProgramsQuery,
  ProgramsQueryVariables
>;
export const ProjectAttachmentsDocument = gql`
  query projectAttachments($id: ID!) {
    projectAttachments: projectById(id: $id) {
      id
      attachments {
        id
        filename
        encoding
        mimetype
        url
        key
        createdAt
        createdByName
      }
    }
  }
`;

/**
 * __useProjectAttachmentsQuery__
 *
 * To run a query within a React component, call `useProjectAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectAttachmentsQuery,
    ProjectAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectAttachmentsQuery,
    ProjectAttachmentsQueryVariables
  >(ProjectAttachmentsDocument, options);
}
export function useProjectAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectAttachmentsQuery,
    ProjectAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectAttachmentsQuery,
    ProjectAttachmentsQueryVariables
  >(ProjectAttachmentsDocument, options);
}
export type ProjectAttachmentsQueryHookResult = ReturnType<
  typeof useProjectAttachmentsQuery
>;
export type ProjectAttachmentsLazyQueryHookResult = ReturnType<
  typeof useProjectAttachmentsLazyQuery
>;
export type ProjectAttachmentsQueryResult = Apollo.QueryResult<
  ProjectAttachmentsQuery,
  ProjectAttachmentsQueryVariables
>;
export const ProjectByIdDocument = gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      ...projectFragmentLong
    }
  }
  ${ProjectFragmentLongFragmentDoc}
`;

/**
 * __useProjectByIdQuery__
 *
 * To run a query within a React component, call `useProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectByIdQuery,
    ProjectByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(
    ProjectByIdDocument,
    options
  );
}
export function useProjectByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectByIdQuery,
    ProjectByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(
    ProjectByIdDocument,
    options
  );
}
export type ProjectByIdQueryHookResult = ReturnType<typeof useProjectByIdQuery>;
export type ProjectByIdLazyQueryHookResult = ReturnType<
  typeof useProjectByIdLazyQuery
>;
export type ProjectByIdQueryResult = Apollo.QueryResult<
  ProjectByIdQuery,
  ProjectByIdQueryVariables
>;
export const ProjectStagesByPiplineDocument = gql`
  query projectStagesByPipline($pipelineId: ID!) {
    pipelineById(id: $pipelineId) {
      id
      stages {
        id
        label
        order
      }
    }
  }
`;

/**
 * __useProjectStagesByPiplineQuery__
 *
 * To run a query within a React component, call `useProjectStagesByPiplineQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStagesByPiplineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStagesByPiplineQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useProjectStagesByPiplineQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectStagesByPiplineQuery,
    ProjectStagesByPiplineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectStagesByPiplineQuery,
    ProjectStagesByPiplineQueryVariables
  >(ProjectStagesByPiplineDocument, options);
}
export function useProjectStagesByPiplineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectStagesByPiplineQuery,
    ProjectStagesByPiplineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectStagesByPiplineQuery,
    ProjectStagesByPiplineQueryVariables
  >(ProjectStagesByPiplineDocument, options);
}
export type ProjectStagesByPiplineQueryHookResult = ReturnType<
  typeof useProjectStagesByPiplineQuery
>;
export type ProjectStagesByPiplineLazyQueryHookResult = ReturnType<
  typeof useProjectStagesByPiplineLazyQuery
>;
export type ProjectStagesByPiplineQueryResult = Apollo.QueryResult<
  ProjectStagesByPiplineQuery,
  ProjectStagesByPiplineQueryVariables
>;
export const ProjectTypeByPipelineDocument = gql`
  query projectTypeByPipeline($id: ID!) {
    pipelineById(id: $id) {
      id
      projectType {
        ...projectTypeFragment
      }
    }
  }
  ${ProjectTypeFragmentFragmentDoc}
`;

/**
 * __useProjectTypeByPipelineQuery__
 *
 * To run a query within a React component, call `useProjectTypeByPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTypeByPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTypeByPipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTypeByPipelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectTypeByPipelineQuery,
    ProjectTypeByPipelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTypeByPipelineQuery,
    ProjectTypeByPipelineQueryVariables
  >(ProjectTypeByPipelineDocument, options);
}
export function useProjectTypeByPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTypeByPipelineQuery,
    ProjectTypeByPipelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTypeByPipelineQuery,
    ProjectTypeByPipelineQueryVariables
  >(ProjectTypeByPipelineDocument, options);
}
export type ProjectTypeByPipelineQueryHookResult = ReturnType<
  typeof useProjectTypeByPipelineQuery
>;
export type ProjectTypeByPipelineLazyQueryHookResult = ReturnType<
  typeof useProjectTypeByPipelineLazyQuery
>;
export type ProjectTypeByPipelineQueryResult = Apollo.QueryResult<
  ProjectTypeByPipelineQuery,
  ProjectTypeByPipelineQueryVariables
>;
export const ProjectTypesByCustomerDocument = gql`
  query projectTypesByCustomer {
    projectTypesByCustomer {
      ...projectTypeFragment
    }
  }
  ${ProjectTypeFragmentFragmentDoc}
`;

/**
 * __useProjectTypesByCustomerQuery__
 *
 * To run a query within a React component, call `useProjectTypesByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTypesByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTypesByCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectTypesByCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectTypesByCustomerQuery,
    ProjectTypesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectTypesByCustomerQuery,
    ProjectTypesByCustomerQueryVariables
  >(ProjectTypesByCustomerDocument, options);
}
export function useProjectTypesByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectTypesByCustomerQuery,
    ProjectTypesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectTypesByCustomerQuery,
    ProjectTypesByCustomerQueryVariables
  >(ProjectTypesByCustomerDocument, options);
}
export type ProjectTypesByCustomerQueryHookResult = ReturnType<
  typeof useProjectTypesByCustomerQuery
>;
export type ProjectTypesByCustomerLazyQueryHookResult = ReturnType<
  typeof useProjectTypesByCustomerLazyQuery
>;
export type ProjectTypesByCustomerQueryResult = Apollo.QueryResult<
  ProjectTypesByCustomerQuery,
  ProjectTypesByCustomerQueryVariables
>;
export const ProjectsDocument = gql`
  query projects($searchText: String, $projectIds: [ID]) {
    projects(searchText: $searchText, projectIds: $projectIds) {
      ...projectFragment
      businesses {
        id
        title
        phone
        website
        logo {
          id
          url
        }
      }
    }
  }
  ${ProjectFragmentFragmentDoc}
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options
  );
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options
  );
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>;
export type ProjectsQueryResult = Apollo.QueryResult<
  ProjectsQuery,
  ProjectsQueryVariables
>;
export const ProjectsByCustomerDocument = gql`
  query projectsByCustomer {
    projectsByCustomer {
      ...projectFragment
      pipelineStage {
        id
        label
        order
      }
      pipelineName
    }
  }
  ${ProjectFragmentFragmentDoc}
`;

/**
 * __useProjectsByCustomerQuery__
 *
 * To run a query within a React component, call `useProjectsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsByCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectsByCustomerQuery,
    ProjectsByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectsByCustomerQuery,
    ProjectsByCustomerQueryVariables
  >(ProjectsByCustomerDocument, options);
}
export function useProjectsByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsByCustomerQuery,
    ProjectsByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectsByCustomerQuery,
    ProjectsByCustomerQueryVariables
  >(ProjectsByCustomerDocument, options);
}
export type ProjectsByCustomerQueryHookResult = ReturnType<
  typeof useProjectsByCustomerQuery
>;
export type ProjectsByCustomerLazyQueryHookResult = ReturnType<
  typeof useProjectsByCustomerLazyQuery
>;
export type ProjectsByCustomerQueryResult = Apollo.QueryResult<
  ProjectsByCustomerQuery,
  ProjectsByCustomerQueryVariables
>;
export const ProjectsByStageDocument = gql`
  query projectsByStage($pipelineId: ID!, $stageId: String!) {
    projectsByStage(pipelineId: $pipelineId, stageId: $stageId) {
      ...projectFragment
      businesses {
        id
        title
        phone
        website
        logo {
          id
          url
        }
      }
    }
  }
  ${ProjectFragmentFragmentDoc}
`;

/**
 * __useProjectsByStageQuery__
 *
 * To run a query within a React component, call `useProjectsByStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByStageQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useProjectsByStageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectsByStageQuery,
    ProjectsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsByStageQuery, ProjectsByStageQueryVariables>(
    ProjectsByStageDocument,
    options
  );
}
export function useProjectsByStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsByStageQuery,
    ProjectsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectsByStageQuery,
    ProjectsByStageQueryVariables
  >(ProjectsByStageDocument, options);
}
export type ProjectsByStageQueryHookResult = ReturnType<
  typeof useProjectsByStageQuery
>;
export type ProjectsByStageLazyQueryHookResult = ReturnType<
  typeof useProjectsByStageLazyQuery
>;
export type ProjectsByStageQueryResult = Apollo.QueryResult<
  ProjectsByStageQuery,
  ProjectsByStageQueryVariables
>;
export const ReportsTestDocument = gql`
  query reportsTest($surveyTemplateId: ID) {
    reportsTest(surveyTemplateId: $surveyTemplateId) {
      id
      question {
        ...surveyQuestionFragment
        childQuestions {
          id
          label
          description
          customerId
          isDefault
          category
          key
          showIfKey
          showIfAnswer
          options {
            label
            value
          }
          questionType
        }
      }
      businessIds
      totalRespondents
      surveyTemplateId
      questionResponses
      responses {
        answers
        label
      }
      matrixResults {
        id
        question {
          id
          label
          description
          customerId
          isDefault
          category
          key
          showIfKey
          showIfAnswer
          options {
            label
            value
          }
          questionType
        }
        businessIds
        totalRespondents
        surveyTemplateId
        questionResponses
        responses {
          answers
          label
        }
      }
    }
  }
  ${SurveyQuestionFragmentFragmentDoc}
`;

/**
 * __useReportsTestQuery__
 *
 * To run a query within a React component, call `useReportsTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsTestQuery({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useReportsTestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsTestQuery,
    ReportsTestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsTestQuery, ReportsTestQueryVariables>(
    ReportsTestDocument,
    options
  );
}
export function useReportsTestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsTestQuery,
    ReportsTestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportsTestQuery, ReportsTestQueryVariables>(
    ReportsTestDocument,
    options
  );
}
export type ReportsTestQueryHookResult = ReturnType<typeof useReportsTestQuery>;
export type ReportsTestLazyQueryHookResult = ReturnType<
  typeof useReportsTestLazyQuery
>;
export type ReportsTestQueryResult = Apollo.QueryResult<
  ReportsTestQuery,
  ReportsTestQueryVariables
>;
export const Reports_BusinessAssistedByServiceDocument = gql`
  query reports_BusinessAssistedByService {
    reports_BusinessAssistedByService {
      title
      total
    }
  }
`;

/**
 * __useReports_BusinessAssistedByServiceQuery__
 *
 * To run a query within a React component, call `useReports_BusinessAssistedByServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_BusinessAssistedByServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_BusinessAssistedByServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_BusinessAssistedByServiceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_BusinessAssistedByServiceQuery,
    Reports_BusinessAssistedByServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_BusinessAssistedByServiceQuery,
    Reports_BusinessAssistedByServiceQueryVariables
  >(Reports_BusinessAssistedByServiceDocument, options);
}
export function useReports_BusinessAssistedByServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_BusinessAssistedByServiceQuery,
    Reports_BusinessAssistedByServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_BusinessAssistedByServiceQuery,
    Reports_BusinessAssistedByServiceQueryVariables
  >(Reports_BusinessAssistedByServiceDocument, options);
}
export type Reports_BusinessAssistedByServiceQueryHookResult = ReturnType<
  typeof useReports_BusinessAssistedByServiceQuery
>;
export type Reports_BusinessAssistedByServiceLazyQueryHookResult = ReturnType<
  typeof useReports_BusinessAssistedByServiceLazyQuery
>;
export type Reports_BusinessAssistedByServiceQueryResult = Apollo.QueryResult<
  Reports_BusinessAssistedByServiceQuery,
  Reports_BusinessAssistedByServiceQueryVariables
>;
export const Reports_BusinessesByClusterDocument = gql`
  query reports_BusinessesByCluster($top: Int) {
    reports_BusinessesByCluster(top: $top) {
      title
      numberOfBusinesses
    }
  }
`;

/**
 * __useReports_BusinessesByClusterQuery__
 *
 * To run a query within a React component, call `useReports_BusinessesByClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_BusinessesByClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_BusinessesByClusterQuery({
 *   variables: {
 *      top: // value for 'top'
 *   },
 * });
 */
export function useReports_BusinessesByClusterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_BusinessesByClusterQuery,
    Reports_BusinessesByClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_BusinessesByClusterQuery,
    Reports_BusinessesByClusterQueryVariables
  >(Reports_BusinessesByClusterDocument, options);
}
export function useReports_BusinessesByClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_BusinessesByClusterQuery,
    Reports_BusinessesByClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_BusinessesByClusterQuery,
    Reports_BusinessesByClusterQueryVariables
  >(Reports_BusinessesByClusterDocument, options);
}
export type Reports_BusinessesByClusterQueryHookResult = ReturnType<
  typeof useReports_BusinessesByClusterQuery
>;
export type Reports_BusinessesByClusterLazyQueryHookResult = ReturnType<
  typeof useReports_BusinessesByClusterLazyQuery
>;
export type Reports_BusinessesByClusterQueryResult = Apollo.QueryResult<
  Reports_BusinessesByClusterQuery,
  Reports_BusinessesByClusterQueryVariables
>;
export const Reports_BusinessesByNaicsDocument = gql`
  query reports_BusinessesByNaics($top: Int) {
    reports_BusinessesByNaics(top: $top) {
      title
      code
      numberOfBusinesses
    }
  }
`;

/**
 * __useReports_BusinessesByNaicsQuery__
 *
 * To run a query within a React component, call `useReports_BusinessesByNaicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_BusinessesByNaicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_BusinessesByNaicsQuery({
 *   variables: {
 *      top: // value for 'top'
 *   },
 * });
 */
export function useReports_BusinessesByNaicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_BusinessesByNaicsQuery,
    Reports_BusinessesByNaicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_BusinessesByNaicsQuery,
    Reports_BusinessesByNaicsQueryVariables
  >(Reports_BusinessesByNaicsDocument, options);
}
export function useReports_BusinessesByNaicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_BusinessesByNaicsQuery,
    Reports_BusinessesByNaicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_BusinessesByNaicsQuery,
    Reports_BusinessesByNaicsQueryVariables
  >(Reports_BusinessesByNaicsDocument, options);
}
export type Reports_BusinessesByNaicsQueryHookResult = ReturnType<
  typeof useReports_BusinessesByNaicsQuery
>;
export type Reports_BusinessesByNaicsLazyQueryHookResult = ReturnType<
  typeof useReports_BusinessesByNaicsLazyQuery
>;
export type Reports_BusinessesByNaicsQueryResult = Apollo.QueryResult<
  Reports_BusinessesByNaicsQuery,
  Reports_BusinessesByNaicsQueryVariables
>;
export const Reports_BusinessesBySectorDocument = gql`
  query reports_BusinessesBySector($top: Int) {
    reports_BusinessesBySector(top: $top) {
      title
      numberOfBusinesses
    }
  }
`;

/**
 * __useReports_BusinessesBySectorQuery__
 *
 * To run a query within a React component, call `useReports_BusinessesBySectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_BusinessesBySectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_BusinessesBySectorQuery({
 *   variables: {
 *      top: // value for 'top'
 *   },
 * });
 */
export function useReports_BusinessesBySectorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_BusinessesBySectorQuery,
    Reports_BusinessesBySectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_BusinessesBySectorQuery,
    Reports_BusinessesBySectorQueryVariables
  >(Reports_BusinessesBySectorDocument, options);
}
export function useReports_BusinessesBySectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_BusinessesBySectorQuery,
    Reports_BusinessesBySectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_BusinessesBySectorQuery,
    Reports_BusinessesBySectorQueryVariables
  >(Reports_BusinessesBySectorDocument, options);
}
export type Reports_BusinessesBySectorQueryHookResult = ReturnType<
  typeof useReports_BusinessesBySectorQuery
>;
export type Reports_BusinessesBySectorLazyQueryHookResult = ReturnType<
  typeof useReports_BusinessesBySectorLazyQuery
>;
export type Reports_BusinessesBySectorQueryResult = Apollo.QueryResult<
  Reports_BusinessesBySectorQuery,
  Reports_BusinessesBySectorQueryVariables
>;
export const Reports_CasesResolvedByDateRangeDocument = gql`
  query reports_CasesResolvedByDateRange($start: String, $end: String) {
    reports_CasesResolvedByDateRange(start: $start, end: $end)
  }
`;

/**
 * __useReports_CasesResolvedByDateRangeQuery__
 *
 * To run a query within a React component, call `useReports_CasesResolvedByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_CasesResolvedByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_CasesResolvedByDateRangeQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useReports_CasesResolvedByDateRangeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_CasesResolvedByDateRangeQuery,
    Reports_CasesResolvedByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_CasesResolvedByDateRangeQuery,
    Reports_CasesResolvedByDateRangeQueryVariables
  >(Reports_CasesResolvedByDateRangeDocument, options);
}
export function useReports_CasesResolvedByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_CasesResolvedByDateRangeQuery,
    Reports_CasesResolvedByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_CasesResolvedByDateRangeQuery,
    Reports_CasesResolvedByDateRangeQueryVariables
  >(Reports_CasesResolvedByDateRangeDocument, options);
}
export type Reports_CasesResolvedByDateRangeQueryHookResult = ReturnType<
  typeof useReports_CasesResolvedByDateRangeQuery
>;
export type Reports_CasesResolvedByDateRangeLazyQueryHookResult = ReturnType<
  typeof useReports_CasesResolvedByDateRangeLazyQuery
>;
export type Reports_CasesResolvedByDateRangeQueryResult = Apollo.QueryResult<
  Reports_CasesResolvedByDateRangeQuery,
  Reports_CasesResolvedByDateRangeQueryVariables
>;
export const Reports_CasesResolvedByMonthDocument = gql`
  query reports_CasesResolvedByMonth {
    reports_CasesResolvedByMonth {
      title
      total
    }
  }
`;

/**
 * __useReports_CasesResolvedByMonthQuery__
 *
 * To run a query within a React component, call `useReports_CasesResolvedByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_CasesResolvedByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_CasesResolvedByMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_CasesResolvedByMonthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_CasesResolvedByMonthQuery,
    Reports_CasesResolvedByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_CasesResolvedByMonthQuery,
    Reports_CasesResolvedByMonthQueryVariables
  >(Reports_CasesResolvedByMonthDocument, options);
}
export function useReports_CasesResolvedByMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_CasesResolvedByMonthQuery,
    Reports_CasesResolvedByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_CasesResolvedByMonthQuery,
    Reports_CasesResolvedByMonthQueryVariables
  >(Reports_CasesResolvedByMonthDocument, options);
}
export type Reports_CasesResolvedByMonthQueryHookResult = ReturnType<
  typeof useReports_CasesResolvedByMonthQuery
>;
export type Reports_CasesResolvedByMonthLazyQueryHookResult = ReturnType<
  typeof useReports_CasesResolvedByMonthLazyQuery
>;
export type Reports_CasesResolvedByMonthQueryResult = Apollo.QueryResult<
  Reports_CasesResolvedByMonthQuery,
  Reports_CasesResolvedByMonthQueryVariables
>;
export const Reports_InteractionsByMonthDocument = gql`
  query reports_InteractionsByMonth {
    reports_InteractionsByMonth {
      id
      title
      email
      phone
      visit
    }
  }
`;

/**
 * __useReports_InteractionsByMonthQuery__
 *
 * To run a query within a React component, call `useReports_InteractionsByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_InteractionsByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_InteractionsByMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_InteractionsByMonthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_InteractionsByMonthQuery,
    Reports_InteractionsByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_InteractionsByMonthQuery,
    Reports_InteractionsByMonthQueryVariables
  >(Reports_InteractionsByMonthDocument, options);
}
export function useReports_InteractionsByMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_InteractionsByMonthQuery,
    Reports_InteractionsByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_InteractionsByMonthQuery,
    Reports_InteractionsByMonthQueryVariables
  >(Reports_InteractionsByMonthDocument, options);
}
export type Reports_InteractionsByMonthQueryHookResult = ReturnType<
  typeof useReports_InteractionsByMonthQuery
>;
export type Reports_InteractionsByMonthLazyQueryHookResult = ReturnType<
  typeof useReports_InteractionsByMonthLazyQuery
>;
export type Reports_InteractionsByMonthQueryResult = Apollo.QueryResult<
  Reports_InteractionsByMonthQuery,
  Reports_InteractionsByMonthQueryVariables
>;
export const Reports_NumberOfBusinessesDocument = gql`
  query reports_NumberOfBusinesses {
    reports_NumberOfBusinesses
  }
`;

/**
 * __useReports_NumberOfBusinessesQuery__
 *
 * To run a query within a React component, call `useReports_NumberOfBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_NumberOfBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_NumberOfBusinessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_NumberOfBusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_NumberOfBusinessesQuery,
    Reports_NumberOfBusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_NumberOfBusinessesQuery,
    Reports_NumberOfBusinessesQueryVariables
  >(Reports_NumberOfBusinessesDocument, options);
}
export function useReports_NumberOfBusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_NumberOfBusinessesQuery,
    Reports_NumberOfBusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_NumberOfBusinessesQuery,
    Reports_NumberOfBusinessesQueryVariables
  >(Reports_NumberOfBusinessesDocument, options);
}
export type Reports_NumberOfBusinessesQueryHookResult = ReturnType<
  typeof useReports_NumberOfBusinessesQuery
>;
export type Reports_NumberOfBusinessesLazyQueryHookResult = ReturnType<
  typeof useReports_NumberOfBusinessesLazyQuery
>;
export type Reports_NumberOfBusinessesQueryResult = Apollo.QueryResult<
  Reports_NumberOfBusinessesQuery,
  Reports_NumberOfBusinessesQueryVariables
>;
export const Reports_NumberOfBusinessesAssistedDocument = gql`
  query reports_NumberOfBusinessesAssisted {
    reports_NumberOfBusinessesAssisted
  }
`;

/**
 * __useReports_NumberOfBusinessesAssistedQuery__
 *
 * To run a query within a React component, call `useReports_NumberOfBusinessesAssistedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_NumberOfBusinessesAssistedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_NumberOfBusinessesAssistedQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_NumberOfBusinessesAssistedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_NumberOfBusinessesAssistedQuery,
    Reports_NumberOfBusinessesAssistedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_NumberOfBusinessesAssistedQuery,
    Reports_NumberOfBusinessesAssistedQueryVariables
  >(Reports_NumberOfBusinessesAssistedDocument, options);
}
export function useReports_NumberOfBusinessesAssistedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_NumberOfBusinessesAssistedQuery,
    Reports_NumberOfBusinessesAssistedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_NumberOfBusinessesAssistedQuery,
    Reports_NumberOfBusinessesAssistedQueryVariables
  >(Reports_NumberOfBusinessesAssistedDocument, options);
}
export type Reports_NumberOfBusinessesAssistedQueryHookResult = ReturnType<
  typeof useReports_NumberOfBusinessesAssistedQuery
>;
export type Reports_NumberOfBusinessesAssistedLazyQueryHookResult = ReturnType<
  typeof useReports_NumberOfBusinessesAssistedLazyQuery
>;
export type Reports_NumberOfBusinessesAssistedQueryResult = Apollo.QueryResult<
  Reports_NumberOfBusinessesAssistedQuery,
  Reports_NumberOfBusinessesAssistedQueryVariables
>;
export const Reports_OrganizationsByTypeDocument = gql`
  query reports_OrganizationsByType {
    reports_OrganizationsByType {
      title
      total
    }
  }
`;

/**
 * __useReports_OrganizationsByTypeQuery__
 *
 * To run a query within a React component, call `useReports_OrganizationsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_OrganizationsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_OrganizationsByTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_OrganizationsByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_OrganizationsByTypeQuery,
    Reports_OrganizationsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_OrganizationsByTypeQuery,
    Reports_OrganizationsByTypeQueryVariables
  >(Reports_OrganizationsByTypeDocument, options);
}
export function useReports_OrganizationsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_OrganizationsByTypeQuery,
    Reports_OrganizationsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_OrganizationsByTypeQuery,
    Reports_OrganizationsByTypeQueryVariables
  >(Reports_OrganizationsByTypeDocument, options);
}
export type Reports_OrganizationsByTypeQueryHookResult = ReturnType<
  typeof useReports_OrganizationsByTypeQuery
>;
export type Reports_OrganizationsByTypeLazyQueryHookResult = ReturnType<
  typeof useReports_OrganizationsByTypeLazyQuery
>;
export type Reports_OrganizationsByTypeQueryResult = Apollo.QueryResult<
  Reports_OrganizationsByTypeQuery,
  Reports_OrganizationsByTypeQueryVariables
>;
export const Reports_ProjectsByStageDocument = gql`
  query reports_ProjectsByStage($pipelineId: ID!) {
    reports_ProjectsByStage(pipelineId: $pipelineId) {
      title
      total
    }
  }
`;

/**
 * __useReports_ProjectsByStageQuery__
 *
 * To run a query within a React component, call `useReports_ProjectsByStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_ProjectsByStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_ProjectsByStageQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useReports_ProjectsByStageQuery(
  baseOptions: Apollo.QueryHookOptions<
    Reports_ProjectsByStageQuery,
    Reports_ProjectsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_ProjectsByStageQuery,
    Reports_ProjectsByStageQueryVariables
  >(Reports_ProjectsByStageDocument, options);
}
export function useReports_ProjectsByStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_ProjectsByStageQuery,
    Reports_ProjectsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_ProjectsByStageQuery,
    Reports_ProjectsByStageQueryVariables
  >(Reports_ProjectsByStageDocument, options);
}
export type Reports_ProjectsByStageQueryHookResult = ReturnType<
  typeof useReports_ProjectsByStageQuery
>;
export type Reports_ProjectsByStageLazyQueryHookResult = ReturnType<
  typeof useReports_ProjectsByStageLazyQuery
>;
export type Reports_ProjectsByStageQueryResult = Apollo.QueryResult<
  Reports_ProjectsByStageQuery,
  Reports_ProjectsByStageQueryVariables
>;
export const Reports_SupportProgramsByStageDocument = gql`
  query reports_SupportProgramsByStage {
    reports_SupportProgramsByStage {
      title
      total
    }
  }
`;

/**
 * __useReports_SupportProgramsByStageQuery__
 *
 * To run a query within a React component, call `useReports_SupportProgramsByStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_SupportProgramsByStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_SupportProgramsByStageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_SupportProgramsByStageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_SupportProgramsByStageQuery,
    Reports_SupportProgramsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_SupportProgramsByStageQuery,
    Reports_SupportProgramsByStageQueryVariables
  >(Reports_SupportProgramsByStageDocument, options);
}
export function useReports_SupportProgramsByStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_SupportProgramsByStageQuery,
    Reports_SupportProgramsByStageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_SupportProgramsByStageQuery,
    Reports_SupportProgramsByStageQueryVariables
  >(Reports_SupportProgramsByStageDocument, options);
}
export type Reports_SupportProgramsByStageQueryHookResult = ReturnType<
  typeof useReports_SupportProgramsByStageQuery
>;
export type Reports_SupportProgramsByStageLazyQueryHookResult = ReturnType<
  typeof useReports_SupportProgramsByStageLazyQuery
>;
export type Reports_SupportProgramsByStageQueryResult = Apollo.QueryResult<
  Reports_SupportProgramsByStageQuery,
  Reports_SupportProgramsByStageQueryVariables
>;
export const Reports_SupportProgramsByTypeDocument = gql`
  query reports_SupportProgramsByType {
    reports_SupportProgramsByType {
      title
      total
    }
  }
`;

/**
 * __useReports_SupportProgramsByTypeQuery__
 *
 * To run a query within a React component, call `useReports_SupportProgramsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_SupportProgramsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_SupportProgramsByTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useReports_SupportProgramsByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Reports_SupportProgramsByTypeQuery,
    Reports_SupportProgramsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Reports_SupportProgramsByTypeQuery,
    Reports_SupportProgramsByTypeQueryVariables
  >(Reports_SupportProgramsByTypeDocument, options);
}
export function useReports_SupportProgramsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Reports_SupportProgramsByTypeQuery,
    Reports_SupportProgramsByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Reports_SupportProgramsByTypeQuery,
    Reports_SupportProgramsByTypeQueryVariables
  >(Reports_SupportProgramsByTypeDocument, options);
}
export type Reports_SupportProgramsByTypeQueryHookResult = ReturnType<
  typeof useReports_SupportProgramsByTypeQuery
>;
export type Reports_SupportProgramsByTypeLazyQueryHookResult = ReturnType<
  typeof useReports_SupportProgramsByTypeLazyQuery
>;
export type Reports_SupportProgramsByTypeQueryResult = Apollo.QueryResult<
  Reports_SupportProgramsByTypeQuery,
  Reports_SupportProgramsByTypeQueryVariables
>;
export const ResourceByIdDocument = gql`
  query resourceById($id: ID!) {
    resourceById(id: $id) {
      ...resourceFragment
    }
  }
  ${ResourceFragmentFragmentDoc}
`;

/**
 * __useResourceByIdQuery__
 *
 * To run a query within a React component, call `useResourceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceByIdQuery,
    ResourceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceByIdQuery, ResourceByIdQueryVariables>(
    ResourceByIdDocument,
    options
  );
}
export function useResourceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceByIdQuery,
    ResourceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceByIdQuery, ResourceByIdQueryVariables>(
    ResourceByIdDocument,
    options
  );
}
export type ResourceByIdQueryHookResult = ReturnType<
  typeof useResourceByIdQuery
>;
export type ResourceByIdLazyQueryHookResult = ReturnType<
  typeof useResourceByIdLazyQuery
>;
export type ResourceByIdQueryResult = Apollo.QueryResult<
  ResourceByIdQuery,
  ResourceByIdQueryVariables
>;
export const SearchDocument = gql`
  query search($searchText: String!, $tagIds: [ID!]) {
    search(searchText: $searchText, tagIds: $tagIds) {
      projects {
        ...projectFragment
        pipelineName
      }
      businesses {
        id
        title
        street
        fullAddress
        logo {
          id
          filename
          mimetype
          encoding
          url
          key
        }
      }
      contacts {
        id
        firstName
        lastName
        email
        title
        avatar {
          id
          filename
          mimetype
          encoding
          url
          key
        }
        businesses {
          id
          title
        }
      }
      todos {
        id
        key
        title
      }
      resources {
        ...resourceFragment
      }
      sites {
        ...siteFragment
      }
      organizations {
        id
        title
      }
      cases {
        id
        key
        title
      }
    }
  }
  ${ProjectFragmentFragmentDoc}
  ${ResourceFragmentFragmentDoc}
  ${SiteFragmentFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const SearchUsersDocument = gql`
  query searchUsers($params: UserSearchParams) {
    searchUsers(params: $params) {
      id
      email
      roles
      firstName
      lastName
      avatar {
        id
        url
      }
    }
  }
`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchUsersQuery,
    SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export function useSearchUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUsersQuery,
    SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<
  typeof useSearchUsersLazyQuery
>;
export type SearchUsersQueryResult = Apollo.QueryResult<
  SearchUsersQuery,
  SearchUsersQueryVariables
>;
export const SiteByIdDocument = gql`
  query siteById($id: ID!) {
    siteById(id: $id) {
      ...siteFragment
      contacts {
        id
        firstName
        lastName
        title
        email
      }
      businesses {
        id
        title
        website
        phone
        logo {
          id
          filename
          mimetype
          encoding
          url
          key
        }
      }
    }
  }
  ${SiteFragmentFragmentDoc}
`;

/**
 * __useSiteByIdQuery__
 *
 * To run a query within a React component, call `useSiteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<SiteByIdQuery, SiteByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteByIdQuery, SiteByIdQueryVariables>(
    SiteByIdDocument,
    options
  );
}
export function useSiteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteByIdQuery,
    SiteByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteByIdQuery, SiteByIdQueryVariables>(
    SiteByIdDocument,
    options
  );
}
export type SiteByIdQueryHookResult = ReturnType<typeof useSiteByIdQuery>;
export type SiteByIdLazyQueryHookResult = ReturnType<
  typeof useSiteByIdLazyQuery
>;
export type SiteByIdQueryResult = Apollo.QueryResult<
  SiteByIdQuery,
  SiteByIdQueryVariables
>;
export const SitesDocument = gql`
  query sites($searchText: String, $siteIds: [ID], $skip: Int, $limit: Int) {
    sites(
      searchText: $searchText
      siteIds: $siteIds
      skip: $skip
      limit: $limit
    ) {
      count
      sites {
        ...siteFragment
      }
    }
  }
  ${SiteFragmentFragmentDoc}
`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      siteIds: // value for 'siteIds'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SitesQuery, SitesQueryVariables>(
    SitesDocument,
    options
  );
}
export function useSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(
    SitesDocument,
    options
  );
}
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<
  SitesQuery,
  SitesQueryVariables
>;
export const SuggestedResourcesDocument = gql`
  query suggestedResources($businessId: ID!) {
    suggestedResources(businessId: $businessId) {
      ...resourceFragment
    }
  }
  ${ResourceFragmentFragmentDoc}
`;

/**
 * __useSuggestedResourcesQuery__
 *
 * To run a query within a React component, call `useSuggestedResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedResourcesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSuggestedResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestedResourcesQuery,
    SuggestedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuggestedResourcesQuery,
    SuggestedResourcesQueryVariables
  >(SuggestedResourcesDocument, options);
}
export function useSuggestedResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestedResourcesQuery,
    SuggestedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestedResourcesQuery,
    SuggestedResourcesQueryVariables
  >(SuggestedResourcesDocument, options);
}
export type SuggestedResourcesQueryHookResult = ReturnType<
  typeof useSuggestedResourcesQuery
>;
export type SuggestedResourcesLazyQueryHookResult = ReturnType<
  typeof useSuggestedResourcesLazyQuery
>;
export type SuggestedResourcesQueryResult = Apollo.QueryResult<
  SuggestedResourcesQuery,
  SuggestedResourcesQueryVariables
>;
export const SurveyResponseByIdDocument = gql`
  query surveyResponseById($id: ID!) {
    surveyResponseById(id: $id) {
      id
      surveyTemplateId
      businessId
      attributionDate
      answers {
        surveyQuestionId
        label
        answer
      }
    }
  }
`;

/**
 * __useSurveyResponseByIdQuery__
 *
 * To run a query within a React component, call `useSurveyResponseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResponseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyResponseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyResponseByIdQuery,
    SurveyResponseByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SurveyResponseByIdQuery,
    SurveyResponseByIdQueryVariables
  >(SurveyResponseByIdDocument, options);
}
export function useSurveyResponseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyResponseByIdQuery,
    SurveyResponseByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SurveyResponseByIdQuery,
    SurveyResponseByIdQueryVariables
  >(SurveyResponseByIdDocument, options);
}
export type SurveyResponseByIdQueryHookResult = ReturnType<
  typeof useSurveyResponseByIdQuery
>;
export type SurveyResponseByIdLazyQueryHookResult = ReturnType<
  typeof useSurveyResponseByIdLazyQuery
>;
export type SurveyResponseByIdQueryResult = Apollo.QueryResult<
  SurveyResponseByIdQuery,
  SurveyResponseByIdQueryVariables
>;
export const SurveyResponsesDocument = gql`
  query surveyResponses($businessIds: [ID], $surveyTemplateId: ID!) {
    surveyResponses(
      businessIds: $businessIds
      surveyTemplateId: $surveyTemplateId
    ) {
      id
      surveyTemplateId
      businessId
      business {
        id
        title
      }
      answers {
        surveyQuestionId
        label
        answer
      }
    }
  }
`;

/**
 * __useSurveyResponsesQuery__
 *
 * To run a query within a React component, call `useSurveyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResponsesQuery({
 *   variables: {
 *      businessIds: // value for 'businessIds'
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useSurveyResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyResponsesQuery,
    SurveyResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyResponsesQuery, SurveyResponsesQueryVariables>(
    SurveyResponsesDocument,
    options
  );
}
export function useSurveyResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyResponsesQuery,
    SurveyResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SurveyResponsesQuery,
    SurveyResponsesQueryVariables
  >(SurveyResponsesDocument, options);
}
export type SurveyResponsesQueryHookResult = ReturnType<
  typeof useSurveyResponsesQuery
>;
export type SurveyResponsesLazyQueryHookResult = ReturnType<
  typeof useSurveyResponsesLazyQuery
>;
export type SurveyResponsesQueryResult = Apollo.QueryResult<
  SurveyResponsesQuery,
  SurveyResponsesQueryVariables
>;
export const TagSearchDocument = gql`
  query tagSearch($tagIds: [ID!]) {
    tagSearch(tagIds: $tagIds) {
      projects {
        id
        title
        pipelineName
      }
      businesses {
        id
        title
        logo {
          id
          filename
          mimetype
          encoding
          url
          key
        }
      }
      contacts {
        id
        firstName
        lastName
        email
        title
        avatar {
          id
          filename
          mimetype
          encoding
          url
          key
        }
        businesses {
          id
          title
        }
      }
      todos {
        id
        key
        title
      }
      resources {
        id
        title
      }
      sites {
        id
        title
      }
      organizations {
        id
        title
      }
      cases {
        id
        key
        title
      }
    }
  }
`;

/**
 * __useTagSearchQuery__
 *
 * To run a query within a React component, call `useTagSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagSearchQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useTagSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<TagSearchQuery, TagSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagSearchQuery, TagSearchQueryVariables>(
    TagSearchDocument,
    options
  );
}
export function useTagSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagSearchQuery,
    TagSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagSearchQuery, TagSearchQueryVariables>(
    TagSearchDocument,
    options
  );
}
export type TagSearchQueryHookResult = ReturnType<typeof useTagSearchQuery>;
export type TagSearchLazyQueryHookResult = ReturnType<
  typeof useTagSearchLazyQuery
>;
export type TagSearchQueryResult = Apollo.QueryResult<
  TagSearchQuery,
  TagSearchQueryVariables
>;
export const TagsDocument = gql`
  query tags {
    tags {
      count
      tags {
        ...tagFragment
      }
    }
  }
  ${TagFragmentFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    options
  );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TeamDocument = gql`
  query team($deactivated: Boolean, $doNotInclude: [ID!]) {
    team(deactivated: $deactivated, doNotInclude: $doNotInclude) {
      ...userFragment
      acceptedInvite
      recentlySentInvite
      lastInviteExpiration
      deactivated
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      deactivated: // value for 'deactivated'
 *      doNotInclude: // value for 'doNotInclude'
 *   },
 * });
 */
export function useTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
}
export function useTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(
    TeamDocument,
    options
  );
}
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const TodoByIdDocument = gql`
  query todoById($id: ID!) {
    todoById(id: $id) {
      ...todoFragment
    }
  }
  ${TodoFragmentFragmentDoc}
`;

/**
 * __useTodoByIdQuery__
 *
 * To run a query within a React component, call `useTodoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTodoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<TodoByIdQuery, TodoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodoByIdQuery, TodoByIdQueryVariables>(
    TodoByIdDocument,
    options
  );
}
export function useTodoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TodoByIdQuery,
    TodoByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodoByIdQuery, TodoByIdQueryVariables>(
    TodoByIdDocument,
    options
  );
}
export type TodoByIdQueryHookResult = ReturnType<typeof useTodoByIdQuery>;
export type TodoByIdLazyQueryHookResult = ReturnType<
  typeof useTodoByIdLazyQuery
>;
export type TodoByIdQueryResult = Apollo.QueryResult<
  TodoByIdQuery,
  TodoByIdQueryVariables
>;
export const TodoListsDocument = gql`
  query todoLists(
    $sortBy: TodoListSortByEnum
    $archived: Boolean
    $searchText: String
  ) {
    todoLists(archived: $archived) {
      count
      todoLists {
        ...todoListFragment
      }
    }
  }
  ${TodoListFragmentFragmentDoc}
`;

/**
 * __useTodoListsQuery__
 *
 * To run a query within a React component, call `useTodoListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodoListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodoListsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      archived: // value for 'archived'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useTodoListsQuery(
  baseOptions?: Apollo.QueryHookOptions<TodoListsQuery, TodoListsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodoListsQuery, TodoListsQueryVariables>(
    TodoListsDocument,
    options
  );
}
export function useTodoListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TodoListsQuery,
    TodoListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodoListsQuery, TodoListsQueryVariables>(
    TodoListsDocument,
    options
  );
}
export type TodoListsQueryHookResult = ReturnType<typeof useTodoListsQuery>;
export type TodoListsLazyQueryHookResult = ReturnType<
  typeof useTodoListsLazyQuery
>;
export type TodoListsQueryResult = Apollo.QueryResult<
  TodoListsQuery,
  TodoListsQueryVariables
>;
export const TodosDocument = gql`
  query todos(
    $businessId: ID
    $contactId: ID
    $done: Boolean
    $assignedToId: ID
    $projectId: ID
    $caseId: ID
    $siteId: ID
    $limit: Int
    $skip: Int
  ) {
    todos(
      businessId: $businessId
      contactId: $contactId
      done: $done
      siteId: $siteId
      caseId: $caseId
      assignedToId: $assignedToId
      projectId: $projectId
      limit: $limit
      skip: $skip
    ) {
      count
      todos {
        ...todoFragment
        project {
          id
          title
          image {
            id
            url
          }
        }
      }
    }
  }
  ${TodoFragmentFragmentDoc}
`;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *      done: // value for 'done'
 *      assignedToId: // value for 'assignedToId'
 *      projectId: // value for 'projectId'
 *      caseId: // value for 'caseId'
 *      siteId: // value for 'siteId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useTodosQuery(
  baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodosQuery, TodosQueryVariables>(
    TodosDocument,
    options
  );
}
export function useTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(
    TodosDocument,
    options
  );
}
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosQueryResult = Apollo.QueryResult<
  TodosQuery,
  TodosQueryVariables
>;
export const UserByIdDocument = gql`
  query userById($id: ID!) {
    userById(id: $id) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByIdQuery,
    UserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<
  typeof useUserByIdLazyQuery
>;
export type UserByIdQueryResult = Apollo.QueryResult<
  UserByIdQuery,
  UserByIdQueryVariables
>;
export const UsersDocument = gql`
  query users($params: UserSearchParams) {
    users(params: $params) {
      count
      users {
        ...userFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const YourWorkDocument = gql`
  query yourWork(
    $type: YourWorkTypeEnum
    $assignedToIds: [ID!]
    $pagination: PaginationInput
  ) {
    yourWork(
      type: $type
      assignedToIds: $assignedToIds
      pagination: $pagination
    ) {
      count
      results {
        __typename
        ... on Todo {
          ...todoFragment
        }
        ... on Case {
          ...caseFragment
        }
      }
    }
  }
  ${TodoFragmentFragmentDoc}
  ${CaseFragmentFragmentDoc}
`;

/**
 * __useYourWorkQuery__
 *
 * To run a query within a React component, call `useYourWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useYourWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYourWorkQuery({
 *   variables: {
 *      type: // value for 'type'
 *      assignedToIds: // value for 'assignedToIds'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useYourWorkQuery(
  baseOptions?: Apollo.QueryHookOptions<YourWorkQuery, YourWorkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YourWorkQuery, YourWorkQueryVariables>(
    YourWorkDocument,
    options
  );
}
export function useYourWorkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YourWorkQuery,
    YourWorkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YourWorkQuery, YourWorkQueryVariables>(
    YourWorkDocument,
    options
  );
}
export type YourWorkQueryHookResult = ReturnType<typeof useYourWorkQuery>;
export type YourWorkLazyQueryHookResult = ReturnType<
  typeof useYourWorkLazyQuery
>;
export type YourWorkQueryResult = Apollo.QueryResult<
  YourWorkQuery,
  YourWorkQueryVariables
>;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type RefreshTokensMutationFn = Apollo.MutationFunction<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >(RefreshTokensDocument, options);
}
export type RefreshTokensMutationHookResult = ReturnType<
  typeof useRefreshTokensMutation
>;
export type RefreshTokensMutationResult =
  Apollo.MutationResult<RefreshTokensMutation>;
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;
