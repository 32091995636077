import { useEffect, useState } from "react";
import Loading from "components/common/Loading";
import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import Tag from "components/common/Tag";
import Row from "components/common/Row";
import Col from "components/common/Col";
import {
  EmailCampaignItemFragmentFragment,
  EmailCampaignItemStatusEnum,
  EmailCampaignStatusEnum,
  useCancelOneEmailCampaignMutation,
  useGetOneEmailCampaignWithItemsQuery,
  useStopOneScheduledEmailMutation,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "routes/app-automations-create/shared";
import Button from "components/common/Button";
import Popconfirm from "components/common/Popconfirm";
import {
  ModalOverlay,
  Modal,
  ModalBody,
  ModalTitle,
  ButtonArea,
} from "components/common/GrowlabModal";
import FormLabel from "components/text/FormLabel";
import { message, Tooltip } from "antd";
import Text from "components/text/Text";
import { LoadingOutlined } from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";
import RescheduleManyModal from "./RescheduleManyModal";
import RescheduleModal from "./RescheduleModal";

interface EmailType {
  toEmail: string;
  subject: string;
  body: string;
}

const GlobalStyle = createGlobalStyle`
  .ant-picker-dropdown {
    z-index: 15000 !important;
  }
`;

export default function AppEmailCampaignsDetail() {
  const params = useParams<{ id: string }>();
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  const [shouldPoll, setShouldPoll] = useState<boolean>(false);
  const { data, loading } = useGetOneEmailCampaignWithItemsQuery({
    variables: {
      emailCampaignId: (params?.id as string) || "",
      campaignItemsPagination: {
        skip,
        limit,
      },
    },
    pollInterval: shouldPoll ? 20000 : undefined,
    skip: params?.id ? false : true,
  });

  const [cancelOneEmailCampaignMutation] = useCancelOneEmailCampaignMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<EmailType | null>(null);
  const [stopOneScheduledEmailMutation] = useStopOneScheduledEmailMutation();

  const [isRescheduleModalVisible, setIsRescheduleModalVisible] =
    useState(false);
  const [selectedFailedEmail, setSelectedFailedEmail] =
    useState<EmailCampaignItemFragmentFragment | null>(null);

  const [isRescheduleManyModalVisible, setIsRescheduleManyModalVisible] =
    useState(false);

  const campaign = data?.getOneEmailCampaign;

  useEffect(() => {
    try {
      const shouldStartPolling =
        campaign?.status === EmailCampaignStatusEnum.Cancelling ||
        campaign?.status === EmailCampaignStatusEnum.Creating;

      if (shouldStartPolling && !shouldPoll) {
        setShouldPoll(true);
      } else if (!shouldStartPolling && shouldPoll) {
        setShouldPoll(false);
      }
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  }, [campaign?.status, shouldPoll]);

  if (loading) return <Loading />;

  const canCancelCampaign =
    campaign?.status !== EmailCampaignStatusEnum.Cancelled &&
    campaign?.status !== EmailCampaignStatusEnum.Cancelling &&
    campaign?.status !== EmailCampaignStatusEnum.Draft &&
    campaign?.status !== EmailCampaignStatusEnum.Complete &&
    campaign?.status !== EmailCampaignStatusEnum.Creating;

  return (
    <PageContainer>
      <GlobalStyle />
      <RescheduleModal
        isVisible={isRescheduleModalVisible}
        onClose={() => {
          setIsRescheduleModalVisible(false);
          setSelectedFailedEmail(null);
        }}
        email={selectedFailedEmail}
      />
      <RescheduleManyModal
        isVisible={isRescheduleManyModalVisible}
        emailCampaignId={params?.id as string}
        onClose={() => {
          setIsRescheduleManyModalVisible(false);
        }}
      />
      <Row>
        <Col xs={12}>
          <PageTitle style={{ marginBottom: 8 }}>{campaign?.title} </PageTitle>
          {(() => {
            try {
              switch (campaign?.status) {
                case EmailCampaignStatusEnum.Cancelled:
                  return <Tag red>Canceled</Tag>;
                case EmailCampaignStatusEnum.Cancelling:
                  return (
                    <Tag yellow>
                      <LoadingOutlined style={{ marginRight: 8 }} />
                      Canceling campaign... this can take a few minutes
                    </Tag>
                  );
                case EmailCampaignStatusEnum.Creating:
                  return (
                    <Tag blue>
                      <LoadingOutlined style={{ marginRight: 8 }} />
                      Creating campaign... this can take a few minutes
                    </Tag>
                  );
                default:
                  return (
                    <Text>
                      Campaign Status: <Tag grey>{campaign?.status}</Tag>
                    </Text>
                  );
              }
            } catch (error) {
              return null;
            }
          })()}

          <Text style={{ marginTop: 8 }}>
            Recipients:{" "}
            {campaign?.recipientReferences?.map((item) => (
              <Tag key={item?.id}>{item?.title}</Tag>
            ))}
          </Text>
        </Col>
        <Col xs={12}>
          <div
            style={{
              marginTop: 8,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {campaign?.status === EmailCampaignStatusEnum.PartiallyFailed && (
              <Button
                secondary
                onClick={() => setIsRescheduleManyModalVisible(true)}
              >
                Reschedule Failed Emails
              </Button>
            )}
            {canCancelCampaign && (
              <Popconfirm
                title="Are you sure you want to cancel the entire campaign?"
                okText="Yes, I'm sure"
                onConfirm={async () => {
                  try {
                    await cancelOneEmailCampaignMutation({
                      variables: { emailCampaignId: params?.id as string },
                    });
                  } catch (err: any) {
                    message.error(err?.message);
                  }
                }}
              >
                <Button grey>Cancel Campaign</Button>
              </Popconfirm>
            )}
          </div>
        </Col>
      </Row>

      <div style={{ marginBottom: 24 }}></div>
      {campaign?.status !== EmailCampaignStatusEnum.Creating && (
        <Table
          dataSource={campaign?.emailCampaignItems?.results}
          columns={[
            {
              title: "email",
              render: (row: EmailCampaignItemFragmentFragment) => {
                if (row?.contactId) {
                  return (
                    <Link
                      to={`/app/contacts/${row?.contactId}`}
                      target="_blank"
                    >
                      {row?.email}
                    </Link>
                  );
                }
                return row?.email;
              },
            },
            {
              title: "Status",
              render: (row: EmailCampaignItemFragmentFragment) => {
                if (
                  row?.status === EmailCampaignItemStatusEnum.Sent ||
                  row?.status === EmailCampaignItemStatusEnum.Opened
                ) {
                  return (
                    <>
                      <Tag>{row?.status}</Tag>
                    </>
                  );
                }
                if (row?.status === EmailCampaignItemStatusEnum.Scheduled) {
                  return (
                    <>
                      <Tag blue>{row?.status}</Tag>
                    </>
                  );
                }
                if (
                  row?.status === EmailCampaignItemStatusEnum.Failed ||
                  row?.status === EmailCampaignItemStatusEnum.Bounced
                ) {
                  return (
                    <>
                      {row?.failedReason ? (
                        <Tooltip title={row?.failedReason}>
                          <Tag red style={{ marginLeft: 8 }}>
                            {row?.status}
                          </Tag>
                        </Tooltip>
                      ) : (
                        <Tag red>{row?.status}</Tag>
                      )}
                    </>
                  );
                }
                return (
                  <>
                    <Tag grey>{row?.status}</Tag>
                  </>
                );
              },
            },

            {
              title: "scheduled for",
              render: (row: EmailCampaignItemFragmentFragment) =>
                row?.scheduledAt &&
                moment(parseInt(row?.scheduledAt) * 1000)?.format(
                  // they are saved in seconds but moment expects milliseconds
                  "MMM DD, YYYY hh:mma"
                ),
            },

            {
              title: "actions",
              render: (row: EmailCampaignItemFragmentFragment) => (
                <>
                  {row.status !== EmailCampaignItemStatusEnum.Failed && (
                    <Button
                      grey
                      style={{ marginRight: 24 }}
                      title="See what is scheduled to be sent"
                      onClick={async () => {
                        try {
                          if (typeof row?.id === "string") {
                            setSelectedEmail(
                              () =>
                                ({
                                  subject: row.subject ?? null,
                                  body: row.body ?? null,
                                  toEmail: row.toEmail ?? null,
                                }) as any
                            );
                            setIsModalVisible(true);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    >
                      Preview
                    </Button>
                  )}

                  {row.status === EmailCampaignItemStatusEnum.Failed && (
                    <Button
                      secondary
                      title="Reschedule a single failed email"
                      onClick={() => {
                        setSelectedFailedEmail(row);
                        setIsRescheduleModalVisible(true);
                      }}
                    >
                      Reschedule
                    </Button>
                  )}
                  {row.status === EmailCampaignItemStatusEnum.Scheduled && (
                    <Popconfirm
                      title="Are you sure you want to cancel this scheduled email?"
                      okText="Yes"
                      onConfirm={async () => {
                        try {
                          if (typeof row?.id === "string") {
                            await stopOneScheduledEmailMutation({
                              variables: {
                                emailCampaignItemId: row?.id,
                              },
                            });
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    >
                      <Button danger title="Cancel this scheduled email">
                        Cancel
                      </Button>
                    </Popconfirm>
                  )}
                </>
              ),
            },
          ]}
        />
      )}

      {isModalVisible && (
        <ModalOverlay>
          <Modal>
            <ModalBody>
              <ModalTitle>Email Preview</ModalTitle>
              <FormLabel>To Email:</FormLabel>
              {selectedEmail?.toEmail}
              <FormLabel style={{ marginTop: 24 }}>Subject:</FormLabel>
              {selectedEmail?.subject}
              <FormLabel style={{ marginTop: 24 }}>Body:</FormLabel>
              {selectedEmail?.body && (
                <div
                  dangerouslySetInnerHTML={{ __html: selectedEmail?.body }}
                />
              )}
            </ModalBody>
            <ButtonArea>
              {" "}
              <Button onClick={() => setIsModalVisible(false)}>Close</Button>
            </ButtonArea>
          </Modal>
        </ModalOverlay>
      )}
    </PageContainer>
  );
}
