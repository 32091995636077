import { useState } from "react";
import styled from "styled-components";
import numeral from "numeral";
// COMPONENTS
import EditTitleInput from "../EditTitleInput";
import ProjectCard from "../ProjectCard";
import message from "components/common/message";
import { LoadingOutlined } from "@ant-design/icons";
// APOLLO
import { Droppable } from "react-beautiful-dnd";
import {
  PipelineStageFragmentFragment,
  useProjectsByStageQuery,
  useUpdatePipelineColMutation,
} from "generated/graphql";

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: auto;
  min-width: 282px;
  max-width: 282px;
  flex: 1 1 auto;
  background: #e7edf4;
  padding: 4px;
  height: 70vh;
  overflow-y: scroll;
  position: relative;
  padding-top: 0px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const Title = styled.h2`
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.neutral3};
  letter-spacing: 0.08em;
`;

const TitleHover = styled.div`
  padding: 8px;
  margin-top: 2px;
  position: relative;
  &:hover {
    background: ${(p) => p.theme.colors.neutral9};
    cursor: text;
  }
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: #e7edf4;
  &:before {
    opacity: 0;
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0px;
    right: 0px;
    height: 2px;
    pointer-events: none;
    background-color: rgb(151, 160, 175);
    transition: opacity 0.2s ease 0s;
  }
`;

const TotalAmount = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  height: 40px;
  background: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoldAmount = styled.p`
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  margin-left: 4px;
  color: ${(p) => p.theme.colors.neutral1};
`;

export default function StageBlock({
  stage,
  pipelineId,
}: {
  stage: PipelineStageFragmentFragment;
  pipelineId: string;
}) {
  const [editName, setEditName] = useState(false);
  const [label, setLabel] = useState(stage.label);
  const [updatePipelineCol] = useUpdatePipelineColMutation();
  const { loading, data, error } = useProjectsByStageQuery({
    variables: {
      pipelineId,
      // stage: stage.order,
      stageId: stage.id,
    },
    // fetchPolicy: 'cache-and-network',
  });

  // returns the total at the bottom of the column

  const getTotal = (projects) => {
    if (loading) {
      return "...";
    }
    if (projects.length > 0) {
      let amounts = projects.map((item) => item.amount);
      let sum = amounts.reduce((a, b) => a + b, 0);
      return `$${numeral(sum).format("0,0")}`;
    }
    return `$0`;
  };

  // if an error exists, return null
  if (error) {
    console.log({
      error,
    });
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <BlockContainer>
        <StickyHeader>
          {!editName ? (
            <TitleHover onClick={() => setEditName(true)}>
              <Title data-testid="col-stage-label">{stage.label}</Title>
              <Title style={{ position: "absolute", right: 8, top: 8 }}>
                {loading ? ".." : data?.projectsByStage?.length}
              </Title>
            </TitleHover>
          ) : (
            <EditTitleInput
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              onCancel={() => setEditName(false)}
              onClick={async () => {
                await updatePipelineCol({
                  variables: {
                    params: {
                      pipelineId,
                      id: stage.id,
                      label,
                      order: stage.order,
                    },
                  },
                });
                message.success("Column name successfully updated!");
                setEditName(false);
              }}
            />
          )}
        </StickyHeader>
        {loading && <LoadingOutlined />}
        <Droppable droppableId={stage.id}>
          {(provided) => {
            return (
              <div
                key={stage.id}
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ minHeight: 500 }}
                data-testid={`droppable-area-${stage?.label}`}
              >
                {!loading &&
                  data?.projectsByStage?.map((project, index) => (
                    <ProjectCard
                      key={project?.id}
                      project={project}
                      index={index}
                    />
                  ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </BlockContainer>
      <TotalAmount>
        Total:{" "}
        <BoldAmount>
          {getTotal((data && data.projectsByStage) || [])}
        </BoldAmount>
      </TotalAmount>
    </div>
  );
}
