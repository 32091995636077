import { useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { debounce } from "lodash";
// APOLLO
import CONTACTS from "ApolloClient/Queries/contacts";
import SEARCH_USERS from "ApolloClient/Queries/searchUsers";
import NAICS_SEARCH from "ApolloClient/Queries/naicsSearch";
// COMPONENTS
import ApolloClient from "ApolloClient/index";
import { CardAvatar } from "components/detail-components";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { SearchOutlined } from "@ant-design/icons";
import {
  BusinessesDocument,
  ProjectsDocument,
  SitesDocument,
} from "generated/graphql";
const { Option } = Select;

const Logo = styled.img`
  width: 24px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const EmptyLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.neutral9};
`;

const OptContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral10};
`;

const OptText = styled.div`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 16px;
  font-weight: 600;
`;

const getPlaceholder = (type) => {
  if (type === "contacts") {
    return "Search contacts";
  }
  if (type === "businesses") {
    return "Search businesses";
  }
  if (type === "projects") {
    return "Search projects";
  }
  if (type === "naics") {
    return "Search NAICs codes";
  }
  return "Type to start searching..";
};

const BusinessOption = ({ item }) => {
  return (
    <OptContainer>
      <div style={{ marginRight: 6 }}>
        {item.logo && item.logo.url ? (
          <Logo src={item.logo.url} />
        ) : (
          <EmptyLogo />
        )}
      </div>
      <div>
        <OptText>{item.title}</OptText>
      </div>
    </OptContainer>
  );
};

const ProjectOption = ({ item }) => {
  return (
    <OptionItem>
      <CardAvatar type="projects" src={item.image && item.image.url} />
      <div>
        {" "}
        <OptText>{item.title}</OptText>
        {/* <span> {item.email}</span> */}
      </div>
    </OptionItem>
  );
};

const SiteOption = ({ item }) => {
  return (
    <OptionItem>
      {/* <CardAvatar type="projects" src={item.image && item.image.url} /> */}
      <div>
        {" "}
        <OptText>{item.title}</OptText>
        {/* <span> {item.email}</span> */}
      </div>
    </OptionItem>
  );
};

const NaicsOption = ({ item }) => {
  return (
    <OptionItem>
      <div>
        {" "}
        <OptText>
          {item.title} {item.code}
        </OptText>
      </div>
    </OptionItem>
  );
};

const UserOption = ({ item }) => {
  return (
    <OptionItem>
      <CardAvatar type="contacts" src={item?.avatar?.url || undefined} />
      <div>
        {" "}
        <OptText>
          {item.firstName} {item.lastName}
        </OptText>
        <span> {item.email}</span>
      </div>
    </OptionItem>
  );
};

// const Avatar = ({url}) => {
//   return (
//     <>
//       {url && <Image src={url} alt="avatar" h />}
//       {!url && <EmptyAvatar />}
//     </>
//   );
// };

const OptionItem = styled.div`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral10};
  padding: 8px;
`;

const ContactOption = ({ item }) => {
  return (
    <OptionItem>
      <CardAvatar type="contacts" src={item?.avatar?.url || undefined} />
      <div>
        {" "}
        <OptText>
          {item.firstName} {item.lastName}
        </OptText>
        <span> {item.email}</span>
      </div>
    </OptionItem>
  );
};

const getByOptionLabel = (item, type) => {
  switch (type) {
    case "businesses":
      return item.title;
    case "contacts":
      return `${item.firstName} ${item.lastName}`;
    case "projects":
      return item.title;
    case "users":
      return `${item.firstName} ${item.lastName}`;
    case "naics":
      return item.title;
    case "sites":
      return item.title;
    default:
      return null;
  }
};

export default function SearchInput({
  onSelect,
  type,
  placeholder,
}: {
  onSelect: (obj: any) => void;
  type?: string;
  placeholder?: string;
}) {
  const [value, setValue] = useState(undefined);
  const [options, setOptions] = useState<any[] | []>([]);
  const onSearch = async (searchText) => {
    try {
      let res;
      if (type === "contacts") {
        res = await ApolloClient.query({
          query: CONTACTS,
          variables: {
            params: {
              searchText,
            },
          },
        });
        setOptions(res.data.contacts.contacts);
      }
      if (type === "sites") {
        res = await ApolloClient.query({
          query: SitesDocument,
          variables: {
            searchText,
            limit: 30,
          },
        });
        setOptions(res?.data?.sites?.sites);
      }
      if (type === "users") {
        res = await ApolloClient.query({
          query: SEARCH_USERS,
          variables: {
            params: {
              searchText,
            },
          },
        });
        setOptions(res.data.searchUsers);
      }

      if (type === "naics") {
        res = await ApolloClient.query({
          query: NAICS_SEARCH,
          variables: {
            searchText,
          },
        });
        setOptions(res.data.naicsSearch);
      }

      if (type === "projects") {
        res = await ApolloClient.query({
          query: ProjectsDocument,
          variables: {
            searchText,
          },
        });
        setOptions(res.data.projects);
      }

      if (type === "businesses") {
        res = await ApolloClient.query({
          query: BusinessesDocument,
          variables: {
            params: {
              searchText,
            },
          },
        });
        setOptions(res.data.businesses.businesses);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilter = debounce((finalSearchText) => {
    onSearch(finalSearchText);
  }, 250);

  const onChange = (changeValue) => {
    options.forEach((item) => {
      if (item?.id === changeValue) {
        onSelect(item);
        setValue(undefined);
      }
    });
    return undefined;
  };

  console.log({
    value,
  });

  return (
    <DarkerDropdown>
      <Select
        showSearch
        defaultValue={undefined}
        value={undefined}
        filterOption={false}
        defaultActiveFirstOption={false}
        suffixIcon={<SearchOutlined />}
        placeholder={placeholder || getPlaceholder(type)}
        style={{ width: "100%" }}
        onSearch={handleFilter}
        onChange={onChange}
        notFoundContent={null}
        optionLabelProp="label"
        data-testid={`search-input-${type}`}
      >
        {options.map((item) => (
          <Option
            key={item.id}
            label={getByOptionLabel(item, type)}
            data-testid={`option-${item.title}`}
          >
            {(() => {
              switch (type) {
                case "businesses":
                  return <BusinessOption item={item} />;
                case "sites":
                  return <SiteOption item={item} />;
                case "contacts":
                  return <ContactOption item={item} />;
                case "projects":
                  return <ProjectOption item={item} />;
                case "users":
                  return <UserOption item={item} />;
                case "naics":
                  return <NaicsOption item={item} />;
                default:
                  return null;
              }
            })()}
          </Option>
        ))}
      </Select>
    </DarkerDropdown>
  );
}

const DarkerDropdown = styled(DropdownStyleWrapper)`
  .ant-select-selection-search-input,
  .ant-select-selector,
  .ant-select-single {
    background-color: ${({ theme }) => theme.colors.neutral10} !important;
    background: ${({ theme }) => theme.colors.neutral10} !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.neutral10} !important;
    background: ${({ theme }) => theme.colors.neutral10} !important;
  }
`;
