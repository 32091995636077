import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import mailSVG from "./mail.svg";
import phoneSVG from "./phone.svg";
import locationSVG from "./location.svg";
import { Title, Description, Card, Name, CreatedAt } from "../index";
import LinkButton from "components/common/LinkButton";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import {
  CurrentUserFragmentFragment,
  Interaction,
  InteractionFragmentFragment,
  InteractionLongFragmentFragment,
  InteractionTypeEnum,
  ObjectTypeEnum,
  UserPermissionEnum,
  useDeleteInteractionMutation,
} from "generated/graphql";
import Tooltip from "components/common/Tooltip";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Dropdown from "components/common/Dropdown";
import Menu from "components/common/Menu";
import Modal from "components/common/Modal";
import useUrlChange from "hooks/useUrlChange";
import FORM_TYPES from "lib/constants/formTypes";
import useCurrentUser from "lib/hooks/useCurrentUser";
import ObjectPermissionsManager from "components/common/ObjectPermissionsManager";
import { Popover } from "antd";

const DetailLabel = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral6};
`;

const DetailValue = styled.p`
  margin: 0px;
`;

function createMarkup(text = "First &middot; Second") {
  return { __html: text };
}

const EmailContentContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  padding: 16px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.neutral11};
`;

const EmailLabel = styled.p`
  font-weight: 400;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  margin-right: 4px;
`;

const AttachmentLink = styled.a`
  font-size: 14px;
  color: ${(p) => p.theme.colors.blue4} !important;
`;

const EmailContent = ({ email }) => {
  return (
    <EmailContentContainer>
      <div>
        <Name>
          <EmailLabel>To:</EmailLabel>
          {email.to}
        </Name>
      </div>
      <div>
        {" "}
        <Name>
          <EmailLabel>From:</EmailLabel>
          {email.from}
        </Name>
      </div>
      {email?.attachments?.[0] && (
        <div style={{ marginBottom: 16 }}>
          <Name>
            <EmailLabel>Attachments:</EmailLabel>
            <div>
              {email?.attachments.map((attachment) => {
                return (
                  <AttachmentLink
                    key={attachment.id}
                    target="_blank"
                    href={attachment.url}
                  >
                    {attachment.filename}
                  </AttachmentLink>
                );
              })}
            </div>
          </Name>
        </div>
      )}
      <EmailLabel>Subject:</EmailLabel>
      <div style={{ marginBottom: 16 }}>{email.subject}</div>
      <EmailLabel>Body:</EmailLabel>
      <div dangerouslySetInnerHTML={createMarkup(email.body)} />
    </EmailContentContainer>
  );
};

const getIcon = (interaction: InteractionFragmentFragment) => {
  switch (interaction?.type) {
    case InteractionTypeEnum.Email:
      return mailSVG;
    case InteractionTypeEnum.Phone:
      return phoneSVG;
    case InteractionTypeEnum.Visit:
      return locationSVG;
    default:
      return phoneSVG;
  }
};

const getIconTitle = (interaction: InteractionFragmentFragment) => {
  switch (interaction?.type) {
    case InteractionTypeEnum.Email:
      return "Email";
    case InteractionTypeEnum.Phone:
      return "Phone Call";
    case InteractionTypeEnum.Visit:
      return "Site Visit";
    default:
      return "";
  }
};

const OptionsArea = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  display: flex;
  align-items: center;
`;

const OptionsBtn = styled.button`
  font-size: 22px;
  border: 0px;
  cursor: pointer;
  background: transparent;
  margin-left: 16px;
`;

const TransparentBtn = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
`;

const { confirm } = Modal;

const MenuWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const DropdownMenu = ({
  interaction,
  onEdit,
  currentUser,
}: {
  interaction: InteractionFragmentFragment;
  onEdit: any;
  currentUser?: CurrentUserFragmentFragment;
}) => {
  const [deleteInteractionMutation] = useDeleteInteractionMutation();
  return (
    <MenuWrapper>
      <Menu>
        {currentUser?.id === interaction?.createdBy?.id && (
          <Menu.Item>
            <TransparentBtn
              onClick={onEdit}
              data-testid="interactions-card-options-edit"
            >
              Edit Interaction
            </TransparentBtn>
          </Menu.Item>
        )}
        <Menu.Item danger>
          <TransparentBtn
            data-testid="interactions-card-options-delete"
            onClick={() => {
              confirm({
                className: "growlab-confirm-modal",
                title: "Are you sure that you want to delete this interaction?",
                icon: <ExclamationCircleOutlined />,
                content:
                  "You will not be able to recover it. Are you sure you want to delete this? ",
                async onOk() {
                  try {
                    if (!interaction?.id) return;
                    await deleteInteractionMutation({
                      variables: {
                        id: interaction?.id,
                      },
                      refetchQueries: ["interactions"],
                    });
                  } catch (err) {
                    console.log(err);
                  }
                },
                okText: "Yes I'm sure.",
                onCancel() {
                  console.log("Cancel");
                },
              });
            }}
          >
            Delete Interaction
          </TransparentBtn>
        </Menu.Item>
      </Menu>
    </MenuWrapper>
  );
};

const TopBar = ({
  interaction,
  onEdit,
  currentUser,
}: {
  interaction: InteractionFragmentFragment;
  onEdit: any;
  currentUser?: CurrentUserFragmentFragment;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 16 }}>
          <Tooltip title={getIconTitle(interaction)}>
            <img
              src={getIcon(interaction)}
              alt="interfaction-icon"
              style={{ position: "relative", top: 0 }}
            />
          </Tooltip>
        </div>
        <div>
          <Name>
            {interaction?.createdBy?.firstName}{" "}
            {interaction?.createdBy?.lastName}
          </Name>
          {interaction.date && (
            <CreatedAt>
              {moment(parseInt(interaction.date)).format("MMMM D, YYYY")} at{" "}
              {moment(parseInt(interaction.date)).format("h:mm A")}
            </CreatedAt>
          )}
          {interaction?.project?.id && (
            <CreatedAt>
              <Link
                to={`/app/projects/${interaction?.project?.id}`}
                target="_blank"
              >
                {interaction?.project?.title}
              </Link>
            </CreatedAt>
          )}
        </div>
      </div>

      <OptionsArea>
        {currentUser?.permission?.includes(
          UserPermissionEnum.Administrator
        ) && (
          <Popover
            destroyTooltipOnHide
            content={
              <ObjectPermissionsManager
                objectType={ObjectTypeEnum?.Interaction}
                id={interaction?.id}
              />
            }
            placement="bottom"
          >
            <TransparentBtn
              onClick={() => console.log("permissions")}
              data-testid="sharing-group-permissions-btn"
            >
              Share
            </TransparentBtn>
          </Popover>
        )}

        <OptionsBtn data-testid="interactions-card-options">
          <Dropdown
            overlay={
              <DropdownMenu
                interaction={interaction}
                onEdit={onEdit}
                currentUser={currentUser}
              />
            }
          >
            <EllipsisOutlined />
          </Dropdown>
        </OptionsBtn>
      </OptionsArea>
    </div>
  );
};

export default function InteractionCard({
  interaction,
}: {
  interaction?: InteractionLongFragmentFragment;
}) {
  const [showEmail, setShowEmail] = useState(false);

  const { onUrlChange } = useUrlChange();
  const currentUser = useCurrentUser();

  if (!interaction) return null;

  return (
    <Card data-testid={`interaction-card-${interaction?.title}`}>
      <TopBar
        interaction={interaction}
        currentUser={currentUser}
        onEdit={() => {
          if (interaction?.type === InteractionTypeEnum.Visit) {
            onUrlChange({
              editInteractionId: interaction?.id,
              drawerVisible: FORM_TYPES?.VISIT,
            });
          }
          if (interaction?.type === InteractionTypeEnum.Email) {
            onUrlChange({
              editInteractionId: interaction?.id,
              drawerVisible: FORM_TYPES?.EMAIL,
            });
          }
          if (interaction?.type === InteractionTypeEnum.Phone) {
            onUrlChange({
              editInteractionId: interaction?.id,
              drawerVisible: FORM_TYPES?.PHONE,
            });
          }
        }}
      />
      <Title>{interaction.title}</Title>
      <div style={{ marginTop: 8 }}>
        <Description>
          <div
            dangerouslySetInnerHTML={createMarkup(
              interaction?.description || ""
            )}
          />
        </Description>
        {interaction?.attachments?.[0] && (
          <div style={{ marginBottom: 16, marginTop: 16 }}>
            <Name>
              <EmailLabel style={{ marginBottom: 4 }}>Attachments:</EmailLabel>
              <div>
                {interaction?.attachments.map((attachment) => {
                  if (!attachment?.url) return null;
                  return (
                    <div key={attachment?.id} style={{ marginBottom: 4 }}>
                      <AttachmentLink target="_blank" href={attachment?.url}>
                        {attachment?.filename}
                      </AttachmentLink>
                    </div>
                  );
                })}
              </div>
            </Name>
          </div>
        )}
      </div>
      {interaction && interaction.contact && (
        <div style={{ marginTop: 8 }}>
          <DetailLabel>Contacted</DetailLabel>
          <DetailValue>
            <Link to={`/app/contacts/${interaction.contact.id}`}>
              {interaction?.contact?.fullName
                ? `${interaction?.contact?.fullName}`
                : interaction?.contact?.email}
            </Link>
          </DetailValue>
        </div>
      )}
      {interaction?.emailCampaign?.id && (
        <div style={{ marginTop: 8 }}>
          <DetailLabel>Email Campaign</DetailLabel>
          <DetailValue>
            <Link to={`/app/email-campaigns/${interaction.emailCampaign.id}`}>
              {interaction?.emailCampaign?.title}
            </Link>
          </DetailValue>
        </div>
      )}
      {interaction?.email?.body && (
        <>
          <div
            style={{
              marginTop: 8,
              marginBottom: 8,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LinkButton
              onClick={() => setShowEmail((previousState) => !previousState)}
            >
              {!showEmail ? (
                <>
                  Show Full{" "}
                  <DownOutlined
                    style={{
                      fontSize: 10,
                      position: "relative",
                      top: -2,
                      left: 4,
                    }}
                  />
                </>
              ) : (
                <>
                  Hide Full{" "}
                  <UpOutlined
                    style={{
                      fontSize: 10,
                      position: "relative",
                      top: -2,
                      left: 4,
                    }}
                  />
                </>
              )}
            </LinkButton>
          </div>
          {showEmail && <EmailContent email={interaction.email} />}
        </>
      )}
    </Card>
  );
  // return (
  //   <ItemContainer>
  //     <div style={{marginRight: 16}}>
  //       <img
  //         src={mailSVG}
  //         alt="mail-icon"
  //         style={{position: 'relative', top: 8}}
  //       />
  //     </div>
  //     <div style={{width: '100%'}}>
  //       <ItemTitle>{interaction.title}</ItemTitle>
  //       <ItemDescription>{interaction.description}</ItemDescription>
  //       <SectionLine />
  //       <div style={{display: 'flex'}}>
  //         <div style={{flex: 2}}>
  //           {interaction.contact && (
  //             <>
  //               <DetailLabel>Contacted</DetailLabel>
  //               <DetailValue>
  //                 {interaction.contact && `${interaction.contact.email}`}
  //               </DetailValue>
  //             </>
  //           )}
  //         </div>
  //         <div style={{flex: 1}}>
  //           <DetailLabel>Date</DetailLabel>
  //           <DetailValue>
  //             {moment(parseInt(interaction.date)).format('M/DD/YYYY')}
  //           </DetailValue>
  //         </div>
  //         <div style={{flex: 1}}>
  //           <DetailLabel>Time</DetailLabel>
  //           <DetailValue>
  //             {moment(parseInt(interaction.date)).format('h:mm A')}
  //           </DetailValue>
  //         </div>
  //       </div>
  //       <SectionLine />
  //       <div style={{display: 'flex'}}>
  //         <div style={{flex: 2}}>
  //           <DetailLabel>Created By</DetailLabel>
  //           <DetailValue>
  //             {interaction &&
  //               interaction.createdBy &&
  //               `${interaction.createdBy.firstName} ${interaction.createdBy.lastName}`}
  //           </DetailValue>
  //         </div>
  //         <div style={{flex: 1}}>
  //           <DetailLabel>Created on</DetailLabel>
  //           <DetailValue>
  //             {moment(parseInt(interaction.createdAt)).format('M/DD/YYYY')}
  //           </DetailValue>
  //         </div>
  //         <div style={{flex: 1}} />
  //       </div>
  //       {interaction.attachments && (
  //         <div style={{marginTop: 24}}>
  //           <DetailLabel>Attachments</DetailLabel>
  //           {interaction.attachments.map((attachment) => {
  //             return (
  //               <AttachmentLink
  //                 key={attachment.id}
  //                 href={attachment.url}
  //                 download
  //               >
  //                 {attachment.filename}
  //               </AttachmentLink>
  //             );
  //           })}
  //         </div>
  //       )}
  //     </div>
  //   </ItemContainer>
  // );
}
