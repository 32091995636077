import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";
import Caption from "components/text/Caption";
import message from "components/common/message";

const OptionsContainer = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const OptionLabel = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const OptionValue = styled.code`
  background-color: #e0e0e0;
  padding: 2px 4px;
  border-radius: 2px;
  margin-right: 8px;
`;

const ExplanationSection = styled.div`
  margin-top: 16px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  border-radius: 2px;
  margin-left: 16px;
  cursor: pointer;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral3};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

const TemplateVariableOptions = ({ emailCampaign }) => {
  const emailOptions = [
    { label: "Unsubscribe URL", value: "{{unsubscribe_url}}" },
    { label: "Contact First Name", value: "{{contact_first_name}}" },
    { label: "Business Name", value: "{{business_name}}" },
    ...(emailCampaign?.surveyTemplateId
      ? [{ label: "Survey URL", value: "{{survey_url}}" }]
      : []),
  ];

  const handleCopy = (value) => {
    // Use the Clipboard API with a fallback for older browsers
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          message.success("Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          message.error("Failed to copy. Please try again.");
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        message.success("Copied to clipboard");
      } catch (err) {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy. Please try again.");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <OptionsContainer>
      <Caption style={{ marginBottom: 16 }}>Email template variables:</Caption>
      {emailOptions.map((option, index) => (
        <OptionItem key={index}>
          <OptionLabel>{option.label}:</OptionLabel>
          <OptionValue>{option.value}</OptionValue>
          <CopyButton onClick={() => handleCopy(option.value)}>
            <CopyOutlined />
          </CopyButton>
        </OptionItem>
      ))}
      <ExplanationSection>
        <Caption style={{ marginBottom: 8 }}>Using default values:</Caption>
        <p>
          You can specify a default value for any variable using the following
          syntax:
        </p>
        <OptionValue>{'{{tag_name || default: "Default Value"}}'}</OptionValue>
      </ExplanationSection>
      <ExplanationSection>
        <Caption style={{ marginBottom: 8 }}>Using OR operator:</Caption>
        <p style={{ marginTop: 8 }}>
          You can also use an "OR operator" by using a double pipe operator. For
          example:
          <OptionValue>
            {
              '{{business_name || contact_first_name || default: "Valued Customer"}}'
            }
          </OptionValue>
        </p>
        <p>
          This will use the business name if it exists, otherwise it will use
          the contact's first name, and if that is not available, it will use
          "Valued Customer".
        </p>
      </ExplanationSection>
    </OptionsContainer>
  );
};

export default TemplateVariableOptions;
