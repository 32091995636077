import { gql } from "@apollo/client";

const customerSharingSettingsFragment = gql`
  fragment customerSharingSettingsFragment on SharingCustomerSetting {
    key
    enabled
  }
`;

export default customerSharingSettingsFragment;
