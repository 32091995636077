import styled from "styled-components";
import Title from "components/text/Title";
import Caption from "components/text/Caption";
import LinkButton from "components/common/LinkButton";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
import formTypes from "lib/constants/formTypes";
import theme from "lib/theme";
//icons
import BuildingFilled from "components/icons/BuildingFilled";
import UserFilled from "components/icons/UserFilled";
import CheckCircleFilled from "components/icons/CheckCircleFilled";
import BriefcaseFilled from "components/icons/BriefcaseFilled";
import ClipboardFilled from "components/icons/ClipboardFilled";

import {
  ArrowRightOutlined,
  EnvironmentFilled,
  PhoneFilled,
  ProjectFilled,
  DollarCircleFilled,
  GoldFilled,
  MailFilled,
  ThunderboltFilled,
} from "@ant-design/icons";

import featureIsOn from "lib/helpers/featureIsOn";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";
import useCurrentUser from "lib/hooks/useCurrentUser";

const OptionCardContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  border-radius: 5px;
  width: 375px;
  display: inline-flex;
  margin-right: 16px;
  margin-top: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.neutral8};
  }
`;

const IconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary9};
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionCard = ({
  title,
  caption,
  linkText,
  onClick,
  icon,
  testId,
}: {
  title: string;
  caption: string;
  linkText: string;
  onClick: any;
  icon: any;
  testId: string;
}) => {
  return (
    <OptionCardContainer onClick={onClick} data-testid={testId}>
      <div>
        <IconCircle>{icon}</IconCircle>
      </div>
      <div>
        <Title style={{ margin: 0 }}>{title}</Title>
        <Caption style={{ margin: 0 }}>{caption}</Caption>
        <LinkButton style={{ marginTop: 8 }} onClick={onClick}>
          {linkText}
          <ArrowRightOutlined
            style={{
              marginLeft: 4,
              position: "relative",
              top: 0,
              fontSize: 11,
            }}
          />
        </LinkButton>
      </div>
    </OptionCardContainer>
  );
};

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.neutral2};
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  line-height: 24px;
`;

const SectionBreak = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.colors.neutral10};
  margin-bottom: 8px;
  margin-top: 56px;
`;

// const SectionDescription = styled.p`
//   margin: 0px;
//   color: ${({ theme }) => theme.colors.neutral5};
//   font-size: 14px;
//   font-family: ${({ theme }) => theme.fontFamily};
//   line-height: 20px;
//   font-weight: 500;
//   max-width: 500px;
//   margin-bottom: 8px;
// `;

export enum EntityTypeEnum {
  "TODO" = "TODO", // create a todo
  "NOTE" = "NOTE", // create a note
  "BUSINESS" = "BUSINESS", // create a project
  "EMAIL" = "EMAIL", // log an email
  "PROJECT" = "PROJECT", // create a project
  "PHONE" = "phone", // log a phone interaction
  "CONTACT" = "CONTACT",
  "SITE_VISIT" = "SITE_VISIT",
  "COMPANY_INVESTMENT" = "COMPANY_INVESTMENT",
  "CASE" = "CASE",
  "ORGANIZATION" = "ORGANIZATION",
  "FUNDING_RESOURCE" = "FUNDING_RESOURCE",
  "SITE" = "SITE",
  "SUPPORT_RESOURCE" = "SUPPORT_RESOURCE",
}

export const EntityIcon = ({
  type,
  color,
  size,
}: {
  type: EntityTypeEnum;
  color?: string;
  size?: number;
}) => {
  const defaultColor = theme.colors.blue2;
  const defaultSize = 24;

  switch (type) {
    case EntityTypeEnum.CONTACT:
      return (
        <UserFilled
          height={defaultSize || size}
          width={defaultSize || size}
          fill={color || defaultColor}
        />
      );

    case EntityTypeEnum.CASE:
      return (
        <BriefcaseFilled
          height={defaultSize || size}
          width={defaultSize || size}
          fill={color || defaultColor}
        />
      );
    case EntityTypeEnum.TODO:
      return (
        <CheckCircleFilled
          height={defaultSize || size}
          width={defaultSize || size}
          fill={color || defaultColor}
        />
      );
    case EntityTypeEnum.PROJECT:
      return (
        <ProjectFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );
    case EntityTypeEnum.SITE_VISIT:
      return (
        <ClipboardFilled
          height={defaultSize || size}
          width={defaultSize || size}
          fill={color || defaultColor}
        />
      );
    case EntityTypeEnum.SITE:
      return (
        <EnvironmentFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );
    case EntityTypeEnum.PHONE:
      return (
        <PhoneFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );
    case EntityTypeEnum.SUPPORT_RESOURCE:
      return (
        <ThunderboltFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );
    case EntityTypeEnum.FUNDING_RESOURCE:
      return (
        <DollarCircleFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );
    case EntityTypeEnum.EMAIL:
      return (
        <MailFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );

    case EntityTypeEnum.BUSINESS:
      return (
        <BuildingFilled
          height={defaultSize || size}
          width={defaultSize || size}
          fill={color || defaultColor}
        />
      );
    case EntityTypeEnum.ORGANIZATION:
      return (
        <GoldFilled
          style={{
            color: color || defaultColor,
            fontSize: defaultSize || size,
          }}
        />
      );

    default:
      return null;
  }
};

export default function CreateNewWindow({ onClose }: { onClose: () => void }) {
  const { onUrlChange } = useUrlChange();
  const currentUser = useCurrentUser();
  const ICON_COLOR = theme.colors.primary3;

  const OPTIONS = [
    {
      title: "Store your contacts",
      //   caption: "Organize your businesses and people",
      cards: [
        {
          title: "Business",
          caption:
            "Create a business profile so you can track interactions, make notes, upload files, etc",
          icon: (
            <EntityIcon type={EntityTypeEnum.BUSINESS} color={ICON_COLOR} />
          ),
          linkText: "Create a business",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.BUSINESSES });
            onClose();
          },
          testId: "create-a-business-item-btn",
        },
        {
          title: "Person",
          caption:
            "Create a person profile so you can track interactions, makenotes, upload files, etc",
          icon: <EntityIcon type={EntityTypeEnum.CONTACT} color={ICON_COLOR} />,
          linkText: "Create a person",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.CONTACTS });
            onClose();
          },
          testId: "create-a-contact-item-btn",
        },
      ],
    },
    {
      title: "Organize your work",
      //   caption: "Track your work with projects, cases and interactions",
      cards: [
        ...(featureIsOn({
          customer: currentUser?.customer,
          feature: CustomerFeatureSettingKeyEnum.Projects,
        })
          ? [
              {
                title: "Project",
                caption:
                  "Track processes like recruiting efforts or general work by building custom workflows",
                icon: (
                  <EntityIcon
                    type={EntityTypeEnum.PROJECT}
                    color={ICON_COLOR}
                  />
                ),
                linkText: "Create a project",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.PROJECTS });
                  onClose();
                },
                testId: "create-a-project-item-btn",
              },
            ]
          : []),
        ...(featureIsOn({
          customer: currentUser?.customer,
          feature: CustomerFeatureSettingKeyEnum.Cases,
        })
          ? [
              {
                title: "Case",
                caption: "Track incoming business requests and their outcomes",
                icon: (
                  <EntityIcon type={EntityTypeEnum.CASE} color={ICON_COLOR} />
                ),
                linkText: "Create a case",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.CASES });
                  onClose();
                },
                testId: "create-a-case-item-btn",
              },
            ]
          : []),

        {
          title: "Todo",
          caption:
            "Keep track of miscellaneous work tasks, assign team members and monitor progress",
          icon: <EntityIcon type={EntityTypeEnum.TODO} color={ICON_COLOR} />,
          linkText: "Create a todo",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.TODOS });
            onClose();
          },
          testId: "create-a-todo-item-btn",
        },
      ],
    },
    {
      title: "Track your interactions",
      //   caption: "Track your work with projects, cases and interactions",
      cards: [
        {
          title: "Phone call",
          caption:
            "Log a phone call and associate it with business, people, projects and more",
          icon: <EntityIcon type={EntityTypeEnum.PHONE} color={ICON_COLOR} />,
          linkText: "Log a phone call",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.PHONE });
            onClose();
          },
          testId: "create-a-phone-item-btn",
        },
        {
          title: "Site Visit",
          caption:
            "Log a site visit and associate it with business, people, projects and more",
          icon: (
            <EntityIcon type={EntityTypeEnum.SITE_VISIT} color={ICON_COLOR} />
          ),
          linkText: "Log a site visit",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.VISIT });
            onClose();
          },
          testId: "create-a-visit-item-btn",
        },
        {
          title: "Email",
          caption:
            "Log an email and associate it with business, people, projects and more",
          icon: <EntityIcon type={EntityTypeEnum.EMAIL} color={ICON_COLOR} />,
          linkText: "Log an email",
          onClick: () => {
            onUrlChange({ drawerVisible: formTypes.EMAIL });
            onClose();
          },
          testId: "create-a-email-item-btn",
        },
      ],
    },
    ...(featureIsOn({
      customer: currentUser?.customer,
      feature: CustomerFeatureSettingKeyEnum.Resources,
    })
      ? [
          {
            title:
              "Store data about the resources & opportunities in your area",
            //   caption:
            //     "Store information about the resources, locations, programs and other opportunties available in your business ecosystem",
            icon: (
              <BuildingFilled
                height={24}
                width={24}
                fill={theme.colors.blue2}
              />
            ),
            cards: [
              {
                title: "Support Resource",
                caption:
                  "Keep an inventory of free mentoring, legal help and other business support resources",
                icon: (
                  <EntityIcon
                    type={EntityTypeEnum.SUPPORT_RESOURCE}
                    color={ICON_COLOR}
                  />
                ),
                linkText: "Create a resource",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.SUPPORT });
                  onClose();
                },
              },
              {
                title: "Funding Resource",
                caption:
                  "Keep an inventory of grants, incentives, loan programs and other funding resources",
                icon: (
                  <EntityIcon
                    type={EntityTypeEnum.FUNDING_RESOURCE}
                    color={ICON_COLOR}
                  />
                ),
                linkText: "Create a resource",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.FUNDING });
                  onClose();
                },
              },
              {
                title: "Site",
                caption:
                  "Keep an inventory of your available parcels, buildings, industrial parks are more",
                icon: (
                  <EntityIcon type={EntityTypeEnum.SITE} color={ICON_COLOR} />
                ),
                linkText: "Create a site",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.SITE });
                  onClose();
                },
              },
              {
                title: "Ecosystem Organization",
                caption:
                  "Keep an inventory of the other organizations in your business ecosystem",
                icon: (
                  <EntityIcon
                    type={EntityTypeEnum.ORGANIZATION}
                    color={ICON_COLOR}
                  />
                ),
                linkText: "Create an organization",
                onClick: () => {
                  onUrlChange({ drawerVisible: formTypes.ORGANIZATIONS });
                  onClose();
                },
              },
            ],
          },
        ]
      : []),
  ];
  return (
    <div style={{ padding: 24 }}>
      {OPTIONS?.map((section, index) => {
        return (
          <div key={section.title}>
            {index !== 0 && <SectionBreak />}
            <SectionTitle>{section.title}</SectionTitle>
            {/* <SectionDescription>{section.caption}</SectionDescription> */}
            <div>
              {section.cards?.map((card) => {
                return <OptionCard {...card} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
