import React from "react";
import styled from "styled-components";
//
export default styled.div<{ background?: string; style?: React.CSSProperties }>`
  display: inline-block;
  width: ${({ style }) => (style?.width ? style?.width : "100%")};
  max-width: ${({ style }) => (style?.maxWidth ? style?.maxWidth : "100%")};

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0px;
  }

  z-index: 10000;

  .ant-select,
  .ant-select-multiple,
  .ant-select-selector {
    border: 1px solid black;
    min-height: ${(p) => p?.style?.height || "50px"} !important;
    background: ${(p) =>
      p.background ? p.background : p.theme.colors.neutral11} !important;
    border: 1px solid ${(p) => p.theme.colors.neutral11};
    border-radius: 5px;
    &:focus {
      outline: 0;
      background: #fff;
      border: 1px solid ${(p) => p.theme.colors.primary7};
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: ${(p) => p.theme.colors.primary7};
    border-color: transparent;
  }

  .ant-select-focused {
    border: 1px solid ${(p) => p.theme.colors.primary7};
    border-color: ${(p) => p.theme.colors.primary7};
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #fff;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }

  .ant-select-selector {
    border: 0px;
    height: ${(p) =>
      p?.style?.height ? parseInt(p?.style?.height as any) - 2 : "48px"};
  }

  .ant-select-selection-search-input {
    background: ${(p) =>
      p.background ? p.background : p.theme.colors.neutral11} !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: ${(p) => p?.style?.height || "50px"} !important;
    align-items: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid ${(p) => p.theme.colors.neutral11};
    background: ${(p) =>
      p.background ? p.background : p.theme.colors.neutral11} !important;
  }
  .ant-select-selection-search-input {
    height: ${(p) =>
      p?.style?.height ? parseInt(p?.style?.height as any) - 2 : "48px"};
  }
  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.colors.neutral6} !important;
    opacity: 1;
    font-weight: 400;
    font-size: 14px;
    z-index: 1000;
  }
  .ant-select-selection-item {
    font-weight: 400;
    font-size: 14px;
  }

  input {
    min-height: ${(p) =>
      p?.style?.height ? parseInt(p?.style?.height as any) - 2 : "48px"};
  }
`;
