import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useProjectStagesByPiplineQuery } from "generated/graphql";
import { useState } from "react";

const { Option } = Select;

export default function PipelineStageInput({
  value,
  placeholder,
  onChange,
  pipelineId,
  containerStyle,
  onBlur,
}: {
  value?: string;
  placeholder?: string;
  onChange?: any;
  pipelineId: string;
  containerStyle?: React.CSSProperties;
  onBlur?: any;
}) {
  const [localValue, setLocalValue] = useState(value);
  const { data, loading } = useProjectStagesByPiplineQuery({
    variables: {
      pipelineId,
    },
    fetchPolicy: "cache-and-network",
  });

  const stages = data?.pipelineById?.stages || [];

  return (
    <DropdownStyleWrapper style={containerStyle}>
      <Select
        value={!loading && localValue ? (localValue as any) : undefined}
        placeholder={placeholder}
        onBlur={onBlur}
        loading={loading}
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={(value) => {
          onChange(value);
          setLocalValue(value);
        }}
        optionLabelProp="label"
      >
        {stages?.map((item) => {
          if (!item?.id) return null;
          return (
            <Option key={item.id} value={item.id} label={item.label}>
              <strong style={{ marginRight: 8, fontSize: 16 }}>
                {item.label}
              </strong>
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
}
