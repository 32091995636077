import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import theme from "lib/theme";
// COMPONENTS
import Button from "components/common/Button";
import Table from "components/common/Table";
import {
  DownloadOutlined,
  Loading3QuartersOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import columns from "./columns";

// import Filters from './Filters';
// APOLLO
import CSV_DOWNLOAD_CONTACTS from "ApolloClient/Queries/csvDownload_contacts";
import client from "ApolloClient/index";
// LIB
import formTypes from "lib/constants/formTypes";
import useUrlChange from "hooks/useUrlChange";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import message from "components/common/message";
import {
  ContactSearchParams,
  CustomerFeatureSettingKeyEnum,
  ListTypeEnum,
  useAddManyItemsToSurveyCampaignMutation,
  useContactsQuery,
  useGetManySurveyCampaignsQuery,
} from "generated/graphql";
import { PageContainer, MiddleContent } from "components/common/PageContainer";
import { Modal, Select, Spin } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import Title from "components/text/Title";
import FormItem from "components/common/FormItem";
import GeneralHelpers from "lib/helpers/GeneralHelpers";
import AddToListModal from "./AddToListModal";
import LinkButton from "components/common/LinkButton";
import Caption from "components/text/Caption";

const TopRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

// const LeftCol = styled.div`
//   flex-basis: 20%;
//   max-width: 500px;
//   min-width: 280px;
//   background: #fff;
//   overflow-y: scroll;
//   z-index: 1;
//   padding: 24px;
//   overflow-y: scroll;
//   border-right: 1px solid ${(p) => p.theme.colors.neutral9};
//   position: relative;
// `;

const cleanDataForCSV = (contacts) => {
  return contacts.map((contact) => {
    return {
      ID: contact.id,
      Email: contact.email,
      "First Name": contact.firstName,
      "Last Name": contact.lastName,
      Department: contact.department,
      "Work Phone": contact.workPhone,
      "Cell Phone": contact.cellPhone,
      "Home Phone": contact.homePhone,
      Title: contact.title,
      Status: contact.status,
      Website: contact.website,
      Twitter: contact.twitter,
      Instagram: contact.instagram,
      LinkedIn: contact.linkedin,
      Facebook: contact.facebook,
      tags: contact?.tags?.map((tag) => tag.title),
      // address
      Address: contact.street,
      City: contact.city,
      State: contact.state,
      Postal: contact.postal,
      Country: contact.country,
      // lat
      // lng
      // placeId
      // fullAddress
      // country
      // state
      // city
    };
  });
};

const AddToSurveyCampaignModal = ({
  onClose,
  open,
  loading,
  itemIds,
  selectedAll,
  contactsQuery,
}: {
  onClose: any;
  open: boolean;
  loading?: boolean;
  itemIds?: string[];
  selectedAll: boolean;
  contactsQuery?: ContactSearchParams;
}) => {
  const navigate = useNavigate();
  const [addManyItemsToSurveyCampaignMutation, { loading: saving }] =
    useAddManyItemsToSurveyCampaignMutation();
  const [surveyCampaignId, setSurveyCampaignId] = useState<string | null>(null);
  const {
    data,
    refetch,
    networkStatus,
    loading: fetchingLists,
  } = useGetManySurveyCampaignsQuery({
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      listType: ListTypeEnum.Contacts,
    },
  });

  const handleOnSubmit = async () => {
    try {
      if (!surveyCampaignId && !selectedAll)
        return message.error("Please choose a list");

      if (typeof surveyCampaignId === "string" && itemIds?.[0]) {
        await addManyItemsToSurveyCampaignMutation({
          variables: {
            surveyCampaignId,
            itemIds,
          },
        });
        onClose();
      }
      if (typeof surveyCampaignId === "string" && selectedAll) {
        await addManyItemsToSurveyCampaignMutation({
          variables: {
            surveyCampaignId,
            itemIds: [],
            addFromContactsQuery: selectedAll ? contactsQuery : {}, // ContactSearchParams,
          },
        });
        onClose();
      }

      message.success("Contacts successfully added to your campaign");

      navigate(`/app/surveys/campaigns/${surveyCampaignId}`);
    } catch (err: any) {
      message.error(err?.message);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <div style={{ marginBottom: 40 }}>
        <Title>Add to campaign</Title>
        <Caption>
          Select a campaign to add the chosen contacts to. Survey campaigns are
          a way to track survey responses as part of a single cohort (or
          campaign).
        </Caption>
      </div>
      <FormItem label="Name">
        <Select
          size="large"
          onChange={(newValue) => {
            if (newValue === "viewAll") {
              window.open(
                "/app/surveys/campaigns",
                "_blank",
                "rel=noopener noreferrer"
              );
              return undefined;
            }
            setSurveyCampaignId(newValue);
          }}
          style={{ width: "100%" }}
          placeholder="Select a campaign..."
          dropdownRender={(menu) => <div>{menu}</div>}
        >
          {fetchingLists ? (
            <Select.Option disabled>
              <Spin size="small" /> Loading...
            </Select.Option>
          ) : (
            <>
              {data?.getManySurveyCampaigns?.map((item) => (
                <Select.Option key={item?.id}>{item?.name}</Select.Option>
              ))}
              <Select.Option
                key="viewAll"
                value="viewAll"
                style={{
                  color: theme.colors?.supportB4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                View All Survey Campaigns{" "}
                <ExportOutlined style={{ marginRight: 8 }} />
              </Select.Option>
            </>
          )}
        </Select>
      </FormItem>
      <div
        style={{
          padding: "8px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LinkButton onClick={() => refetch()}>
          Refresh campaigns{" "}
          {networkStatus === 4 ? (
            <SyncOutlined spin style={{ marginLeft: 4 }} />
          ) : (
            <SyncOutlined style={{ marginLeft: 4 }} />
          )}
        </LinkButton>
        {console.log(networkStatus)}
      </div>
      <Button
        disabled={
          loading || !surveyCampaignId || (!itemIds?.[0] && !selectedAll)
        }
        loading={saving}
        onClick={handleOnSubmit}
        style={{ marginTop: 24 }}
      >
        Add to campaign
      </Button>
    </Modal>
  );
};

export default function PeopleTab({ windowWidth, currentUser }) {
  const navigate = useNavigate();
  const [openListModal, setOpenListModa] = useState(false);
  const [openSurveyModal, setOpenSurveyModal] = useState(false);
  const { onUrlChange, query } = useUrlChange();
  const [downloadFile, downloadingCSV] = useDownloadCSV();
  const [downloading, setDownloading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState<number | false>(false);
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  useEffect(() => {
    resetTableParamsToDefault();
  }, []);

  const contactsQueryVariables = {
    searchText: query?.searchText as string,
    statuses: [],
  };
  const { data, loading } = useContactsQuery({
    variables: {
      params: contactsQueryVariables,
      skip,
      limit,
    },
    fetchPolicy: "cache-and-network",
  });

  const onRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        let element = e.srcElement || e.target;
        // only cancel if the user clicked the Link to business detail page
        if (element.id === "business-click") {
          return null;
        }
        navigate(`/app/contacts/${record.id}`);
      },
      onDoubleClick: (event) => {}, // double click row
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };

  const onDownloadContacts = async () => {
    try {
      setDownloading(true);
      let result = await client.query({
        query: CSV_DOWNLOAD_CONTACTS,
        variables: {},
      });
      downloadFile(
        cleanDataForCSV(result.data.csvDownload_contacts),
        "contacts.csv"
      );
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log(err);
    }
  };

  const isMobile = parseInt(theme.breakpoints.medium) > windowWidth;

  const rowSelection = {
    type: "checkbox",
    columnWidth: "25px",
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected && data?.contacts?.count && !isNaN(data?.contacts?.count)) {
        // if they selected all, select all
        setSelectedAll(data?.contacts?.count);
      } else {
        // if they are unselecting, unselect all
        setSelectedRowKeys([]);
        setSelectedAll(false);
      }
    },

    onSelect: (record, selected, selectedRows: any[]) => {
      setSelectedAll(false);
      if (selected) {
        setSelectedRowKeys(selectedRows?.map(({ id }) => id) as string[]);
      } else {
        setSelectedRowKeys(
          selectedRows?.filter(({ id }) => id !== record.id) as string[]
        );
      }
    },
    getCheckboxProps: (record: any) => ({
      id: record.id,
    }),
  };

  const showAddButtons = selectedAll || selectedRowKeys.length > 0;

  return (
    <PageContainer>
      <MiddleContent>
        <TopRow>
          <div>
            {showAddButtons && (
              <>
                <span style={{ marginLeft: 8 }}>
                  Selected {selectedAll ? selectedAll : selectedRowKeys.length}{" "}
                  items
                </span>
                {GeneralHelpers.featureIsOn({
                  customer: currentUser.customer,
                  feature: CustomerFeatureSettingKeyEnum.Surveys,
                }) && (
                  <>
                    <AddToSurveyCampaignModal
                      open={openSurveyModal}
                      itemIds={selectedRowKeys}
                      selectedAll={selectedAll ? true : false}
                      contactsQuery={contactsQueryVariables}
                      onClose={() => setOpenSurveyModal(false)}
                    />
                    <Button
                      secondary
                      style={{ marginLeft: 16 }}
                      onClick={() => setOpenSurveyModal(true)}
                    >
                      Add to Campaign
                    </Button>
                  </>
                )}
                <>
                  <AddToListModal
                    open={openListModal}
                    itemIds={selectedRowKeys}
                    selectedAll={selectedAll ? true : false}
                    contactsQuery={contactsQueryVariables}
                    onClose={() => setOpenListModa(false)}
                  />
                  <Button
                    secondary
                    style={{ marginLeft: 16 }}
                    onClick={() => setOpenListModa(true)}
                  >
                    Add to List
                  </Button>
                </>
              </>
            )}
          </div>
          {!loading && !isMobile && (
            <Button
              onClick={() => onUrlChange({ drawerVisible: formTypes.CONTACTS })}
              data-testid="contacts-new-contacts-btn"
              secondary
            >
              + New person
            </Button>
          )}
        </TopRow>
        <div>
          <Table
            dataSource={data?.contacts?.contacts || []}
            pagination={{
              total: data?.contacts?.count || 0,
              pageSize: limit,
              onChange: (page, pageSize) => {
                onTablePaginationChange(page, pageSize);
              },
              showSizeChanger: true,
              current: currentPage,
            }}
            rowSelection={rowSelection}
            columns={!isMobile ? columns : [columns[0], columns[3]]}
            loading={loading}
            rowKey={(record) => record.id}
            onRow={onRow}
          />
        </div>
        {!loading && !isMobile && (
          <Button
            onClick={onDownloadContacts}
            disabled={downloading || downloadingCSV}
            loading={downloading || downloadingCSV}
            style={{ position: "relative", top: -35, width: 210 }}
            grey
          >
            <DownloadOutlined /> Download CSV
          </Button>
        )}
      </MiddleContent>
    </PageContainer>
  );
}
