// TOP LEVEL IMPORTS
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
// COMPONENTS
import { Menu, Dropdown, Tag } from "antd";
import {
  UserOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Row from "components/common/Row";
import Popover from "components/common/Popover";
import Avatar from "components/common/Avatar";
import AddButton from "./AddButton";

import SettingsButton from "./SettingsButton";
import NotificationsButton from "./NotificationsButton";

// lib

import logo from "lib/media/logo-icon.svg";
import theme from "lib/theme";
import helpers from "lib/helpers/GeneralHelpers/index";
import {
  CustomerFeatureSettingKeyEnum,
  UserPermissionEnum,
} from "generated/graphql";
import useSignout from "lib/hooks/useSignout";
import SearchModal from "./SearchModal";

const HeaderLink = styled.button<{ active?: boolean }>`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145.27%;
  color: ${(p) => p.theme.colors.neutral4} !important;
  cursor: pointer;
  &:hover {
    color: ${(p) => (p.active ? "#0fb5ba" : "#0fb5ba")};
  }
`;

const HeaderLinkLogout = styled.button<{ active?: boolean }>`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  border: 0px;
  background: transparent;
  font-size: 16px;
  line-height: 145.27%;
  color: ${(p) => (p.active ? "#780A0A" : "#780A0A")} !important;
  cursor: pointer;
  &:hover {
    color: ${(p) => (p.active ? "#780A0A" : "#780A0A")};
  }
`;

const ContentLink = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #bcccdc;
`;

// STYLED-COMPONENTS
//-------------------------
const Logo = styled.img`
  height: 32px;
  margin-top: 8px;
`;

export const HeaderOuter = styled.div`
  background-color: #fff;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
`;

export const HeaderContainer = styled.div`
  background: #fff;
  padding: 10px;
  display: flex;
  max-width: 100%;
  font-weight: 600;
  margin: auto;
  width: 90%;
`;

const ICON_STYLE = {
  marginRight: 10,
  fontSize: 16,
  color: theme.colors.neutral6,
};

const MenuContentItem = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-start;
`;

const Content = ({ navigate, onLogout }) => (
  <div style={{ width: 230 }}>
    <ContentLink>ACCOUNT</ContentLink>
    <MenuContentItem>
      <UserOutlined style={ICON_STYLE} />
      <HeaderLink onClick={() => navigate(`/app/profile`)}>
        My Profile
      </HeaderLink>
    </MenuContentItem>
    <div
      style={{ marginTop: 20, marginBottom: 8, borderTop: "2px solid #efefef" }}
    />
    <ContentLink>SUPPORT</ContentLink>
    <MenuContentItem>
      <QuestionCircleOutlined style={ICON_STYLE} />
      <HeaderLink onClick={() => navigate(`/app/help`)}>Get Help</HeaderLink>
    </MenuContentItem>
    <div
      style={{ marginTop: 20, marginBottom: 8, borderTop: "2px solid #efefef" }}
    />
    <Row align="middle" justify="start" style={{ marginTop: 12 }}>
      <LogoutOutlined style={{ marginRight: 10, color: "#780A0A" }} />
      <HeaderLinkLogout
        data-testid="header-dropdown-logout-btn"
        onClick={onLogout}
      >
        Logout
      </HeaderLinkLogout>
    </Row>
  </div>
);

const ItemContainer = styled.div<{ active?: boolean }>`
  cursor: pointer;
  .menu-item-label {
    color: ${(p) =>
      p.active ? p.theme.colors.neutral1 : p.theme.colors.neutral4};
  }
  .menu-item-label:hover {
    color: ${(p) =>
      p.active ? p.theme.colors.neutral1 : p.theme.colors.neutral2};
    cursor: pointer;
  }
`;

const MenuItem = ({
  label,
  active,
  onClick,
  menu,
  popover,
  isBeta,
  isNew,
}: {
  label: any;
  active: boolean;
  onClick: any;
  menu?: any;
  popover?: any;
  isBeta?: boolean;
  isNew?: boolean;
}) => {
  const MenuItem = (
    <ItemContainer
      active={active}
      style={{ height: 24, marginRight: 24, minWidth: 50 }}
    >
      <div
        className="menu-item-label"
        style={{
          fontWeight: 600,
          fontSize: 14,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {label}

        <DownOutlined
          style={{
            fontSize: 9,
            position: "relative",
            bottom: 5,
            right: -6,
          }}
        />
      </div>
      {active && (
        <div
          style={{
            height: 3,
            position: "relative",
            bottom: -10,
            width: "105%",
            right: "7%",
            background: theme.colors.primary6,
          }}
        />
      )}
    </ItemContainer>
  );

  if (menu) {
    return <Dropdown overlay={menu}>{MenuItem}</Dropdown>;
  }

  if (popover) {
    return (
      <div onClick={onClick}>
        <Popover
          overlayStyle={{ padding: 0 }}
          overlayClassName="your-work-popover"
          content={popover}
          // visible: set visible true to test/view the popover
          placement="bottomLeft"
        >
          {MenuItem}
        </Popover>
      </div>
    );
  }

  return (
    <ItemContainer
      active={active}
      onClick={onClick}
      data-testid={`header-nav-item-${label}`}
      style={{ height: 24, marginRight: 32 }}
    >
      <div
        className="menu-item-label"
        style={{ fontWeight: 600, fontSize: 14 }}
      >
        {label}{" "}
        {isBeta && (
          <Tag
            color="purple"
            style={{
              marginLeft: 4,
              fontSize: 10,
              padding: "0px 4px",
              height: 20,
            }}
          >
            BETA
          </Tag>
        )}
        {isNew && (
          <Tag
            color="blue"
            style={{
              marginLeft: 4,
              fontSize: 10,
              padding: "0px 4px",
              height: 20,
            }}
          >
            NEW
          </Tag>
        )}
      </div>
      {active && (
        <div
          style={{
            height: 3,
            position: "relative",
            bottom: -10,
            width: "120%",
            right: "10%",
            background: theme.colors.primary6,
          }}
        />
      )}
    </ItemContainer>
  );
};

const DropdownMenuLink = styled(Link)`
  color: ${(p) => p.theme.colors.neutral3} !important;
  font-size: 14px;
  margin: 8px;
`;

const ResourcesMenu = (
  <Menu>
    <Menu.Item>
      <DropdownMenuLink
        rel="noopener noreferrer"
        to="/app/resources?sidenav=sites"
      >
        Sites
      </DropdownMenuLink>
    </Menu.Item>
    <Menu.Item>
      <DropdownMenuLink
        rel="noopener noreferrer"
        to="/app/resources?sidenav=programs"
      >
        Programs
      </DropdownMenuLink>
    </Menu.Item>
    <Menu.Item>
      <DropdownMenuLink
        rel="noopener noreferrer"
        to="/app/resources?sidenav=funding"
      >
        Funding
      </DropdownMenuLink>
    </Menu.Item>
    <Menu.Item>
      <DropdownMenuLink
        rel="noopener noreferrer"
        to="/app/resources?sidenav=organizations"
      >
        Organizations
      </DropdownMenuLink>
    </Menu.Item>
  </Menu>
);

const ToolsMenu = ({ currentUser }) => (
  <Menu>
    {helpers.featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.Surveys,
    }) &&
      helpers.hasPermission(currentUser, [
        UserPermissionEnum.Administrator,
        UserPermissionEnum.Member,
      ]) && (
        <Menu.Item>
          <DropdownMenuLink
            rel="noopener noreferrer"
            to="/app/surveys/templates"
          >
            Surveys <Tag color="blue">NEW</Tag>
          </DropdownMenuLink>
        </Menu.Item>
      )}
    {helpers.featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.EmailCampaigns,
    }) &&
      helpers.hasPermission(currentUser, [
        UserPermissionEnum.Administrator,
        UserPermissionEnum.Member,
      ]) && (
        <Menu.Item>
          <DropdownMenuLink rel="noopener noreferrer" to="/app/email-campaigns">
            Email Campaigns <Tag color="purple">BETA</Tag>
          </DropdownMenuLink>
        </Menu.Item>
      )}
    {helpers.featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.DataDiscovery,
    }) &&
      helpers.hasPermission(currentUser, [
        UserPermissionEnum.Administrator,
      ]) && (
        <Menu.Item>
          <DropdownMenuLink rel="noopener noreferrer" to="/app/discovery/runs">
            Discovery <Tag color="purple">BETA</Tag>
          </DropdownMenuLink>
        </Menu.Item>
      )}
  </Menu>
);

const ContactsMenu = (
  <Menu>
    <Menu.Item>
      <DropdownMenuLink rel="noopener noreferrer" to="/app/businesses">
        Businesses
      </DropdownMenuLink>
    </Menu.Item>
    <Menu.Item>
      <DropdownMenuLink rel="noopener noreferrer" to="/app/contacts">
        People
      </DropdownMenuLink>
    </Menu.Item>
    <Menu.Item>
      <DropdownMenuLink rel="noopener noreferrer" to="/app/lists">
        Lists
      </DropdownMenuLink>
    </Menu.Item>
  </Menu>
);

// COMPONENT:
//------------------------------------------------------------------------------

function Header({ currentUser }) {
  const [signOut] = useSignout();
  const location = useLocation();
  const navigate = useNavigate();
  const onLogout = async () => {
    try {
      // run logout function
      await signOut(currentUser);
    } catch (err) {
      return;
    }
  };

  return (
    <HeaderOuter>
      <HeaderContainer>
        <div>
          <Link to="/app/dashboard">
            <Logo
              src={logo}
              alt="realy-logo"
              // style={{height: 24, marginTop: 8}}
            />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: 11000,
            maxWidth: "100%",
            paddingTop: 16,
            marginLeft: 40,
          }}
        >
          <MenuItem
            label="Dashboard"
            onClick={() => navigate("/app/dashboard")}
            active={location?.pathname.includes("/app/dashboard")}
          />
          <MenuItem
            label="Contacts"
            onClick={() => navigate("/app/businesses")}
            active={location?.pathname.includes("/app/businesses")}
            menu={ContactsMenu}
          />
          <MenuItem
            label="Your Work"
            onClick={() => navigate("/app/your-work")}
            active={location?.pathname.includes("/app/your-work")}
            // popover={<YourWork currentUser={currentUser} />}
          />
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Projects,
          }) &&
            helpers.hasPermission(currentUser, [
              UserPermissionEnum.Administrator,
            ]) && (
              <MenuItem
                label="Projects"
                onClick={() => navigate("/app/projects")}
                active={location?.pathname.includes("/app/projects")}
              />
            )}
          {/* <MenuItem
            label="Contacts"
            onClick={() => navigate('/app/contacts')}
            active={location?.pathname.includes('/app/contacts')}
          /> */}
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Cases,
          }) &&
            helpers.hasPermission(currentUser, [
              UserPermissionEnum.Administrator,
            ]) && (
              <MenuItem
                label="Cases"
                onClick={() => navigate("/app/cases")}
                active={location?.pathname.includes("/app/cases")}
              />
            )}

          <MenuItem
            label="Tools"
            onClick={() => console.log("tools")}
            active={location?.pathname.includes("/app/tools")}
            menu={<ToolsMenu currentUser={currentUser} />}
          />
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Resources,
          }) && (
            <MenuItem
              label="Ecosystem"
              onClick={() => navigate("/app/resources")}
              active={location?.pathname.includes("/app/resources")}
              menu={ResourcesMenu}
            />
          )}

          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Reports,
          }) &&
            helpers.hasPermission(currentUser, [
              UserPermissionEnum.Administrator,
            ]) && (
              <MenuItem
                label="Reports"
                onClick={() => navigate("/app/reports")}
                active={location?.pathname.includes("/app/reports")}
              />
            )}
        </div>
        {/* <div
          style={{
            position: "absolute",
            right: "17%",
            width: 350,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <AutoComplete />
        </div> */}
        <div style={{ position: "absolute", right: 32, display: "flex" }}>
          <div style={{ marginLeft: 0, display: "flex", alignItems: "center" }}>
            {helpers.hasPermission(currentUser, [
              UserPermissionEnum.Administrator,
              UserPermissionEnum.Member,
            ]) && <AddButton />}
          </div>
          <div>
            <SearchModal />
          </div>
          <div
            style={{
              marginRight: 0,
              marginLeft: 16,
              display: "flex",
              alignItems: "center",
            }}
          >
            <NotificationsButton currentUser={currentUser} />
          </div>
          <div
            style={{
              marginRight: 16,
              marginLeft: 16,
              display: "flex",
              alignItems: "center",
            }}
          >
            <SettingsButton />
          </div>
          <Popover
            content={<Content onLogout={onLogout} navigate={navigate} />}
          >
            <Avatar
              data-testid="header-avatar-dropdown"
              src={currentUser && currentUser.avatar && currentUser.avatar.url}
              style={{
                cursor: "pointer",
                border: `1px solid ${theme.colors.neutral10}`,
              }}
              size="large"
            />
          </Popover>
        </div>
      </HeaderContainer>
    </HeaderOuter>
  );
}

export default Header;
