import { CheckCircleTwoTone } from "@ant-design/icons";
import Loading from "components/common/Loading";
import SurveyFormView from "components/common/SurveyFormView";
import { useGetOneSurveyTemplateNewQuery } from "generated/graphql";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  background: #f3f3f3;
  min-height: 100vh;
`;

const ResultContainer = styled.div`
  text-align: center;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 400px;
  max-width: 100%;
`;

const ResultIcon = styled.div`
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
`;

const ResultTitle = styled.h1`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
`;

const ResultSubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
`;

const SuccessfulSubmission = ({ logo }: { logo?: string }) => {
  return (
    <ResultContainer>
      <ResultIcon>
        {logo ? (
          <img
            src={logo}
            alt="successful-submission"
            style={{ height: 60, width: "auto" }}
          />
        ) : (
          <CheckCircleTwoTone style={{ fontSize: 40 }} />
        )}
      </ResultIcon>
      <ResultTitle>Thank you for your feedback.</ResultTitle>
      <ResultSubTitle>
        Your insights are valuable and we appreciate your time.
      </ResultSubTitle>
    </ResultContainer>
  );
};

export default function PublicSurveyView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const templateId = queryParams.get("templateId") || "";
  const answeredBy = queryParams.get("answeredBy");
  const referrerId = queryParams.get("referrerId");
  const businessId = queryParams.get("businessId");
  const surveyCampaignId = queryParams.get("surveyCampaignId");

  const surveyCampaignItemId = queryParams.get("surveyCampaignItemId");
  const mode = queryParams.get("mode");

  const { data, loading } = useGetOneSurveyTemplateNewQuery({
    variables: { templateId: templateId || "" },
  });

  if (mode === "success")
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SuccessfulSubmission
          logo={data?.getOneSurveyTemplateNew?.template?.logo}
        />
      </Container>
    );

  if (loading) return <Loading />;

  return (
    <Container>
      <SurveyFormView
        businessId={businessId}
        referrerId={referrerId}
        surveyCampaignId={surveyCampaignId}
        templateId={templateId}
        template={data?.getOneSurveyTemplateNew.template}
        surveyCampaignItemId={surveyCampaignItemId}
        answeredBy={answeredBy}
      />
    </Container>
  );
}
