import { gql } from "@apollo/client";

export default gql`
  fragment customerFeatureSettingFragment on CustomerFeatureSetting {
    title
    key
    active
    available
    featureSettings {
      key
      extraFields {
        fieldName
        value
        fieldType
      }
    }
  }
`;
