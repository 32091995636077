import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import Tag from "components/common/Tag";
import { useGetManyDataDiscoveryRunsQuery } from "generated/graphql";
import { Link } from "react-router-dom";
import moment from "moment";

export default function DiscoveryRuns() {
  const { data, loading } = useGetManyDataDiscoveryRunsQuery();
  return (
    <PageContainer>
      <Table
        dataSource={data?.getManyDataDiscoveryRuns?.results}
        loading={loading}
        scroll={{ x: 1300 }}
        columns={[
          {
            title: "Ran On",
            key: "id",
            width: 290,
            render: (row) => (
              <Link to={`/app/discovery/runs/${row?.id}`}>
                {row?.createdAt &&
                  moment(parseInt(row?.createdAt))?.format(
                    "MMMM DD, YYYY @ h:mma"
                  )}
              </Link>
            ),
          },
          {
            title: "Merged",
            width: 200,
            render: (row) =>
              row?.mergedAt ? (
                <Tag>
                  Merged {row?.mergedBusinesses} on{" "}
                  {moment(parseInt(row?.mergedAt))?.format("MM/DD/YYYY")}
                </Tag>
              ) : (
                <Tag yellow>Not Merged</Tag>
              ),
          },
          {
            title: "New Businesses",
            width: 150,
            render: (row) => row?.newBusinessesFound,
          },
          {
            title: "Total Businesses",
            width: 150,
            render: (row) => row?.businessesFound,
          },
          {
            title: "Total Contacts",
            width: 150,
            render: (row) => row?.contactsFound,
          },
          // {
          //   title: "Search Terms",
          //   width: 800,
          //   render: (row) =>
          //     row?.searchTerms?.map((tag) => (
          //       <Tag key={tag} grey>
          //         {tag}
          //       </Tag>
          //     )),
          // },
        ]}
      />
    </PageContainer>
  );
}
