import { BarChartOutlined, DownloadOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { Modal, Popconfirm, Select, Tag, Tooltip, message } from "antd";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";
import LinkButton from "components/common/LinkButton";
import Table from "components/common/Table";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import TextInput from "components/inputs/TextInput";
import Caption from "components/text/Caption";
import PageHeader from "components/text/PageHeader";
import Title from "components/text/Title";
import {
  GetManySurveyCampaignItemsDocument,
  ListTypeEnum,
  SurveyCampaignFragmentFragment,
  SurveyCampaignItem,
  SurveyCampaignItemFragmentFragment,
  SurveyCampaignItemStatusEnum,
  useCreateOneSurveyCampaignMutation,
  useGetManySurveyCampaignItemsQuery,
  useGetManySurveyCampaignsQuery,
  useGetManySurveyTemplatesNewQuery,
  useRemoveOneFromSurveyCampaignMutation,
} from "generated/graphql";
import useClipboardHook from "hooks/useClipboard";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { generateSurveyUrl } from "routes/app-survey/ShareLink";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 1400px;
  margin: auto;
  padding-top: 24px;
  max-width: 100%;
  height: calc(100vh - 61px);
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

function AppSurveyCampaignDetails({ id }) {
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();
  useEffect(() => {
    resetTableParamsToDefault();
  }, []);
  const [downloadFile, downloadingCSV] = useDownloadCSV();
  const { data, loading } = useGetManySurveyCampaignItemsQuery({
    variables: {
      surveyCampaignId: id || "",
      pagination: { limit: limit || 10, skip: skip || 0 },
    },
    skip: !id,
  });

  const [removeOneFromSurveyCampaignMutation, { loading: removing }] =
    useRemoveOneFromSurveyCampaignMutation();
  const apollo = useApolloClient();
  const [copyToClipboard] = useClipboardHook();

  // const getSurveyUrl = ({
  //   answeredBy,
  //   businessId,
  //   templateId,
  //   referrerId,
  //   surveyCampaignId,
  //   surveyCampaignItemId,
  // }) => {
  //   const queryParams: string[] = [];

  //   if (surveyCampaignId)
  //     queryParams.push(
  //       `surveyCampaignId=${encodeURIComponent(surveyCampaignId)}`
  //     );
  //   if (answeredBy)
  //     queryParams.push(`answeredBy=${encodeURIComponent(answeredBy)}`);
  //   if (referrerId)
  //     queryParams.push(`referrerId=${encodeURIComponent(referrerId)}`);
  //   if (businessId)
  //     queryParams.push(`businessId=${encodeURIComponent(businessId)}`);
  //   if (templateId)
  //     queryParams.push(`templateId=${encodeURIComponent(templateId)}`);
  //   if (surveyCampaignItemId)
  //     queryParams.push(
  //       `surveyCampaignItemId=${encodeURIComponent(surveyCampaignItemId)}`
  //     );

  //   return `${window.location.hostname}/app/survey/view?${queryParams.join(
  //     "&"
  //   )}`;
  // };

  const handleDownloadCSV = (results: SurveyCampaignItemFragmentFragment[]) => {
    try {
      const mappedResul = results?.map((item) => {
        const answeredBy = item?.contact?.id;
        const business = item?.contact?.businesses?.[0];
        const businessId = business?.id;
        const templateId =
          data?.getManySurveyCampaignItems?.campaign?.surveyTemplateId;
        // todo: referrerId=5ff2132f5616e36331fbce45
        return {
          id: item?.id,
          business: business?.title,
          firstName: item?.contact?.firstName,
          lastName: item?.contact?.lastName,
          email: item?.contact?.email,
          status: item?.status,
          url: `${window.location.hostname}${generateSurveyUrl({
            answeredBy,
            businessId,
            templateId: templateId as string,
            surveyCampaignItemId: item?.id,
            surveyCampaignId: data?.getManySurveyCampaignItems?.campaign?.id,
            referrerId:
              data?.getManySurveyCampaignItems?.campaign?.createdBy?.id,
          })}`,
        };
      });
      downloadFile(
        mappedResul,
        `${data?.getManySurveyCampaignItems?.campaign?.name}-${moment()?.format(
          "MM-DD-YYYY-hh:mma"
        )}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  // shows a table of the items in the survey campaign
  return (
    <PageContainer>
      <TitleContainer>
        <div>
          {" "}
          <PageHeader style={{ marginBottom: 0 }}>
            {data?.getManySurveyCampaignItems?.campaign?.name || "..."}
          </PageHeader>
          <Caption> Survey Campaign </Caption>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* http://localhost:3050/app/survey/report?templateId=656b0f7e2c9595665789b59c */}
          {data?.getManySurveyCampaignItems?.campaign?.id && (
            <Button
              grey
              style={{ marginRight: 24 }}
              onClick={() => {
                window.open(
                  `/app/survey/report?templateId=${data?.getManySurveyCampaignItems?.campaign?.surveyTemplateId}&surveyCampaignId=${data?.getManySurveyCampaignItems?.campaign?.id}`
                );
              }}
            >
              <BarChartOutlined style={{ marginRight: 8 }} />
              View Campaign Results
            </Button>
          )}
          <Tooltip title="Click to download a spreadsheet of campaign respondents and their individual survey links. This is useful for creating email campaigns in outside tools like Constant Contact.">
            <div>
              <Button
                grey
                onClick={async () => {
                  try {
                    // download all campaign items
                    message.info(
                      `Downloading campaign.... this may take a few minutes...`
                    );
                    const results: any = await apollo.query({
                      query: GetManySurveyCampaignItemsDocument,
                      variables: {
                        surveyCampaignId: id || "",
                        pagination: { limit: 10000, skip: 0 },
                      },
                    });
                    handleDownloadCSV(
                      results?.data?.getManySurveyCampaignItems?.results
                    );
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <DownloadOutlined style={{ marginRight: 8 }} />
                Download Campaign
              </Button>
            </div>
          </Tooltip>
        </div>
      </TitleContainer>
      <Table
        loading={loading}
        columns={[
          {
            key: "id",
            title: "Name",
            render: (record: SurveyCampaignItemFragmentFragment) => (
              <Link to={`/app/contacts/${record?.contact?.id}`} target="_blank">
                {getFullName(record?.contact)}
              </Link>
            ),
          },
          {
            title: "Email",
            render: (record: SurveyCampaignItemFragmentFragment) =>
              record?.contact?.email,
          },
          {
            title: "Business",
            render: (record: SurveyCampaignItemFragmentFragment) =>
              record?.contact?.businesses?.map((item) => item && item?.title),
          },
          {
            title: "Survey Status",
            render: (record: SurveyCampaignItemFragmentFragment) => {
              return (
                <Tooltip
                  placement="left"
                  title={() => {
                    if (
                      record?.status === SurveyCampaignItemStatusEnum.Pending
                    ) {
                      return (
                        <>
                          <p>
                            This means the survey has not been sent (through
                            Growlab). Once it is sent, the status will move to
                            'Sent'.
                          </p>
                          <p>
                            Keep in mind, if you are sending out the survey
                            through an alternative system other than Growlab,
                            the status in Growlab may not update until we
                            receive a survey response.
                          </p>
                        </>
                      );
                    }
                    if (record?.status === SurveyCampaignItemStatusEnum.Sent) {
                      return "This means the survey has been sent (through Growlab). We are awaiting a response.";
                    }
                    if (
                      record?.status === SurveyCampaignItemStatusEnum.Responded
                    ) {
                      return `We received a response on ${moment(
                        record?.surveyResultAt
                      )?.format("MMM D, YYYY")} at ${moment(
                        record?.surveyResultAt
                      )?.format("hh:mma")}`;
                    }
                  }}
                >
                  <div>
                    {(() => {
                      switch (record?.status) {
                        case SurveyCampaignItemStatusEnum.Pending:
                          return <Tag color="orange">Pending</Tag>;
                        case SurveyCampaignItemStatusEnum.Responded:
                          return <Tag color="green">Responded</Tag>;
                        case SurveyCampaignItemStatusEnum.Sent:
                          return <Tag color="blue">Sent</Tag>;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </Tooltip>
              );
            },
          },
          {
            title: "Survey Link",
            render: (record: SurveyCampaignItemFragmentFragment) => {
              if (record?.status === SurveyCampaignItemStatusEnum.Responded) {
                return null;
              }
              const onClick = () => {
                const answeredBy = record?.contact?.id;
                const businessId = record?.contact?.businesses?.[0]?.id;
                const templateId =
                  data?.getManySurveyCampaignItems?.campaign?.surveyTemplateId;
                copyToClipboard(
                  `${window.location.hostname}${generateSurveyUrl({
                    answeredBy,
                    businessId,
                    templateId: templateId as string,
                    surveyCampaignItemId: record?.id,
                    surveyCampaignId:
                      data?.getManySurveyCampaignItems?.campaign?.id,
                    referrerId:
                      data?.getManySurveyCampaignItems?.campaign?.createdBy?.id,
                  })}`
                );
                message.success("Survey link copied to your clipboard");
              };

              return (
                <Tooltip title="Click to copy a link to the page where the respondent can take this survey">
                  <LinkButton onClick={onClick}>Get Survey Link</LinkButton>
                </Tooltip>
              );
            },
          },
          {
            title: "Actions",
            render: (record: SurveyCampaignItemFragmentFragment) => {
              if (record?.status === SurveyCampaignItemStatusEnum.Responded) {
                return (
                  <Link
                    target="_blank"
                    to={`/app/survey/view/result?surveyResponseId=${record?.surveyResultId}`}
                  >
                    View Response
                  </Link>
                );
              }

              return (
                <Popconfirm
                  title="Are you sure you want to remove this from your campaign?"
                  onConfirm={async () => {
                    try {
                      await removeOneFromSurveyCampaignMutation({
                        variables: {
                          surveyCampaignItemId: record?.id,
                        },
                        refetchQueries: ["getManySurveyCampaignItems"],
                      });
                      message.success("Item successfully removed");
                    } catch (err: any) {
                      console.log(err);
                      message.success(err?.message);
                    }
                  }}
                  okText="Yes"
                >
                  <LinkButton danger disabled={removing}>
                    {!removing ? "Remove" : "..."}
                  </LinkButton>
                </Popconfirm>
              );
            },
          },
        ]}
        pagination={{
          total: data?.getManySurveyCampaignItems?.count,
          current: currentPage,
          pageSize: limit,
          onChange: (page, pageSize) => {
            onTablePaginationChange(page, pageSize);
          },
          showSizeChanger: true,
        }}
        dataSource={data?.getManySurveyCampaignItems?.results || []}
      />
    </PageContainer>
  );
}

const SurveyCampaignModal = ({
  onClose,
  open,
}: {
  onClose: any;
  open: boolean;
}) => {
  const [surveyTemplateId, setSurveyTemplateId] = useState<string | undefined>(
    undefined
  );
  const [createOneSurveyCampaignMutation, { loading }] =
    useCreateOneSurveyCampaignMutation();
  const [name, setName] = useState<string | undefined>(undefined);
  const { data } = useGetManySurveyTemplatesNewQuery();

  const handleOnSubmit = async () => {
    try {
      if (!name || !surveyTemplateId) return;
      await createOneSurveyCampaignMutation({
        variables: {
          name,
          surveyTemplateId,
        },
        refetchQueries: ["getManySurveyCampaigns"],
      });
      onClose();
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Title style={{ marginBottom: 40 }}>New Surey Campaign </Title>
      <FormItem
        label="Campaign Name"
        hint="This is for internal use only and you can always change the name later."
        containerProps={{ style: { marginBottom: 48 } }}
      >
        <TextInput
          onChange={(value) => setName(value)}
          value={name || ""}
          placeholder="Give your campaign a name..."
        />
      </FormItem>

      <FormItem
        label="Survey Template"
        containerProps={{ style: { marginBottom: 32 } }}
        hint="This is the survey that will be a part of this campaign."
      >
        <DropdownStyleWrapper>
          <Select
            size="large"
            value={surveyTemplateId}
            onChange={(newValue) => {
              setSurveyTemplateId(newValue);
            }}
            style={{ width: "100%" }}
            placeholder="Select a survey campaign..."
            showSearch
          >
            {data?.getManySurveyTemplatesNew?.map((item) => {
              return (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.title}
                </Select.Option>
              );
            })}
          </Select>
        </DropdownStyleWrapper>
      </FormItem>

      <Button
        disabled={loading}
        loading={loading}
        onClick={handleOnSubmit}
        style={{ marginTop: 24 }}
      >
        Save Campaign
      </Button>
    </Modal>
  );
};

export default function AppSurveyCampaigns() {
  const params = useParams();
  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);

  const { data, loading } = useGetManySurveyCampaignsQuery({
    variables: {
      listType: ListTypeEnum.Contacts,
    },
  });

  if (params?.id) {
    return <AppSurveyCampaignDetails id={params?.id} />;
  }

  const COLUMNS = [
    {
      key: "id",
      title: "Name",
      render: (record: SurveyCampaignFragmentFragment) => (
        <Link to={`/app/surveys/campaigns/${record.id}`} target="_blank">
          {record?.name}
        </Link>
      ),
    },
    {
      title: "Survey",
      render: (record: SurveyCampaignFragmentFragment) => (
        <>{record?.surveyTemplate?.title}</>
      ),
    },
    {
      title: "Last modified",
      render: (record: SurveyCampaignFragmentFragment) =>
        record?.updatedAt &&
        moment(record?.updatedAt)?.format("MM/DD/YYYY hh:mma"),
    },
    {
      title: "Owner",
      render: (record: SurveyCampaignFragmentFragment) =>
        record?.createdBy?.email,
    },
    {
      title: "Records",
      render: (record: SurveyCampaignFragmentFragment) =>
        record?.numberOfRecords,
    },
  ];
  return (
    <>
      <SurveyCampaignModal
        onClose={() => setShowNewCampaignModal(false)}
        open={showNewCampaignModal}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <PageHeader style={{ marginBottom: 16 }}>Survey Campaigns </PageHeader>
        <Button secondary onClick={() => setShowNewCampaignModal(true)}>
          New Campaign
        </Button>
      </div>
      <Table
        columns={COLUMNS}
        dataSource={data?.getManySurveyCampaigns || []}
        loading={loading}
      />
    </>
  );
}
