import {
  ContactSearchParams,
  GetManyListsOwnerTypeEnum,
  ListTypeEnum,
  useAddManyItemsToListMutation,
  useGetManyListsQuery,
} from "generated/graphql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import { Modal, Select, message } from "antd";
import FormItem from "components/common/FormItem";
import Title from "components/text/Title";
import LinkButton from "components/common/LinkButton";
import theme from "lib/theme";
import { ExportOutlined, SyncOutlined } from "@ant-design/icons";

export default function AddToListModal({
  onClose,
  open,
  loading,
  itemIds,
  selectedAll,
  contactsQuery,
}: {
  onClose: any;
  open: boolean;
  loading?: boolean;
  itemIds?: string[];
  selectedAll: boolean;
  contactsQuery?: ContactSearchParams;
}) {
  const navigate = useNavigate();
  const [addManyItemsToListMutation, { loading: saving }] =
    useAddManyItemsToListMutation();
  const [listId, setSurveyCampaignId] = useState<string | null>(null);
  const { data, networkStatus, refetch } = useGetManyListsQuery({
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      listType: ListTypeEnum.Contacts,
      ownerType: GetManyListsOwnerTypeEnum.All,
      pagination: {
        limit: 1000,
      },
    },
  });

  const handleOnSubmit = async () => {
    try {
      if (!listId && !selectedAll) return message.error("Please choose a list");

      if (typeof listId === "string" && itemIds?.[0]) {
        await addManyItemsToListMutation({
          variables: {
            itemIds,
            listId,
          },
        });
        onClose();
      }
      if (typeof listId === "string" && selectedAll) {
        await addManyItemsToListMutation({
          variables: {
            itemIds: [],
            listId,
            addFromContactsQuery: selectedAll ? contactsQuery : {}, // ContactSearchParams,
          },
        });
        onClose();
      }

      message.success("Contacts successfully added to your list");

      navigate(`/app/lists/${listId}`);
    } catch (err: any) {
      message.error(err?.message);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Title style={{ marginBottom: 40 }}>Add to list </Title>
      <FormItem label="Name">
        <Select
          size="large"
          onChange={(newValue) => {
            if (newValue === "viewAll") {
              window.open("/app/lists", "_blank", "rel=noopener noreferrer");
              return undefined;
            }
            setSurveyCampaignId(newValue);
          }}
          style={{ width: "100%" }}
          placeholder="Select a list..."
        >
          {data?.getManyLists?.results?.map((item) => {
            return <Select.Option key={item?.id}>{item?.name}</Select.Option>;
          })}{" "}
          <Select.Option
            key="viewAll"
            value="viewAll"
            style={{
              color: theme.colors?.supportB4,
              display: "flex",
              alignItems: "center",
            }}
          >
            View All Lists <ExportOutlined style={{ marginRight: 8 }} />
          </Select.Option>
        </Select>
      </FormItem>
      <div
        style={{
          padding: "8px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LinkButton onClick={() => refetch()}>
          Refresh lists{" "}
          {networkStatus === 4 ? (
            <SyncOutlined spin style={{ marginLeft: 4 }} />
          ) : (
            <SyncOutlined style={{ marginLeft: 4 }} />
          )}
        </LinkButton>
        {console.log(networkStatus)}
      </div>
      <>
        <Button
          disabled={loading || !listId || (!itemIds?.[0] && !selectedAll)}
          loading={saving}
          onClick={handleOnSubmit}
          style={{ marginTop: 24 }}
        >
          Add to list
        </Button>
      </>
    </Modal>
  );
}
