// TOP LEVEL IMPORTS
import { useEffect, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useWindowWidth } from "@react-hook/window-size";
// COMPONENTS
import Loading from "components/common/Loading";
// ROUTE CONTAINERS
import PublicRoute from "components/route-components/PublicRoute"; // will Navigate them to home screen if signed in
import ProtectedRoute from "components/route-components/ProtectedRoute"; // will Navigate them to login if signed out
import AdminRoute from "components/route-components/AdminRoute"; // will Navigate them to login if signed out
// LAYOUTS
import PublicLayout from "components/layout/PublicLayout";
import AuthenticatedLayout from "components/layout/AuthenticatedLayout";
import AdminLayout from "components/layout/AdminLayout";
// public
import PageNotFound from "routes/public-not-found";
// AUTH
import AuthLoginRoute from "routes/auth-login";
import AuthForgotPassword from "routes/auth-forgot-password";
import ResetPassword from "routes/auth-reset-password";
// // APP
import AppProfileRoute from "routes/app-profile";
import AppDashboard from "routes/app-dashboard";
import AppHelpRoute from "routes/app-help";
import AppSurvey from "routes/app-survey/index3";
import ErrorState from "components/common/ErrorState";

import AppSurveyTemplates from "routes/app-surveytemplates/index2";
import AutomationsCreate from "routes/app-automations-create";
// GLOBAL COMPONENTS
import TodoDetail from "components/common/TodoDetail";
import CaseDetail from "components/common/CaseDetail";
import GlobalDrawer2 from "components/common/GlobalDrawer2";
// import { CaseDetail, TodoDetail } from "components/common/IssueDetail";
// OTHER
import MobileRouter from "./MobileRouter";
// lib
import theme from "lib/theme";
import PublicLegal from "routes/public-legal";
import AppBusinessDetailRoute from "routes/app-detail-business";
import AppContactsDetailRoute from "routes/app-detail-contacts";
import { useCurrentUserQuery } from "generated/graphql";
import AppYourWork from "./app-your-work";
import identifyUser from "lib/helpers/GeneralHelpers/identifyUser";
import AppSearch from "./app-search";
import AuthRedirect from "./auth-redirect";
import BlankRoute from "components/route-components/BlankRoute";
import AppCaseTemplates from "./app-cases-templates";
import AppSurveyCreation from "./app-survey-new";
import PublicSurveyView from "./public-survey-view";
import AppSurveyReport from "./app-survey-report";
import AdminDefaultSurveyForm from "./admin-default-survey-template";
import AdminDefaultSurveyTemplateCreate from "./admin-default-survey-template-create";
import SurveyFromAnswerView from "components/common/SurveyFormAnswerView";
import AppListsPage from "./app-lists";
import AppSurveyCampaigns from "./app-survey-campaigns";
import AdminContactUploader from "./admin-contact-uploader";
import AppEmailCampaigns from "./app-email-campaigns";
import AppEmailCampaignsEditor from "./app-email-campaigns-editor";
import AppEmailCampaignsDetail from "./app-email-campaigns-detail";
import PublicNylasOAuth from "./public-nylas-oauth";
import AppListDetail from "./app-lists-detail";
import DiscoveryRuns from "./app-discovery-runs";
import DiscoveryRunDetail from "./app-discovery-runs-detail";
import PublicUnsubscribe from "./public-email-unsubscribe";

/**
  LAZY LOAD APP COMPONENTS
*/
const AppSiteDetail = lazy(() => import("./app-detail-site"));
const AppNotifications = lazy(() => import("./app-notifications"));
const AppOrganizationDetail = lazy(
  () => import("./app-detail-ecosystem-organizations")
);
const AppSupportDetailRoute = lazy(() => import("./app-detail-site"));
const AppHomeRoute = lazy(() => import("./app-home"));
const AppReportsRoute = lazy(() => import("./app-reports"));
const AppSettingsRoute = lazy(() => import("./app-settings"));
const AppProjectsDetailRoute = lazy(() => import("./app-detail-projects"));

/**
  LAZY LOAD ADMIN COMPONENTS
*/
const AdminUsers = lazy(() => import("./admin-users"));
const AdminHomeRoute = lazy(() => import("./admin-home"));
const AdminCustomersRoute = lazy(() => import("./admin-customers"));
const AdminCustomersDetailRoute = lazy(
  () => import("./admin-customers-detail")
);
const AdminSettingsRoute = lazy(() => import("./admin-settings"));

/**
  GLOBAL DRAWER
*/
const GlobalComponents = ({ currentUser }) => {
  const location = useLocation();
  const { selectedTodo, selectedCase } = queryString.parse(location.search);
  return (
    <>
      {/* <GlobalDrawer currentUser={currentUser} /> */}
      <GlobalDrawer2 currentUser={currentUser} />
      <TodoDetail selectedTodo={selectedTodo} currentUser={currentUser} />
      <CaseDetail selectedCase={selectedCase} currentUser={currentUser} />
    </>
  );
};

export default function AppRoutes(props) {
  const windowWidth = useWindowWidth();

  const { loading, error, data, networkStatus } = useCurrentUserQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    pollInterval: 600000,
  });

  const errorExists = error?.message;

  const currentUser = data?.currentUser;

  useEffect(() => {
    if (currentUser?.id) {
      identifyUser(currentUser.id, currentUser?.email || "");
    }
  }, [currentUser?.id, currentUser?.email]);

  if (loading && networkStatus !== 4 && networkStatus !== 6) {
    return <Loading />;
  }

  if (
    errorExists?.includes("Network error") ||
    error?.message?.includes("Timeout exceeded") ||
    error?.message?.includes("ConnectionTimeout")
  ) {
    return <ErrorState title="We seem to be having trouble connecting..." />;
  }

  // all other errors (that are not in our white listed errors list) just print to screen for now, unless it's a white listed error
  if (
    errorExists &&
    !errorExists.includes("Error writing result to store for query")
  ) {
    return <ErrorState />;
  }

  const defaultProps = {
    ...props,
    currentUser,
    windowWidth,
  };

  // we render a completely different application on mobile
  if (windowWidth <= parseInt(theme.breakpoints.medium)) {
    return (
      <>
        <GlobalComponents {...defaultProps} />
        <MobileRouter {...defaultProps} />
      </>
    );
  }

  return (
    <>
      <GlobalComponents {...defaultProps} />
      <Routes>
        {/* ADMIN */}

        <Route
          path="/admin"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminHomeRoute}
            />
          }
        />
        <Route
          path="/admin/customers"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminCustomersRoute}
            />
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminUsers}
            />
          }
        />
        <Route
          path="/admin/settings"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminSettingsRoute}
            />
          }
        />
        <Route
          path="/admin/defaultSurveyTemplates"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminDefaultSurveyForm}
            />
          }
        />
        <Route
          path="/admin/contact/uploader"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminContactUploader}
            />
          }
        />
        <Route
          path="/admin/defaultSurveyTemplates/create"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminDefaultSurveyTemplateCreate}
            />
          }
        />
        <Route
          path="/admin/customers/:id"
          element={
            <AdminRoute
              exact
              layout={AdminLayout}
              currentUser={currentUser}
              component={AdminCustomersDetailRoute}
            />
          }
        />
        {/* HOME APP ROUTES */}
        <Route
          path="/app/cases/templates"
          element={
            <ProtectedRoute
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppCaseTemplates}
            />
          }
        />

        {[
          "/app/cases",
          "/app/resources",
          "/app/projects",
          "/app/businesses",
          "/app/contacts",
        ]?.map((path) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                <ProtectedRoute
                  layout={AuthenticatedLayout}
                  currentUser={currentUser}
                  component={AppHomeRoute}
                />
              }
            />
          );
        })}

        <Route
          path="/app/discovery/runs/:runId"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={DiscoveryRunDetail}
            />
          }
        />
        <Route
          path="/app/discovery/runs"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={DiscoveryRuns}
            />
          }
        />
        <Route
          path="/app/help"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppHelpRoute}
            />
          }
        />
        <Route
          path="/app/your-work"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppYourWork}
            />
          }
        />
        <Route
          path="/app/surveys/templates"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurveyTemplates}
            />
          }
        />
        <Route
          path="/app/surveys/campaigns/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurveyCampaigns}
            />
          }
        />
        <Route
          path="/app/surveys/campaigns"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurveyCampaigns}
            />
          }
        />

        <Route
          path="/app/notifications"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppNotifications}
            />
          }
        />
        <Route
          path="/app/dashboard"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppDashboard}
            />
          }
        />
        <Route
          path="/app/survey/report"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurveyReport}
            />
          }
        />
        <Route
          path="/app/projects/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppProjectsDetailRoute}
            />
          }
        />
        <Route
          path="/app/organizations/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppOrganizationDetail}
            />
          }
        />
        <Route
          path="/app/email-campaigns/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppEmailCampaignsDetail}
            />
          }
        />
        <Route
          path="/app/email-campaigns/editor/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppEmailCampaignsEditor}
              showHeader={false}
            />
          }
        />
        <Route
          path="/app/email-campaigns"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppEmailCampaigns}
            />
          }
        />

        <Route
          path="/app/search"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSearch}
            />
          }
        />
        <Route
          path="/app/support/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSupportDetailRoute}
            />
          }
        />
        <Route
          path="/app/automations/create"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AutomationsCreate}
            />
          }
        />
        <Route
          path="/app/sites/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSiteDetail}
            />
          }
        />
        <Route
          path="/app/survey/"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurvey}
            />
          }
        />
        <Route
          path="/app/profile"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppProfileRoute}
            />
          }
        />
        <Route
          path="/app/lists/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppListDetail}
            />
          }
        />
        <Route
          path="/app/lists"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppListsPage}
            />
          }
        />
        <Route
          path="/app/contacts/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppContactsDetailRoute}
            />
          }
        />
        <Route
          path="/app/survey/create"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppSurveyCreation}
            />
          }
        />
        <Route
          path="/app/survey/view/result"
          element={
            <ProtectedRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={SurveyFromAnswerView}
            />
          }
        />
        <Route
          path="/app/businesses/:id"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppBusinessDetailRoute}
            />
          }
        />
        <Route
          path="/app/reports"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              component={AppReportsRoute}
            />
          }
        />
        <Route
          path="/app/settings"
          element={
            <ProtectedRoute
              exact
              layout={AuthenticatedLayout}
              currentUser={currentUser}
              path="/app/settings"
              component={AppSettingsRoute}
            />
          }
        />
        {/*  PUBLIC ROUTES */}

        <Route
          path="/forgot-password"
          element={
            <PublicRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={AuthForgotPassword}
            />
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <PublicRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={ResetPassword}
            />
          }
        />
        <Route
          path="/set-password/:token"
          element={
            <PublicRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={ResetPassword}
            />
          }
        />

        <Route
          path="/legal/:page"
          element={
            <PublicRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={PublicLegal}
            />
          }
        />
        <Route
          path="/app/survey/view"
          element={
            <BlankRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={PublicSurveyView}
            />
          }
        />

        <Route
          path="/actions/email-unsubscribe"
          element={
            <BlankRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={PublicUnsubscribe}
            />
          }
        />
        <Route
          path="/oauth/nylas/callback"
          element={
            <BlankRoute
              exact
              layout={PublicLayout}
              currentUser={currentUser}
              component={PublicNylasOAuth}
            />
          }
        />
        {["/integration-auth/:service", "/cc-auth"]?.map((path) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                <BlankRoute
                  exact
                  component={AuthRedirect}
                  currentUser={currentUser}
                  layout={PublicLayout}
                />
              }
            />
          );
        })}

        {["/", "/login"]?.map((path) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                <PublicRoute
                  exact
                  layout={PublicLayout}
                  currentUser={currentUser}
                  component={AuthLoginRoute}
                />
              }
            />
          );
        })}

        <Route
          element={
            <PublicLayout>
              <PageNotFound />
            </PublicLayout>
          }
        />
      </Routes>
    </>
  );
}
