import React from "react";
import AntdTable from "antd/lib/table";
import "antd/lib/table/style/css";
import Pagination from "antd/lib/pagination";
import "antd/lib/pagination/style/css";
import styled from "styled-components";
import TablePrimaryCol from "components/text/TablePrimaryCol";
import moment from "moment";
import BusinessLogo from "components/common/BusinessLogo";
import Caption from "components/text/Caption";
import Text from "components/text/Text";

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  /* width: 100%;
  border-radius: 5px;
 
  .ant-table-thead {
    background: #fbfafa !important;
    border-bottom-color: transparent !important;
  }
  .ant-table-column-title {
    text-transform: uppercase;
    width: 143px;
    height: 22px;
    font-family: ${(p) => p.theme.fontFamily};
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.04px;
    color: #9fb3c8 !important;
  } */

  border-radius: 5px;

  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
  .ant-table-thead > tr > th {
    color: ${(p) => p.theme.colors.neutral5};
    font-weight: 600;
  }

  td {
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 500;
    color: ${(p) => p.theme.colors.neutral4};
  }

  th {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 13px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-pagination-item {
    a {
      color: ${(p) => p.theme.colors.neutral6} !important;
    }
  }
  .ant-pagination-item-active {
    a {
      color: ${(p) => p.theme.colors.primary3} !important;
      font-weight: 700 !important;
    }
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: ${(p) => p.theme.colors.primary3} !important;
  }
`;

const ResultsCountText = styled.span`
  display: inline-flex;
  margin-right: 24px;
  align-items: center;
`;

const ResultsCount = ({ pagination }) => {
  const amountShown = pagination?.current * pagination?.pageSize;
  return (
    <ResultsCountText>
      {pagination?.current * pagination?.pageSize - pagination?.pageSize + 1} -
      {amountShown <= pagination?.total ? amountShown : pagination?.total} of{" "}
      {pagination?.total} Results
    </ResultsCountText>
  );
};

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export interface TableCol {
  sorter?: boolean;
  field: string;
  title: string;
  render: any;
  key?: string;
  dataIndex?: string;
}

const NAText = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral7};
  font-size: 12px;
`;

export const renderBusinessNameColumn = ({ title, logo, id }) => (
  <NameContainer key={id} data-testid={`business-table-${title}`}>
    <div style={{ marginRight: 6 }}>
      <BusinessLogo src={logo?.url} alt={logo?.filename} width="32" />
    </div>
    <TablePrimaryCol>{title}</TablePrimaryCol>
  </NameContainer>
);

export const formatDateColumn = (dateString) => {
  // Check if dateString is not null and is a number
  if (dateString && !isNaN(dateString)) {
    const dateMoment = moment(parseInt(dateString, 10));

    // Use moment's isValid() function to check if the date is valid
    if (dateMoment.isValid()) {
      return <Text>{dateMoment.format("MMM DD, YYYY")}</Text>;
    }
  }

  // If dateString is null, not a number, or not a valid date, return "N/A"
  return <NAText>N/A</NAText>;
};

export const renderDefaultColumn = (text) =>
  typeof text !== "object" ? (
    <div>{text ? <Text>{text}</Text> : <NAText>N/A</NAText>}</div>
  ) : (
    <NAText>N/A</NAText>
  );

export default function Table({ pagination, onSortChange, ...args }: any) {
  const handleTableChange = (pagination, filters, sorter) => {
    let newSortParams;

    // Check for 'columnKey' instead of 'field'
    if (sorter?.column?.field && sorter.order) {
      newSortParams = [
        {
          field: sorter?.column?.field,
          order: sorter.order === "ascend" ? "ASC" : "DESC",
        },
      ];
    } else {
      newSortParams = undefined; // Reset to undefined to indicate no sorting
    }

    onSortChange(newSortParams);
  };
  return (
    <>
      <TableWrapper>
        <AntdTable onChange={handleTableChange} {...args} pagination={false} />
      </TableWrapper>
      {!args.loading && pagination && (
        <PaginationWrapper>
          {pagination && <ResultsCount pagination={pagination} />}
          <Pagination
            showSizeChanger={pagination?.showSizeChanger || false}
            {...pagination}
            onChange={pagination?.onChange || args.onChange}
          />
        </PaginationWrapper>
      )}
    </>
  );
}
