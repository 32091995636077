import { useState } from "react";
import styled from "styled-components";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { CardAvatar } from "components/detail-components";
import { useContactsQuery } from "generated/graphql";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import theme from "lib/theme";

const { Option } = Select;

const OptText = styled.div`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;

const OptContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral10};
`;

export default function ContactInputSimple({
  value,
  placeholder,
  onChange,
  defaultContactId,
  businessId,
  onBlur,
  ...args
}: {
  value?: string;
  placeholder?: string;
  onChange?: any;
  defaultContactId?: string;
  businessId?: string;
  onBlur?: any;
}) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selectedContact, setSelectedContact] = useState<any>(undefined);
  const { data, loading } = useContactsQuery({
    variables: {
      limit: 10,
      params: {
        businessId,
        searchText,
        includesAtleastContactIds: value ? [value] : [],
        contactIds:
          !searchText && defaultContactId ? [defaultContactId] : undefined,
      },
    },
  });

  const contacts = data?.contacts?.contacts
    ? [...data?.contacts?.contacts]
    : [selectedContact];
  return (
    <DropdownStyleWrapper>
      <Select
        onBlur={onBlur}
        data-testid={args?.["data-testid"] || "contact-select-input-simple"}
        value={!loading && value ? (value as string) : undefined}
        placeholder={placeholder || "Type to search for people..."}
        onSearch={(value) => setSearchText(value)}
        showSearch
        allowClear
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        optionLabelProp="label"
        onChange={(value) => {
          if (value === "clear") {
            return onChange(undefined);
          }
          setSelectedContact(contacts?.find((biz) => biz.id === value));
          onChange(value);
        }}
      >
        {contacts?.map((item) => {
          if (!item?.id) return null;
          return (
            <Option
              key={item.id}
              label={getFullName(item)}
              value={item.id}
              data-testid={`contact-select-input-option-${getFullName(item)}`}
            >
              <OptContainer>
                <div style={{ width: 40 }}>
                  <CardAvatar
                    type="contacts"
                    src={item?.avatar?.url || undefined}
                  />{" "}
                </div>
                <div>
                  {" "}
                  <OptText>{getFullName(item)}</OptText>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      margin: 0,
                      color: theme.colors.neutral5,
                    }}
                  >
                    {item.email}
                  </span>
                </div>
              </OptContainer>
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
}
