//   logEvent: async (name, meta: LogEventMetaData = {}) => {

import client from "ApolloClient/index";
import { EventLogKeyEnum, TrackEventDocument } from "generated/graphql";
import config from "lib/config";
import { get } from "lodash";
import * as Sentry from "@sentry/browser";

export default async function logEvent(key: EventLogKeyEnum, meta = {}) {
  await client.mutate({
    mutation: TrackEventDocument,
    variables: {
      key,
      content: key,
      meta: JSON.stringify(meta),
    },
  });
  // only log in production
  if (config.NODE_ENV === "production") {
    const mixpanel = get(window, "mixpanel") as any;
    const FS = get(window, "FS") as any;

    const finalMetaData = { ...meta };

    (mixpanel as any)?.track(key, finalMetaData);

    (FS as any)?.event(key, finalMetaData);

    Sentry?.addBreadcrumb({
      category: key,
      message: key,
      data: finalMetaData,
    });

    //
  } else {
    // if on staging or local dev, just console log it
    console.log(key, meta);
  }
}
