import { useUnsubscribeOneContactFromEmailCampaignMutation } from "generated/graphql";
import { useLocation } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import Loading from "../../components/common/Loading";
import styled from "styled-components";
import constants from "lib/constants";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const ResultContainer = styled.div`
  text-align: center;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const ResultIcon = styled.div`
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
`;

const ResultTitle = styled.h1`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
`;

const ResultSubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
`;

const Result = ({ status, title, subTitle, extra }) => (
  <ResultContainer>
    <ResultIcon>
      {status === "success" ? "✅" : status === "error" ? "❌" : ""}
    </ResultIcon>
    <ResultTitle>{title}</ResultTitle>
    <ResultSubTitle>{subTitle}</ResultSubTitle>
    {extra}
  </ResultContainer>
);

export default function PublicUnsubscribe() {
  const [unsubscribeOneContactFromEmailCampaign, { loading }] =
    useUnsubscribeOneContactFromEmailCampaignMutation();
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const location = useLocation();

  const finishGrant = useCallback(
    async (email: string, customerId: string) => {
      try {
        if (!email) throw new Error("Email is required");
        if (!customerId) throw new Error("Customer ID is required");
        const result = await unsubscribeOneContactFromEmailCampaign({
          variables: {
            email: decodeURIComponent(email),
            customerId,
          },
        });
        if (result?.data?.unsubscribeOneContactFromEmailCampaign?.success) {
          setStatus("success");
        } else {
          throw new Error(
            `Something went wrong while trying to unsubscribe you. Please reach out to support at ${constants.supportEmail}`
          );
        }
      } catch (err: any) {
        setStatus("error");
        setErrorMessage(err?.message || "An unexpected error occurred");
      }
    },
    [unsubscribeOneContactFromEmailCampaign]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const customerId = searchParams.get("customerId");

    if (email && customerId) {
      finishGrant(email, customerId);
    } else {
      setStatus("error");
      setErrorMessage("Missing email or customer ID");
    }
  }, [location.search, finishGrant]);

  return (
    <StyledContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          {status === "success" && (
            <Result
              status="success"
              title="Successfully Unsubscribed"
              subTitle="You have been successfully unsubscribed from our email campaign."
              extra={null}
            />
          )}
          {status === "error" && (
            <Result
              status="error"
              title="Unsubscribe Failed"
              subTitle={
                errorMessage || "An error occurred while unsubscribing."
              }
              extra={null}
            />
          )}
          {status === null && <Loading />}
        </>
      )}
    </StyledContainer>
  );
}
