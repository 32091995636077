import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  z-index: 9000;
  height: 100vh;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.45);
  .ant-select-dropdown {
    z-index: 14000 !important;
  }
`;

export const Modal = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  width: 480px;
  border-radius: 5px;
  position: relative;
  max-height: 100vh;
  overflow-y: scroll;
  min-height: 400px;
  border-radius: 5px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
  }
  .ant-select-dropdown {
    z-index: 14000 !important;
  }
`;

export const ModalBody = styled.div`
  padding: 48px 32px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 32px 16px 112px 16px;
  }
`;

export const ModalText = styled.p`
  font-size: 16px !important;
  label {
    font-size: 16px !important;
  }
  strong {
    font-size: 16px !important;
  }
`;

export const ButtonArea = styled.div<{ style?: React.CSSProperties }>`
  background-color: ${({ theme, style }) =>
    style?.backgroundColor ? style?.backgroundColor : theme.colors.neutral11};
  height: 10%;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: ${({ style }) => (style?.height ? style?.height : "80px")};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: ${({ style }) => style?.justifyContent || "space-between"};
  padding: 8px 32px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    position: fixed;
    padding: 8px;
  }
`;

export const ModalTitle = styled.h2`
  font-size: 20px;
  line-height: 150%;
  display: block;
  font-weight: 600;
  margin-bottom: 32px;
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral2};
  line-height: 125%;
  text-align: center;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;
