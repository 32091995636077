const openToCommunication = (optResult: boolean | null | undefined) => {
  if (optResult === false) {
    return "Yes";
  }
  if (optResult === true) {
    return "No";
  }
  return "Unkown";
};

export default openToCommunication;
