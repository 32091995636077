// TOP LEVEL IMPORTS
import styled, { keyframes } from "styled-components";
// COMPONENTS
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "components/common/Button";

import CreateNewWindow from "./CreateNewWindow";

import { fadeInUp, fadeIn } from "react-animations";
import SectionHeader from "components/text/SectionHeader";
import { useQueryParam, BooleanParam } from "use-query-params";

const animation = keyframes`${fadeInUp}`;

const fadeInAnimation = keyframes`${fadeIn}`;

const ModalOverlay = styled.div`
  background: rgba(9, 30, 66, 0.54);
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 0px;
  overflow-y: scroll;
  animation: 0.25s ${fadeInAnimation};
`;

const CloseBtn = styled(CloseOutlined)`
  font-size: 18px;
  padding: 16px;
  color: ${(p) => p.theme.colors.neutral5};
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  border-radius: 5px;
  width: 830px;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
  animation: 0.25s ${animation};
  padding-bottom: 24px;
`;
const LineBreak = styled.div`
  height: 2px;
  background-color: ${(p) => p.theme.colors.neutral10};
`;
const ModalBody = styled.div`
  padding: 16px;
`;

export default function AddButton() {
  const [open, setCreateItem] = useQueryParam(
    "showCreateItemMenu",
    BooleanParam
  );

  return (
    <>
      {open && (
        <ModalOverlay>
          <ModalContent>
            <ModalBody>
              <CloseBtn onClick={() => setCreateItem(undefined)} />
              <SectionHeader>Create a New Item</SectionHeader>
            </ModalBody>
            <LineBreak />
            <CreateNewWindow onClose={() => setCreateItem(undefined)} />
          </ModalContent>
        </ModalOverlay>
      )}

      <Button
        onClick={() => setCreateItem(true)}
        data-testid="create-new-item-btn"
        secondary
      >
        <PlusOutlined style={{ marginRight: 6 }} /> Create New Item
      </Button>
    </>
  );
  // return (
  //   <DrawerContext.Consumer>
  //     {(context: any) => {
  //       return (
  //         <Popover
  //           content={
  //             <div style={{ width: 100 }}>
  //               {/* <GlobalAddItem
  //                 onClick={() =>
  //                   onUrlChange({ drawerVisible: formTypes.TODOS })
  //                 }
  //               >
  //                 <Icon src={checkSVG} alt="todo-icon" /> Todo
  //               </GlobalAddItem> */}
  //               {helpers.featureIsOn({
  //                 customer: currentUser.customer,
  //                 feature: CustomerFeatureSettingKeyEnum.Cases,
  //               }) && (
  //                 <GlobalAddItem
  //                   onClick={() =>
  //                     onUrlChange({ drawerVisible: formTypes.CASES })
  //                   }
  //                 >
  //                   {" "}
  //                   <BriefcaseFilled
  //                     style={{ marginTop: 2, marginRight: 8 }}
  //                     height={18}
  //                     width={18}
  //                     fill={theme.colors.neutral8}
  //                   />{" "}
  //                   Case
  //                 </GlobalAddItem>
  //               )}
  //               {helpers.featureIsOn({
  //                 customer: currentUser.customer,
  //                 feature: CustomerFeatureSettingKeyEnum.Surveys,
  //               }) && (
  //                 <GlobalAddItem onClick={() => navigate(`/app/survey`)}>
  //                   <Icon
  //                     src={surveySVG}
  //                     style={{ marginTop: 2, marginRight: 8 }}
  //                   />{" "}
  //                   Forms
  //                 </GlobalAddItem>
  //               )}
  //               {helpers.featureIsOn({
  //                 customer: currentUser.customer,
  //                 feature: CustomerFeatureSettingKeyEnum.Projects,
  //               }) && (
  //                 <GlobalAddItem
  //                   onClick={() => context.setDrawerVisible(formTypes.PROJECTS)}
  //                 >
  //                   {" "}
  //                   <Icon src={clipboardSVG} alt="project-icon" /> Project
  //                 </GlobalAddItem>
  //               )}
  //               <GlobalAddItem
  //                 onClick={() =>
  //                   onUrlChange({ drawerVisible: formTypes.BUSINESSES })
  //                 }
  //               >
  //                 <Icon
  //                   src={userGroupSVG}
  //                   alt="business-icon"
  //                   style={{
  //                     position: "relative",
  //                     top: -3,
  //                   }}
  //                 />{" "}
  //                 Business
  //               </GlobalAddItem>
  //             </div>
  //           }
  //         >
  //           <GlobalAdd>
  //             <PlusOutlined />
  //           </GlobalAdd>
  //         </Popover>
  //       );
  //     }}
  //   </DrawerContext.Consumer>
  // );
}
