import * as Sentry from "@sentry/browser";

export default function identifyUser(userId: string, email?: string) {
  if (!userId) {
    console.log(
      "userId is required to identify a user in mixpanel or fullstory"
    );
    return;
  }
  const mixpanel: any = (window as any).mixpanel;
  const FS: any = (window as any).FS;

  if (mixpanel) {
    mixpanel.identify(userId);
  }
  if (FS) {
    FS.identify(userId);
  }

  if (Sentry) {
    Sentry?.setUser({ id: userId, email });
  }
}
